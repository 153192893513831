import React from "react";
import { Typography, Grid, Button } from "@mui/material";
import { Box } from "@mui/system";
import DateRangeIcon from "@mui/icons-material/DateRange";
import { NavLink } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
import RemoveIcon from "@mui/icons-material/Remove";
import { SupportRunningProjectData } from "../../ApiIntegration/SupportPageComponent/SupportProjectPage";

export const PastInitativesBottom = () => {
  const [displayedItems, setDisplayedItems] = useState(3);

  const [pastsupportprojectsData, setPastsupportprojectsData] = React.useState(
    []
  );

  React.useEffect(() => {
    const SupportrunningprojectsDataAsync = async () => {
      try {
        const apiData = await SupportRunningProjectData();
        setPastsupportprojectsData(apiData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    SupportrunningprojectsDataAsync();
  }, []);

  const handleShowMore = () => {
    setDisplayedItems(pastsupportprojectsData.length);
  };
  const handleShowLess = () => {
    setDisplayedItems(3);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "100px",
      }}
    >
      <Box>
        <Typography
          variant="h4"
          sx={{
            fontWeight: "bold",
            textAlign: "center",
            marginTop: "5px",
            marginBottom: "10px",
            fontSize: {
              xs: "14px",
              sm: "15px",
              md: "16px",
              lg: "18px",
              xl: "18px",
            },
            fontFamily: "var(--primary-font)",
            color: "var(--theme-color)",
          }}
        >
          COMPLETED PROJECTS
        </Typography>
        <Typography
          variant="h4"
          sx={{
            textAlign: "center",
            fontSize: {
              xs: "23px",
              sm: "24px",
              md: "26px",
              lg: "29px",
              xl: "30px",
            },
            fontFamily: "var(--primary-font)",
            color: "var(--light-black)",
            marginBottom: "50px",
          }}
        >
          WE HAVE SUCCESSFULLY MADE POSITIVE IMPACT
        </Typography>
      </Box>
      {/* end of title */}
      {/* start of grid */}
      <Box
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container spacing={3}>
          {pastsupportprojectsData
            ?.slice(0, displayedItems)
            .map((item, index) => (
              <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={index}>
                <Box>
                  <Box
                    sx={{
                      width: "100%",
                      height: "47vh",
                    }}
                  >
                    <img
                      src={item.featured_image}
                      alt="..."
                      style={{
                        height: "100%",
                        width: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                    <Box
                      sx={{
                        position: "relative",
                        width: "10%",
                        height: "auto",
                        backgroundColor: "var(--yellow)",
                        padding: "25px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        bottom: "30px",
                        left: "78%",
                        border: "5px solid var(--white)",
                      }}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "./assets/handshake.png"}
                        alt=""
                        style={{
                          width: "80%",
                          height: "auto",
                          zIndex: "1",
                          position: "absolute",
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      marginTop: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        margin: "10px 0px 10px 0px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <DateRangeIcon
                        fontSize="medium"
                        sx={{
                          color: "var(--theme-color)",
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          color: "var( --light-black)",
                          fontSize: {
                            xs: "11px",
                            sm: "12px",
                            md: "13px",
                            lg: "14px",
                            xl: "14px",
                          },
                          fontFamily: "var(--primary-font)",
                          marginLeft: "10px",
                        }}
                      >
                        {item.start_date}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        margin: "10px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <RemoveIcon fontSize="small" />
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",

                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <DateRangeIcon
                        fontSize="medium"
                        sx={{
                          color: "var(--theme-color)",
                        }}
                      />
                      <Typography
                        variant="body1"
                        sx={{
                          color: "var( --light-black)",
                          fontSize: {
                            xs: "11px",
                            sm: "12px",
                            md: "13px",
                            lg: "14px",
                            xl: "14px",
                          },
                          fontFamily: "var(--primary-font)",
                          marginLeft: "10px",
                        }}
                      >
                        {item.end_date}
                      </Typography>
                    </Box>
                  </Box>
                  {/* end of date and time */}

                  {/* start od lines */}
                  <Box
                    sx={{
                      marginTop: "10px",
                      marginBottom: "20px",
                      width: "100%",
                    }}
                  >
                    <Box className="horizontal-line"></Box>
                    <Box className="horizontal-line"></Box>
                    <Box className="horizontal-line"></Box>
                  </Box>

                  {/* end of lines */}

                  {/* start of data */}
                  <Box>
                    <Typography
                      variant="h3"
                      sx={{
                        width: "100%",
                        textAlign: "justify",
                        marginTop: "5px",
                        marginBottom: "20px",
                        fontSize: {
                          xs: "17px",
                          sm: "18px",
                          md: "20px",
                          lg: "23px",
                          xl: "20px",
                        },
                        fontFamily: "var(--primary-font)",
                        color: "var(--black)",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        width: "100%",
                        textAlign: "justify",
                        marginTop: "5px",
                        marginBottom: "20px",
                        fontSize: {
                          xs: "11px",
                          sm: "12px",
                          md: "13px",
                          lg: "14px",
                          xl: "14px",
                        },
                        fontFamily: "var(--primary-font)",
                        color: "var( --light-black)",
                        overflow: "hidden", // Hide any overflowing text
                        textOverflow: "ellipsis", // Display ellipsis for long texts
                        display: "-webkit-box",
                        WebkitLineClamp: 3, // Adjust the number of lines to fit your requirements
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {item.description}
                    </Typography>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      justifyContent: "flex-start", // Align NavLink to the left
                      alignItems: "flex-start",
                      width: "100%",
                    }}
                  >
                    <NavLink
                      to={`/pastinitatives/${item.id}`}
                      style={{ color: "var(--theme-color)" }}
                    >
                      Read More
                    </NavLink>
                  </Box>
                </Box>

                {/* end of data */}
              </Grid>
            ))}
        </Grid>
      </Box>
      {displayedItems < pastsupportprojectsData.length && (
        <Button
          variant="outlined"
          sx={{
            color: "var(--theme-color)",
            border: "1px solid var(--theme-color)",
            width: {
              xs: "100%",
              sm: "100%",
              md: "15%",
              lg: "15%",
              xl: "15%",
            },
            display: "flex",
            justifyContent: "center",
            textTransform: "capitalize",
            alignItems: "center",
            margin: "auto",
            marginTop: "50px",
            marginBottom: "50px",
            fontSize: {
              xs: "11px",
              sm: "11px",
              md: "13px",
              lg: "15px",
              xl: "15px",
            },
            "&:hover": {
              backgroundColor: "var(--theme-color)",
              color: "white",
              border: "1px solid var(--theme-color)",
              transform: "scale(1.1)",
            },
          }}
          onClick={handleShowMore}
        >
          Show More
          <KeyboardArrowDownIcon />
        </Button>
      )}
      {displayedItems > 3 && (
        <Button
          variant="outlined"
          sx={{
            color: "var(--theme-color)",
            border: "1px solid var(--theme-color)",
            textTransform: "capitalize",
            width: {
              xs: "100%",
              sm: "100%",
              md: "15%",
              lg: "15%",
              xl: "15%",
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "50px",
            marginBottom: "50px",
            fontSize: {
              xs: "11px",
              sm: "11px",
              md: "13px",
              lg: "15px",
              xl: "15px",
            },
            "&:hover": {
              backgroundColor: "var(--theme-color)",
              color: "var(--white)",
              border: "1px solid var(--theme-color)",
              transform: "scale(1.1)",
            },
          }}
          onClick={handleShowLess}
        >
          Show Less
          <KeyboardArrowUpIcon />
        </Button>
      )}
    </Box>
  );
};
