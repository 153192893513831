import React from "react";
import { TopSection } from "../../components/Shared/TopSection/TopSection";
import { Box } from "@mui/system";
import { Container, Skeleton } from "@mui/material";
import { DharmaGoodSearch } from "../../components/DharmaGoodPage/DharmaGoodSearch";
import { Subscribe } from "../../components/Shared/Subscribe/Subscribe";
import useAPI from "../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { endpoint } from "../../constant/endpoint";

export const DharmaGoodPage = () => {
  const { get, getCategoriesdataById } = useAPI();

  const getCategoriesData = async () => {
    const response = await get(endpoint.dharmas);
    return response.data;
  };

  const { data: categories } = useQuery({
    queryKey: ["categories"],
    queryFn: getCategoriesData,
  });

  const DharmaGoodData = categories?.filter(
    (item) => item.name === "Dharma Goods"
  );

  const Name = DharmaGoodData?.map((item) => item.name);
  const id = DharmaGoodData?.map((item) => item.id);

  const getDharmaGoodSubcategoriesData = async () => {
    const response = await getCategoriesdataById(endpoint.subcategories, 1);
    return response.data;
  };

  const { isDataLoading, data: dharmagoodcategories } = useQuery({
    queryKey: [["dharmagoodcategories"], id],
    queryFn: getDharmaGoodSubcategoriesData,
  });

  const DharmaGoodDataLength = dharmagoodcategories?.length;


  if (isDataLoading) {
    return (
      <>
        <Skeleton variant="text" width="100%" height="20px" />
        <Skeleton variant="text" width="100%" height="20px" />
      </>
    );
  }

  return (
    <Box>
      {DharmaGoodData?.map((item, index) => {
        return (
          <div key={index}>
            <TopSection
              title={item.name}
              description={item.description}
              id={item.id}
              image={item.image}
            />
            <Container>
              <DharmaGoodSearch
                DharmaGoodDataLength={DharmaGoodDataLength}
                Name={Name}
                id={id}
              />
              <Subscribe />
            </Container>
          </div>
        );
      })}
    </Box>
  );
};
