import React from "react";
import { Box } from "@mui/system";
import { Container } from "@mui/material";
import { TopSection } from "../../components/Shared/TopSection/TopSection";
import { DonationForm } from "../../components/DonationPageComp/DonationForm";
import { Subscribe } from "../../components/Shared/Subscribe/Subscribe";
import useAPI from "../../hooks/useApi";
import { endpoint } from "../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";
import { ArtistDonationTopDetail } from "../../components/DonationPageComp/ArtistDonationTopDetail";

export const DonationPage = () => {
  const { get } = useAPI();

  const getArtistDonationData = async () => {
    const response = await get(endpoint.artistdonation);
    return response.data;
  };

  const { isLoading, data: artistDonationData } = useQuery({
    queryKey: ["artistDonationData"],
    queryFn: getArtistDonationData,
  });

  if (isLoading) return <div>Loading...</div>;

  return (
    <Box>
      <Box>
        <TopSection
          image={artistDonationData?.top_image}
          title={artistDonationData?.top_title}
          description={artistDonationData?.top_description}
        />
      </Box>

      <Container>
        <ArtistDonationTopDetail artistDonationData={artistDonationData} />
        <DonationForm />
        <Box
          sx={{
            marginTop: "80px",
          }}
        >
          <Subscribe />
        </Box>
      </Container>
    </Box>
  );
};
