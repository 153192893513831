import React from "react";
import { Box } from "@mui/system";
import { Typography, Container } from "@mui/material";
import { invoiceData } from "../../Data/InvoiceData";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--invoice--color)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    backgroundColor: "var(--invoice--color--lighter)",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const hr_line = {
  width: "100%",
  height: "2px",
  backgroundColor: "var(--invoice--color)",
  marginTop: "10px",
  marginBottom: "10px",
};

export const Invoice = () => {
  return (
    <Container
      sx={{
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
      }}
    >
      {invoiceData.map((item, key) => {
        return (
          <Box
            sx={{
              marginTop: "80px",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-start",
            }}
            key={key}
          >
            {item.CompanyDetails.map((company, key) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                  key={key}
                >
                  <Typography
                    variant="h4"
                    sx={{
                      color: "var(--invoice--color)",
                      fontSize: {
                        xs: "15px",
                        sm: "15px",
                        md: "20px",
                        lg: "20px",
                        xl: "35px",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    {company.Companyname}
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      //   alignItems: "flex-start",

                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        marginTop: "30px",
                        gap: "80px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="span"
                          sx={{
                            color: "var(--black)",
                            fontSize: {
                              xs: "10px",
                              sm: "10px",
                              md: "15px",
                              lg: "15px",
                              xl: "15px",
                            },
                            fontWeight: "bold",
                          }}
                        >
                          {company.Buildingname}
                        </Typography>
                        <Typography
                          variant="span"
                          sx={{
                            color: "var(--black)",
                            fontSize: {
                              xs: "10px",
                              sm: "10px",
                              md: "15px",
                              lg: "15px",
                              xl: "15px",
                            },
                            fontWeight: "bold",
                            marginTop: "5px",
                          }}
                        >
                          {company.Street}
                        </Typography>

                        <Typography
                          variant="span"
                          sx={{
                            color: "var(--black)",
                            fontSize: {
                              xs: "10px",
                              sm: "10px",
                              md: "15px",
                              lg: "15px",
                              xl: "15px",
                            },
                            fontWeight: "bold",
                            marginTop: "5px",
                          }}
                        >
                          {company.City}, {company.State}, {company.Country}
                        </Typography>
                        <Typography
                          variant="span"
                          sx={{
                            color: "var(--black)",
                            fontSize: {
                              xs: "10px",
                              sm: "10px",
                              md: "15px",
                              lg: "15px",
                              xl: "15px",
                            },
                            fontWeight: "bold",
                            marginTop: "5px",
                          }}
                        >
                          {company.Zipcode}
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "flex-start",
                        }}
                      >
                        <Typography
                          variant="span"
                          sx={{
                            color: "var(--black)",
                            fontSize: {
                              xs: "10px",
                              sm: "10px",
                              md: "15px",
                              lg: "15px",
                              xl: "15px",
                            },
                            fontWeight: "bold",
                          }}
                        >
                          {company.contactNumber}
                        </Typography>
                        <Typography
                          variant="span"
                          sx={{
                            color: "var(--black)",
                            fontSize: {
                              xs: "10px",
                              sm: "10px",
                              md: "15px",
                              lg: "15px",
                              xl: "15px",
                            },
                            fontWeight: "bold",
                            marginTop: "5px",
                          }}
                        >
                          {company.email}
                        </Typography>
                        <Typography
                          variant="span"
                          sx={{
                            color: "var(--black)",
                            fontSize: {
                              xs: "10px",
                              sm: "10px",
                              md: "15px",
                              lg: "15px",
                              xl: "15px",
                            },
                            fontWeight: "bold",
                            marginTop: "5px",
                          }}
                        >
                          {company.website}
                        </Typography>
                      </Box>
                    </Box>
                    <Box>
                      <img src={company.logo} alt="..." />
                    </Box>
                  </Box>
                </Box>
              );
            })}

            {item.CustomerDetails.map((customer, key) => {
              return (
                <Box
                  sx={{
                    marginTop: "30px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                  key={key}
                >
                  <Typography
                    variant="span"
                    sx={{
                      color: "var(--invoice--color)",
                      fontSize: {
                        xs: "10px",
                        sm: "10px",
                        md: "15px",
                        lg: "15px",
                        xl: "15px",
                      },
                      fontWeight: "bold",
                    }}
                  >
                    {" "}
                    BILLED TO
                  </Typography>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      marginTop: "10px",
                    }}
                  >
                    <Typography
                      variant="span"
                      sx={{
                        color: "var(--black)",
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        fontWeight: "bold",
                      }}
                    >
                      {customer.Customername}
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        color: "var(--black)",
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        fontWeight: "bold",
                        marginTop: "5px",
                      }}
                    >
                      {customer.street}
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        color: "var(--black)",
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        fontWeight: "bold",
                        marginTop: "5px",
                      }}
                    >
                      {customer.city}, {customer.state}, {customer.country}
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        color: "var(--black)",
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        fontWeight: "bold",
                        marginTop: "5px",
                      }}
                    >
                      {customer.zipcode}
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        color: "var(--black)",
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        fontWeight: "bold",
                        marginTop: "5px",
                      }}
                    >
                      {customer.contactNumber}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
            {item.InvoiceDetails.map((invoice, key) => {
              return (
                <Box
                  sx={{
                    marginTop: "50px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                  key={key}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      variant="h4"
                      sx={{
                        color: "var(--invoice--color)",
                        fontSize: {
                          xs: "15px",
                          sm: "15px",
                          md: "20px",
                          lg: "20px",
                          xl: "35px",
                        },
                        fontWeight: "bold",
                      }}
                    >
                      Invoice
                    </Typography>

                    <Typography
                      variant="span"
                      sx={{
                        color: "var(--invoice--color)",
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        fontWeight: "bold",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginTop: "20px",
                      }}
                    >
                      INVOICE NUMBER:
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        color: "var(--black)",
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        fontWeight: "bold",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginTop: "5px",
                      }}
                    >
                      {invoice.invoiceNumber}
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        color: "var(--invoice--color)",
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        fontWeight: "bold",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginTop: "20px",
                      }}
                    >
                      DATE OF ISSUE:
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        color: "var(--black)",
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        fontWeight: "bold",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginTop: "5px",
                      }}
                    >
                      {invoice.invoiceDate}
                    </Typography>
                  </Box>
                  <Box>
                    <TableContainer component={Paper}>
                      <Table
                        sx={{ minWidth: 900 }}
                        aria-label="customized table"
                      >
                        <TableHead>
                          <TableRow>
                            <StyledTableCell>Description</StyledTableCell>
                            <StyledTableCell align="right">
                              Unit Cost
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              QTY/HR Rate
                            </StyledTableCell>
                            <StyledTableCell align="right">
                              Amount
                            </StyledTableCell>
                          </TableRow>
                        </TableHead>
                        <Box
                          sx={{
                            marginBottom: "5px",
                          }}
                        ></Box>
                        <TableBody>
                          {item.ItemDetails.map((prod) => (
                            <StyledTableRow key={prod.id}>
                              <StyledTableCell component="th" scope="row">
                                {prod.description}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {prod.rate}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {prod.quantity}
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                {prod.amount}
                              </StyledTableCell>
                            </StyledTableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Box>
                </Box>
              );
            })}
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-end",
                alignSelf: "flex-end",
                width: "25%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "30px",
                }}
              >
                <Typography
                  variant="span"
                  sx={{
                    color: "var(--invoice--color)",
                    fontSize: {
                      xs: "10px",
                      sm: "10px",
                      md: "15px",
                      lg: "15px",
                      xl: "15px",
                    },
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  SUBTOTAL
                </Typography>
                <Typography
                  variant="span"
                  sx={{
                    color: "var(--black)",
                    fontSize: {
                      xs: "10px",
                      sm: "10px",
                      md: "15px",
                      lg: "15px",
                      xl: "15px",
                    },
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  ${item.SubTotal}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Typography
                  variant="span"
                  sx={{
                    color: "var(--invoice--color)",
                    fontSize: {
                      xs: "10px",
                      sm: "10px",
                      md: "15px",
                      lg: "15px",
                      xl: "15px",
                    },
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  Discount
                </Typography>
                <Typography
                  variant="span"
                  sx={{
                    color: "var(--black)",
                    fontSize: {
                      xs: "10px",
                      sm: "10px",
                      md: "15px",
                      lg: "15px",
                      xl: "15px",
                    },
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  $ {item.Discount}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Typography
                  variant="span"
                  sx={{
                    color: "var(--invoice--color)",
                    fontSize: {
                      xs: "10px",
                      sm: "10px",
                      md: "15px",
                      lg: "15px",
                      xl: "15px",
                    },
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  TAX RATE
                </Typography>
                <Typography
                  variant="span"
                  sx={{
                    color: "var(--black)",
                    fontSize: {
                      xs: "10px",
                      sm: "10px",
                      md: "15px",
                      lg: "15px",
                      xl: "15px",
                    },
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  {item.TaxRate}%
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                  marginTop: "10px",
                }}
              >
                <Typography
                  variant="span"
                  sx={{
                    color: "var(--invoice--color)",
                    fontSize: {
                      xs: "10px",
                      sm: "10px",
                      md: "15px",
                      lg: "15px",
                      xl: "15px",
                    },
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  SUBTOTAL
                </Typography>
                <Typography
                  variant="span"
                  sx={{
                    color: "var(--black)",
                    fontSize: {
                      xs: "10px",
                      sm: "10px",
                      md: "15px",
                      lg: "15px",
                      xl: "15px",
                    },
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  ${item.Tax}
                </Typography>
              </Box>
              <Box sx={hr_line}></Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-end",
                }}
              >
                <Typography
                  variant="span"
                  sx={{
                    color: "var(--invoice--color)",
                    fontSize: {
                      xs: "13px",
                      sm: "16px",
                      md: "16px",
                      lg: "18px",
                      xl: "18px",
                    },
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  INVOICE TOTAL
                </Typography>
                <Typography
                  variant="span"
                  sx={{
                    color: "var(--invoice--color)",
                    fontSize: {
                      xs: "13px",
                      sm: "16px",
                      md: "16px",
                      lg: "18px",
                      xl: "18px",
                    },
                    fontWeight: "bold",
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "flex-start",
                  }}
                >
                  ${item.Total}.00
                </Typography>
              </Box>

              {item.BankDetails.map((bank, key) => {
                return (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      width: "100%",
                      marginTop: "50px",
                    }}
                    key={key}
                  >
                    <Typography
                      variant="span"
                      sx={{
                        color: "var(--invoice--color)",
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        fontWeight: "bold",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginTop: "20px",
                      }}
                    >
                      BANK ACCOUNT DETAILS
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        color: "var(--black)",
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        fontWeight: "bold",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginTop: "10px",
                      }}
                    >
                      Account Holder: {""} {bank.accountholder}
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        color: "var(--black)",
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        fontWeight: "bold",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginTop: "5px",
                      }}
                    >
                      Account Number: {""}
                      {bank.accountNumber}
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        color: "var(--black)",
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        fontWeight: "bold",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginTop: "5px",
                      }}
                    >
                      ABA rtn {bank.ifsc}
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        color: "var(--black)",
                        fontSize: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "15px",
                          xl: "15px",
                        },
                        fontWeight: "bold",
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "flex-start",
                        marginTop: "5px",
                      }}
                    >
                      Wire rtn {bank.ifsc}
                    </Typography>
                  </Box>
                );
              })}
            </Box>
            <Box
              sx={{
                position: "relative",
                bottom: "50px",
              }}
            >
              <Typography
                variant="span"
                sx={{
                  color: "var(--invoice--color)",
                  fontSize: {
                    xs: "13px",
                    sm: "16px",
                    md: "16px",
                    lg: "18px",
                    xl: "18px",
                  },
                  fontWeight: "bold",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                }}
              >
                TERMS
              </Typography>
              <Typography
                variant="span"
                sx={{
                  color: "var(--invoice--color)",
                  fontSize: {
                    xs: "10px",
                    sm: "10px",
                    md: "15px",
                    lg: "15px",
                    xl: "15px",
                  },
                  fontWeight: "bold",
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginTop: "5px",
                }}
              >
                Please pay invoice by {item.DueDate}
              </Typography>
            </Box>
          </Box>
        );
      })}
    </Container>
  );
};
