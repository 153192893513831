import React from "react";

export function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      src={process.env.PUBLIC_URL + "/assets/arrows/next.png"}
      style={{
        ...style,
        display: "block",
        height: "90px",
        width: "90px",
        zIndex: "1",
        right: "100px",
      }}
      onClick={onClick}
      alt="Next"
    />
  );
}

export function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      src={process.env.PUBLIC_URL + "/assets/arrows/pervious.png"}
      style={{
        ...style,
        display: "block",
        height: "90px",
        width: "90px",
        zIndex: "1",
        left: "100px",
      }}
      onClick={onClick}
      alt="Previous"
    />
  );
}

////for container components
export function ContNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      src={process.env.PUBLIC_URL + "/assets/arrows/next.png"}
      style={{
        ...style,
        display: "block",
        height: "90px",
        width: "90px",
        zIndex: "1",
        right: "-38px",
        top: "40%",
      }}
      onClick={onClick}
      alt="Next"
    />
  );
}

export function ContPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      src={process.env.PUBLIC_URL + "/assets/arrows/pervious.png"}
      style={{
        ...style,
        display: "block",
        height: "90px",
        width: "90px",
        zIndex: "1",
        left: "-45px",
        top: "40%",
      }}
      onClick={onClick}
      alt="Previous"
    />
  );
}

export function MobContNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      src={process.env.PUBLIC_URL + "/assets/arrows/next.png"}
      style={{
        ...style,
        display: "block",
        height: "50px",
        width: "50px",
        zIndex: "1",
        right: "35%",
        top: "105%",
      }}
      onClick={onClick}
      alt="Next"
    />
  );
}

export function MobContPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      src={process.env.PUBLIC_URL + "/assets/arrows/pervious.png"}
      style={{
        ...style,
        display: "block",
        height: "50px",
        width: "50px",
        zIndex: "1",
        left: "35%",
        top: "105%",
      }}
      onClick={onClick}
      alt="Previous"
    />
  );
}

export function CustNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      src={process.env.PUBLIC_URL + "/assets/arrows/next.png"}
      style={{
        ...style,
        position: "absolute",
        display: "block",
        height: "60px",
        width: "60px",
        zIndex: "1",
        marginTop: "65px",
        right: "30%", // Adjust the percentage value as needed
        top: "100%", // Adjust the percentage value as needed
        transform: "translateY(-50%)", // Center the arrow vertically
      }}
      onClick={onClick}
      alt="Next"
    />
  );
}

export function CustPrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      className={className}
      src={process.env.PUBLIC_URL + "/assets/arrows/pervious.png"}
      style={{
        ...style,
        position: "absolute",
        display: "block",
        height: "60px",
        width: "60px",
        zIndex: "1",
        marginTop: "65px",
        left: "30%", // Adjust the percentage value as needed
        top: "100%", // Adjust the percentage value as needed
        transform: "translateY(-50%)", // Center the arrow vertically
      }}
      onClick={onClick}
      alt="Previous"
    />
  );
}
