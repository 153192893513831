import React, { useState } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { NavLink } from "react-router-dom";

export const StrongerSection = ({ title, description, image }) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };

  return (
    <Box
      sx={{
        marginBottom: "80px",
        display: {
          xs: "flex",
          sm: "block",
          md: "block",
          lg: "block",
          xl: "block",
        },
        flexDirection: {
          xs: "column",
          sm: "row",
          md: "row",
          lg: "row",
          xl: "row",
        },
      }}
    >
      <Box>
        <Box
          sx={{
            height: {
              xs: "50vh",
              sm: "30vh",
              md: "35vh",
              lg: "55vh",
              xl: "55vh",
            },
            width: {
              xs: "50vw",
              sm: "20vw",
              md: "25vw",
              lg: "15vw",
              xl: "15vw",
            },
            float: {
              xs: "none",
              sm: "right",
              md: "right",
              lg: "right",
              xl: "right",
            },
            marginLeft: {
              xs: "0px",
              sm: "30px",
              md: "30px",
              lg: "70px",
              xl: "70px",
            },
            display: {
              xs: "none",
              sm: "block",
              md: "block",
              lg: "block",
              xl: "block",
            },

            position: "relative",
          }}
        >
          <img
            src={
              process.env.PUBLIC_URL +
              `./assets/AboutPage/strongerimages/handimg.png`
            }
            alt="..."
            style={{ height: "100%", width: "100%" }}
          />
        </Box>
        <Box
          sx={{
            textAlign: "left",
          }}
        >
          <Typography
            variant="p"
            sx={{
              fontSize: {
                xs: "12px",
                sm: "12px",
                md: "14px",
                lg: "16px",
                xl: "16px",
              },
              fontWeight: "bold",
              color: "var(--theme-color)",
              textAlign: "left",
              fontFamily: "var(--primary-font)",
              marginBottom: "15px",
            }}
          >
            {title}
          </Typography>
          <Box
            sx={{
              marginBottom: "30px",
              width: {
                xs: "100%",
                sm: "75%",
                md: "75%",
                lg: "50%",
                xl: "75%",
              },
            }}
          >
            <Box className="horizontal-line"></Box>
            <Box className="horizontal-line"></Box>
            <Box className="horizontal-line"></Box>
          </Box>

          <Typography
            sx={{
              fontSize: "15px",
              fontFamily: "var(--primary-font-family)",
              color: "var(--dark-color)",
            }}
          >
            {showMore ? (
              <span
                style={{
                  padding: "0",
                  margin: "0",
                  display: "inline ", // Keep description and NavLink on the same line
                }}
                dangerouslySetInnerHTML={{
                  __html: description,
                }}
              ></span>
            ) : (
              <span
                style={{
                  padding: "0",
                  margin: "0",
                  display: "inline", // Keep description and NavLink on the same line
                }}
                dangerouslySetInnerHTML={{
                  __html: `${description?.slice(0, 2000)}`,
                }}
              ></span>
              // <div>
              //   <span
              //     style={{
              //       color: "red",
              //       display: "inline-block",
              //     }}
              //     dangerouslySetInnerHTML={{
              //       __html: description?.split(" ")[0],
              //     }}
              //   ></span>
              //   {""}
              //   <span
              //     style={{
              //       display: "inline",
              //     }}
              //     dangerouslySetInnerHTML={{
              //       __html: description
              //         ?.slice(description?.indexOf(" ") + 1)
              //         ?.slice(0, 2000),
              //     }}
              //   ></span>
              // </div>
            )}
            {description?.length > 2000 && (
              <NavLink
                style={{
                  color: "var(--theme-color)",
                  marginBottom: "30px",
                  whiteSpace: "nowrap",
                  display: "inline",
                }}
                onClick={toggleShowMore}
              >
                {showMore ? "Read less" : "Read more"}
              </NavLink>
            )}
          </Typography>
        </Box>
        <Box
          sx={{
            width: "100%",
            display: {
              xs: "flex",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "none",
            },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              height: {
                xs: "50vh",
                sm: "50vh",
                md: "50vh",
                lg: "60vh",
                xl: "60vh",
              },
              width: {
                xs: "50vw",
                sm: "30vw",
                md: "30vw",
                lg: "15vw",
                xl: "15vw",
              },
              display: {
                xs: "flex",
                sm: "none",
                md: "none",
                lg: "none",
                xl: "none",
              },
              position: "relative",
              marginTop: "20px",
              justifyContent: "center",
              alignItems: "center",
              alignSelf: "center",
            }}
          >
            <img
              src={
                process.env.PUBLIC_URL +
                `./assets/AboutPage/strongerimages/handimg.png`
              }
              alt="..."
              style={{ height: "100%", width: "100%" }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
