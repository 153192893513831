import React from "react";
import Slider from "react-slick";
import { Button } from "@mui/material";
import { Box } from "@mui/system";
import { Typography, Container } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import "./GoodDharms.css";
import {
  ContNextArrow,
  ContPrevArrow,
  MobContNextArrow,
  MobContPrevArrow,
} from "../../Shared/arrows/arrows";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";
import Skeleton from "@mui/material/Skeleton";
import { useCookies } from "react-cookie";

const sectOptions = [
  {
    id: 1,
    title: "Nyingma",
  },
  {
    id: 2,
    title: "Kagyu",
  },
  {
    id: 3,
    title: "Sakya",
  },
  {
    id: 4,
    title: "Gelug",
  },
];

export const GoodDharma = () => {
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(false);
  const [token, setToken] = useState(""); // Initialize the token state
  const [cookies] = useCookies(["userId", "token"]);

  useEffect(() => {
    // Check if the token is available in cookies
    if (cookies.token) {
      setToken(cookies.token);
      // You can also perform other actions that depend on the token here
    }
  }, [cookies.token]);

  useEffect(() => {
    // Set isMobile based on screen width
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const { get } = useAPI();

  const getsitesettingsData = async () => {
    const res = await get(endpoint.sitesettings);
    return res.data;
  };

  const { DataisLoading, data: siteSettings } = useQuery({
    queryKey: ["siteSettings"],
    queryFn: getsitesettingsData,
  });

  const getData = async () => {
    const res = await get(endpoint.featured_gooddharma);
    return res.data;
  };

  const { isDataLoading, data: featuredGoodDharmaData } = useQuery({
    queryKey: ["featured_gooddharma"],
    queryFn: getData,
  });

  if (DataisLoading) {
    return (
      <Container>
        <Skeleton variant="rectangular" height={300} width={"100%"} />
      </Container>
    );
  }

  if (isDataLoading)
    return (
      <>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: "20px",
          }}
        >
          {[1, 2, 3].map((index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Skeleton variant="rectangular" width={300} height={200} />
              <Skeleton variant="text" width={300} height={30} />
              <Skeleton variant="text" width={300} height={30} />
            </Box>
          ))}
        </Box>
      </>
    );

  var settings = {
    dots: false,
    infinite: true,
    slidesToShow:
      featuredGoodDharmaData?.length > 3 ? 3 : featuredGoodDharmaData?.length,
    slidesToScroll: 1,
    autoplay: isMobile ? true : false,
    speed: 500,
    autoplaySpeed: 500,
    pauseOnHover: true,
    cssEase: "linear",
    nextArrow: isMobile ? <MobContNextArrow /> : <ContNextArrow />,
    prevArrow: isMobile ? <MobContPrevArrow /> : <ContPrevArrow />,

    // autoplay: isMobile ? true : false,
    // autoplaySpeed: 2000,
    // dots: isMobile ? false : true,

    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          // arrows: false,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box
      sx={{
        marginBottom: "110px",
      }}
    >
      <Typography
        variant="p"
        sx={{
          marginTop: "10px",
          color: "var(--theme-color)",
          fontSize: {
            xs: "16px",
            sm: "18px",
            md: "18px",
            lg: "20px",
            xl: "20px",
          },
        }}
      >
        {siteSettings?.feature_service}
      </Typography>
      <Slider {...settings}>
        {featuredGoodDharmaData?.map((item) => (
          <Box
            key={item.id}
            sx={{
              textAlign: "left",
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "flex-start",
              marginTop: "50px",
            }}
          >
            <Box
              sx={{
                position: "relative",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  backgroundColor: "var(--theme-color)",
                  position: "absolute",
                  top: "0",
                  zIndex: 1,
                  // width: "120px",
                  margin: "0px 10px 0px 10px",
                  padding: "0px 10px 0px 0px",
                }}
              >
                <Box>
                  <img
                    src={process.env.PUBLIC_URL + "./assets/icons/Vector.png"}
                    alt="..."
                    style={{
                      width: "50%",
                      height: "60%",
                      margin: "0px 10px 0px 10px",
                    }}
                  />
                </Box>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "10px",
                      sm: "11px",
                      md: "11px",
                      lg: "12px",
                      xl: "12px",
                    },
                    fontFamily: "var(--primary-font)",
                    color: "var( --white)",
                    margin: "10px 0px 10px 0px",
                  }}
                >
                  FEATURED
                </Typography>
              </Box>
              <Box
                sx={{
                  // width: {
                  //   xs: "100%",
                  //   sm: "98%",
                  //   md: "98%",
                  //   lg: "95%",
                  //   xl: "95%",
                  // },
                  height: {
                    xs: "45vh",
                    sm: "35vh",
                    md: "30vh",
                    lg: "30vh",
                    xl: "30vh",
                  },
                  margin: "0px 10px 0px 10px",
                }}
              >
                <img
                  src={item.images[0]?.image}
                  alt={item.name}
                  style={{
                    height: "100%",
                    width: "100%",
                    marginBottom: "10px",
                    // objectFit: "cover",
                    // objectPosition: "center",
                  }}
                />
              </Box>
            </Box>

            <Box
              sx={{
                margin: "10px 10px 0px 10px",
              }}
            >
              <Typography
                variant="p"
                sx={{
                  marginBottom: "10px",
                  display: "flex",
                  textAlign: "center",
                  justifyContent: "flex-start",
                  fontSize: {
                    xs: "15px",
                    sm: "18px",
                    md: "20px",
                    lg: "20px",
                    xl: "20px",
                  },
                }}
              >
                {item.name}
              </Typography>
              <Typography
                sx={{
                  marginBottom: "10px",
                  display: "flex",
                  textAlign: "justify",
                  marginRight: "20px",
                  fontSize: {
                    xs: "12px",
                    sm: "14px",
                    md: "15px",
                    lg: "17px",
                    xl: "17px",
                  },
                  overflow: "hidden", // Hide any overflowing text
                  textOverflow: "ellipsis", // Display ellipsis for long texts
                  display: "-webkit-box",
                  WebkitLineClamp: 3, // Adjust the number of lines to fit your requirements
                  WebkitBoxOrient: "vertical",
                }}
                dangerouslySetInnerHTML={{ __html: item.description }}
              >
                {/* {item.description} */}
              </Typography>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "var(--theme-color)",
                    color: "var(--white)",
                    marginBottom: "10px",
                    fontSize: {
                      xs: "10px",
                      sm: "10px",
                      md: "12px",
                      lg: "15px",
                      xl: "15px",
                    },
                    padding: "10px 10px 10px 10px",
                    ":hover": {
                      //zoom the buttom
                      transform: "scale(1.1)",
                      backgroundColor: "var(--theme-color)",
                    },
                  }}
                  onClick={() => {
                    navigate(`/gooddharma/${item.id}`);
                  }}
                >
                  Take a look
                  <img
                    src={process.env.PUBLIC_URL + "/assets/icons/left.png"}
                    alt="..."
                    style={{
                      width: "15px",
                      height: "20px",
                      marginLeft: "10px",
                    }}
                  />
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "var(--very-lightthemecolor)",
                      padding: "5px",
                      borderRadius: "50%",
                      color: "var(--theme-color)",
                      "&:hover": {
                        //zoom the buttom
                        transform: "scale(1.1)",
                        backgroundColor: "var(--theme-color)",
                        color: "var(--white)",
                      },
                    }}
                    onClick={async () => {
                      const todaysDate = new Date();
                      todaysDate.setDate(todaysDate.getDate() + 1); // Add 1 day to get tomorrow's date
                      const selecteddate = todaysDate
                        .toISOString()
                        .split("T")[0]; // Format as "YYYY-MM-DD"

                      const selectedData = {
                        service_id: item.id,
                        // title: serviceDetails.name,
                        // imgUrl: serviceDetails.images[0].image,
                        date: selecteddate,
                        sect: sectOptions[0].title,
                        size: item.sizes[0].size,
                        price: item.sizes[0].price,
                      };

                      try {
                        const response = await axios.post(
                          process.env.REACT_APP_JINLAP_BASE_URL +
                            `/services/add-to-wishlist`,
                          selectedData,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                              Accept: "application/json",
                              "Content-Type": "application/json",
                            },
                          }
                        );
                        {
                          response.data.status === true
                            ? toast.success(
                                `${item.name} added to wishlist successfully!`
                              )
                            : toast.warning(response.data.message);
                        }
                      } catch (error) {
                        toast.error(error.response.data.message);
                      }
                    }}
                  >
                    <FavoriteBorderIcon />
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "var(--very-lightthemecolor)",
                      padding: "5px",
                      borderRadius: "50%",
                      color: "var(--theme-color)",
                      marginLeft: "10px",
                      "&:hover": {
                        //zoom the buttom
                        transform: "scale(1.1)",
                        backgroundColor: "var(--theme-color)",
                        color: "var(--white)",
                      },
                    }}
                    onClick={async () => {
                      const todaysDate = new Date();
                      todaysDate.setDate(todaysDate.getDate() + 1); // Add 1 day to get tomorrow's date
                      const selecteddate = todaysDate
                        .toISOString()
                        .split("T")[0]; // Format as "YYYY-MM-DD"

                      const selectedData = {
                        service_id: item.id,
                        // title: serviceDetails.name,
                        // imgUrl: serviceDetails.images[0].image,
                        date: selecteddate,
                        sect: sectOptions[0].title,
                        size: item.sizes[0].size,
                        price: item.sizes[0].price,
                      };

                      try {
                        const response = await axios.post(
                          process.env.REACT_APP_JINLAP_BASE_URL +
                            `/services/add-to-cart`,
                          selectedData,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                              Accept: "application/json",
                              "Content-Type": "application/json",
                            },
                          }
                        );
                        {
                          response.data.status === true
                            ? toast.success(
                                `${item.name} added to cart successfully!`
                              )
                            : toast.warning(response.data.message);
                        }
                      } catch (error) {
                        toast.error(error.response.data.message);
                      }
                    }}
                  >
                    <ShoppingBagOutlinedIcon fontSize="medium" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        ))}
      </Slider>
    </Box>
  );
};
