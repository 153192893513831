import React from "react";
import { Button, Skeleton } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const GoodsSection = ({ id, searchResults }) => {
  const navigate = useNavigate();
  const [hoveredCategory, setHoveredCategory] = React.useState(null);

  const { getCategoriesdataById } = useAPI();

  const getData = async () => {
    const res = await getCategoriesdataById(endpoint.subcategories, 1);
    return res.data;
  };

  const { isDataLoading, data: dharmagoodcategories } = useQuery({
    queryKey: ["dharmagoodcategories", id.id],
    queryFn: getData,
  });

  if (isDataLoading) {
    return (
      <>
        <Skeleton variant="text" width="100%" height="20px" />
      </>
    );
  }

  const dataToRender =
    searchResults.length > 0 ? searchResults : dharmagoodcategories;

  return (
    <Box
      sx={{
        marginBottom: "60px",
        marginTop: "50px",
      }}
    >
      <Grid container spacing={3}>
        {dataToRender?.map((item) => (
          <Grid
            item
            xs={6}
            sm={4}
            md={4}
            lg={3}
            xl={3}
            key={item.id}
            onClick={() => {
              navigate(`/goods/${item.id}`);
            }}
            onMouseEnter={() => setHoveredCategory(item.id)}
            onMouseLeave={() => setHoveredCategory(null)}
          >
            <Box
              sx={{
                height: {
                  xs: "20vh",
                  sm: "25vh",
                  md: "28vh",
                  lg: "35vh",
                  xl: "35vh",
                },
                position: "relative", // Added to position the image container
              }}
            >
              <img
                src={item.image}
                alt={item.name}
                style={{
                  height: "100%",
                  width: "100%",
                  objectPosition: "center",
                  filter:
                    hoveredCategory === item.id
                      ? "grayscale(0%)"
                      : "grayscale(0%)",
                  transition: "filter 0.3s ease-in-out", // Added for smooth transition
                }}
              />
              {/* Overlay for color */}
              {hoveredCategory === item.id && (
                <div
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%",
                    backgroundColor: "var(--light-theme-color)", // Light pink color
                    opacity: 0.5,
                    pointerEvents: "none",
                  }}
                />
              )}
            </Box>

            <Box>
              <Typography
                variant="p"
                sx={{
                  color: "var(--theme-color)",
                  fontSize: {
                    xs: "11px",
                    sm: "11px",
                    md: "13px",
                    lg: "15px",
                    xl: "15px",
                  },
                  textAlign: "center",
                }}
              >
                {item.name}
              </Typography>
            </Box>
          </Grid>
        ))}
      </Grid>

      <Button
        // variant="outlined"
        sx={{
          color: "var(--theme-color)",
          border: "1px solid var(--theme-color)",
          width: { xs: "100%", sm: "100%", md: "15%", lg: "15%", xl: "15%" },
          display: "flex",
          justifyContent: "center",
          textTransform: "capitalize",
          alignItems: "center",
          margin: "auto",
          marginTop: "50px",
          fontSize: {
            xs: "11px",
            sm: "11px",
            md: "13px",
            lg: "15px",
            xl: "15px",
          },
          "&:hover": {
            backgroundColor: "var(--theme-color)",
            color: "white",
            border: "1px solid var(--theme-color)",
          },
        }}
      >
        SHOW MORE
        <KeyboardArrowDownIcon />
      </Button>
    </Box>
  );
};
