import React from "react";
import { Box } from "@mui/system";
import { Typography, Button, TextField } from "@mui/material";
import discountIcon from "../assets/discount.png";
import HelpIcon from "@mui/icons-material/Help";
import Modal from "@mui/material/Modal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { Cookies } from "react-cookie";

export const ProductsList = ({ showpPromodiscount, orderData, orderId }) => {
  const cookies = new Cookies();
  const userToken = cookies.get("token");
  const [code, setCode] = React.useState("");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [appliedDiscount, setAppliedDiscount] = React.useState("");
  const [promocode, setPromocode] = React.useState("");
  var converter = require("number-to-words");
  const [isProcessing, setIsProcessing] = React.useState(false);

  React.useEffect(() => {
    try {
      const fetchPromocodeData = async () => {
        try {
          const response = await axios.get(
            process.env.REACT_APP_JINLAP_BASE_URL + `/coupon`,
            {
              headers: {
                Authorization: `Bearer ${userToken}`,
                Accept: "application/json",
                "Content-Type": "application/json",
              },
            }
          );
          setPromocode(response.data.payload);
        } catch (error) {
          if (error.response.status === 401) {
            toast.error("Unauthorized access");
          } else {
            console.error("Error fetching data:", error);
          }
        }
      };

      fetchPromocodeData();
    } catch (error) {
      console.error("error", error);
    }
  }, [userToken]);

  const rate = orderData?.map((item) => {
    return item.rate;
  });

  const IntRate = parseInt(rate);

  const handleDiscount = () => {
    setIsProcessing(true);
    const appliedPromoCode = promocode.code === code;

    if (appliedPromoCode) {
      const discountAmount = promocode.amount / IntRate;
      const totalamt = orderData[0]?.total_amount - discountAmount;

      if (orderData[0]) {
        const response = axios.put(
          process.env.REACT_APP_JINLAP_BASE_URL + `/checkout/${orderId}`,
          {
            discount_amount: promocode.amount / IntRate,
            total_amount: totalamt,
          },
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );

        // Check if the API call was successful before showing the success message.
        response
          .then((response) => {
            if (response.status === 200) {
              toast.success("Promo code applied successfully");
            } else {
              toast.error("Failed to apply promo code");
            }
          })
          .catch((error) => {
            toast.error("Failed to apply promo code");
          });
      } else {
        toast.error("Order data is not available");
      }
    } else {
      setAppliedDiscount(0);
      toast.error("Promo Code is Invalid");
    }
    setPromocode("");
    setIsProcessing(false);
  };

  return (
    <>
      {orderData?.slice(0, 1)?.map((item, index) => {
        const GrandTotal =
          item.discount_amount !== 0
            ? item.total_amount
            : (item.total_amount - appliedDiscount / IntRate).toFixed(2);
        return (
          <Box
            key={index}
            sx={{
              marginTop: "30px",
              marginBottom: "80px",
              marginLeft: {
                xs: "0px",
                sm: "0px",
                md: "0px",
                lg: "40px",
                xl: "40px",
              },
              padding: "20px",
            }}
          >
            {item.checkout_items?.map((checkoutitem, index) => {
              return (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "20px",
                    marginBottom: "20px",
                  }}
                  key={index}
                >
                  {checkoutitem.product ? (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        gap: "20px",
                        marginBottom: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          height: "80px",
                          width: "80px",
                        }}
                      >
                        <img
                          src={checkoutitem?.product?.images[0].image}
                          alt="..."
                          style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "10px",
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="h5"
                          sx={{
                            fontFamily: "var(--primary-font)",
                            color: "var(--light-black)",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            fontSize: {
                              xs: "15px",
                              sm: "15px",
                              md: "20px",
                              lg: "20px",
                              xl: "20px",
                            },
                          }}
                        >
                          {checkoutitem?.product?.name}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            width: "100%",
                            marginTop: "10px",
                          }}
                        >
                          <Typography
                            variant="span"
                            sx={{
                              color: "var(--light-black)",
                              fontSize: {
                                xs: "11px",
                                sm: "12px",
                                md: "13px",
                                lg: "14px",
                                xl: "14px",
                              },
                            }}
                          >
                            Quantity :{" "}
                            <span style={{ color: "var(--theme-color)" }}>
                              {checkoutitem.quantity}
                            </span>
                          </Typography>
                          <Box
                            sx={{
                              color: "#C4C4C4",
                              marginLeft: "20px",
                            }}
                          >
                            |
                          </Box>
                          <Box
                            sx={{
                              marginLeft: "20px",
                            }}
                          >
                            <Typography
                              variant="span"
                              sx={{
                                color: "var(--light-black)",
                                fontSize: {
                                  xs: "11px",
                                  sm: "12px",
                                  md: "13px",
                                  lg: "14px",
                                  xl: "14px",
                                },
                              }}
                            >
                              Price :{" "}
                              <span style={{ color: "var(--theme-color)" }}>
                                {item.currency} {checkoutitem.price / IntRate}
                              </span>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  ) : (
                    <>
                      <Box
                        sx={{
                          height: "80px",
                          width: "80px",
                        }}
                      >
                        <img
                          src={checkoutitem?.service?.images[0].image}
                          alt="..."
                          style={{
                            height: "100%",
                            width: "100%",
                            borderRadius: "10px",
                            objectFit: "cover",
                            objectPosition: "center",
                          }}
                        />
                      </Box>
                      <Box>
                        <Typography
                          variant="h5"
                          sx={{
                            fontFamily: "var(--primary-font)",
                            color: "var(--light-black)",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            fontSize: {
                              xs: "15px",
                              sm: "15px",
                              md: "20px",
                              lg: "20px",
                              xl: "20px",
                            },
                          }}
                        >
                          {checkoutitem?.service?.name}
                        </Typography>
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            width: "100%",
                            marginTop: "10px",
                          }}
                        >
                          <Typography
                            variant="span"
                            sx={{
                              color: "var(--light-black)",
                              fontSize: {
                                xs: "11px",
                                sm: "12px",
                                md: "13px",
                                lg: "14px",
                                xl: "14px",
                              },
                            }}
                          >
                            Quantity :{" "}
                            <span style={{ color: "var(--theme-color)" }}>
                              1
                            </span>
                          </Typography>
                          <Box
                            sx={{
                              color: "#C4C4C4",
                              marginLeft: "20px",
                            }}
                          >
                            |
                          </Box>
                          <Box
                            sx={{
                              marginLeft: "20px",
                            }}
                          >
                            <Typography
                              variant="span"
                              sx={{
                                color: "var(--light-black)",
                                fontSize: {
                                  xs: "11px",
                                  sm: "12px",
                                  md: "13px",
                                  lg: "14px",
                                  xl: "14px",
                                },
                              }}
                            >
                              Price :{" "}
                              <span style={{ color: "var(--theme-color)" }}>
                                {item.currency}{" "}
                                {(checkoutitem.price / IntRate).toFixed(2)}
                              </span>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    </>
                  )}
                </Box>
              );
            })}

            {showpPromodiscount === false && (
              <Box
                sx={{
                  marginTop: "50px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    color: "var(--light-black)",
                    fontSize: {
                      xs: "12px",
                      sm: "13px",
                      md: "13px",
                      lg: "15px",
                      xl: "15px",
                    },
                  }}
                >
                  <HelpIcon
                    fontSize="medium"
                    sx={{
                      color: "var(--theme-color)",
                      marginRight: "10px",
                    }}
                    onClick={handleOpen}
                  />
                  If you have a promotion code, Please enter here to claim
                  discounts
                </Typography>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: {
                      xs: "column",
                      sm: "row",
                      md: "row",
                      lg: "row",
                      xl: "row",
                    },
                    justifyContent: "flex-start",
                    alignItems: "center",
                    fontSize: {
                      xs: "13px",
                      sm: "13px",
                      md: "20px",
                      lg: "20px",
                      xl: "20px",
                    },
                    marginTop: "10px",
                    width: "100%",
                  }}
                >
                  <TextField
                    id="search"
                    type="text"
                    label="Enter Promotion Code"
                    size="small"
                    value={code}
                    onChange={(e) => setCode(e.target.value)}
                    sx={{
                      width: {
                        xs: "100%",
                        sm: "60%",
                        md: "60%",
                        lg: "70%",
                        xl: "70%",
                      },

                      backgroundColor: "#fff",
                      border: "0",
                      " & .MuiOutlinedInput-root": {
                        "  &.Mui-focused fieldset": {
                          borderColor: "var(--theme-color)",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "0px solid var(--white)",
                        borderRadius: "0",
                        padding: "0",
                      },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          border: "1px solid #eee",
                        },
                      //border colour when focused
                      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          border: "1px solid var(--theme-color)",
                        },
                      //floating label color when focused
                      "& .MuiInputLabel-outlined.Mui-focused": {
                        color: "var(--theme-color)",
                        backgroundColor: "transparent",
                      },
                    }}
                    inputProps={{
                      style: {
                        fontSize: {
                          xs: "11px",
                          sm: "11px",
                          md: "18px",
                          lg: "20px",
                          xl: "20px",
                        },
                      },
                    }}
                  />
                  <Button
                    variant="contained"
                    sx={{
                      backgroundColor: "var(--theme-color)",
                      height: "auto",
                      textTransform: "capitalize",
                      fontSize: {
                        xs: "9px",
                        sm: "9px",
                        md: "10px",
                        lg: "12px",
                        xl: "12px",
                      },
                      padding: {
                        xs: "12px 15px 12px 15px ",
                        sm: "10px",
                        md: "11px",
                        lg: "10px",
                        xl: "10px",
                      },
                      marginTop: {
                        xs: "15px",
                        sm: "0px",
                        md: "0px",
                        lg: "0px",
                        xl: "0px",
                      },
                      alignSelf: { xs: "flex-start", sm: "flex-start" },
                      "&:hover": {
                        backgroundColor: "var(--theme-color)",
                        color: "var(--white)",
                        transform: "scale(1.1)",
                      },
                      fontFamily: "var(--primary-font)",
                    }}
                    onClick={handleDiscount}
                    disabled={isProcessing}
                  >
                    <img
                      src={process.env.PUBLIC_URL + `${discountIcon}`}
                      alt="..."
                      style={{
                        width: "15px",
                        height: "15px",
                        marginRight: "10px",
                      }}
                    />
                    {isProcessing ? "Processing..." : "Apply Discount"}
                  </Button>

                  <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                  >
                    <Box
                      sx={{
                        position: "absolute",
                        top: "50%",
                        left: "50%",
                        transform: "translate(-50%, -50%)",
                        width: {
                          xs: "80%",
                          sm: "60%",
                          md: "60%",
                          lg: "30%",
                          xl: "30%",
                        },
                        bgcolor: "var(--white)",
                        padding: "20px",
                        borderColor: "var(--white)",
                      }}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "var(--primary-font)",
                          fontSize: {
                            xs: "12px",
                            sm: "13px",
                            md: "13px",
                            lg: "15px",
                            xl: "15px",
                          },
                          color: "var(--black)",
                        }}
                      >
                        If you receive some coupons in our message or email you
                        can use to claim your discount.
                      </Typography>
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "var(--primary-font)",
                          fontSize: {
                            xs: "12px",
                            sm: "13px",
                            md: "13px",
                            lg: "15px",
                            xl: "15px",
                          },
                          color: "var(--black)",
                          marginTop: "20px",
                        }}
                      >
                        Example: <br />
                        <span>
                          Type “
                          <span
                            style={{
                              color: "var(--theme-color)",
                            }}
                          >
                            DISCOUNT
                          </span>
                          ” and click on apply discount
                        </span>
                      </Typography>
                    </Box>
                  </Modal>
                </Box>
              </Box>
            )}

            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginTop: "70px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "16px",
                      lg: "18px",
                      xl: "18px",
                    },
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  Subtotal: {""}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "16px",
                      lg: "18px",
                      xl: "18px",
                    },
                    color: "var(--theme-color)",
                    marginLeft: "10px",
                    fontWeight: "bold",
                    display: "flex",
                    justifyContent: "flex-end",
                    alignItems: "flex-end",
                  }}
                >
                  {item.currency} {item.sub_total.toFixed(2)}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: "30px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "var(--primary-font)",
                      fontSize: {
                        xs: "13px",
                        sm: "14px",
                        md: "15px",
                        lg: "16px",
                        xl: "16px",
                      },
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    Delivery Charge/Shipping Charge: {""}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "var(--primary-font)",
                      fontSize: {
                        xs: "13px",
                        sm: "14px",
                        md: "15px",
                        lg: "16px",
                        xl: "16px",
                      },
                      color: "var(--theme-color)",
                      marginLeft: "10px",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    {item.currency} {item.delivery_charge}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginBottom: "10px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "var(--primary-font)",
                      fontSize: {
                        xs: "13px",
                        sm: "14px",
                        md: "15px",
                        lg: "16px",
                        xl: "16px",
                      },
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    Discount: {""}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "var(--primary-font)",
                      fontSize: {
                        xs: "13px",
                        sm: "14px",
                        md: "15px",
                        lg: "16px",
                        xl: "16px",
                      },
                      color: "var(--theme-color)",
                      marginLeft: "10px",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    - {""} {item.currency}{" "}
                    {item.discount_amount
                      ? item.discount_amount
                      : (appliedDiscount / IntRate).toFixed(2)}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "var(--primary-font)",
                      fontSize: {
                        xs: "13px",
                        sm: "14px",
                        md: "15px",
                        lg: "16px",
                        xl: "16px",
                      },
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    Tax: {""}
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "var(--primary-font)",
                      fontSize: {
                        xs: "13px",
                        sm: "14px",
                        md: "15px",
                        lg: "16px",
                        xl: "16px",
                      },
                      color: "var(--theme-color)",
                      marginLeft: "10px",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                    }}
                  >
                    {item.currency} {item.tax}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginTop: "30px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "var(--primary-font)",
                      fontSize: {
                        xs: "14px",
                        sm: "15px",
                        md: "16px",
                        lg: "18px",
                        xl: "18px",
                      },
                      fontWeight: "bold",
                      display: "flex",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    Grand Total:
                  </Typography>
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "var(--primary-font)",
                      fontSize: {
                        xs: "14px",
                        sm: "15px",
                        md: "16px",
                        lg: "18px",
                        xl: "18px",
                      },
                      color: "var(--theme-color)",
                      marginLeft: "10px",
                      fontWeight: "bold",
                      display: "flex",
                      justifyContent: "flex-end",
                      alignItems: "flex-end",
                      backgroundColor: "var(--very-lightthemecolor)",
                    }}
                  >
                    {item.currency} {GrandTotal ? GrandTotal : 0}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    backgroundColor: "var(--white)",
                    marginTop: "20px",
                    padding: "10px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "var(--primary-font)",
                      fontSize: {
                        xs: "11px",
                        sm: "12px",
                        md: "13px",
                        lg: "14px",
                        xl: "14px",
                      },
                      color: "var(--light-black)",
                    }}
                  >
                    Amount on words:
                    <span
                      style={{
                        color: "var(--theme-color)",
                        marginLeft: "10px",
                        textTransform: "capitalize",
                      }}
                    >
                      {converter.toWords(GrandTotal ? GrandTotal : 0)}
                      {""} Only
                    </span>
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}
    </>
  );
};
