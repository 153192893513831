import axios from "axios";
import { Cookies } from "react-cookie";

const cookies = new Cookies();
const token = cookies.get("token");

const AuthAxiosInstance = axios.create({
  baseURL: process.env.REACT_APP_JINLAP_BASE_URL,
  timeout: 10000,
  headers: {
    "Content-Type": "application/json",
    Accept: "application/json",
    Authorization: `Bearer ${token}`,
    RetryAfter: 5000,
  },
});

export default AuthAxiosInstance;
