import React from "react";
import { Box } from "@mui/system";
import { Typography, Button } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { NavLink } from "react-router-dom";
import Modal from "@mui/material/Modal";
import Stripe from "../../Payment/Stripe";
import axios from "axios";
import { Cookies } from "react-cookie";

export const ShippingForm = ({ onContinue, totalamount, orderData }) => {
  const [hasSubmitted, setHasSubmitted] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [orderid, setOrderid] = React.useState(null);

  const [openVisa, setOpenVisa] = React.useState(false);
  const handleOpenVisa = () => setOpenVisa(true);
  const handleCloseVisa = () => setOpenVisa(false);

  const [isLoading, setIsLoading] = React.useState(false);

  const cookies = new Cookies();

  const errorStyle = {
    color: "red",
    fontSize: "12px",
    marginleft: "3rem",
  };

  const userData = JSON.parse(localStorage.getItem("userInformation"));

  const initialValues = {
    ReceiversName:
      userData?.name === userData.receiver_firstname
        ? userData.name
        : userData.receiver_firstname + "" + userData.receiver_secondname,
    ReceiverEmail: userData?.receiver_email
      ? userData.receiver_email
      : userData.email,
    ReceiversContact: userData?.receiver_number
      ? userData.receiver_number
      : userData.number,
    ShiptoAddress: userData?.receiver_address,
  };

  const validationSchema = Yup.object().shape({
    ReceiversName: Yup.string().required("Required"),
    ReceiverEmail: Yup.string()
      .email("Invalid email format")
      .required("Required"),
    ReceiversContact: Yup.string().required("Required"),
    ShiptoAddress: Yup.string().required("Required"),
  });

  const handleClickVisa = () => {
    handleOpenVisa();
    handleClose();
  };

  const checkoutData = {
    total_amount: orderData[0]?.total_amount,
    sub_total: orderData[0]?.sub_total,
    delivery_charge: orderData[0]?.delivery_charge,
    discount_amount: orderData[0]?.discount_amount,
    tax: orderData[0]?.tax,
    currency: orderData[0]?.currency,
    quantity: orderData[0]?.quantity,
    products: [],
    services: [],
    rate: orderData[0]?.rate,
  };
  orderData[0]?.checkout_items.forEach((item) => {
    if (item.product) {
      checkoutData?.products?.push({
        product_id: item.product.id,
        product_qty: item.quantity,
        product_price: item.price,
      });
    } else if (item.service) {
      checkoutData?.services?.push({
        service_id: item.service.id,
        service_price: item.price,
      });
    }
  });

  const submitHandler = async () => {
    // Check if the function has already been executed
    if (hasSubmitted) {
      return;
    }

    // Set the flag to true to prevent further execution
    setHasSubmitted(true);
    setIsLoading(true);
    try {
      const res = await axios.post(
        process.env.REACT_APP_JINLAP_BASE_URL + `/orders`,
        checkoutData,
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );
      setOrderid(res.data.id);
      setIsLoading(false);
      handleOpen();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        alignItems: "flex-start",
        // backgroundColor: "var(--white)",
        marginTop: "30px",
        marginBottom: "80px",
        width: "100%",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "flex-start",
          padding: "15px 0px 15px 0px",
          width: "100%",
        }}
      >
        <Typography
          variant="p"
          sx={{
            color: "var(--light-black)",
            fontFamily: "var(--primary-font)",
            fontSize: {
              xs: "10px",
              sm: "11px",
              md: "12px",
              lg: "14px",
              xl: "14px",
            },
          }}
        >
          Information {">"} {""}
          <span
            style={{
              color: "var(--theme-color)",
              fontFamily: "var(--primary-font)",
              fontWeight: "600",
            }}
          >
            Shipping {""}
          </span>
          {">"} Payment
        </Typography>
      </Box>
      <Box
        sx={{
          width: {
            xs: "100%",
            sm: "90%",
            md: "90%",
            lg: "90%",
            xl: "90%",
          },
        }}
      >
        <Formik
          initialValues={initialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
        >
          {({ errors, touched }) => (
            <Form>
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Box
                  sx={{
                    marginTop: "20px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <InputLabel
                    htmlFor="input-with-icon-adornment"
                    sx={{
                      color: "var(--black-color)",
                      fontFamily: "var(--primary-font)",
                      marginBottom: "10px",
                    }}
                  >
                    Receiver's Name:
                  </InputLabel>
                  <Field
                    as={TextField}
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                    name="ReceiversName"
                    id="input-with-icon-adornment"
                    className={
                      errors.ReceiversName && touched.ReceiversName
                        ? "input-error"
                        : null
                    }
                    InputProps={{
                      endAdornment: (
                        <NavLink
                          style={{
                            marginRight: "10px",
                            color: "var(--theme-color)",
                          }}
                          onClick={onContinue}
                        >
                          Change
                        </NavLink>
                      ),
                    }}
                    sx={{
                      backgroundColor: "transparent",
                      fontFamily: "var(--primary-font)",
                      width: "100%",

                      " & .MuiOutlinedInput-root": {
                        "  &.Mui-focused fieldset": {
                          borderColor: "var(--btn-color)",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "0px solid var(--theme-color)",
                        borderRadius: "0",
                        padding: "0",
                      },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          // border: "1px solid #E4E4E4",
                          borderRadius: "5px",
                        },
                      //border colour when focused
                      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          border: "0px solid var(--btn-color))",
                        },
                      //floating label color when focused
                      "& .MuiInputLabel-outlined.Mui-focused": {
                        color: "var(--btn-color)",
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                  <ErrorMessage
                    name="ReceiversName"
                    component="span"
                    className="error"
                    style={errorStyle}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: "20px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <InputLabel
                    htmlFor="input-with-icon-adornment"
                    sx={{
                      color: "var(--black-color)",
                      fontFamily: "var(--primary-font)",
                      marginBottom: "10px",
                    }}
                  >
                    Email Address:
                  </InputLabel>
                  <Field
                    as={TextField}
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                    name="ReceiverEmail"
                    id="input-with-icon-adornment"
                    className={
                      errors.ReceiverEmail && touched.ReceiverEmail
                        ? "input-error"
                        : null
                    }
                    InputProps={{
                      endAdornment: (
                        <NavLink
                          style={{
                            marginRight: "10px",
                            color: "var(--theme-color)",
                          }}
                          onClick={onContinue}
                        >
                          Change
                        </NavLink>
                      ),
                    }}
                    sx={{
                      backgroundColor: "transparent",
                      fontFamily: "var(--primary-font)",
                      width: "100%",

                      " & .MuiOutlinedInput-root": {
                        "  &.Mui-focused fieldset": {
                          borderColor: "var(--btn-color)",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "0px solid var(--theme-color)",
                        borderRadius: "0",
                        padding: "0",
                      },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          // border: "1px solid #E4E4E4",
                          borderRadius: "5px",
                        },
                      //border colour when focused
                      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          border: "0px solid var(--btn-color))",
                        },
                      //floating label color when focused
                      "& .MuiInputLabel-outlined.Mui-focused": {
                        color: "var(--btn-color)",
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                  <ErrorMessage
                    name="ReceiverEmail"
                    component="span"
                    className="error"
                    style={errorStyle}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: "20px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <InputLabel
                    htmlFor="input-with-icon-adornment"
                    sx={{
                      color: "var(--black-color)",
                      fontFamily: "var(--primary-font)",
                      marginBottom: "10px",
                    }}
                  >
                    Contact:
                  </InputLabel>
                  <Field
                    as={TextField}
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                    name="ReceiversContact"
                    id="input-with-icon-adornment"
                    className={
                      errors.ReceiversContact && touched.ReceiversContact
                        ? "input-error"
                        : null
                    }
                    InputProps={{
                      endAdornment: (
                        <NavLink
                          style={{
                            marginRight: "10px",
                            color: "var(--theme-color)",
                          }}
                          onClick={onContinue}
                        >
                          Change
                        </NavLink>
                      ),
                    }}
                    sx={{
                      backgroundColor: "transparent",
                      fontFamily: "var(--primary-font)",
                      width: "100%",

                      " & .MuiOutlinedInput-root": {
                        "  &.Mui-focused fieldset": {
                          borderColor: "var(--btn-color)",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "0px solid var(--theme-color)",
                        borderRadius: "0",
                        padding: "0",
                      },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          // border: "1px solid #E4E4E4",
                          borderRadius: "5px",
                        },
                      //border colour when focused
                      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          border: "0px solid var(--btn-color))",
                        },
                      //floating label color when focused
                      "& .MuiInputLabel-outlined.Mui-focused": {
                        color: "var(--btn-color)",
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                  <ErrorMessage
                    name="ReceiversContact"
                    component="span"
                    className="error"
                    style={errorStyle}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: "20px",
                    width: "100%",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                  }}
                >
                  <InputLabel
                    htmlFor="input-with-icon-adornment"
                    sx={{
                      color: "var(--black-color)",
                      fontFamily: "var(--primary-font)",
                      marginBottom: "10px",
                    }}
                  >
                    Ship to:
                  </InputLabel>
                  <Field
                    as={TextField}
                    variant="outlined"
                    autoComplete="off"
                    size="small"
                    name="ShiptoAddress"
                    id="input-with-icon-adornment"
                    className={
                      errors.ShiptoAddress && touched.ShiptoAddress
                        ? "input-error"
                        : null
                    }
                    InputProps={{
                      endAdornment: (
                        <NavLink
                          style={{
                            marginRight: "10px",
                            color: "var(--theme-color)",
                          }}
                          onClick={onContinue}
                        >
                          Change
                        </NavLink>
                      ),
                    }}
                    sx={{
                      backgroundColor: "transparent",
                      fontFamily: "var(--primary-font)",
                      width: "100%",

                      " & .MuiOutlinedInput-root": {
                        "  &.Mui-focused fieldset": {
                          borderColor: "var(--btn-color)",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "0px solid var(--theme-color)",
                        borderRadius: "0",
                        padding: "0",
                      },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          // border: "1px solid #E4E4E4",
                          borderRadius: "5px",
                        },
                      //border colour when focused
                      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          border: "0px solid var(--btn-color))",
                        },
                      //floating label color when focused
                      "& .MuiInputLabel-outlined.Mui-focused": {
                        color: "var(--btn-color)",
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                  <ErrorMessage
                    name="ShiptoAddress"
                    component="span"
                    className="error"
                    style={errorStyle}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                    md: "row",
                    lg: "row",
                    xl: "row",
                  },
                  justifyContent: {
                    xs: "flex-start",
                    sm: "space-between",
                    md: "space-between",
                    lg: "space-between",
                    xl: "space-between",
                  },
                  alignItems: {
                    xs: "flex-start",
                    sm: "center",
                    md: "center",
                    lg: "center",
                    xl: "center",
                  },
                  marginTop: "40px",
                }}
              >
                <NavLink
                  style={{
                    color: "var(--theme-color)",
                  }}
                  onClick={onContinue}
                >
                  <img
                    src={process.env.PUBLIC_URL + "./assets/leftarrow.png"}
                    alt="..."
                    style={{
                      width: "20px",
                      height: "20px",
                      marginRight: "10px",
                    }}
                  />
                  Return to information
                </NavLink>
                <Button
                  variant="outlined"
                  type="submit"
                  // onClick={onContinue}
                  // className={!(dirty && isValid) ? "disabled-btn" : ""}
                  sx={{
                    backgroundColor: "var(--theme-color)",
                    color: "var(--white)",
                    fontFamily: "var(--primary-font)",
                    textTransform: "capitalize",
                    marginTop: {
                      xs: "20px",
                      sm: "0px",
                      md: "0px",
                      lg: "0px",
                      xl: "0px",
                    },
                    borderRadius: "5px",
                    padding: "10px 20px 10px 20px",
                    borderColor: "var(--theme-color)",
                    fontSize: {
                      xs: "9px",
                      sm: "10px",
                      md: "11px",
                      lg: "12px",
                      xl: "12px",
                    },
                    "&:hover": {
                      backgroundColor: "transparent",
                      color: "var(--theme-color)",
                      borderColor: "var(--theme-color)",
                    },
                  }}
                  disabled={isLoading}
                >
                  {isLoading ? (
                    <span style={{ color: "white" }}>Processing...</span>
                  ) : (
                    "Place Order"
                  )}
                </Button>

                <Modal
                  open={open}
                  onClose={handleClose}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      backgroundColor: "var(--white)",
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      padding: "10px",
                      width: {
                        xs: "95%",
                        sm: "75%",
                        md: "75%",
                        lg: "42%",
                        xl: "42%",
                      },
                      borderColor: "var(--white)",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        // flexWrap: "wrap",
                        marginTop: "10px",
                      }}
                    >
                      <InputLabel
                        htmlFor="input-with-icon-adornment"
                        sx={{
                          color: "var(--black-color)",
                          marginBottom: "10px",
                          fontWeight: "600",
                          fontFamily: "var(--primary-font)",
                          fontSize: {
                            xs: "14px",
                            sm: "15px",
                            md: "16px",
                            lg: "18px",
                            xl: "18px",
                          },
                        }}
                      >
                        Payment:
                      </InputLabel>
                      <Typography
                        variant="body1"
                        sx={{
                          color: "var(--light-black)",
                          fontFamily: "var(--primary-font)",
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "12px",
                            lg: "14px",
                            xl: "14px",
                          },
                          marginBottom: "10px",
                        }}
                      >
                        All transactions are secure and encrypted.
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100px",
                            height: "50px",
                            margin: "10px",
                            //hover
                            "&:hover": {
                              transform: "scale(1.2)",
                              transition: "all 0.2s ease-in-out",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `./assets/footer/khalti.png`
                            }
                            alt="..."
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              objectPosition: "center",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "100px",
                            height: "50px",
                            marginBottom: "10px",
                            //hover
                            "&:hover": {
                              transform: "scale(1.2)",
                              transition: "all 0.2s ease-in-out",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `./assets/footer/esewa.png`
                            }
                            alt="..."
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              objectPosition: "center",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "100px",
                            height: "50px",
                            marginBottom: "10px",
                            //hover
                            "&:hover": {
                              transform: "scale(1.2)",
                              transition: "all 0.2s ease-in-out",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `./assets/footer/paypal.png`
                            }
                            alt="..."
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              objectPosition: "center",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "100px",
                            height: "50px",
                            marginBottom: "10px",
                            //hover
                            "&:hover": {
                              transform: "scale(1.2)",
                              transition: "all 0.2s ease-in-out",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `./assets/footer/skrill.png`
                            }
                            alt="..."
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              objectPosition: "center",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "100px",
                            height: "50px",
                            marginBottom: "10px",
                            //hover
                            "&:hover": {
                              transform: "scale(1.2)",
                              transition: "all 0.2s ease-in-out",
                              cursor: "pointer",
                            },
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `./assets/footer/mastercard.png`
                            }
                            alt="..."
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              objectPosition: "center",
                            }}
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "100px",
                            height: "50px",
                            marginBottom: "10px",
                            //hover
                            "&:hover": {
                              transform: "scale(1.2)",
                              transition: "all 0.2s ease-in-out",
                              cursor: "pointer",
                            },
                          }}
                          onClick={handleClickVisa}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              `./assets/footer/visa.png`
                            }
                            alt="..."
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "contain",
                              objectPosition: "center",
                            }}
                          />
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                </Modal>
              </Box>
              <Modal
                open={openVisa}
                onClose={handleCloseVisa}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
              >
                <Box
                  sx={{
                    backgroundColor: "var(--white)",
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    padding: "10px",
                    width: {
                      xs: "95%",
                      sm: "75%",
                      md: "75%",
                      lg: "42%",
                      xl: "42%",
                    },
                    borderColor: "var(--white)",
                  }}
                >
                  <Stripe
                    totalamount={totalamount}
                    orderId={orderid}
                    handleCloseVisa={handleCloseVisa}
                  />
                </Box>
              </Modal>
            </Form>
          )}
        </Formik>
      </Box>
    </Box>
  );
};
