import React from "react";
import { Container, Skeleton } from "@mui/material";
import { TopSection } from "../../components/Shared/TopSection/TopSection";
import { Box } from "@mui/system";
import HorizontalLines from "../../components/Shared/Horizontalline/Horizontalline";
import Vector from "../../../src/components/assets/Vector.png";
import manicon from "../../components/assets/manicon.png";
import { RunningProjects } from "../../components/ProjectsComponent/Runningprojects/RunningProjects";
import { SupportProjects } from "../../components/ProjectsComponent/ProjectsBottom/SupportProjects";
import { PastInitiatives } from "../../components/ProjectsComponent/ProjectsBottom/PastInitiatives";
import { Subscribe } from "../../components/Shared/Subscribe/Subscribe";
import { Testimonial } from "../../components/Shared/Testimonial/Testimonial";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../hooks/useApi";
import { endpoint } from "../../constant/endpoint";

export const ProjectPage = () => {
  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.home_support_projects);
    return res.data;
  };

  const getTestimonialData = async () => {
    const res = await get(endpoint.project_testimonials);
    return res.data;
  };

  const { isDataLoading, data: supportprojectsData } = useQuery({
    queryKey: ["supportprojects"],
    queryFn: getData,
  });

  const { data: testimonial } = useQuery({
    queryKey: ["testimonial"],
    queryFn: getTestimonialData,
  });

  if (isDataLoading)
    return (
      <>
        <Skeleton variant="rectangular" width="50%" height="55vh" />
      </>
    );

  return (
    <Box>
      {" "}
      {supportprojectsData && (
        <Box>
          <TopSection
            title2={"PROJECTS"}
            subtitle1={supportprojectsData?.title_third}
            id={supportprojectsData?.id}
            image={supportprojectsData?.image}
          />
        </Box>
      )}
      <Container>
        <HorizontalLines imgUrl={`${Vector}`} />
        <RunningProjects />
      </Container>
      <SupportProjects />
      <Container>
        <HorizontalLines imgUrl={`${Vector}`} />
        <PastInitiatives />
        <HorizontalLines imgUrl={`${manicon}`} />
      </Container>
      <Testimonial data={testimonial} />
      <Container
        sx={{
          marginTop: {
            xs: "180px",
            sm: "180px",
            md: "110px",
            lg: "100px",
            xl: "100px",
          },
        }}
      >
        <Subscribe />
      </Container>
    </Box>
  );
};
