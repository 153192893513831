import React from "react";
import { Box } from "@mui/system";
import "./Account.css";
import { Container, Typography } from "@mui/material";
import { accountdata } from "../../Data/AccountData/AccountData";
import Tab from "@mui/material/Tab";
import TabContext from "@material-ui/lab/TabContext";
import TabList from "@material-ui/lab/TabList";
import TabPanel from "@material-ui/lab/TabPanel";
import { MyAccount } from "./MyAccount/MyAccount";
import { Orders } from "./Orders/Orders";
import { Profile } from "./Profile";
import { ReturnAndCancellation } from "./ReturnAndCancellation";
import { ChangePassword } from "./ChangePassword";
import Tabs from "@mui/material/Tabs";

import { styled } from "@mui/system";

const CustomTabPanel = styled(TabPanel)({
  "&.MuiTabPanel-root": {
    padding: "30px 0px 0px 0px",
  },
});

export const AccountTopContent = (props) => {
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const indicatorStyles = {
    backgroundColor: "var(--white)", // Change this to your desired color
    height: "3px", // Adjust the height of the indicator if needed
  };

  return (
    <Box>
      {accountdata.map((item) => {
        return (
          <Box
            className="accounttop-section"
            key={item.id}
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              padding: {
                xs: "50px 0px 50px 0px",
                sm: "50px 0px 50px 0px",
                md: "50px 0px 50px 0px",
                lg: "50px 0px 0px 0px",
                xl: "50px 0px 0px 0px",
              },
            }}
          >
            <Box
              className="top-section-left"
              sx={{
                width: {
                  xs: "95%",
                  sm: "95%",
                  md: "95%",
                  lg: "70%",
                  xl: "70%",
                },
                marginLeft: {
                  xs: "0",
                  sm: "1%",
                  md: "1%",
                  lg: "9%",
                  xl: "9%",
                },
                textAlign: "justify",
                padding: {
                  xs: "1rem",
                  sm: "1rem",
                  md: "1rem",
                  lg: "3.2rem",
                  xl: "3.2rem",
                },
                zIndex: "1",
              }}
            >
              <Typography
                variant="p"
                className="top-section-left-heading"
                sx={{
                  fontSize: {
                    xs: "1rem",
                    sm: "1rem",
                    md: "1.5rem",
                    lg: "2rem",
                    xl: "2.5rem",
                  },
                }}
              >
                {item.title}
              </Typography>
            </Box>
            <Box
              className="top-section-right"
              sx={{ zIndex: "0", width: "40%" }}
            >
              <img
                src={item.imgUrl}
                alt="Dharma Good Top Section"
                className="top-section-right-image"
                style={{ height: "auto", width: "100%" }}
              />
            </Box>
          </Box>
        );
      })}
      <Container
        sx={{
          marginTop: "-50px",
        }}
      >
        <TabContext value={value}>
          <Box>
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "flex",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
              }}
            >
              <TabList
                onChange={handleChange}
                aria-label="lab API tabs example"
                TabIndicatorProps={{
                  style: indicatorStyles,
                }}
              >
                <Tab
                  label="My Account"
                  value="1"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    color: "var(--white)",
                    fontSize: {
                      xs: "9px",
                      sm: "12px",
                      md: "12px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                />
                <Tab
                  label="Orders"
                  value="2"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    color: "var(--white)",
                    fontSize: {
                      xs: "9px",
                      sm: "12px",
                      md: "12px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                />
                <Tab
                  label="Profile"
                  value="3"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    color: "var(--white)",
                    fontSize: {
                      xs: "9px",
                      sm: "12px",
                      md: "12px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                />
                <Tab
                  label="Return and Cancellation"
                  value="4"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    color: "var(--white)",
                    fontSize: {
                      xs: "9px",
                      sm: "12px",
                      md: "12px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                />
                <Tab
                  label="Change Password"
                  value="5"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    color: "var(--white)",
                    fontSize: {
                      xs: "9px",
                      sm: "12px",
                      md: "12px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                />
              </TabList>
            </Box>
            <Box
              sx={{
                maxWidth: { xs: 380, sm: 480 },
                bgcolor: "background.paper",
                display: {
                  xs: "flex",
                  sm: "none",
                  md: "none",
                  lg: "none",
                  xl: "none",
                },
              }}
            >
              <Tabs
                TabIndicatorProps={{
                  style: indicatorStyles,
                }}
                value={value}
                onChange={handleChange}
                variant="scrollable"
                scrollButtons
                allowScrollButtonsMobile
                sx={{
                  "& .MuiTab-root.Mui-selected": {
                    color: "white",
                  },
                }}
              >
                <Tab
                  label="My Account"
                  value="1"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    color: "var(--white)",
                    fontSize: {
                      xs: "9px",
                      sm: "12px",
                      md: "12px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                />
                <Tab
                  label="Orders"
                  value="2"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    color: "var(--white)",
                    fontSize: {
                      xs: "9px",
                      sm: "12px",
                      md: "12px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                />
                <Tab
                  label="Profile"
                  value="3"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    color: "var(--white)",
                    fontSize: {
                      xs: "9px",
                      sm: "12px",
                      md: "12px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                />
                <Tab
                  label="Return and Cancellation"
                  value="4"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    color: "var(--white)",
                    fontSize: {
                      xs: "9px",
                      sm: "12px",
                      md: "12px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                />
                <Tab
                  label="Change Password"
                  value="5"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    color: "var(--white)",
                    fontSize: {
                      xs: "9px",
                      sm: "12px",
                      md: "12px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                />
              </Tabs>
            </Box>

            <CustomTabPanel {...props} value="1">
              <MyAccount />
            </CustomTabPanel>
            <CustomTabPanel {...props} value="2">
              <Orders />
            </CustomTabPanel>
            <CustomTabPanel {...props} value="3">
              <Profile />
            </CustomTabPanel>
            <CustomTabPanel {...props} value="4">
              <ReturnAndCancellation />
            </CustomTabPanel>
            <CustomTabPanel {...props} value="5">
              <ChangePassword />
            </CustomTabPanel>
          </Box>
        </TabContext>
      </Container>
    </Box>
  );
};
