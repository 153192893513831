import React from "react";
import { Box } from "@mui/system";
import { Typography, Button } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import axios from "axios";
import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const errorStyle = {
  color: "red",
  fontSize: "12px",
  marginleft: "3rem",
};

export const ChangePassword = () => {
  const cookies = new Cookies();
  const userToken = cookies.get("token");
  const [showOldPassword, setShowOldPassword] = React.useState(false);
  const [isProcessing, setIsProcessing] = React.useState(false);

  const handleClickShowOldPassword = () => setShowOldPassword((show) => !show);

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const [showNewPassword, setShowNewPassword] = React.useState(false);

  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const [showNewConfirmPassword, setShowNewConfirmPassword] =
    React.useState(false);

  const handleClickShowNewConfirmPassword = () =>
    setShowNewConfirmPassword((show) => !show);

  const initialValues = {
    oldpassword: "",
    newpassword: "",
    newconfirmpassword: "",
  };

  const DataSchema = Yup.object().shape({
    oldpassword: Yup.string().required("Required"),
    newpassword: Yup.string()
      .required("Required")
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
      ),
    newconfirmpassword: Yup.string()
      .required("Required")
      .oneOf([Yup.ref("newpassword"), null], "Passwords must match"),
  });

  const submitHandler = async (values, { resetForm }) => {
    setIsProcessing(true);
    const updatePaswords = {
      old_password: values.oldpassword,
      password: values.newpassword,
      password_confirmation: values.newconfirmpassword,
    };
    try {
      await axios.put(
        process.env.REACT_APP_JINLAP_BASE_URL + `/users/reset`,
        updatePaswords,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        }
      );
      toast.success("Password Updated Successfully");
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsProcessing(false);
    resetForm();
  };
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        flexDirection: "column",
        width: "100%",
      }}
    >
      <Typography
        variant="h6"
        component="div"
        sx={{
          color: "var(--black)",
          fontWeight: "500",
          fontFamily: "var(--primary-font)",
          marginBottom: "20px",
          marginTop: "30px",
          fontSize: {
            xs: "14px",
            sm: "16px",
            md: "18px",
            lg: "20px",
            xl: "24px",
          },
        }}
      >
        Change Password
      </Typography>
      <Formik
        initialValues={initialValues}
        validationSchema={DataSchema}
        onSubmit={submitHandler}
      >
        {({ errors, touched }) => (
          <Form
            style={{
              width: "100%",
            }}
          >
            <Box
              sx={{
                width: "100%",
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Box
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                  width: {
                    xs: "100%",
                    sm: "40%",
                    md: "40%",
                    lg: "40%",
                    xl: "40%",
                  },
                }}
              >
                <InputLabel
                  htmlFor="input-with-icon-adornment"
                  sx={{
                    color: "var(--black-color)",
                    fontFamily: "var(--primary-font)",
                    marginBottom: "10px",
                  }}
                >
                  Password <span style={{ color: "red" }}>*</span>
                </InputLabel>
                <Field
                  as={TextField}
                  variant="outlined"
                  size="medium"
                  name="oldpassword"
                  type={showOldPassword ? "text" : "password"}
                  label="Enter Your Password"
                  autoComplete="off"
                  id="input-with-icon-adornment"
                  InputProps={{
                    // <-- This is where the toggle button is added.
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowOldPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showOldPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  className={
                    errors.oldpassword && touched.oldpassword
                      ? "input-error"
                      : null
                  }
                  sx={{
                    backgroundColor: "transparent",
                    fontFamily: "var(--primary-font)",
                    width: "100%",

                    " & .MuiOutlinedInput-root": {
                      "  &.Mui-focused fieldset": {
                        borderColor: "var(--btn-color)",
                      },
                    },
                    "& .MuiOutlinedInput-root": {
                      border: "0px solid var(--theme-color)",
                      borderRadius: "0",
                      padding: "0",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        // border: "1px solid #E4E4E4",
                        borderRadius: "5px",
                      },
                    //border colour when focused
                    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "0px solid var(--btn-color))",
                      },
                    //floating label color when focused
                    "& .MuiInputLabel-outlined.Mui-focused": {
                      color: "var(--btn-color)",
                      backgroundColor: "transparent",
                    },
                  }}
                />
                <ErrorMessage
                  name="oldpassword"
                  component="span"
                  className="error"
                  style={errorStyle}
                />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "row",
                    md: "row",
                    lg: "row",
                    xl: "row",
                  },
                  width: "100%",
                  marginTop: "20px",
                }}
              >
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    width: {
                      xs: "100%",
                      sm: "40%",
                      md: "40%",
                      lg: "40%",
                      xl: "40%",
                    },
                  }}
                >
                  <InputLabel
                    htmlFor="input-with-icon-adornment"
                    sx={{
                      color: "var(--black-color)",
                      fontFamily: "var(--primary-font)",
                      marginBottom: "10px",
                    }}
                  >
                    Enter new Password <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Field
                    as={TextField}
                    variant="outlined"
                    size="medium"
                    name="newpassword"
                    type={showNewPassword ? "text" : "password"}
                    label="Enter Your Password"
                    autoComplete="off"
                    id="input-with-icon-adornment"
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showNewPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    className={
                      errors.newpassword && touched.newpassword
                        ? "input-error"
                        : null
                    }
                    sx={{
                      backgroundColor: "transparent",
                      fontFamily: "var(--primary-font)",
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                        lg: "100%",
                        xl: "100%",
                      },

                      " & .MuiOutlinedInput-root": {
                        "  &.Mui-focused fieldset": {
                          borderColor: "var(--btn-color)",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "0px solid var(--theme-color)",
                        borderRadius: "0",
                        padding: "0",
                      },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          // border: "1px solid #E4E4E4",
                          borderRadius: "5px",
                        },
                      //border colour when focused
                      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          border: "0px solid var(--btn-color))",
                        },
                      //floating label color when focused
                      "& .MuiInputLabel-outlined.Mui-focused": {
                        color: "var(--btn-color)",
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                  <ErrorMessage
                    name="newpassword"
                    component="span"
                    className="error"
                    style={errorStyle}
                  />
                </Box>
                <Box
                  sx={{
                    marginTop: "20px",
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    width: {
                      xs: "100%",
                      sm: "40%",
                      md: "40%",
                      lg: "40%",
                      xl: "40%",
                    },
                    marginLeft: {
                      xs: "0",
                      sm: "5%",
                      md: "5%",
                      lg: "5%",
                      xl: "5%",
                    },
                  }}
                >
                  <InputLabel
                    htmlFor="input-with-icon-adornment"
                    sx={{
                      color: "var(--black-color)",
                      fontFamily: "var(--primary-font)",
                      marginBottom: "10px",
                    }}
                  >
                    Enter Confirm Password{" "}
                    <span style={{ color: "red" }}>*</span>
                  </InputLabel>
                  <Field
                    as={TextField}
                    variant="outlined"
                    size="medium"
                    name="newconfirmpassword"
                    type={showNewConfirmPassword ? "text" : "password"}
                    label="Enter Your Password"
                    autoComplete="off"
                    id="input-with-icon-adornment"
                    InputProps={{
                      // <-- This is where the toggle button is added.
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewConfirmPassword}
                            onMouseDown={handleMouseDownPassword}
                          >
                            {showNewConfirmPassword ? (
                              <Visibility />
                            ) : (
                              <VisibilityOff />
                            )}
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    className={
                      errors.newconfirmpassword && touched.newconfirmpassword
                        ? "input-error"
                        : null
                    }
                    sx={{
                      backgroundColor: "transparent",
                      fontFamily: "var(--primary-font)",
                      width: {
                        xs: "100%",
                        sm: "100%",
                        md: "100%",
                        lg: "100%",
                        xl: "100%",
                      },

                      " & .MuiOutlinedInput-root": {
                        "  &.Mui-focused fieldset": {
                          borderColor: "var(--btn-color)",
                        },
                      },
                      "& .MuiOutlinedInput-root": {
                        border: "0px solid var(--theme-color)",
                        borderRadius: "0",
                        padding: "0",
                      },
                      "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          // border: "1px solid #E4E4E4",
                          borderRadius: "5px",
                        },
                      //border colour when focused
                      "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                        {
                          border: "0px solid var(--btn-color))",
                        },
                      //floating label color when focused
                      "& .MuiInputLabel-outlined.Mui-focused": {
                        color: "var(--btn-color)",
                        backgroundColor: "transparent",
                      },
                    }}
                  />
                  <ErrorMessage
                    name="newconfirmpassword"
                    component="span"
                    className="error"
                    style={errorStyle}
                  />
                </Box>
              </Box>
              <Button
                variant="outlined"
                type="submit"
                sx={{
                  marginTop: "40px",
                  borderColor: "var(--theme-color)",
                  backgroundColor: "var(--theme-color)",
                  color: "var(--white)",
                  fontFamily: "var(--primary-font)",
                  fontSize: {
                    xs: "11px",
                    sm: "14px",
                    md: "14px",
                    lg: "15px",
                    xl: "15px",
                  },
                  "&:hover": {
                    backgroundColor: "var(--theme-color)",
                    borderColor: "var(--theme-color)",
                    color: "var(--white)",
                    transition: "all 0.5s ease",
                    transform: "scale(1.1)",
                  },
                }}
                disabled={isProcessing}
              >
                {isProcessing ? "Processing..." : "Update Password"}
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/left.png"}
                  alt="..."
                  style={{
                    width: "15px",
                    height: "20px",
                    marginLeft: "10px",
                  }}
                />
              </Button>
            </Box>
          </Form>
        )}
      </Formik>
    </Box>
  );
};
