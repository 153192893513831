import React from "react";
import { Box } from "@mui/system";
import { Typography, Container } from "@mui/material";
import HorizontalLines from "../../Shared/Horizontalline/Horizontalline";
import lotus from "../../assets/lotus.png";
import { StoryBottom } from "./StoryBottom";
import "./Story.css";

export const Story = ({
  left_image,
  left_title,
  left_description,
  center_image,
  right_image,
  right_title,
  right_description,
  background_image,
  watermark,
}) => {
  return (
    <Box
      className="story-cont"
      sx={{
        backgroundImage: `url(${background_image})`,
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="overlay"></div>
      <Container
        sx={{
          position: "relative",
          marginBottom: "25px",
        }}
      >
        <Box
          sx={{
            position: "relative",
            // top: {
            //   xs: "-250px",
            //   sm: "-200px",
            //   md: "-90px",
            //   lg: "-65px",
            //   xl: "-70px",
            // },
            top: "0px",
          }}
        >
          <HorizontalLines imgUrl={`${lotus}`} />
        </Box>

        <Box>
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: "10px",
                sm: "11px",
                md: "13px",
                lg: "15px",
                xl: "15px",
              },
              color: "var(--white)",
              fontFamily: "var(--primary-font)",
              textAlign: "center",
            }}
          >
            STORY BEHIND JINLAP "DHARMA GOODS"
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontSize: {
                xs: "20px",
                sm: "23px",
                md: "28px",
                lg: "33px",
                xl: "35px",
              },
              color: "var(--white)",
              fontFamily: "var(--primary-font)",
              textAlign: "center",
            }}
          >
            What makes all this possible?
          </Typography>
        </Box>
      </Container>

      <StoryBottom
        left_image={left_image}
        left_title={left_title}
        left_description={left_description}
        center_image={center_image}
        right_image={right_image}
        right_title={right_title}
        right_description={right_description}
        watermark={watermark}
      />
    </Box>
  );
};
