import React from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../../hooks/useApi";

export const GoodDharma = () => {
  const navlinkStyle = {
    textDecoration: "none",
    color: "var(--black)",
    margin: "5px",
  };

  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.all_good_dharma);
    return res.data;
  };

  const { isDataLoading, data: allGoodDharma } = useQuery({
    queryKey: ["allGoodDharma"],
    queryFn: getData,
  });

  if (isDataLoading) return <>Loading ...</>;
  return (
    <>
      <Grid container>
        <Grid
          item
          xs={12}
          sx={{
            textAlign: "justify",
          }}
        >
          <Box sx={{ marginBottom: "20px" }}>
            <Typography
              variant="span"
              sx={{
                color: "var(--theme-color)",
                fontWeight: "bold",
                marginBottom: "20px",
                fontSize: {
                  xs: "15px",
                  sm: "15px",
                  md: "17px",
                  lg: "20px",
                  xl: "20px",
                },
              }}
            >
              Good Dharma
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              fontSize: {
                xs: "11px",
                sm: "11px",
                md: "13px",
                lg: "15px",
                xl: "15px",
              },
            }}
          >
            {allGoodDharma?.map((item) => {
              return (
                <NavLink
                  to={`/gooddharma/${item.id}`}
                  style={navlinkStyle}
                  key={item.id}
                >
                  <Typography variant="span">{item.name}</Typography>
                </NavLink>
              );
            })}
          </Box>
        </Grid>
      </Grid>
    </>
  );
};
