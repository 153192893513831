import React, { useEffect } from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { GoodsSection } from "./GoodsSection/GoodsSection";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const DharmaGoodSearch = ({ DharmaGoodDataLength, Name, id }) => {
  const [query, setQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]); // State to store search results

  // Function to handle the query and perform API request
  const handleQueryChange = async (event) => {
    if (event) {
      const newQuery = event.target.value;
      setQuery(newQuery);
      try {
        const response = await axios.post(
          process.env.REACT_APP_JINLAP_BASE_URL + `/product/search`,
          {
            search: newQuery,
          }
        );
        setSearchResults(response.data.data);
      } catch (error) {
        console.error("Error making API request:", error);
      }
    }
  };

  useEffect(() => {
    handleQueryChange();
  }, [query]);

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
            md: "row",
            lg: "row",
            xl: "row",
          },
          width: "100%",
          justifyContent: {
            xs: "flex-start",
            sm: "space-between",
            md: "space-between",
            lg: "space-between",
            xl: "space-between",
          },
          alignItems: "flex-start",
          marginBottom: "2rem",
        }}
      >
        <Box
          className="searchleft_section"
          sx={{
            textAlign: "justify",
            display: {
              xs: "none",
              sm: "flex",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Typography variant="p" sx={{ color: "var(--theme-color)" }}>
            SHOWING RESULTS
          </Typography>

          <Typography variant="p">
            {DharmaGoodDataLength} result of "
            <Typography variant="span" sx={{ color: "var(--theme-color)" }}>
              {Name}
            </Typography>
            "
          </Typography>
        </Box>
        <Box
          className="searchright_section"
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row",
            },
            // backgroundColor: "green",
            width: {
              xs: "100%",
              sm: "40%",
              md: "30%",
              lg: "30%",
              xl: "30%",
            },
            justifyContent: "flex-end",
            alignItems: {
              xs: "flex-start",
              sm: "flex-end",
              md: "center",
              lg: "center",
              xl: "center",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
            }}
          >
            <TextField
              id="outlined-basic"
              label="What are you looking for?"
              variant="outlined"
              size="small"
              required
              // value={query}
              onChange={handleQueryChange}
              sx={{
                width: {
                  xs: "100%",
                  sm: "100%",
                  md: "100%",
                  lg: "100%",
                  xl: "100%",
                },
                height: "100%",
                " & .MuiOutlinedInput-root": {
                  "  &.Mui-focused fieldset": {
                    borderColor: "var(--theme-color)",
                  },
                },
                "& .MuiOutlinedInput-root": {
                  border: "0px solid var(--white)",
                  borderRadius: "0",
                  padding: "0",
                },
                "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                  border: "1px solid #eee",
                },
                //border colour when focused
                "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                  {
                    border: "1px solid var(--theme-color)",
                  },
                //floating label color when focused
                "& .MuiInputLabel-outlined.Mui-focused": {
                  color: "var(--theme-color)",
                },
              }}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon />
                  </InputAdornment>
                ),
              }}
              inputProps={{
                style: {
                  fontSize: {
                    xs: "11px",
                    sm: "11px",
                    md: "18px",
                    lg: "20px",
                    xl: "20px",
                  },
                },
              }}
            />
          </Box>
        </Box>
        <Box
          className="searchleft_section"
          sx={{
            textAlign: "justify",
            display: {
              xs: "flex",
              sm: "none",
              md: "none",
              lg: "none",
              xl: "none",
            },
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            marginTop: "20px",
          }}
        >
          <Typography variant="p" sx={{ color: "var(--theme-color)" }}>
            SHOWING RESULTS
          </Typography>

          <Typography variant="p">
            {DharmaGoodDataLength} result of "
            <Typography variant="span" sx={{ color: "var(--theme-color)" }}>
              {Name}
            </Typography>
            "
          </Typography>
        </Box>
      </Box>
      <Box sx={{ marginBottom: "2rem" }}>
        <div className="horizontal-line"></div>
        <div className="horizontal-line"></div>
        <div className="horizontal-line"></div>

        <GoodsSection id={id} searchResults={searchResults} />
      </Box>
    </>
  );
};
