import React from "react";
import { Box } from "@mui/system";
import { Topbar } from "../Topbar/Topbar";
import { Navbar } from "../Navbar/Navbar";
import { Bottombar } from "../../LandingPage/Bottombar/Bottombar";
import { useNavigate } from "react-router-dom";
import "./AppBar.css";
import { endpoint } from "../../../constant/endpoint";
import Skeleton from "@mui/material/Skeleton";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../../hooks/useApi";

export const AppBar = () => {
  const navigate = useNavigate();
  const { get } = useAPI();

  const getData = async () => {
    const res = await get(endpoint.sitesettings);
    return res.data;
  };

  const { isDataLoading, data: SitesettingsData } = useQuery({
    queryKey: ["sitesettingsData"],
    queryFn: getData,
  });

  if (isDataLoading)
    return (
      <>
        <Skeleton variant="rectangular" width="150px" height="200px" />
      </>
    );

  return (
    <div>
      <Box
        sx={{
          top: "0",
          position: "relative",
          zIndex: "1",
        }}
      >
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
          }}
        >
          <Topbar />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "flex-end",
          }}
        >
          <Box
            sx={{
              marginTop: {
                xs: "0px",
                sm: "0px",
                md: "-15px",
                lg: "-15px",
                xl: "-15px",
              },
              cursor: "pointer",
            }}
            onClick={() => {
              navigate("/");
            }}
          >
            <Box
              sx={{
                position: "relative",
                display: "flex",
                justifyContent: "flex-end",
                alignItems: "center",
                top: {
                  xs: "10px",
                  sm: "0px",
                  md: "0px",
                  lg: "0px",
                  xl: "0px",
                },
                left: {
                  xs: "10px",
                  sm: "30px",
                  md: "30px",
                  lg: "40px",
                  xl: "40px",
                },
                zIndex: "100",
                margin: {
                  xs: "10px 0px 0px 0px",
                  sm: "10px 0px 0px 0px",
                  md: "10px 0px 0px 10px",
                  lg: "10px 0px 0px 10px",
                  xl: "10px 0px 0px 10px",
                },
                height: {
                  xs: "6.5vh",
                  sm: "8vh",
                  md: "9vh",
                  lg: "10vh",
                  xl: "10vh",
                },
                width: {
                  xs: "34vw",
                  sm: "25vw",
                  md: "15vw",
                  lg: "13vw",
                  xl: "13vw",
                },
              }}
              className="logo"
            >
              <img
                src={SitesettingsData?.logo}
                alt="..."
                style={{
                  position: "absolute",
                  width: "100%",
                  height: "100%",
                }}
              />
            </Box>
          </Box>
          <Navbar />
        </Box>
      </Box>
      <Box sx={{ display: "flex" }}>
        <Bottombar />
      </Box>
    </div>
  );
};
