import React from "react";
import { Box } from "@mui/system";
import { Grid, Container } from "@mui/material";
import { ProductDetailsTopbar } from "../../components/ProductDetails/ProductDetailsTopbar";
import { useParams } from "react-router-dom";
import { ProductDetailsLeftSection } from "../../components/ProductDetails/ProductDetailsLeftSection";
import { useMediaQuery, useTheme } from "@mui/material";
import { ProductDetailsRightSection } from "../../components/ProductDetails/ProductDetailsRightSection";
import { ProdReviews } from "../../components/GoodDharmaDetails/PujaReviews/Reviews/ProdReviews";
import { Similarproducts } from "../../components/ProductDetails/Similarproducts";
import HorizontalLines from "../../components/Shared/Horizontalline/Horizontalline";
import Buddhaicon from "../../components/assets/buddhaicon.png";
import { ArtistStory } from "../../components/ProductDetails/ArtistsStory";
import useAPI from "../../hooks/useApi";
import { endpoint } from "../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const ProductDetails = () => {
  const { id } = useParams();

  const { getById } = useAPI();

  const getData = async () => {
    const res = await getById(endpoint.getgoodsbyId, id);
    return res.data;
  };

  const { data: productsDetails } = useQuery({
    queryKey: ["productsDetails", id],
    queryFn: getData,
  });

  const theme = useTheme();
  // Use the useMediaQuery hook to get the screen size
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm", "xs"));
  const spacing = isSmallScreen ? 0 : 4;

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {
        productsDetails && (
          <>
            <ProductDetailsTopbar
              firsttitle={
                productsDetails?.category && productsDetails?.category.name
              }
              secondtitle={productsDetails?.name}
            />
            <Container>
              <Grid
                container
                xs={12}
                spacing={spacing}
                sx={{
                  marginTop: "30px",
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "column",
                    lg: "row",
                    xl: "row",
                  },
                  justifyContent: "space-between",
                }}
              >
                <>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <ProductDetailsLeftSection />
                    <Box
                      sx={{
                        display: {
                          xs: "none",
                          sm: "none",
                          md: "none",
                          lg: "block",
                          xl: "block",
                        },
                      }}
                    >
                      {productsDetails?.artist && (
                        <ArtistStory
                          name={productsDetails?.artist.name}
                          imgsrc={productsDetails?.artist.image[0]?.image_path}
                          desc={productsDetails?.artist_description}
                        />
                      )}
                    </Box>
                  </Grid>
                  <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
                    <Box>
                      <ProductDetailsRightSection />
                    </Box>
                  </Grid>

                  <Grid item xs={12}>
                    <Box
                      sx={{
                        display: {
                          xs: "flex",
                          sm: "flex",

                          md: "flex",
                          lg: "none",
                          xl: "none",
                        },
                      }}
                    >
                      {productsDetails?.artist && (
                        <ArtistStory
                          name={productsDetails?.artist.name}
                          imgsrc={productsDetails?.artist.image[0]?.image_path}
                          desc={productsDetails?.artist_description}
                        />
                      )}
                    </Box>
                  </Grid>
                </>
              </Grid>
            </Container>
            <Container>
              <ProdReviews
                prodId={productsDetails?.id}
                prodTitle={productsDetails?.name}
                prodreviews={productsDetails?.reviews}
                prodquestions={productsDetails?.questions}
                avgRating={productsDetails?.average_rating}
                totalreviews={productsDetails?.total_reviews}
              />
              <Box>
                <HorizontalLines imgUrl={`${Buddhaicon}`} />
                <h5
                  style={{ color: "var(--theme-color)", marginBottom: "20px" }}
                >
                  DHARMA GOODS
                </h5>
                <h2
                  style={{ color: "var(--light-black)", marginBottom: "50px" }}
                >
                  CRAFTED BY HANDS, MADE WITH SOUL
                </h2>
                <Similarproducts />
              </Box>
            </Container>
          </>
        )
        // );
        // })}
      }
    </Box>
  );
};
