import React, { useState, useEffect } from "react";
import axios from "axios";
import { Box } from "@mui/system";
import { Typography, Button, Grid } from "@mui/material";
import { SearchComponent } from "./SearchComponent";
import { useSelector } from "react-redux";
import { IconButton } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useNavigate } from "react-router-dom";
import { NavLink } from "react-router-dom";
import ReactPlayer from "react-player";
import VideocamIcon from "@mui/icons-material/Videocam";
import HorizontalLines from "../Shared/Horizontalline/Horizontalline";
import { useCookies } from "react-cookie";


const sectOptions = [
  {
    id: 1,
    title: "Nyingma",
  },
  {
    id: 2,
    title: "Kagyu",
  },
  {
    id: 3,
    title: "Sakya",
  },
  {
    id: 4,
    title: "Gelug",
  },
];

export const LandingSearchComponent = ({ query }) => {
  const [token, setToken] = useState(""); // Initialize the token state
  const [cookies] = useCookies(["userId", "token"]);

  useEffect(() => {
    // Check if the token is available in cookies
    if (cookies.token) {
      setToken(cookies.token);
      // You can also perform other actions that depend on the token here
    }
  }, [cookies.token]);
  const navigate = useNavigate();
  const { selectedCurrency, rate } = useSelector((state) => state.currency);
  const [searchData, setSearchData] = useState([]);

  const [displayedItems, setDisplayedItems] = useState(2);
  const [showMoreMap, setShowMoreMap] = useState({});
  const [showiconMap, setShowiconMap] = useState({});

  const handleShowMoreClick = (itemId) => {
    setShowMoreMap((prevShowMoreMap) => ({
      ...prevShowMoreMap,
      [itemId]: true,
    }));
  };

  const handleShowLessClick = (itemId) => {
    setShowMoreMap((prevShowMoreMap) => ({
      ...prevShowMoreMap,
      [itemId]: false,
    }));
  };

  useEffect(() => {
    const fetchSearchData = async () => {
      const res = await axios.post(
        process.env.REACT_APP_JINLAP_BASE_URL + `/search`,
        query,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setSearchData(res.data);
    };
    fetchSearchData();
  }, []);

  const resultNumber =
    searchData.products?.length + searchData.services?.length;

  return (
    <Box>
      <SearchComponent query={query} resultNumber={resultNumber} />

      {searchData.products?.length > 0 ? (
        <Box
          sx={{
            marginTop: "100px",
            marginBottom: "100px",
          }}
        >
          <HorizontalLines title={"DHARMA GOODS"} />
          <Grid container spcaing={2}>
            {searchData.products.map((item) => {
              return (
                <Grid xs={12} sm={6} md={6} lg={3} xl={3} key={item.id}>
                  <div className="card" style={{ border: "0" }}>
                    <Box
                      sx={{
                        position: "relative",
                        "&:hover": {
                          "&::before": {
                            content: "''",
                            position: "absolute",
                            top: 0,
                            left: 0,
                            right: 0,
                            bottom: 0,
                            backgroundColor: "var(--light-theme-color)", // Light pink color
                            opacity: 0.7,
                            zIndex: 1,
                          },
                          "& button": {
                            visibility: "visible",
                          },
                        },
                      }}
                    >
                      <Box
                        sx={{
                          position: "relative",
                        }}
                      >
                        <Box
                          sx={{
                            position: "absolute",
                            top: "0",
                            right: "0",
                            zIndex: 2,
                            padding: "2px 5px 2px 5px",
                            backgroundColor: "var(--yellow)",
                            color: "var(--white)",
                          }}
                        >
                          <Typography
                            variant="p"
                            sx={{
                              fontSize: {
                                xs: "10px",
                                sm: "11px",
                                md: "11px",
                                lg: "12px",
                                xl: "12px",
                              },
                            }}
                          >
                            4.3
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            height: "35vh",
                          }}
                        >
                          <img
                            src={item.images[0]?.image}
                            alt={item.name}
                            style={{
                              height: "100%",
                              width: "100%",
                              // marginRight: "20px",
                              objectFit: "cover",
                              objectPosition: "center",
                            }}
                          />
                        </Box>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                        }}
                      >
                        {/* Icons */}
                        <IconButton
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "40%",
                            transform: "translate(-50%, -50%)",
                            visibility: "hidden",
                            zIndex: 2,
                          }}
                          size="small"
                          color="primary"
                          onClick={async () => {
                            const wishlistdata = {
                              color: item.colors[0].color,
                              size: item.sizes[0].size,
                              product_id: item.id,
                              currency: selectedCurrency,
                              qty: 1,
                              price: item.after_discount
                                ? (item.after_discount / rate).toFixed(2)
                                : (item.price / rate).toFixed(2),
                            };

                            try {
                              const response = await axios.post(
                                process.env.REACT_APP_JINLAP_BASE_URL +
                                  `/products/add-to-wishlist`,
                                wishlistdata,
                                {
                                  headers: {
                                    Authorization: `Bearer ${token}`,
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                  },
                                }
                              );
                              {
                                response.data.status === true
                                  ? toast.success(
                                      `${item.name} added to wishlist successfully!`
                                    )
                                  : toast.warning(response.data.message);
                              }
                            } catch (error) {
                              toast.error(error.response.data.message);
                            }
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: "var(--very-lightthemecolor)",
                              padding: "5px",
                              borderRadius: "50%",
                              color: "var(--theme-color)",
                              "&:hover": {
                                //zoom the buttom
                                transform: "scale(1.1)",
                                backgroundColor: "var(--theme-color)",
                                color: "var(--white)",
                              },
                            }}
                          >
                            <FavoriteBorderIcon fontSize="medium" />
                          </Box>
                        </IconButton>
                        <IconButton
                          sx={{
                            position: "absolute",
                            top: "50%",
                            left: "60%",
                            transform: "translate(-50%, -50%)",
                            visibility: "hidden",
                            zIndex: 2,
                          }}
                          size="small"
                          color="primary"
                          onClick={async () => {
                            const data = {
                              color: item.colors[0].color,
                              size: item.sizes[0].size,
                              qty: 1,
                              product_id: item.id,
                              currency: selectedCurrency,
                              price: item.after_discount
                                ? (item.after_discount / rate).toFixed(2)
                                : (item.price / rate).toFixed(2),
                            };

                            try {
                              const response = await axios.post(
                                process.env.REACT_APP_JINLAP_BASE_URL +
                                  `/products/add-to-cart`,
                                data,
                                {
                                  headers: {
                                    Authorization: `Bearer ${token}`,
                                    Accept: "application/json",
                                    "Content-Type": "application/json",
                                  },
                                }
                              );
                              {
                                response.data.status === true
                                  ? toast.success(
                                      `${item.name} added to cart successfully!`
                                    )
                                  : toast.warning(response.data.message);
                              }
                            } catch (error) {
                              toast.error(error.response.data.message);
                            }
                          }}
                        >
                          <Box
                            sx={{
                              backgroundColor: "var(--very-lightthemecolor)",
                              padding: "5px",
                              borderRadius: "50%",
                              color: "var(--theme-color)",
                              "&:hover": {
                                //zoom the buttom
                                transform: "scale(1.1)",
                                backgroundColor: "var(--theme-color)",
                                color: "var(--white)",
                              },
                            }}
                          >
                            <ShoppingBagOutlinedIcon fontSize="medium" />
                          </Box>
                        </IconButton>
                      </Box>
                    </Box>

                    <div className="card-body">
                      <Typography
                        variant="p"
                        sx={{
                          marginBottom: "10px",
                          display: "flex",
                          justifyContent: "flex-start",
                          color: "var(--theme-color)",
                          fontSize: {
                            xs: "15px",
                            sm: "18px",
                            md: "20px",
                            lg: "20px",
                            xl: "20px",
                          },
                          cursor: "pointer",
                        }}
                        onClick={() => {
                          navigate(`/productdetails/${item.id}`);
                        }}
                      >
                        {item.name}
                      </Typography>
                      <Typography
                        sx={{
                          marginBottom: "10px",
                          display: "flex",
                          textAlign: "justify",
                          marginRight: "20px",
                          fontSize: {
                            xs: "11px",
                            sm: "11px",
                            md: "13px",
                            lg: "15px",
                            xl: "15px",
                          },
                          overflow: "hidden", // Hide any overflowing text
                          textOverflow: "ellipsis", // Display ellipsis for long texts
                          display: "-webkit-box",
                          WebkitLineClamp: 2, // Adjust the number of lines to fit your requirements
                          WebkitBoxOrient: "vertical",
                        }}
                      >
                        {item.description}
                      </Typography>
                      <Typography
                        variant="span"
                        sx={{
                          display: "flex",
                          justifyContent: "flex-start",
                          color: "var(--theme-color)",
                          fontSize: {
                            xs: "7px",
                            sm: "11px",
                            md: "13px",
                            lg: "15px",
                            xl: "15px",
                          },
                        }}
                      >
                        {item.after_discount ? (
                          <>
                            <del>
                              {selectedCurrency} {""}{" "}
                              {(item.price / rate).toFixed(2)}
                            </del>
                            <span
                              style={{
                                marginLeft: "10px",
                              }}
                            >
                              {selectedCurrency} {""}{" "}
                              {(item.after_discount / rate).toFixed(2)}
                            </span>
                          </>
                        ) : (
                          <span>
                            {selectedCurrency} {""}
                            {item.price.toFixed(2)}
                          </span>
                        )}
                      </Typography>
                    </div>
                  </div>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      ) : (
        <Box>
          <Typography variant="h5">No Results Found</Typography>
        </Box>
      )}

      {searchData.services?.length > 0 ? (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <HorizontalLines title={"GOOD DHARMA"} />
          {searchData.services.slice(0, displayedItems).map((item, index) => {
            const isImageLeft = index % 2 === 0; // Check if the index is even

            const showMore = showMoreMap[item.id] || false;
            const showicon = showiconMap[item.id] || false;

            return (
              <Box
                key={item.id}
                sx={{
                  marginBottom: {
                    xs: "20px",
                    sm: "50px",
                    md: "80px",
                    lg: "100px",
                    xl: "80px",
                  },
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "49%",
                      md: "49%",
                      lg: "49%",
                      xl: "49%",
                    },
                    height: {
                      xs: "auto",
                      sm: "70%",
                      md: "60%",
                      lg: "50%",
                      xl: "40%",
                    },
                    float: {
                      xs: "none",
                      sm: isImageLeft ? "left" : "right",
                      md: isImageLeft ? "left" : "right",
                      lg: isImageLeft ? "left" : "right",
                      xl: isImageLeft ? "left" : "right",
                    },
                    marginLeft: {
                      xs: "0px",
                      sm: isImageLeft ? 0 : "25px",
                      md: isImageLeft ? 0 : "25px",
                      lg: isImageLeft ? 0 : "25px",
                      xl: isImageLeft ? 0 : "25px",
                    },
                    marginRight: {
                      xs: "0px",
                      sm: isImageLeft ? "25px" : 0,
                      md: isImageLeft ? "25px" : 0,
                      lg: isImageLeft ? "25px" : 0,
                      xl: isImageLeft ? "25px" : 0,
                    },
                    position: "relative",
                  }}
                >
                  {showicon === false ? (
                    <>
                      <ReactPlayer
                        url={item.videos[0]?.video}
                        controls={false}
                        width="100%"
                        height="100%"
                        style={{
                          objectFit: "cover",
                          backgroundColor: "var(--pathbar-color)",
                        }}
                      />

                      <Box
                        sx={{
                          backgroundColor: "var(--theme-color)",
                          padding: "8px 10px 10px 10px",
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          margin: "15px",
                          color: "#fff",
                          borderRadius: "50%",
                        }}
                        onMouseEnter={() => {
                          setShowiconMap((prevShowiconMap) => ({
                            ...prevShowiconMap,
                            [item.id]: true,
                          }));
                        }}
                      >
                        <VideocamIcon
                          sx={{
                            height: "25px",
                            width: "25px",
                          }}
                        />
                      </Box>
                    </>
                  ) : (
                    <ReactPlayer
                      url={item.videos[0]?.video}
                      controls={true}
                      width="100%"
                      height="100%"
                      style={{
                        objectFit: "cover",
                        backgroundColor: "var(--pathbar-color)",
                      }}
                    />
                  )}
                </Box>

                <Box
                  sx={{
                    textAlign: "justify",
                  }}
                >
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: {
                        xs: "18px",
                        sm: "18px",
                        md: "20px",
                        lg: "20px",
                        xl: "20px",
                      },
                      fontWeight: "bold",
                      color: "var(--light-black)",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <br />
                  <br />

                  {!showMore && (
                    <>
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: {
                            xs: "13px",
                            sm: "13px",
                            md: "13px",
                            lg: "15px",
                            xl: "15px",
                          },
                          color: "var(--light-black)",
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          display: "-webkit-box",
                          fontFamily: "var(--primary-font) !important",
                          WebkitLineClamp: {
                            xs: 10,
                            sm: 6,
                            md: 9,
                            lg: 11,
                            xl: 11,
                          },
                          WebkitBoxOrient: "vertical",
                        }}
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      />
                      <NavLink
                        style={{
                          color: "var(--theme-color)",
                          marginBottom: "30px",
                        }}
                        onClick={() => handleShowMoreClick(item.id)}
                      >
                        Show more
                      </NavLink>
                    </>
                  )}
                  {showMore && (
                    <>
                      <Typography
                        variant="p"
                        sx={{
                          fontSize: {
                            xs: "13px",
                            sm: "13px",
                            md: "13px",
                            lg: "15px",
                            xl: "15px",
                          },
                          color: "var(--light-black)",
                          //   overflow: "hidden",
                          //   textOverflow: "ellipsis",
                          //   display: "-webkit-box",
                          //   WebkitLineClamp: 3,
                          //   WebkitBoxOrient: "vertical",
                        }}
                        dangerouslySetInnerHTML={{ __html: item.description }}
                      >
                        {/* {item.description} */}
                      </Typography>
                      <NavLink
                        style={{
                          color: "var(--theme-color)",
                          marginBottom: "30px",
                        }}
                        onClick={() => handleShowLessClick(item.id)}
                      >
                        Show less
                      </NavLink>
                    </>
                  )}
                  {/* {!showMore && ( */}
                  <Box sx={{ display: "flex" }}>
                    <Button
                      variant="contained"
                      sx={{
                        backgroundColor: "var(--theme-color)",
                        color: "var(--white)",
                        marginBottom: "10px",
                        fontSize: {
                          xs: "8px",
                          sm: "10px",
                          md: "12px",
                          lg: "15px",
                          xl: "15px",
                        },
                        marginTop: {
                          xs: "10px",
                          sm: "10px",
                          md: "15px",
                          lg: "18px",
                          xl: "18px",
                        },
                        padding: "10px",
                        "&:hover": {
                          backgroundColor: "var(--theme-color)",
                          color: "var(--white)",
                          transform: "scale(1.1)",
                        },
                      }}
                      onClick={() => {
                        navigate(`/gooddharma/${item.id}`);
                      }}
                    >
                      Take a look
                      <img
                        src={process.env.PUBLIC_URL + "/assets/icons/left.png"}
                        alt="..."
                        style={{
                          width: "15px",
                          height: "20px",
                          marginLeft: "10px",
                        }}
                      />
                    </Button>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        marginLeft: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          backgroundColor: "var(--very-lightthemecolor)",
                          padding: "5px",
                          borderRadius: "50%",
                          color: "var(--theme-color)",
                          "&:hover": {
                            //zoom the buttom
                            transform: "scale(1.1)",
                            backgroundColor: "var(--theme-color)",
                            color: "var(--white)",
                          },
                        }}
                        onClick={async () => {
                          const todaysDate = new Date();
                          todaysDate.setDate(todaysDate.getDate() + 1); // Add 1 day to get tomorrow's date
                          const selecteddate = todaysDate
                            .toISOString()
                            .split("T")[0]; // Format as "YYYY-MM-DD"

                          const selectedData = {
                            service_id: item.id,
                            // title: serviceDetails.name,
                            // imgUrl: serviceDetails.images[0].image,
                            date: selecteddate,
                            sect: sectOptions[0].title,
                            size: item.sizes[0].size,
                            price: item.sizes[0].price,
                          };

                          try {
                            const response = await axios.post(
                              process.env.REACT_APP_JINLAP_BASE_URL +
                                `/services/add-to-wishlist`,
                              selectedData,
                              {
                                headers: {
                                  Authorization: `Bearer ${token}`,
                                  Accept: "application/json",
                                  "Content-Type": "application/json",
                                },
                              }
                            );
                            {
                              response.data.status === true
                                ? toast.success(
                                    `${item.name} added to wishlist successfully!`
                                  )
                                : toast.warning(response.data.message);
                            }
                          } catch (error) {
                            toast.error(error.response.data.message);
                          }
                        }}
                      >
                        <FavoriteBorderIcon />
                      </Box>
                      <Box
                        sx={{
                          backgroundColor: "var(--very-lightthemecolor)",
                          padding: "5px",
                          borderRadius: "50%",
                          color: "var(--theme-color)",
                          marginLeft: "10px",
                          "&:hover": {
                            //zoom the buttom
                            transform: "scale(1.1)",
                            backgroundColor: "var(--theme-color)",
                            color: "var(--white)",
                          },
                        }}
                        onClick={async () => {
                          const todaysDate = new Date();
                          todaysDate.setDate(todaysDate.getDate() + 1); // Add 1 day to get tomorrow's date
                          const selecteddate = todaysDate
                            .toISOString()
                            .split("T")[0]; // Format as "YYYY-MM-DD"

                          const selectedData = {
                            service_id: item.id,
                            // title: serviceDetails.name,
                            // imgUrl: serviceDetails.images[0].image,
                            date: selecteddate,
                            sect: sectOptions[0].title,
                            size: item.sizes[0].size,
                            price: item.sizes[0].price,
                          };

                          try {
                            const response = await axios.post(
                              process.env.REACT_APP_JINLAP_BASE_URL +
                                `/services/add-to-cart`,
                              selectedData,
                              {
                                headers: {
                                  Authorization: `Bearer ${token}`,
                                  Accept: "application/json",
                                  "Content-Type": "application/json",
                                },
                              }
                            );
                            {
                              response.data.status === true
                                ? toast.success(
                                    `${item.name} added to cart successfully!`
                                  )
                                : toast.warning(response.data.message);
                            }
                          } catch (error) {
                            toast.error(error.response.data.message);
                          }
                        }}
                      >
                        <ShoppingBagOutlinedIcon fontSize="medium" />
                      </Box>
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
      ) : (
        <Box>
          <Typography variant="h5">No Results Found</Typography>
        </Box>
      )}
    </Box>
  );
};
