export const PastIniativesTopData = [
  {
    id: 1,
    imgUrl: "./assets/DharmaGoodpage/topsec_img.png",
    title: "PAST INITIATIVES",
    subtitle1: "WHAT WE HAVE DONE IN THE PAST AND",
    subtitle2: "WHAT IMPACT WE HAVE MADE",
  },
];


