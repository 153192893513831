import React, { useState, useEffect } from "react";
import "./Testimonial.css";
import chatup from "../../assets/chatup.png";
import chatdown from "../../assets/chatdown.png";
import Slider from "react-slick";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { NavLink } from "react-router-dom";
import {
  ContNextArrow,
  ContPrevArrow,
  MobContNextArrow,
  MobContPrevArrow,
} from "../arrows/arrows";
import { Container } from "@mui/material";

export const Testimonial = ({ data }) => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Set isMobile based on screen width
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return (
    <Box className="testimonial">
      <Typography
        variant="p"
        sx={{
          color: "var(--theme-color)",
          marginBottom: "20px",
          fontSize: {
            xs: "15px",
            sm: "18px",
            md: "20px",
            lg: "20px",
            xl: "20px",
          },
        }}
      >
        TESTIMONIALS
      </Typography>
      <br />
      <Typography
        variant="p"
        sx={{
          color: "var(light-black)",
          fontSize: {
            xs: "18px",
            sm: "20px",
            md: "25px",
            lg: "25px",
            xl: "25px",
          },
          marginTop: "10px",
        }}
      >
        Hear what people have to say about us
      </Typography>

      <Container
        sx={{
          marginTop: {
            xs: "0px",
            sm: "0px",
            md: "80px",
            lg: "100px",
            xl: "40px",
          },
        }}
      >
        <Slider
          autoplay={true}
          dots={false}
          infinite={true}
          speed={500}
          autoplaySpeed={10000}
          slidesToShow={1}
          slidesToScroll={1}
          nextArrow={isMobile ? <MobContNextArrow /> : <ContNextArrow />}
          prevArrow={isMobile ? <MobContPrevArrow /> : <ContPrevArrow />}
        >
          {data?.map((item, index) => {
            return (
              <Box key={index}>
                <Box
                  className="testimonial__card"
                  sx={{
                    marginTop: {
                      xs: "80px",
                      sm: "80px",
                      md: "70px",
                      lg: "0px",
                      xl: "0px",
                    },
                    marginBottom: {
                      xs: "10px",
                      sm: "10px",
                      md: "80px",
                      lg: "100px",
                      xl: "100px",
                    },
                    flexDirection: {
                      xs: "column",
                      sm: "column",
                      md: "row",
                      lg: "row",
                      xl: "row",
                    },
                  }}
                >
                  <Box
                    className="testimonial__card__img"
                    sx={{
                      width: {
                        xs: "48%",
                        sm: "48%",
                        md: "25%",
                        lg: "25%",
                        xl: "25%",
                      },
                      height: "100%",
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/testimonial/mandala.png"
                      }
                      alt="Rectangle-26"
                      border="0"
                      style={{
                        position: "absolute",
                        zIndex: "1",
                        height: "auto",
                      }}
                    />
                    <Box
                      sx={{
                        height: "100px",
                        width: "100px",
                      }}
                    >
                      <img
                        src={item.image}
                        alt={item.title}
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          objectPosition: "center",
                          borderRadius: "50%",
                          zIndex: "2",
                        }}
                      />
                    </Box>
                  </Box>
                  <Box
                    className="testimonial__card__description"
                    sx={{
                      width: {
                        xs: "80%",
                        sm: "80%",
                        md: "55%",
                        lg: "50%",
                        xl: "50%",
                      },
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: {
                        xs: "center",
                        sm: "center",
                        md: "flex-start",
                        lg: "flex-start",
                        xl: "flex-start",
                      },
                      textAlign: {
                        xs: "justify",
                        sm: "justify",
                        md: "justify",
                        lg: "justify",
                        xl: "justify",
                      },
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        color: "var(--theme-color)",
                        marginBottom: "10px",
                        fontSize: {
                          xs: "15px",
                          sm: "15px",
                          md: "18px",
                          lg: "12px",
                          xl: "25px",
                        },
                        marginTop: {
                          xs: "90px",
                          sm: "90px",
                          md: "0px",
                          lg: "0px",
                          xl: "0px",
                        },
                      }}
                    >
                      {item.name}
                    </Typography>
                    <Typography
                      variant="span"
                      sx={{
                        marginBottom: "20px",
                        fontSize: {
                          xs: "13px",
                          sm: "13px",
                          md: "20px",
                          lg: "20px",
                          xl: "20px",
                        },
                        color: "var(--light-black)",
                      }}
                    >
                      {item.designation}
                    </Typography>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "center",
                        alignItems: "flex-start",
                      }}
                    >
                      <img
                        src={chatdown}
                        alt="..."
                        style={{
                          height: "auto",
                          width: "3vw",
                          marginLeft: "-15px",
                        }}
                      />

                      <Typography
                        sx={{
                          fontSize: {
                            xs: "11px",
                            sm: "13px",
                            md: "18px",
                            lg: "18px",
                            xl: "18px",
                          },
                        }}
                        // dangerouslySetInnerHTML={{
                        //   __html: item.description,
                        // }}
                      >
                        {item.description}
                        <Box
                          sx={{
                            display: "flex",
                            justifyContent: "flex-end",
                            alignItems: "flex-end",
                          }}
                        >
                          <img
                            src={chatup}
                            alt="..."
                            style={{
                              height: "100%",
                              width: "3vw",
                            }}
                          />
                        </Box>
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Slider>
      </Container>
    </Box>
  );
};
