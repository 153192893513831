import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import VideocamIcon from "@mui/icons-material/Videocam";
import { endpoint } from "../../../constant/endpoint";
import useAPI from "../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";

export const GoodDharmaContentImageSide = () => {
  const { id } = useParams();
  const { getById } = useAPI();

  const getImageData = async () => {
    const res = await getById(endpoint.all_good_dharma, id);
    return res.data;
  };

  const { data: gooddharmacontent } = useQuery({
    queryKey: ["Gooddharmacontent", id],
    queryFn: getImageData,
  });

  // Convert images object to an array and concatenate with videos array
  const combinedArray = [];

  if (Array.isArray(gooddharmacontent?.images)) {
    combinedArray.push(
      ...gooddharmacontent?.images.map((item) => ({ ...item, type: "image" }))
    );
  }

  if (Array.isArray(gooddharmacontent?.videos)) {
    combinedArray.push(
      ...gooddharmacontent?.videos.map((item) => ({ ...item, type: "video" }))
    );
  }

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1Ref = useRef(null);
  const slider2Ref = useRef(null);

  useEffect(() => {
    setNav1(slider1Ref.current);
    setNav2(slider2Ref.current);
  }, []);
  const [play, setPlay] = useState(false);

  return (
    <Box>
      {gooddharmacontent && (
        <>
          <Slider asNavFor={nav2} ref={slider1Ref} arrows={false} fade={true}>
            {combinedArray?.map((item, key) => {
              return (
                <Box
                  sx={{
                    width: "100%",
                    height: "50vh",
                  }}
                  onClick={() => {
                    setPlay(!play);
                  }}
                  key={key}
                >
                  {item.type === "video" ? (
                    <ReactPlayer
                      url={item.video}
                      controls={true}
                      width="100%"
                      height="100%"
                      playing={play}
                    />
                  ) : (
                    <img
                      src={item.image}
                      alt="projectimage"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: " center",
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Slider>

          <Box
            sx={{
              marginTop: "10px",
              marginBottom: {
                xs: "50px",
                sm: "80px",
                md: "10px",
                lg: "0px",
                xl: "0px",
              },
            }}
          >
            <Slider
              asNavFor={nav1}
              ref={slider2Ref}
              slidesToShow={combinedArray.length < 5 ? combinedArray.length : 5}
              swipeToSlide={true}
              focusOnSelect={true}
              arrows={false}
            >
              {combinedArray.map((item, key) => {
                return (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12} key={key}>
                    <Box
                      sx={{
                        width: {
                          xs: "20vw",
                          sm: "13vw",
                          md: "14vw",
                          lg: "6.5vw",
                          xl: "6.5vw",
                        },
                        height: "10vh",
                        position: "relative",
                      }}
                    >
                      {item.type === "video" ? (
                        <>
                          <ReactPlayer
                            url={item.video}
                            controls={false}
                            height="100%"
                            width="100%"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          />
                          <Box
                            sx={{
                              backgroundColor: "var(--theme-color)",
                              padding: "0px 3px 3px 3px",
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              margin: "3px",
                              color: "#fff",
                              borderRadius: "50%",
                            }}
                          >
                            <VideocamIcon
                              sx={{
                                height: "15px",
                                width: "18px",
                              }}
                            />
                          </Box>
                        </>
                      ) : (
                        <img
                          src={item.image}
                          alt="projectimage"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: " center",
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                );
              })}
            </Slider>
          </Box>
        </>
      )}
    </Box>
  );
};
