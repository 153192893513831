import React from "react";
import { Typography, Button, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { useNavigate } from "react-router-dom";
import useAPI from "../../hooks/useApi";
import { endpoint } from "../../constant/endpoint";
import { useParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";

export const SupportArtistsDonationSection = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const { getDonationId, getFundData } = useAPI();

  const getData = async () => {
    const res = await getDonationId(endpoint.support_artist_donations, id);
    return res.data;
  };

  const { data: donationData } = useQuery({
    queryKey: ["donationData", id],
    queryFn: getData,
  });

  const getFundDatas = async () => {
    const res = await getFundData(endpoint.artistFund, id);
    return res.data;
  };
  const { data: fundData } = useQuery({
    queryKey: ["fundData", id],
    queryFn: getFundDatas,
  });

  return (
    <Box
      sx={{
        width: "100%",
        boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
        borderRadius: "10px",
        padding: "20px 20px 20px 20px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
        }}
      >
        <Box
          className="progress-section"
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "row",
              md: "row",
              lg: "column",
              xl: "column",
            },
            justifyContent: {
              xs: "space-between",
              sm: "space-between",
              md: "space-between",
              lg: "flex-start",
              xl: "flex-start",
            },
            alignItems: "flex-start",
          }}
        >
          <Box className="task-progress">
            <Typography
              variant="p"
              sx={{
                fontSize: {
                  xs: "10px",
                  sm: "13px",
                  md: "13px",
                  lg: "13px",
                  xl: "13px",
                },
                fontFamily: "var(--primary-font)",
                color: "var( --light-black)",
                margin: "10px 0px 10px 0px",
                fontWeight: "bold",
                textAlign: "left",
                float: "left",
              }}
            >
              <span style={{ color: "var(--theme-color" }}>
                {fundData?.currency}
                {fundData?.total_sum}
                {""} {fundData?.goal_amount} raised {""}
              </span>
              of ${fundData?.goal_amount} goal
            </Typography>

            <progress
              Box
              className="progress progress1"
              max={fundData?.goalDonation}
              value={fundData?.raisedDonation}
              style={{
                width: "100%",
              }}
            ></progress>
            <Typography
              variant="body1"
              sx={{
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "12px",
                  lg: "12px",
                  xl: "12px",
                },
                fontFamily: "var(--primary-font)",
                color: "var( --light-black)",
                margin: "10px 0px 10px 0px",
              }}
            >
              {fundData?.count} Donation Made
            </Typography>
          </Box>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Button
              variant="contained"
              sx={{
                backgroundColor: "var(--theme-color)",
                color: "var(--white)",
                width: "100%",
                padding: "15px 15px 15px 15px",
                borderRadius: "5px",
                fontWeight: "bold",
                fontFamily: "var(--primary-font)",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                fontSize: {
                  xs: "10px",
                  sm: "10px",
                  md: "11px",
                  lg: "12px",
                  xl: "12px",
                },

                "&:hover": {
                  backgroundColor: "var(--theme-color)",
                  color: "var(--white)",
                  boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.15)",
                },
              }}
              onClick={() => {
                navigate(`/artist-donation/${id}`);
              }}
            >
              Donate Now
              <img
                src={process.env.PUBLIC_URL + "/assets/icons/left.png"}
                alt=""
                style={{
                  margin: "0px 5px 0px 10px",
                }}
              />
            </Button>
          </Box>
        </Box>

        {/* Add a scrollable container for donorsDetails */}
        <Box
          sx={{
            maxHeight: "60vh", // Set the maximum height as needed
            overflowY: "auto",
            scrollbarWidth: "thin", // Show a thin scrollbar (supported in some modern browsers)
            scrollbarColor: "var(--theme-color) var(--light-black)", // Set the scrollbar color
            "&::-webkit-scrollbar": {
              width: "5px", // Set the width of the scrollbar (only for WebKit browsers)
            },
            "&::-webkit-scrollbar-thumb": {
              backgroundColor: "var(--theme-color)", // Set the color of the thumb (only for WebKit browsers)
              borderRadius: "5px", // Rounded corners for the thumb
            },
            "&::-webkit-scrollbar-track": {
              backgroundColor: "#E4E4E4", // Set the color of the track (only for WebKit browsers)
              borderRadius: "5px", // Rounded corners for the track
            },
          }}
        >
          <Grid container>
            {donationData?.map((item) => (
              <Grid item xs={12} sm={6} md={12} lg={12} xl={12} key={item.id}>
                <Box
                  sx={{
                    marginTop: "30px",
                    display: "flex",
                    flexDirection: "row",
                  }}
                >
                  {item.user ? (
                    <Box
                      sx={{
                        width: "50px",
                        height: "50px",
                      }}
                    >
                      <img
                        src={item.user}
                        alt="..."
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          objectPosition: "center",
                          borderRadius: "50%",
                        }}
                      />
                    </Box>
                  ) : (
                    <Box
                      sx={{
                        width: {
                          xs: "10vw",
                          sm: "6vw",
                          md: "6vw",
                          lg: "3vw",
                          xl: "3vw",
                        },
                        height: "6vh",
                        backgroundColor: "#5D8AA8",
                        borderRadius: "50%",
                        color: "(--white)",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <Typography
                        variant="p"
                        sx={{
                          color: "var(--white)",
                          fontFamily: "var(--font-family)",
                          fontSize: {
                            xs: "15px",
                            sm: "17px",
                            md: "18px",
                            lg: "25px",
                            xl: "25px",
                          },
                          textTransform: "capitalize",
                        }}
                      >
                        {item.payer_first_name[0]}
                      </Typography>
                    </Box>
                  )}
                  <Box
                    sx={{
                      marginLeft: "10px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="h4"
                        className="donation-title"
                        sx={{
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "15px",
                            lg: "15px",
                            xl: "15px",
                          },
                          fontFamily: "var(--primary-font)",
                          color: "var(--black)",
                          fontWeight: "bold",
                        }}
                      >
                        {item.payer_first_name} {""} {item.payer_second_name},{" "}
                      </Typography>
                      <Typography
                        variant="body1"
                        className="donation-description"
                        sx={{
                          fontFamily: "var(--primary-font)",
                          color: "var(--black)",
                          fontWeight: "bold",
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "15px",
                            lg: "15px",
                            xl: "15px",
                          },
                        }}
                      >
                        {item.payer_country}
                      </Typography>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        variant="body1"
                        className="donation-description"
                        sx={{
                          fontSize: {
                            xs: "12px",
                            sm: "12px",
                            md: "15px",
                            lg: "15px",
                            xl: "15px",
                          },
                          color: "var(--theme-color)",
                          fontWeight: "bold",
                          marginRight: "10px",
                        }}
                      >
                        {item.currency} {""} {item.amount}
                      </Typography>
                      {/* <span
                        style={{
                          marginRight: "10px",
                          color: "var(--light-black)",
                        }}
                      >
                        |
                      </span> */}
                      <Typography
                        variant="body1"
                        className="donation-description"
                        sx={{
                          fontSize: {
                            xs: "9px",
                            sm: "10px",
                            md: "12px",
                            lg: "12px",
                            xl: "12px",
                          },
                          fontFamily: "var(--primary-font)",
                          color: "var(--light-black)",
                          fontWeight: "bold",
                        }}
                      >
                        {/* {donor.donationType} */}
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            ))}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};
