import React from "react";
import { Typography, Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import HorizontalLines from "../../Shared/Horizontalline/Horizontalline";
import lotus from "../../assets/lotus.png";
import { GoodDharmaBottom } from "./GoodDharmaBottom";

export const GoodDharmaStry = ({
  story_left_image,
  story_left_title,
  story_left_description,
  story_center_image,
  story_right_image,
  story_right_title,
  story_right_description,
  watermark,
}) => {
  return (
    <Box
      sx={{
        marginBottom: "100px",
        backgroundImage: "url()",
      }}
    >
      <Container>
        <HorizontalLines imgUrl={`${lotus}`} />
        <Box>
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: "10px",
                sm: "11px",
                md: "13px",
                lg: "15px",
                xl: "15px",
              },
              color: "var(--theme-color)",
              fontFamily: "var(--primary-font)",
              textAlign: "center",
            }}
          >
            STORY BEHIND JINLAP "GOOD DHARMA"
          </Typography>
          <Typography
            variant="h4"
            sx={{
              fontSize: {
                xs: "20px",
                sm: "23px",
                md: "28px",
                lg: "33px",
                xl: "35px",
              },
              color: "var(--black)",
              fontFamily: "var(--primary-font)",
              textAlign: "center",
              marginTop: "20px",
            }}
          >
            What makes all this possible?
          </Typography>
        </Box>
      </Container>
      <GoodDharmaBottom
        story_left_image={story_left_image}
        story_left_title={story_left_title}
        story_left_description={story_left_description}
        story_center_image={story_center_image}
        story_right_image={story_right_image}
        story_right_title={story_right_title}
        story_right_description={story_right_description}
        watermark={watermark}
      />
    </Box>
  );
};
