import React, { useState } from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import { Typography, Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { TextField } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import dayjs from "dayjs";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { useEffect } from "react";
import { useCookies } from "react-cookie";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--theme-color)",
    color: "var(--white)",
    fontWeight: "bold",
    fontFamily: "var(--primary-font)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  fontFamily: "var(--primary-font)",
  "& > td": {
    padding: "20px 10px 20px 10px", // Adjust the padding value as per your preference
    fontWeight: 600, // Set the font weight to 600 for the cells in the row
  },
}));

export const GoodDharmaCart = () => {
  const [token, setToken] = useState(""); // Initialize the token state
  const [cookies] = useCookies(["userId", "token"]);

  useEffect(() => {
    // Check if the token is available in cookies
    if (cookies.token) {
      setToken(cookies.token);
      // You can also perform other actions that depend on the token here
    }
  }, [cookies.token]);
  const tomorrow = dayjs().add(1, "day");
  const { selectedCurrency, rate } = useSelector((state) => state.currency);

  const { getCartById, updatedDharmaCart } = useAPI();

  const getData = async () => {
    const response = await getCartById(endpoint.all_good_dharma);
    return response.data;
  };

  const { data: gooddharmacart } = useQuery({
    queryKey: ["gooddharmaCart"],
    queryFn: getData,
  });

  const [selectedService, setSelectedService] = useState("");
  const [selectedDate, setSelectedDate] = useState(null); // Use null for initial date
  const queryClient = useQueryClient();

  const { mutate: updateCart } = useMutation(updatedDharmaCart, {
    onSuccess: () => {
      const message = "service updated successfully";
      toast.success(message);
      // // After the mutation is successful, update the dharmagoodcart data by refetching it.
      // refetchGoodDharmacart();
    },
    onError: (errors) => {
      toast.error(errors.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries("services");
    },
  });

  const handleSelectedService = async (event, item) => {
    const updatedService = event.target.value;
    setSelectedService(updatedService);

    const updatedServiceValues = {
      id: item.id,
      data: {
        size: updatedService,
        price: gooddharmacart
          .find((x) => x.id === item.id)
          .sizes.find((x) => x.size === updatedService).price,
        date: item.date,
      },
    };

    updateCart(updatedServiceValues);
    // fetchGoodDharmaCarttDataAsync();
  };

  const handleDateChange = async (newValue, item) => {
    setSelectedDate(newValue);

    const updatedDataValues = {
      id: item.id,
      data: {
        date: newValue ? newValue.format("YYYY-MM-DD") : null,
        size: item.size,
        price: item.price,
        // Update other fields as needed
      },
    };

    updateCart(updatedDataValues);
  };

  const hr_line = {
    width: "100%",
    height: "1px",
    backgroundColor: "#E4E4E4",
    marginTop: "20px",
    marginBottom: "20px",
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        marginBottom: "50px",
        boxShadow: "none", // Remove the box shadow
      }}
    >
      <Table sx={{ minWidth: 700 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Good Dharma</StyledTableCell>
            <StyledTableCell align="left">Date for</StyledTableCell>
            <StyledTableCell align="left">Service Type</StyledTableCell>
            <StyledTableCell align="left">Price</StyledTableCell>
            <StyledTableCell align="center">Actions</StyledTableCell>
          </TableRow>
        </TableHead>
        <Box
          sx={{
            marginBottom: "20px",
          }}
        ></Box>
        <TableBody>
          {gooddharmacart ? (
            gooddharmacart.map((item) => {
              return (
                <StyledTableRow key={item.id}>
                  {/* {item.map((item, index) => (
                <> */}
                  <StyledTableCell
                    component="th"
                    scope="row"
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      gap: "10px",
                      fontFamily: "var(--primary-font)",
                    }}
                  >
                    <Box
                      sx={{
                        width: {
                          xs: "100px",
                          sm: "100px",
                          md: "100px",
                          lg: "180px",
                          xl: "180px",
                        },
                        height: {
                          xs: "100px",
                          sm: "80px",
                          md: "80px",
                          lg: "130px",
                          xl: "130px",
                        },
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={item.image[0]?.image}
                        alt="..."
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                      />
                    </Box>
                    <Typography
                      variant="h6"
                      sx={{
                        fontFamily: "var(--primary-font)",
                        color: "var(--light-black)",
                        fontSize: {
                          xs: "12px",
                          sm: "14px",
                          md: "16px",
                          lg: "18px",
                          xl: "18px",
                        },
                      }}
                    >
                      {item.service.name}
                    </Typography>
                  </StyledTableCell>
                  <StyledTableCell
                    align="left"
                    sx={{
                      color: "var(--theme-color)",
                    }}
                  >
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        id="date"
                        size="medium"
                        value={item.date ? dayjs(item.date) : null} // Convert the date string to a Dayjs object
                        onChange={(newValue) =>
                          handleDateChange(newValue, item)
                        } // Pass the item to the handler
                        renderInput={(params) => <TextField {...params} />}
                        minDate={tomorrow}
                        sx={{
                          width: "70%",
                          backgroundColor: "#fff",
                          border: "0",
                          " & .MuiOutlinedInput-root": {
                            "  &.Mui-focused fieldset": {
                              borderColor: "var(--theme-color)",
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            border: "1px solid var(--btn-color)",
                            borderRadius: "5px",
                            // padding: "0",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "1px solid #eee",
                            },
                          //border colour when focused
                          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "1px solid var(--theme-color)",
                            },
                          //floating label color when focused
                          "& .MuiInputLabel-outlined.Mui-focused": {
                            color: "var(--theme-color)",
                          },
                        }}
                      />
                    </LocalizationProvider>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Box minWidth={120}>
                      <FormControl fullWidth>
                        <Select
                          size="medium"
                          name="service"
                          value={item.size}
                          onChange={(event) =>
                            handleSelectedService(event, item)
                          }
                          sx={{
                            backgroundColor: "transparent",
                            "& .MuiSelect-icon": {
                              color: "black",
                            },
                            "& .MuiSelect-root": {
                              color: "#D9D9D9",
                            },
                            "& .MuiSelect-select:focus": {
                              backgroundColor: "transparent",
                            },
                            "& .MuiInput-underline:before": {
                              borderBottomColor: "transparent",
                            },
                            "& .MuiInput-underline:hover:not(.Mui-disabled):before":
                              {
                                borderBottomColor: "transparent",
                              },
                            "& .MuiInput-underline:after": {
                              borderBottomColor: "transparent",
                            },
                          }}
                        >
                          {item.sizes.map((option, key) => (
                            <MenuItem
                              value={option.size} // Changed from `option.service` to `item`
                              key={key}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#E4E4E4",
                              }}
                            >
                              {option.size} {/* Render the service name */}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Box>
                  </StyledTableCell>
                  <StyledTableCell
                    align="center"
                    sx={{
                      color: "var(--theme-color)",
                    }}
                  >
                    {selectedCurrency} {(item.price / rate).toFixed(2)}
                  </StyledTableCell>

                  <StyledTableCell align="center">
                    <Button
                      onClick={async () => {
                        try {
                          await axios.delete(
                            process.env.REACT_APP_JINLAP_BASE_URL +
                              `/services/${item.id}/cart`,
                            {
                              headers: {
                                Authorization: `Bearer ${token}`,
                                Accept: "application/json",
                                "Content-Type": "application/json",
                              },
                            }
                          );
                          toast.success(
                            `${item.service.name} removed from cart successfully  `
                          );
                        } catch (error) {
                          toast.error(error.response.data.message);
                        }
                        // dispatch(removeGooddharmaItem(item.id));
                      }}
                    >
                      <CloseIcon
                        fontSize="large"
                        sx={{
                          color: "var(--white)",
                          backgroundColor: "var(--theme-color)",
                          "&:hover": {
                            color: "var(--theme-color)",
                            backgroundColor: "var(--white)",
                          },
                        }}
                      />
                    </Button>
                  </StyledTableCell>
                  {/* </>
              ))} */}
                </StyledTableRow>
              );
            })
          ) : (
            <StyledTableRow>
              <StyledTableCell colSpan={5} align="center">
                No items in the cart.
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "10px",
          margin: "20px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "500",
            fontSize: {
              xs: "13px",
              sm: "13px",
              md: "14px",
              lg: "15px",
              xl: "15px",
            },

            fontFamily: "var(--primary-font)",
            justifyContent: "flex-end",
            display: "flex",
            alignItems: "center",
          }}
        >
          GOOD DHARMA SUBTOTAL: {""}
        </Typography>

        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: "var(--theme-color)",
            marginleft: "20px",
            fontSize: {
              xs: "14px",
              sm: "15px",
              md: "16px",
              lg: "18px",
              xl: "18px",
            },
            justifyContent: "flex-end",
            display: "flex",
            alignItems: "center",
            fontFamily: "var(--primary-font)",
          }}
        >
          {selectedCurrency}{" "}
          {gooddharmacart
            ? gooddharmacart
                ?.reduce((acc, item) => acc + item.price / rate, 0)
                .toFixed(2)
            : 0}
        </Typography>
      </Box>
      <Box sx={hr_line}></Box>
      {/* {gooddharmatotalQuantity} */}
    </TableContainer>
  );
};
