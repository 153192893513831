import React from "react";
import { Typography, Container, Grid, Button } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import { TextField, FormControl } from "@mui/material";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import * as Yup from "yup";
import { Box } from "@mui/system";
import { useMediaQuery, useTheme } from "@mui/material";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";
import Skeleton from "@mui/material/Skeleton";
import { CurrencyData } from "../../../ApiIntegration/Shared/CurrencyData";
import { useNavigate } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    // marginTop: theme.spacing(2),
  },
  formControl: {
    // margin: theme.spacing(5),
    minWidth: 200,
    fontFamily: "var(--primary-font)",
    backgroundColor: "#F8F8F8",
    width: "80px",
    padding: "10px 10px",
    "& .MuiSelect-icon": {
      color: "black",
    },
    "& .MuiSelect-root": {
      color: "#E4E4E4",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "transparent",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: "transparent",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
  },
  select: {
    textAlign: "center",
    textDecoration: "none",
    color: "var(--white)",
    fontFamily: "var(--primary-font)",
    marginTop: "10px",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "5px",
  },
}));

export const SupportProjects = () => {
  const theme = useTheme();

  const [isLoading, setIsLoading] = React.useState(false);

  const initialValues = {
    project: "",
    amount: "",
  };

  const DataSchema = Yup.object().shape({
    project: Yup.string().required("Select the project"),
    amount: Yup.number().required("Enter the amount"),
  });

  const navigate = useNavigate();

  // Use the useMediaQuery hook to get the screen size
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm", "xs"));
  const label = isSmallScreen ? "Enter Amount" : "Enter Contribution Amount";

  const classes = useStyles();
  const [country, setCountry] = React.useState("NRS");
  const { get } = useAPI();

  const getData = async () => {
    const res = await get(endpoint.projects);
    return res.data;
  };

  const { isDataLoading, data: supportprojectsData } = useQuery({
    queryKey: ["supportprojectsData"],
    queryFn: getData,
  });

  const [currencies, setCurrencies] = React.useState([]);

  React.useEffect(() => {
    const fetchCurrenciesDataAsync = async () => {
      try {
        const apiData = await CurrencyData();
        setCurrencies(apiData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCurrenciesDataAsync();
  }, []);
  const projects = supportprojectsData?.map((project) => project.title);

  const projectOptions = projects?.map((project, key) => (
    // eslint-disable-next-line react/no-array-index-key
    <option value={project} key={key}>
      {project}
    </option>
  ));

  if (isDataLoading)
    return (
      <>
        <Skeleton variant="rectangular" width="100%" height="60vh" />
      </>
    );

  const handleChange = (event) => {
    setCountry(event.target.value);
  };

  const submitHandler = (values, { resetForm }) => {
    setIsLoading(true);
    try {
      const projectdetail = supportprojectsData?.map((project) => project);
      const selectedproject = projectdetail?.find(
        (proj) => proj.title === values.project
      );
      const selectedProjectId = selectedproject?.id;
      values.currency = country;
      if (values.selectedProjectId !== null && values.amount !== null) {
        navigate(`/project-donation/${selectedProjectId}?a=${values.amount}`);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("error", error);
    }
    resetForm();
  };

  return (
    <Box
      sx={{
        backgroundImage: `url(${
          process.env.PUBLIC_URL + "assets/ProjectsPage/support_backimg.png"
        })`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        backgroundSize: "cover",
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        padding: "50px 0px 80px 0px",
        marginBottom: "80px",
      }}
    >
      <Container>
        <Grid
          container
          xs={12}
          spacing={3}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "60px 0px 60px 0px",
          }}
        >
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Box
              sx={{
                width: "95%",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  color: "var(--white)",
                  fontSize: {
                    xs: "15px",
                    sm: "17px",
                    md: "18px",
                    lg: "20px",
                    xl: "20px",
                  },
                  fontFamily: "var(--font-family-body)",
                  textAlign: "justify",
                }}
              >
                SUPPORT PROJECTS
              </Typography>
              <Box
                sx={{
                  marginTop: "20px",
                }}
              >
                <Typography
                  variant="h3"
                  sx={{
                    color: "var(--white)",
                    fontSize: {
                      xs: "25px",
                      sm: "28px",
                      md: "30px",
                      lg: "34px",
                      xl: "34px",
                    },
                    fontFamily: "var(--font-family-body)",
                    textAlign: "justify",
                  }}
                >
                  With help of you, Our small act of kindness can create big
                  impact
                </Typography>
              </Box>
            </Box>
            {/* <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                marginTop: "40px",
                backgroundColor: "white",
                width: "100%",
              }}
            > */}
            <Formik
              initialValues={initialValues}
              validationSchema={DataSchema}
              onSubmit={submitHandler}
            >
              {(formik) => {
                const { errors, touched } = formik;
                return (
                  <Form
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                      marginTop: "40px",
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                      }}
                    >
                      <FormControl
                        // style={{ display: "flex", flexDirection: "column" }}
                        sx={{
                          width: {
                            xs: "100%",
                            sm: "75%",
                            md: "70%",
                            lg: "70%",
                            xl: "70%",
                          },
                          display: "flex",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <Field
                          as="select"
                          labelId="sort-order-label"
                          id="project-select"
                          name="project"
                          label="Select Project to contribute"
                          className={
                            errors.project && touched.project
                              ? "input-error"
                              : null
                          }
                          style={{
                            height: "50px",
                            width: "100%",
                            // textAlign: "center",
                            borderRadius: "5px",
                            color: "var(--btn-color)",
                            backgroundColor: "var(--white)",
                            textAlign: "justify",
                          }}
                        >
                          <option value="">
                            {" "}
                            Select Project to contribute
                          </option>
                          {projectOptions}
                        </Field>
                        <ErrorMessage
                          name="project"
                          component="span"
                          className="error"
                          style={{
                            marginLeft: "5px",
                            color: "var(--white)",
                            fontSize: "10px",
                            marginTop: "5px",
                          }}
                        />
                      </FormControl>
                    </Box>
                    <Box
                      sx={{
                        width: {
                          xs: "100%",
                          sm: "100%",
                          md: "80%",
                          lg: "80%",
                          xl: "80%",
                        },
                        marginTop: "40px",
                        height: "50px",
                        backgroundColor: "var(--white)",
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        borderRadius: "5px",
                      }}
                    >
                      <FormControl className={classes.formControl}>
                        <Select
                          // open={open}
                          // onClose={handleClose}
                          // onOpen={handleOpen}
                          value={country}
                          name="country"
                          size="small"
                          onChange={handleChange}
                        >
                          {currencies?.map((option, key) => (
                            <MenuItem
                              value={option.symbol}
                              key={key}
                              sx={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                                backgroundColor: "#E4E4E4",
                              }}
                            >
                              <Box
                                sx={{
                                  height: "37px",
                                  width: "35px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <img
                                  src={option.image}
                                  alt={option.name}
                                  style={{
                                    height: "70%",
                                    width: "90%",
                                    objectFit: "cover",
                                    objectPosition: "center",
                                    marginLeft: "10px",
                                  }}
                                />{" "}
                              </Box>
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                      <TextField
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        name="currency"
                        value={country}
                        readOnly
                        sx={{
                          backgroundColor: "var(--white)",
                          fontFamily: "var(--primary-font)",

                          width: {
                            xs: "24%",
                            sm: "20%",
                            md: "18%",
                            lg: "18%",
                            xl: "18%",
                          },
                          " & .MuiOutlinedInput-root": {
                            "  &.Mui-focused fieldset": {
                              borderColor: "var(--white)",
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            border: "0px solid var(--white)",
                            borderRadius: "0",
                            padding: "0",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "1px solid #eee",
                            },
                          //border colour when focused
                          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "1px solid var(--white)",
                            },
                          //floating label color when focused
                          "& .MuiInputLabel-outlined.Mui-focused": {
                            color: "var(--white)",
                          },
                        }}
                      />

                      <Field
                        as={TextField}
                        id="outlined-basic"
                        variant="outlined"
                        size="small"
                        name="amount"
                        label={label}
                        className={
                          errors.amount && touched.amount ? "input-error" : null
                        }
                        sx={{
                          backgroundColor: "var(--white)",
                          fontFamily: "var(--primary-font)",
                          width: "65%",
                          // padding: "1px",
                          " & .MuiOutlinedInput-root": {
                            "  &.Mui-focused fieldset": {
                              borderColor: "var(--white)",
                            },
                          },
                          "& .MuiOutlinedInput-root": {
                            border: "0px solid var(--white)",
                            borderRadius: "0",
                            padding: "0",
                          },
                          "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "1px solid #eee",
                            },
                          //border colour when focused
                          "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                            {
                              border: "1px solid var(--white)",
                            },
                          //floating label color when focused
                          "& .MuiInputLabel-outlined.Mui-focused": {
                            color: "var(--white)",
                          },
                        }}
                      />
                    </Box>

                    <ErrorMessage
                      name="amount"
                      component="span"
                      className="error"
                      style={{
                        marginLeft: "5px",
                        color: "var(--white)",
                        fontSize: "10px",
                        marginTop: "5px",
                      }}
                    />
                    <Box
                      sx={{
                        marginTop: "40px",
                      }}
                    >
                      <Button
                        variant="contained"
                        type="submit"
                        sx={{
                          color: "white",
                          display: "flex",
                          backgroundColor: "var(--theme-color)",
                          border: "1px solid var(--white)",
                          width: "165px",
                          fontFamily: "Arbutus Slab",
                          textTransform: "capitalize",
                          ":hover": {
                            //zoom the buttom
                            transform: "scale(1.1)",
                            backgroundColor: "var(--theme-color)",
                          },
                        }}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <span
                            style={{
                              color: "white",
                            }}
                          >
                            Processing...
                          </span>
                        ) : (
                          "CONTRIBUTE"
                        )}
                        <img
                          src={process.env.PUBLIC_URL + "assets/icons/left.png"}
                          alt="..."
                          style={{
                            width: "15px",
                            height: "20px",
                            marginLeft: "10px",
                          }}
                        />
                      </Button>
                    </Box>
                  </Form>
                );
              }}
            </Formik>
            {/* </Box> */}
          </Grid>
          <Grid item xs={12} sm={12} md={12} lg={6} xl={6}>
            <Box
              sx={{
                width: "100%",
                height: "auto",
                margin: "10px",
              }}
            >
              <img
                src="assets/ProjectsPage/supportprojects.png"
                alt=""
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
