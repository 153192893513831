import React, { useState } from "react";
import { Box } from "@mui/system";
import { Typography, Button, Grid } from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { SiteSettingsData } from "../../../ApiIntegration/LandingPageApi/SiteSettings";
import { endpoint } from "../../../constant/endpoint";
import useAPI from "../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";

export const ProdQuestions = (prodquestions) => {
  const [displayedItems, setDisplayedItems] = useState(2);

  const handleShowMore = () => {
    setDisplayedItems(prodquestions.prodquestions.length);
  };
  const handleShowLess = () => {
    setDisplayedItems(2);
  };

  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.sitesettings);
    return res.data;
  };

  const { data: siteSettings } = useQuery({
    queryKey: ["sitesettingsData"],
    queryFn: getData,
  });

  return (
    <Box>
      {prodquestions.prodquestions.slice(0, displayedItems).map((item, key) => {
        return (
          <Box key={key}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                marginBottom: "50px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                {item.image ? (
                  <Box
                    sx={{
                      height: "70px",
                      width: "70px",
                    }}
                  >
                    <img
                      src={item.image}
                      alt="..."
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      width: {
                        xs: "10vw",
                        sm: "6vw",
                        md: "6vw",
                        lg: "3vw",
                        xl: "3vw",
                      },
                      height: "6vh",
                      backgroundColor: "#5D8AA8",
                      borderRadius: "50%",
                      color: "(--white)",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      textAlign: "center",
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        color: "var(--white)",
                        fontFamily: "var(--font-family)",
                        fontSize: {
                          xs: "15px",
                          sm: "17px",
                          md: "18px",
                          lg: "25px",
                          xl: "25px",
                        },
                        textTransform: "capitalize",
                      }}
                    >
                      {item.name[0]}
                    </Typography>
                  </Box>
                )}
                <Box
                  sx={{
                    textAlign: "left",
                    marginLeft: "10px",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: "var(--black-color)",
                      fontSize: {
                        xs: "11px",
                        sm: "12px",
                        md: "12px",
                        lg: "14px",
                        xl: "14px",
                      },
                      fontFamily: "var(--primary-font)",
                      fontWeight: "500",
                    }}
                  >
                    {item.name}
                  </Typography>
                  <Typography
                    sx={{
                      color: "var(--black-color)",
                      fontSize: {
                        xs: "10px",
                        sm: "11px",
                        md: "11px",
                        lg: "12px",
                        xl: "12px",
                      },
                      fontFamily: "var(--primary-font)",
                    }}
                  >
                    {item.address ? item.address : ""}
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginTop: "10px",
                  justifyContent: "flex-start",
                  gap: "15px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    color: "var(--dark-color)",
                    fontSize: {
                      xs: "10px",
                      sm: "11px",
                      md: "12px",
                      lg: "14px",
                      xl: "14px",
                    },
                    fontWeight: "500",
                  }}
                >
                  {item.question}
                </Typography>
              </Box>
              {item.answer !== null ? (
                <Box
                  sx={{
                    marginTop: "10px",
                    textAlign: "justify",
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "center",
                    gap: "25px",
                  }}
                >
                  <Box>
                    <img
                      src={siteSettings.secondary_logo}
                      alt="..."
                      style={{
                        height: "30px",
                        width: "30px",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  </Box>

                  <Typography
                    variant="body1"
                    sx={{
                      fontFamily: "var(--primary-font)",
                      color: "var(--light-black)",
                      fontSize: {
                        xs: "10px",
                        sm: "11px",
                        md: "12px",
                        lg: "14px",
                        xl: "14px",
                      },
                    }}
                  >
                    {item.answer}
                  </Typography>
                </Box>
              ) : (
                ""
              )}
            </Box>
          </Box>
        );
      })}
      {displayedItems < prodquestions.prodquestions.length && (
        <Button
          variant="outlined"
          sx={{
            color: "var(--theme-color)",
            border: "1px solid var(--theme-color)",
            textTransform: "capitalize",
            width: {
              xs: "100%",
              sm: "100%",
              md: "15%",
              lg: "15%",
              xl: "15%",
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "50px",
            marginBottom: "50px",
            fontSize: {
              xs: "11px",
              sm: "11px",
              md: "13px",
              lg: "15px",
              xl: "15px",
            },
            "&:hover": {
              backgroundColor: "var(--theme-color)",
              color: "white",
              border: "1px solid var(--theme-color)",
              transform: "scale(1.1)",
            },
          }}
          onClick={handleShowMore}
        >
          Show More
          <KeyboardArrowDownIcon />
        </Button>
      )}

      {displayedItems > 2 && (
        <Button
          variant="outlined"
          sx={{
            color: "var(--theme-color)",
            border: "1px solid var(--theme-color)",
            textTransform: "capitalize",
            width: {
              xs: "100%",
              sm: "100%",
              md: "15%",
              lg: "15%",
              xl: "15%",
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "50px",
            marginBottom: "50px",
            fontSize: {
              xs: "11px",
              sm: "11px",
              md: "13px",
              lg: "15px",
              xl: "15px",
            },
            "&:hover": {
              backgroundColor: "var(--theme-color)",
              color: "var(--white)",
              border: "1px solid var(--theme-color)",
              transform: "scale(1.1)",
            },
          }}
          onClick={handleShowLess}
        >
          Show Less
          <KeyboardArrowUpIcon />
        </Button>
      )}
    </Box>
  );
};
