import React, { useState } from "react";
import { Box } from "@mui/system";
import { Grid, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import VideocamIcon from "@mui/icons-material/Videocam";
import Modal from "@mui/material/Modal";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import CloseIcon from "@mui/icons-material/Close";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import axios from "axios";
import { useEffect } from "react";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const TopContent = () => {
  const { id } = useParams();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [currentVideoIndex, setCurrentVideoIndex] = useState(0);

  const [pastprojectsDetails, setPastprojectsDetails] = React.useState([]);

  useEffect(() => {
    const fetchProjectsDetails = async () => {
      try {
        const response = await axios.get(
          process.env.REACT_APP_JINLAP_BASE_URL + `/projects/${id}`,
          {
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        setPastprojectsDetails(response.data.data);
      } catch (error) {
        console.error(error);
      }
    };
    fetchProjectsDetails();
  }, []);

  const settings = {
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    dots: false,
    arrows: false,
  };

  const firstRowImages = pastprojectsDetails?.images?.slice(0, 2);
  const secondRowImages = pastprojectsDetails?.images?.slice(2, 4);

  const handleNextVideo = () => {
    if (currentVideoIndex < pastprojectsDetails?.videos.length - 1) {
      setCurrentVideoIndex(currentVideoIndex + 1);
    }
  };

  const handlePreviousVideo = () => {
    if (currentVideoIndex > 0) {
      setCurrentVideoIndex(currentVideoIndex - 1);
    }
  };

  return (
    <Box>
      {pastprojectsDetails && (
        <Box>
          <Grid container>
            <Grid item xs={12} sm={12} md={12} lg={5} xl={5}>
              <Box
                sx={{
                  width: "100%",
                  height: "50vh",
                }}
              >
                <img
                  src={pastprojectsDetails?.featured_image}
                  alt="..."
                  style={{
                    width: "100%",
                    height: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={7}
              xl={7}
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "row",
                  sm: "row",
                  md: "row",
                  lg: "column",
                  xl: "column",
                },
                justifyContent: "flex-start",
                alignItems: "center",
                marginTop: {
                  xs: "0px",
                  sm: "0px",
                  md: "20px",
                  lg: "0px",
                  xl: "0px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  marginTop: {
                    xs: "20px",
                    sm: "20px",
                    md: "0px",
                    lg: "0px",
                    xl: "0px",
                  },
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "27.5vw",
                      sm: "20vw",
                      md: "13vw",
                      lg: "13vw",
                      xl: "13vw",
                    },
                    height: {
                      xs: "15vh",
                      sm: "17vh",
                      md: "17vh",
                      lg: "23.5vh",
                      xl: "23.5vh",
                    },
                    display: "flex",
                    flexDirection: "row",

                    margin: {
                      xs: "0px 15px 0px 0px",
                      sm: "0px 15px 0px 0px",
                      md: "0px 10px 0px 0px",
                      lg: "0px 10px 10px 10px",
                      xl: "0px 10px 10px 10px",
                    },
                    position: "relative",
                  }}
                  onClick={handleOpen}
                >
                  {pastprojectsDetails?.videos?.slice(0, 1).map((item) => (
                    <>
                      <ReactPlayer
                        url={item.videos}
                        width="100%"
                        height="100%"
                        controls={false}
                      />
                      <Box
                        sx={{
                          backgroundColor: "var(--theme-color)",
                          padding: "0px 5px 4px 5px",
                          position: "absolute",
                          bottom: 0,
                          left: 0,
                          margin: "3px",
                          color: "#fff",
                          borderRadius: "50%",
                        }}
                      >
                        <VideocamIcon
                          sx={{
                            height: "15px",
                            width: "18px",
                          }}
                        />
                      </Box>
                    </>
                  ))}
                </Box>
                <Modal
                  open={open}
                  aria-labelledby="modal-modal-title"
                  aria-describedby="modal-modal-description"
                >
                  <Box
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%)",
                      width: "40%",
                      height: "65%",
                      bgcolor: "var(--white)",
                      p: 4,
                    }}
                  >
                    <Slider
                      {...settings}
                      style={{ width: "100%", height: "88%" }}
                    >
                      {pastprojectsDetails?.videos?.map((item) => (
                        <ReactPlayer
                          url={item.videos}
                          // width="100%"
                          // height="100%"
                          controls={true}
                        />
                      ))}
                    </Slider>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                        alignItems: "center",
                        backgroundColor: "#ebebeb",
                        padding: "10px",
                        borderRadius: "5px",
                        marginTop: "10px",
                      }}
                    >
                      <Box>
                        {currentVideoIndex + 1} {"/"}
                        {pastprojectsDetails?.videos?.length}
                      </Box>
                      <Box>
                        <Button
                          sx={{
                            backgroundColor: "var(--white)",
                            color: "var(--black)",
                            marginRight: "10px",
                          }}
                          onClick={handlePreviousVideo}
                        >
                          <NavigateBeforeIcon />
                        </Button>
                        <Button
                          sx={{
                            backgroundColor: "var(--white)",
                            color: "var(--black)",
                          }}
                          onClick={handleNextVideo}
                        >
                          <NavigateNextIcon />
                        </Button>
                      </Box>

                      <Button
                        sx={{
                          backgroundColor: "var(--white)",
                          color: "var(--black)",
                        }}
                        onClick={handleClose}
                      >
                        <CloseIcon />
                      </Button>
                    </Box>
                  </Box>
                </Modal>

                {firstRowImages?.map((item) => (
                  <Box
                    key={item.id}
                    sx={{
                      width: {
                        xs: "27.5vw",
                        sm: "20vw",
                        md: "13vw",
                        lg: "13vw",
                        xl: "13vw",
                      },
                      height: {
                        xs: "15vh",
                        sm: "17vh",
                        md: "17vh",
                        lg: "23.5vh",
                        xl: "23.5vh",
                      },
                      display: "flex",
                      flexDirection: "row",

                      margin: {
                        xs: "0px 15px 0px 0px",
                        sm: "0px 15px 0px 0px",
                        md: "0px 10px 0px 0px",
                        lg: "0px 10px 10px 10px",
                        xl: "0px 10px 10px 10px",
                      },
                    }}
                  >
                    <img
                      src={item.images}
                      alt="..."
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  </Box>
                ))}
              </Box>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "flex",
                    lg: "flex",
                    xl: "flex",
                  },
                  flexDirection: "row",
                }}
              >
                <Box
                  key={pastprojectsDetails.id}
                  sx={{
                    width: {
                      xs: "27.5vw",
                      sm: "27.5vw",
                      md: "13vw",
                      lg: "13vw",
                      xl: "13vw",
                    },
                    height: {
                      xs: "15vh",
                      sm: "15vh",
                      md: "15vh",
                      lg: "23.5vh",
                      xl: "23.5vh",
                    },
                    display: "flex",
                    flexDirection: "row",

                    margin: {
                      xs: "0px 15px 0px 0px",
                      sm: "0px 15px 0px 0px",
                      md: "0px 10px 0px 0px",
                      lg: "10px 10px 0px 10px",
                      xl: "10px 10px 0px 10px",
                    },
                    position: "relative",
                  }}
                  onClick={handleOpen}
                >
                  {pastprojectsDetails.videos?.length > 1
                    ? pastprojectsDetails.videos?.slice(1, 2)
                    : pastprojectsDetails.videos
                        ?.slice(0, 1)
                        .map((item, index) => (
                          <Box key={index}>
                            <ReactPlayer
                              url={item.videos}
                              width="100%"
                              height="100%"
                              controls={false}
                            />
                            <Box
                              sx={{
                                backgroundColor: "var(--theme-color)",
                                padding: "0px 5px 4px 5px",
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                margin: "3px",
                                color: "#fff",
                                borderRadius: "50%",
                              }}
                            >
                              <VideocamIcon
                                sx={{
                                  height: "15px",
                                  width: "18px",
                                }}
                              />
                            </Box>
                          </Box>
                        ))}
                </Box>
                {secondRowImages?.map((item) => (
                  <Box
                    key={item.id}
                    sx={{
                      width: {
                        xs: "27.5vw",
                        sm: "27.5vw",
                        md: "13vw",
                        lg: "13vw",
                        xl: "13vw",
                      },
                      height: {
                        xs: "15vh",
                        sm: "15vh",
                        md: "15vh",
                        lg: "23.5vh",
                        xl: "23.5vh",
                      },
                      display: "flex",
                      flexDirection: "row",

                      margin: {
                        xs: "0px 15px 0px 0px",
                        sm: "0px 15px 0px 0px",
                        md: "0px 10px 0px 0px",
                        lg: "10px 10px 0px 10px",
                        xl: "10px 10px 0px 10px",
                      },
                    }}
                  >
                    <img
                      src={item.images}
                      alt="..."
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  </Box>
                ))}
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
};
