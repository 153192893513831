export const ProjectTopData = [
  {
    id: 1,
    imgUrl: "./assets/DharmaGoodpage/topsec_img.png",
    title: "PROJECTS",
    subtitle1: "YOUR CONTRIBUTION MAKES IT POSSIBLE",
    subtitle2: "FOR PEOPLE TO BE HAPPY.",
  },
];

export const runningprojectsData = [
  {
    id: 1,
    imgUrl: "./assets/ProjectsPage/runningprojects1.png",
    date: "July 3, 2022",
    years: "3 years",
    title: "A Small Act Of Kindness With Big Impact",
    raisedDonation: "1524",
    goalDonation: "2500",
    nextdesc:
      " Our small act of kindness aimed to help local homeless people. They quickly discovered that kindness flowed both ways. Meet Maya, a talented handmade artist who has been creating beautiful pottery for over a decade. Her work is not only stunning, but also functional and practical. Maya's pottery is so unique that it has gained a lot of attention and a loyal following over the years. Recently, Maya was faced with a difficult challenge - her beloved pottery studio was destroyed in a fire. She lost all of her equipment, tools, and materials, as well as some of her completed pieces. As a result, Maya was unable to continue creating and selling her pottery, which was her only source of income. Determined not to let this setback defeat her, Maya decided to launch a fundraising campaign to help rebuild her pottery studio. She reached out to her loyal customers, friends, and family, sharing her story and asking for their support.",
    projectglimse: [
      {
        id: 1,
        imgurl: "./assets/ProjectsPage/runningprojects2.png",
        type: "image",
      },
      {
        id: 2,
        imgurl: "./assets/ProjectsPage/runningprojects1.png",
        type: "image",
      },
      {
        id: 3,
        url: "./assets/supportartists/supportartists.mp4",
        type: "video",
      },
    ],
  },
  {
    id: 2,
    imgUrl: "./assets/ProjectsPage/runningprojects22.png",
    date: "June 20, 2022",
    years: "2 years",
    title: "A Small Act Of Kindness With Big Impact",
    raisedDonation: "2004",
    goalDonation: "2500",
    nextdesc:
      "Our small act of kindness aimed to help local homeless people. They quickly discovered that kindness flowed both ways. Meet Maya, a talented handmade artist who has been creating beautiful pottery for over a decade. Her work is not only stunning, but also functional and practical. Maya's pottery is so unique that it has gained a lot of attention and a loyal following over the years. Recently, Maya was faced with a difficult challenge - her beloved pottery studio was destroyed in a fire. She lost all of her equipment, tools, and materials, as well as some of her completed pieces. As a result, Maya was unable to continue creating and selling her pottery, which was her only source of income. Determined not to let this setback defeat her, Maya decided to launch a fundraising campaign to help rebuild her pottery studio. She reached out to her loyal customers, friends, and family, sharing her story and asking for their support.",
    projectglimse: [
      {
        id: 1,
        imgurl: "./assets/ProjectsPage/runningprojects2.png",
        type: "image",
      },
      {
        id: 2,
        imgurl: "./assets/ProjectsPage/runningprojects1.png",
        type: "image",
      },
      {
        id: 3,
        url: "./assets/supportartists/supportartists.mp4",
        type: "video",
      },
    ],
  },
  {
    id: 3,
    imgUrl: "./assets/ProjectsPage/runningprojects3.png",
    date: "July 3, 2022",
    years: "3 years",
    title: "A Small Act Of Kindness With Big Impact",
    raisedDonation: "1004",
    goalDonation: "2500",
    nextdesc:
      "Our small act of kindness aimed to help local homeless people. They quickly discovered that kindness flowed both ways. Meet Maya, a talented handmade artist who has been creating beautiful pottery for over a decade. Her work is not only stunning, but also functional and practical. Maya's pottery is so unique that it has gained a lot of attention and a loyal following over the years. Recently, Maya was faced with a difficult challenge - her beloved pottery studio was destroyed in a fire. She lost all of her equipment, tools, and materials, as well as some of her completed pieces. As a result, Maya was unable to continue creating and selling her pottery, which was her only source of income. Determined not to let this setback defeat her, Maya decided to launch a fundraising campaign to help rebuild her pottery studio. She reached out to her loyal customers, friends, and family, sharing her story and asking for their support.",
    projectglimse: [
      {
        id: 1,
        imgurl: "./assets/ProjectsPage/runningprojects2.png",
        type: "image",
      },
      {
        id: 2,
        imgurl: "./assets/ProjectsPage/runningprojects1.png",
        type: "image",
      },
      {
        id: 3,
        url: "./assets/supportartists/supportartists.mp4",
        type: "video",
      },
    ],
  },
  {
    id: 4,
    imgUrl: "./assets/ProjectsPage/past1.png",
    date: "July 3, 2022",
    years: "3 years",
    title: "A Small Act Of Kindness With Big Impact",
    raisedDonation: "1004",
    goalDonation: "2500",
    nextdesc:
      "Our small act of kindness aimed to help local homeless people. They quickly discovered that kindness flowed both ways. Meet Maya, a talented handmade artist who has been creating beautiful pottery for over a decade. Her work is not only stunning, but also functional and practical. Maya's pottery is so unique that it has gained a lot of attention and a loyal following over the years. Recently, Maya was faced with a difficult challenge - her beloved pottery studio was destroyed in a fire. She lost all of her equipment, tools, and materials, as well as some of her completed pieces. As a result, Maya was unable to continue creating and selling her pottery, which was her only source of income. Determined not to let this setback defeat her, Maya decided to launch a fundraising campaign to help rebuild her pottery studio. She reached out to her loyal customers, friends, and family, sharing her story and asking for their support.",
    projectglimse: [
      {
        id: 1,
        imgurl: "./assets/ProjectsPage/runningprojects2.png",
        type: "image",
      },
      {
        id: 2,
        imgurl: "./assets/ProjectsPage/runningprojects1.png",
        type: "image",
      },
      {
        id: 3,
        url: "./assets/supportartists/supportartists.mp4",
        type: "video",
      },
    ],
  },
  {
    id: 5,
    imgUrl: "./assets/ProjectsPage/past2.png",
    date: "July 3, 2022",
    years: "3 years",
    title: "A Small Act Of Kindness With Big Impact",
    raisedDonation: "1004",
    goalDonation: "2500",
    projectglimse: [
      {
        id: 1,
        imgurl: "./assets/ProjectsPage/runningprojects2.png",
        type: "image",
      },
      {
        id: 2,
        imgurl: "./assets/ProjectsPage/runningprojects1.png",
        type: "image",
      },
      {
        id: 3,
        url: "./assets/supportartists/supportartists.mp4",
        type: "video",
      },
    ],
    nextdesc:
      "Our small act of kindness aimed to help local homeless people. They quickly discovered that kindness flowed both ways. Meet Maya, a talented handmade artist who has been creating beautiful pottery for over a decade. Her work is not only stunning, but also functional and practical. Maya's pottery is so unique that it has gained a lot of attention and a loyal following over the years. Recently, Maya was faced with a difficult challenge - her beloved pottery studio was destroyed in a fire. She lost all of her equipment, tools, and materials, as well as some of her completed pieces. As a result, Maya was unable to continue creating and selling her pottery, which was her only source of income. Determined not to let this setback defeat her, Maya decided to launch a fundraising campaign to help rebuild her pottery studio. She reached out to her loyal customers, friends, and family, sharing her story and asking for their support.",
  },
  {
    id: 6,
    imgUrl: "./assets/ProjectsPage/past3.png",
    date: "July 3, 2022",
    years: "3 years",
    title: "A Small Act Of Kindness With Big Impact",
    raisedDonation: "1004",
    goalDonation: "2500",
    projectglimse: [
      {
        id: 1,
        imgurl: "./assets/ProjectsPage/runningprojects2.png",
        type: "image",
      },
      {
        id: 2,
        imgurl: "./assets/ProjectsPage/runningprojects1.png",
        type: "image",
      },
      {
        id: 3,
        url: "./assets/supportartists/supportartists.mp4",
        type: "video",
      },
    ],
    nextdesc:
      "Our small act of kindness aimed to help local homeless people. They quickly discovered that kindness flowed both ways. Meet Maya, a talented handmade artist who has been creating beautiful pottery for over a decade. Her work is not only stunning, but also functional and practical. Maya's pottery is so unique that it has gained a lot of attention and a loyal following over the years. Recently, Maya was faced with a difficult challenge - her beloved pottery studio was destroyed in a fire. She lost all of her equipment, tools, and materials, as well as some of her completed pieces. As a result, Maya was unable to continue creating and selling her pottery, which was her only source of income. Determined not to let this setback defeat her, Maya decided to launch a fundraising campaign to help rebuild her pottery studio. She reached out to her loyal customers, friends, and family, sharing her story and asking for their support.",
  },
];

export const pastinitiativesData = [
  {
    id: 1,
    imgUrl: "./assets/PastInitiativesDetail/pt1.png",
    totaldonationno: "10",
    startdate: "July 3, 2020",
    enddate: "July 10, 2022",
    title: "A Small Act Of Kindness With Big Impact",
    desc: "Our small act of kindness aimed to help local homeless people. They quickly discovered that kindness flowed both ways. Meet Maya, a talented handmade artist who has been creating beautiful pottery for over a decade. Her work is not only stunning, but also functional and practical. Maya's pottery is so unique that it has gained a lot of attention and a loyal following over the years. Recently, Maya was faced with a difficult challenge - her beloved pottery studio was destroyed in a fire. She lost all of her equipment, tools, and materials, as well as some of her completed pieces. As a result, Maya was unable to continue creating and selling her pottery, which was her only source of income. Determined not to let this setback defeat her, Maya decided to launch a fundraising campaign to help rebuild her pottery studio. She reached out to her loyal customers, friends, and family, sharing her story and asking for their support. Our small act of kindness aimed to help local homeless people. They quickly discovered that kindness flowed both ways. Meet Maya, a talented handmade artist who has been creating beautiful pottery for over a decade. Her work is not only stunning, but also functional and practical. Maya's pottery is so unique that it has gained a lot of attention and a loyal following over the years. Recently, Maya was faced with a difficult challenge - her beloved pottery studio was destroyed in a fire. She lost all of her equipment, tools, and materials, as well as some of her completed pieces. As a result, Maya was unable to continue creating and selling her pottery, which was her only source of income. Determined not to let this setback defeat her, Maya decided to launch a fundraising campaign to help rebuild her pottery studio. She reached out to her loyal customers, friends, and family, sharing her story and asking for their support.",
    raisedDonation: "1524",
    goalDonation: "2500",
    projectglimse: [
      {
        id: 1,
        imgUrl: "./assets/PastInitiativesDetail/pt1.png",
      },
      {
        id: 2,
        imgUrl: "./assets/PastInitiativesDetail/pt2.png",
      },
      {
        id: 3,
        imgUrl: "./assets/PastInitiativesDetail/pt3.png",
      },
      {
        id: 4,
        imgUrl: "./assets/PastInitiativesDetail/pt2.png",
      },
      {
        id: 5,
        imgUrl: "./assets/PastInitiativesDetail/pt2.png",
      },
      // {
      //   id: 6,
      //   imgUrl: "./assets/PastInitiativesDetail/pt3.png",
      //   type: "image",
      // },
      // {
      //   id: 7,
      //   imgUrl: "./assets/PastInitiativesDetail/pt2.png",
      //   type: "image",
      // },
    ],
    videos: [
      {
        id: 1,
        url: "./assets/PastInitiativesDetail/project.mp4",
        type: "video",
      },
      {
        id: 2,
        url: "./assets/PastInitiativesDetail/project2.mp4",
        type: "video",
      },
    ],
  },
  {
    id: 2,
    imgUrl: "./assets/ProjectsPage/past2.png",
    totaldonationno: "10",
    startdate: "July 3, 2020",
    enddate: "July 10, 2022",
    title: "A Small Act Of Kindness With Big Impact",
    desc: "Our small act of kindness aimed to help local homeless people. They quickly discovered that kindness flowed both ways. Meet Maya, a talented handmade artist who has been creating beautiful pottery for over a decade. Her work is not only stunning, but also functional and practical. Maya's pottery is so unique that it has gained a lot of attention and a loyal following over the years. Recently, Maya was faced with a difficult challenge - her beloved pottery studio was destroyed in a fire. She lost all of her equipment, tools, and materials, as well as some of her completed pieces. As a result, Maya was unable to continue creating and selling her pottery, which was her only source of income. Determined not to let this setback defeat her, Maya decided to launch a fundraising campaign to help rebuild her pottery studio. She reached out to her loyal customers, friends, and family, sharing her story and asking for their support. Our small act of kindness aimed to help local homeless people. They quickly discovered that kindness flowed both ways. Meet Maya, a talented handmade artist who has been creating beautiful pottery for over a decade. Her work is not only stunning, but also functional and practical. Maya's pottery is so unique that it has gained a lot of attention and a loyal following over the years. Recently, Maya was faced with a difficult challenge - her beloved pottery studio was destroyed in a fire. She lost all of her equipment, tools, and materials, as well as some of her completed pieces. As a result, Maya was unable to continue creating and selling her pottery, which was her only source of income. Determined not to let this setback defeat her, Maya decided to launch a fundraising campaign to help rebuild her pottery studio. She reached out to her loyal customers, friends, and family, sharing her story and asking for their support.",
    raisedDonation: "2004",
    goalDonation: "2500",
    projectglimse: [
      {
        id: 1,
        imgUrl: "./assets/ProjectsPage/past2.png",
      },
      {
        id: 2,
        imgUrl: "./assets/PastInitiativesDetail/pt3.png",
      },
      {
        id: 3,
        imgUrl: "./assets/PastInitiativesDetail/pt2.png",
      },
      {
        id: 4,
        imgUrl: "./assets/PastInitiativesDetail/pt2.png",
      },
      {
        id: 5,
        imgUrl: "./assets/PastInitiativesDetail/pt3.png",
      },
      // {
      //   id: 6,
      //   imgUrl: "./assets/PastInitiativesDetail/pt2.png",
      // },
    ],
    videos: [
      {
        id: 1,
        url: "./assets/PastInitiativesDetail/project.mp4",
        type: "video",
      },
      {
        id: 2,
        url: "./assets/PastInitiativesDetail/project2.mp4",
        type: "video",
      },
    ],
  },
  {
    id: 3,
    imgUrl: "./assets/ProjectsPage/past3.png",
    totaldonationno: "10",
    startdate: "July 3, 2020",
    enddate: "July 10, 2022",
    title: "A Small Act Of Kindness With Big Impact",
    desc: "Our small act of kindness aimed to help local homeless people. They quickly discovered that kindness flowed both ways. Meet Maya, a talented handmade artist who has been creating beautiful pottery for over a decade. Her work is not only stunning, but also functional and practical. Maya's pottery is so unique that it has gained a lot of attention and a loyal following over the years. Recently, Maya was faced with a difficult challenge - her beloved pottery studio was destroyed in a fire. She lost all of her equipment, tools, and materials, as well as some of her completed pieces. As a result, Maya was unable to continue creating and selling her pottery, which was her only source of income. Determined not to let this setback defeat her, Maya decided to launch a fundraising campaign to help rebuild her pottery studio. She reached out to her loyal customers, friends, and family, sharing her story and asking for their support. Our small act of kindness aimed to help local homeless people. They quickly discovered that kindness flowed both ways. Meet Maya, a talented handmade artist who has been creating beautiful pottery for over a decade. Her work is not only stunning, but also functional and practical. Maya's pottery is so unique that it has gained a lot of attention and a loyal following over the years. Recently, Maya was faced with a difficult challenge - her beloved pottery studio was destroyed in a fire. She lost all of her equipment, tools, and materials, as well as some of her completed pieces. As a result, Maya was unable to continue creating and selling her pottery, which was her only source of income. Determined not to let this setback defeat her, Maya decided to launch a fundraising campaign to help rebuild her pottery studio. She reached out to her loyal customers, friends, and family, sharing her story and asking for their support.",
    raisedDonation: "1004",
    goalDonation: "2500",
    projectglimse: [
      {
        id: 1,
        imgUrl: "./assets/ProjectsPage/past3.png",
      },
      {
        id: 2,
        imgUrl: "./assets/PastInitiativesDetail/pt3.png",
      },
      {
        id: 3,
        imgUrl: "./assets/PastInitiativesDetail/pt2.png",
      },
      {
        id: 4,
        imgUrl: "./assets/PastInitiativesDetail/pt2.png",
      },
      {
        id: 5,
        imgUrl: "./assets/PastInitiativesDetail/pt3.png",
      },
      // {
      //   id: 6,
      //   imgUrl: "./assets/PastInitiativesDetail/pt2.png",
      // },
    ],
    videos: [
      {
        id: 1,
        url: "./assets/PastInitiativesDetail/project.mp4",
        type: "video",
      },
      {
        id: 2,
        url: "./assets/PastInitiativesDetail/project2.mp4",
        type: "video",
      },
    ],
  },
  {
    id: 4,
    imgUrl: "./assets/ProjectsPage/runningprojects3.png",
    totaldonationno: "10",
    startdate: "July 3, 2020",
    enddate: "July 10, 2022",
    title: "A Small Act Of Kindness With Big Impact",
    desc: "Our small act of kindness aimed to help local homeless people. They quickly discovered that kindness flowed both ways. Meet Maya, a talented handmade artist who has been creating beautiful pottery for over a decade. Her work is not only stunning, but also functional and practical. Maya's pottery is so unique that it has gained a lot of attention and a loyal following over the years. Recently, Maya was faced with a difficult challenge - her beloved pottery studio was destroyed in a fire. She lost all of her equipment, tools, and materials, as well as some of her completed pieces. As a result, Maya was unable to continue creating and selling her pottery, which was her only source of income. Determined not to let this setback defeat her, Maya decided to launch a fundraising campaign to help rebuild her pottery studio. She reached out to her loyal customers, friends, and family, sharing her story and asking for their support. Our small act of kindness aimed to help local homeless people. They quickly discovered that kindness flowed both ways. Meet Maya, a talented handmade artist who has been creating beautiful pottery for over a decade. Her work is not only stunning, but also functional and practical. Maya's pottery is so unique that it has gained a lot of attention and a loyal following over the years. Recently, Maya was faced with a difficult challenge - her beloved pottery studio was destroyed in a fire. She lost all of her equipment, tools, and materials, as well as some of her completed pieces. As a result, Maya was unable to continue creating and selling her pottery, which was her only source of income. Determined not to let this setback defeat her, Maya decided to launch a fundraising campaign to help rebuild her pottery studio. She reached out to her loyal customers, friends, and family, sharing her story and asking for their support.",
    raisedDonation: "204",
    goalDonation: "2500",
    projectglimse: [
      {
        id: 1,
        imgUrl: "./assets/ProjectsPage/runningprojects3.png",
      },
      {
        id: 2,
        imgUrl: "./assets/PastInitiativesDetail/pt3.png",
      },
      {
        id: 3,
        imgUrl: "./assets/PastInitiativesDetail/pt2.png",
      },
      {
        id: 4,
        imgUrl: "./assets/PastInitiativesDetail/pt2.png",
      },
      {
        id: 5,
        imgUrl: "./assets/PastInitiativesDetail/pt3.png",
      },
      // {
      //   id: 6,
      //   imgUrl: "./assets/PastInitiativesDetail/pt2.png",
      // },
    ],
    videos: [
      {
        id: 1,
        url: "./assets/PastInitiativesDetail/project.mp4",
        type: "video",
      },
      {
        id: 2,
        url: "./assets/PastInitiativesDetail/project2.mp4",
        type: "video",
      },
    ],
  },
  {
    id: 5,
    imgUrl: "./assets/ProjectsPage/runningprojects2.png",
    totaldonationno: "10",
    startdate: "July 3, 2020",
    enddate: "July 10, 2022",
    title: "A Small Act Of Kindness With Big Impact",
    desc: "Our small act of kindness aimed to help local homeless people. They quickly discovered that kindness flowed both ways. Meet Maya, a talented handmade artist who has been creating beautiful pottery for over a decade. Her work is not only stunning, but also functional and practical. Maya's pottery is so unique that it has gained a lot of attention and a loyal following over the years. Recently, Maya was faced with a difficult challenge - her beloved pottery studio was destroyed in a fire. She lost all of her equipment, tools, and materials, as well as some of her completed pieces. As a result, Maya was unable to continue creating and selling her pottery, which was her only source of income. Determined not to let this setback defeat her, Maya decided to launch a fundraising campaign to help rebuild her pottery studio. She reached out to her loyal customers, friends, and family, sharing her story and asking for their support. Our small act of kindness aimed to help local homeless people. They quickly discovered that kindness flowed both ways. Meet Maya, a talented handmade artist who has been creating beautiful pottery for over a decade. Her work is not only stunning, but also functional and practical. Maya's pottery is so unique that it has gained a lot of attention and a loyal following over the years. Recently, Maya was faced with a difficult challenge - her beloved pottery studio was destroyed in a fire. She lost all of her equipment, tools, and materials, as well as some of her completed pieces. As a result, Maya was unable to continue creating and selling her pottery, which was her only source of income. Determined not to let this setback defeat her, Maya decided to launch a fundraising campaign to help rebuild her pottery studio. She reached out to her loyal customers, friends, and family, sharing her story and asking for their support.",
    raisedDonation: "2204",
    goalDonation: "2500",
    projectglimse: [
      {
        id: 1,
        imgUrl: "./assets/ProjectsPage/runningprojects2.png",
      },
      {
        id: 2,
        imgUrl: "./assets/PastInitiativesDetail/pt3.png",
      },
      {
        id: 3,
        imgUrl: "./assets/PastInitiativesDetail/pt2.png",
      },
      {
        id: 4,
        imgUrl: "./assets/PastInitiativesDetail/pt2.png",
      },
      {
        id: 5,
        imgUrl: "./assets/PastInitiativesDetail/pt3.png",
      },
      // {
      //   id: 6,
      //   imgUrl: "./assets/PastInitiativesDetail/pt2.png",
      // },
    ],
    videos: [
      {
        id: 1,
        url: "./assets/PastInitiativesDetail/project.mp4",
        type: "video",
      },
      {
        id: 2,
        url: "./assets/PastInitiativesDetail/project2.mp4",
        type: "video",
      },
    ],
  },
  {
    id: 6,
    imgUrl: "./assets/ProjectsPage/runningprojects1.png",
    totaldonationno: "10",
    startdate: "July 3, 2020",
    enddate: "July 10, 2022",
    title: "A Small Act Of Kindness With Big Impact",
    desc: "Our small act of kindness aimed to help local homeless people. They quickly discovered that kindness flowed both ways. Meet Maya, a talented handmade artist who has been creating beautiful pottery for over a decade. Her work is not only stunning, but also functional and practical. Maya's pottery is so unique that it has gained a lot of attention and a loyal following over the years. Recently, Maya was faced with a difficult challenge - her beloved pottery studio was destroyed in a fire. She lost all of her equipment, tools, and materials, as well as some of her completed pieces. As a result, Maya was unable to continue creating and selling her pottery, which was her only source of income. Determined not to let this setback defeat her, Maya decided to launch a fundraising campaign to help rebuild her pottery studio. She reached out to her loyal customers, friends, and family, sharing her story and asking for their support. Our small act of kindness aimed to help local homeless people. They quickly discovered that kindness flowed both ways. Meet Maya, a talented handmade artist who has been creating beautiful pottery for over a decade. Her work is not only stunning, but also functional and practical. Maya's pottery is so unique that it has gained a lot of attention and a loyal following over the years. Recently, Maya was faced with a difficult challenge - her beloved pottery studio was destroyed in a fire. She lost all of her equipment, tools, and materials, as well as some of her completed pieces. As a result, Maya was unable to continue creating and selling her pottery, which was her only source of income. Determined not to let this setback defeat her, Maya decided to launch a fundraising campaign to help rebuild her pottery studio. She reached out to her loyal customers, friends, and family, sharing her story and asking for their support.",
    raisedDonation: "1104",
    goalDonation: "2500",
    projectglimse: [
      {
        id: 1,
        imgUrl: "./assets/ProjectsPage/runningprojects1.png",
      },
      {
        id: 2,
        imgUrl: "./assets/PastInitiativesDetail/pt3.png",
      },
      {
        id: 3,
        imgUrl: "./assets/PastInitiativesDetail/pt2.png",
      },
      {
        id: 4,
        imgUrl: "./assets/PastInitiativesDetail/pt2.png",
      },
      {
        id: 5,
        imgUrl: "./assets/PastInitiativesDetail/pt3.png",
      },
      // {
      //   id: 6,
      //   imgUrl: "./assets/PastInitiativesDetail/pt2.png",
      // },
    ],
    videos: [
      {
        id: 1,
        url: "./assets/PastInitiativesDetail/project.mp4",
        type: "video",
      },
      {
        id: 2,
        url: "./assets/PastInitiativesDetail/project2.mp4",
        type: "video",
      },
    ],
  },
];
