import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import { Typography, Grid, Button } from "@mui/material";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { ImWhatsapp } from "react-icons/im";
import { TextField } from "@mui/material";
import { Organizer } from "../Organizer";
import ReactPlayer from "react-player";
import VideocamIcon from "@mui/icons-material/Videocam";
import { useLocation } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { FaXTwitter } from "react-icons/fa6";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import Skeleton from "@mui/material/Skeleton";

export const SupportArtistsContentDataSection = () => {
  const [showMoreMap, setShowMoreMap] = useState({});
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  const { id } = useParams();

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1Ref = useRef(null);
  const slider2Ref = useRef(null);

  useEffect(() => {
    setNav1(slider1Ref.current);
    setNav2(slider2Ref.current);
  }, []);

  const [play, setPlay] = useState(false);
  const handleShowMoreClick = (itemId) => {
    setShowMoreMap((prevShowMoreMap) => ({
      ...prevShowMoreMap,
      [itemId]: true,
    }));
  };

  const handleShowLessClick = (itemId) => {
    setShowMoreMap((prevShowMoreMap) => ({
      ...prevShowMoreMap,
      [itemId]: false,
    }));
  };

  const location = useLocation();
  const iconBoxStyle = {
    paddingTop: "10px",
    paddingBottom: "10px",
  };

  // Function to generate shareable URLs for social media platforms
  const generateShareUrl = () => {
    // Replace these placeholders with the actual URL and content you want to share
    const shareUrl = `${window.location.origin}${location.pathname}`;
    const shareTitle = "Check out this page"; // Replace with your page title
    const shareDescription = "Description of the shared content"; // Replace with your description

    return {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
      twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        shareTitle
      )}&url=${encodeURIComponent(shareUrl)}`,
      whatsapp: `https://api.whatsapp.com/send?text=${encodeURIComponent(
        `${shareTitle}\n${shareUrl}`
      )}`,
      youtubeUrl: "https://www.youtube.com/user/yourchannel", // Replace with the user's YouTube channel URL
      tiktokUrl: "https://www.tiktok.com/youraccount",
    };
  };

  const socialMediaUrls = generateShareUrl();

  // Step 1: Create state to store the link value
  const [link, setLink] = useState(
    `${window.location.origin}${location.pathname}`
  );

  // // Step 2: Add onChange event handler to update the link state
  // const handleLinkChange = (event) => {
  //   setLink(event.target.value);
  // };

  // Step 3: Modify the "Copy Link" button to copy the link to clipboard
  const handleCopyLink = () => {
    if (link) {
      navigator.clipboard
        .writeText(link)
        .then(() => {
          // Optionally, you can show a success message to the user here
          toast.success("Link copied to clipboard!");
        })
        .catch((error) => {
          // Handle any errors that occurred during copying
          toast.error("Error copying link:", error);
        });
    } else {
      // Optionally, you can show an error message if the link is empty
      alert("No link to copy!");
    }
  };

  const { getById } = useAPI();

  const getArtistDetails = async () => {
    const res = await getById(endpoint.artists, id);
    return res.data;
  };

  const { isDataLoading, data: artistDetails } = useQuery({
    queryKey: ["artistData"],
    queryFn: getArtistDetails,
  });

  if (isDataLoading) return;
  <>
    <Skeleton variant="text" width="80%" height="20px" />
  </>;

  const combinedArray = [];

  if (Array.isArray(artistDetails?.images)) {
    combinedArray.push(
      ...artistDetails?.images?.map((item) => ({ ...item, type: "image" }))
    );
  }

  if (Array.isArray(artistDetails?.videos)) {
    combinedArray.push(
      ...artistDetails?.videos?.map((item) => ({ ...item, type: "video" }))
    );
  }

  // const showMore = showMoreMap[id];
  // const shouldShowMore = artistDetails?.description?.length > 100;

  return (
    <Box
      sx={{
        marginTop: "20px",
      }}
    >
      {artistDetails && (
        <Box>
          <Slider asNavFor={nav2} ref={slider1Ref} arrows={false} fade={true}>
            {combinedArray.map((item) => {
              return (
                <Box
                  sx={{
                    width: "100%",
                    height: "50vh",
                  }}
                  onClick={() => {
                    setPlay(!play);
                  }}
                >
                  {item.type === "video" ? (
                    <ReactPlayer
                      url={item.artistVideos}
                      controls={true}
                      width="100%"
                      height="100%"
                      playing={play}
                    />
                  ) : (
                    <img
                      src={item.artistImages}
                      alt="projectimage"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: " center",
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Slider>
          <Box
            sx={{
              marginTop: "20px",
              marginBottom: {
                xs: "50px",
                sm: "80px",
                md: "10px",
                lg: "0px",
                xl: "0px",
              },
              width: {
                xs: "100%",
                sm: "60%",
                md: "60%",
                lg: "45%",
                xl: "45%",
              },
            }}
          >
            <Slider
              asNavFor={nav1}
              ref={slider2Ref}
              slidesToShow={combinedArray.length < 3 ? combinedArray.length : 3}
              swipeToSlide={true}
              focusOnSelect={true}
              arrows={false}
            >
              {combinedArray.map((item) => {
                return (
                  <Grid item xs={12} sm={12} md={12} lg={12} xl={12}>
                    <Box
                      sx={{
                        width: {
                          xs: "20vw",
                          sm: "13vw",
                          md: "14vw",
                          lg: "6.5vw",
                          xl: "6.5vw",
                        },
                        height: "10vh",
                        position: "relative",
                      }}
                    >
                      {item.type === "video" ? (
                        <Box>
                          <ReactPlayer
                            url={item.artistVideos}
                            controls={false}
                            height="12vh"
                            width="100%"
                            onClick={(e) => {
                              e.preventDefault();
                            }}
                          />
                          <Box
                            sx={{
                              backgroundColor: "var(--theme-color)",
                              padding: "0px 3px 3px 3px",
                              position: "absolute",
                              bottom: 0,
                              left: 0,
                              margin: "3px",
                              color: "#fff",
                              borderRadius: "50%",
                            }}
                          >
                            <VideocamIcon
                              sx={{
                                height: "15px",
                                width: "18px",
                              }}
                            />
                          </Box>
                        </Box>
                      ) : (
                        <img
                          src={item.artistImages}
                          alt="projectimage"
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: " center",
                          }}
                        />
                      )}
                    </Box>
                  </Grid>
                );
              })}
            </Slider>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "40px",
            }}
          >
            <Box
              sx={{
                position: "relative",
                width: "8%",
                height: "auto",
                backgroundColor: "var(--theme-color)",
                padding: "25px",
                borderRadius: "50%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",

                border: "5px solid var(--white)",
              }}
            >
              <img
                src={
                  process.env.PUBLIC_URL +
                  "../assets/supportartists/support-icon.png"
                }
                alt=""
                style={{
                  width: "80%",
                  height: "auto",
                  zIndex: "1",
                  position: "absolute",
                }}
              />
            </Box>
            <Box>
              <Typography
                variant="h4"
                sx={{
                  fontSize: {
                    xs: "19px",
                    sm: "19px",
                    md: "20px",
                    lg: "24px",
                    xl: "18px",
                  },
                  color: "var(--black)",
                  fontFamily: "var( --primary-font)",
                  marginLeft: "10px",
                  fontWeight: "600",
                }}
              >
                {artistDetails?.organizer?.name} organization is starting this
                campaign
              </Typography>
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: "20px",
            }}
          >
            <div className="horizontal-line"></div>
            <div className="horizontal-line"></div>
            <div className="horizontal-line"></div>
          </Box>

          <Box
            sx={{
              marginTop: "20px",
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            {showMore ? (
              <Typography
                sx={{
                  fontSize: "15px",
                  fontFamily: "var(--primary-font-family)",
                  color: "var(--dark-color)",
                  // paddingBottom: "25px",
                }}
                dangerouslySetInnerHTML={{
                  __html: artistDetails?.description,
                }}
              ></Typography>
            ) : (
              <Typography
                sx={{
                  fontSize: "15px",
                  fontFamily: "var(--primary-font-family)",
                  color: "var(--dark-color)",
                  // paddingBottom: "25px",
                }}
                dangerouslySetInnerHTML={{
                  __html: `${artistDetails?.description?.slice(0, 800)}`,
                }}
              ></Typography>
            )}
            {artistDetails?.description?.length > 2000 && (
              <NavLink
                style={{
                  color: "var(--theme-color)",
                  marginBottom: "30px",
                }}
                onClick={toggleShowMore}
              >
                {showMore ? "Read less" : "Read more"}
              </NavLink>
            )}
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
                xl: "row",
              },
              justifyContent: "flex-start",
              alignItems: "center",
              marginTop: "50px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "row",
                  sm: "row",
                  md: "row",
                  lg: "row",
                  xl: "row",
                },
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              <Box
                sx={{
                  marginTop: "10px",
                  marginRight: "10px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "11px",
                      sm: "11px",
                      md: "13px",
                      lg: "15px",
                      xl: "18px",
                    },
                    color: "var(--black)",
                    fontFamily: "var( --primary-font)",
                    textAlign: "justify",
                    fontWeight: "500",
                  }}
                >
                  Share artisan story
                </Typography>
              </Box>
              {/* <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "column",
                    sm: "column",
                    md: "row",
                    lg: "row",
                    xl: "row",
                  },
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              > */}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "flex-start",
                  zIndex: "1",
                  marginTop: {
                    xs: "10px",
                    sm: "10px",
                    md: "0",
                    lg: "0",
                    xl: "0",
                  },
                }}
              >
                <ul
                  style={{
                    listStyle: "none",
                    margin: "0",
                    padding: "0",
                    display: "flex",
                  }}
                  className="socialmediaicons"
                >
                  <li className="socialmediaiconlist">
                    <a
                      href={socialMediaUrls.facebook}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mediaiconstags"
                    >
                      <FaFacebookF
                        style={iconBoxStyle}
                        size="40px"
                        className="icon"
                      />
                    </a>
                  </li>
                  <li className="socialmediaiconlist">
                    <a
                      href={socialMediaUrls.twitter}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mediaiconstags"
                    >
                      <FaXTwitter
                        style={iconBoxStyle}
                        size="40px"
                        className="icon"
                      />
                    </a>
                  </li>
                  <li className="socialmediaiconlist">
                    <a
                      href={socialMediaUrls.whatsapp}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="mediaiconstags"
                    >
                      <ImWhatsapp
                        style={iconBoxStyle}
                        size="40px"
                        className="icon"
                      />
                    </a>
                  </li>
                  {/* <li className="socialmediaiconlist">
              <a
                href={socialMediaUrls.youtube}
                target="_blank"
                rel="noopener noreferrer"
                className="mediaiconstags"
              >
                <FaYoutube style={iconBoxStyle} size="40px" className="icon" />
              </a>
            </li>
            <li className="socialmediaiconlist">
              <a
                href={socialMediaUrls.tiktok}
                target="_blank"
                rel="noopener noreferrer"
                className="mediaiconstags"
              >
                <FaTiktok style={iconBoxStyle} size="40px" className="icon" />
              </a>
            </li> */}
                </ul>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "flex-start",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  marginTop: "10px",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "11px",
                      sm: "11px",
                      md: "13px",
                      lg: "15px",
                      xl: "18px",
                    },
                    color: "var(--black)",
                    fontFamily: "var( --primary-font)",
                    textAlign: "justify",
                    fontWeight: "600",
                    marginLeft: "20px",
                  }}
                >
                  or
                </Typography>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: {
                    xs: "row",
                    sm: "row",
                    md: "row",
                    lg: "row",
                    xl: "row",
                  },
                  justifyContent: "center",
                  alignItems: "center",
                  fontSize: {
                    xs: "13px",
                    sm: "13px",
                    md: "20px",
                    lg: "20px",
                    xl: "20px",
                  },
                  marginTop: "10px",
                  // width: "100%",
                }}
              >
                <TextField
                  id="search"
                  type="search"
                  // label="Helpartisan.com"
                  size="small"
                  // onChange={handleLinkChange}
                  values={`${window.location.origin}${location.pathname}`}
                  placeholder={`${window.location.origin}${location.pathname}`}
                  readOnly
                  sx={{
                    width: {
                      xs: "60%",
                      sm: "60%",
                      md: "60%",
                      lg: "60%",
                      xl: "60%",
                    },
                    borderRadius: "5px",
                    backgroundColor: "#fff",
                    border: "0",
                    " & .MuiOutlinedInput-root": {
                      "  &.Mui-focused fieldset": {
                        borderColor: "var(--theme-color)",
                      },
                    },
                    "& .MuiOutlinedInput-root": {
                      border: "0px solid var(--white)",
                      borderRadius: "0",
                      padding: "0",
                    },
                    "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid #E4E4E4",
                      },
                    //border colour when focused
                    "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                      {
                        border: "1px solid var( --btn-color)",
                      },
                    //floating label color when focused
                    "& .MuiInputLabel-outlined.Mui-focused": {
                      color: "var(--theme-color)",
                    },
                  }}
                  inputProps={{
                    style: {
                      textAlign: "center",
                      fontSize: {
                        xs: "11px",
                        sm: "11px",
                        md: "18px",
                        lg: "20px",
                        xl: "20px",
                      },
                    },
                  }}
                />
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "var(--theme-color)",
                    height: "auto",
                    fontSize: {
                      xs: "8px",
                      sm: "9px",
                      md: "12px",
                      lg: "12px",
                      xl: "12px",
                    },
                    padding: "10px 15px 10px 15px",
                    marginTop: {
                      xs: "10px",
                      sm: "20px",
                      md: "0",
                      lg: "0",
                      xl: "0",
                    },
                    alignSelf: { xs: "flex-start", sm: "flex-start" },
                    "&:hover": {
                      backgroundColor: "var(--theme-color)",
                      color: "var(--white)",
                      transform: "scale(1.1)",
                    },
                  }}
                  onClick={handleCopyLink}
                >
                  Copy Link
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      )}
      <Box>
        <Organizer
          organizerLogo={artistDetails?.organizer?.logo}
          Link={artistDetails?.organizer?.link}
          address={artistDetails?.organizer?.address}
          name={artistDetails?.organizer?.name}
        />
      </Box>
    </Box>
  );
};
