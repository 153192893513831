import React, { useState } from "react";
import { Box } from "@mui/system";
import { Typography, Button, Grid } from "@mui/material";
import { NavLink } from "react-router-dom";
import { ReviewForm } from "../../ReviewForm/ReviewForm";
import Modal from "@mui/material/Modal";
import OrderTracker from "./OrderTracker";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { endpoint } from "../../../constant/endpoint";
import useAPI from "../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { PujaReviewForm } from "../../ReviewForm/PujaReviewForm";

export const OrderDetails = ({ orderId }) => {
  const [openreview, setOpenreview] = React.useState(false);
  const handleOpenreview = () => setOpenreview(true);
  const handleClosereview = () => setOpenreview(false);
  const [openPujareview, setOpenPujareview] = React.useState(false);
  const handleOpenPujareview = () => setOpenPujareview(true);
  const handleClosePujareview = () => setOpenPujareview(false);

  const [displayedItems, setDisplayedItems] = useState(2);

  const { getAuthDataById } = useAPI();

  const getNewOrderDetails = async () => {
    const res = await getAuthDataById(endpoint.orderdetails, orderId);
    return res.data;
  };

  const { isLoading, data: orderDetails } = useQuery({
    queryKey: ["orderDetails", orderId],
    queryFn: getNewOrderDetails,
  });

  if (isLoading) return <div>Loading...</div>;


  const handleShowMore = () => {
    setDisplayedItems();
  };

  const handleShowLess = () => {
    setDisplayedItems(1);
  };

  const rate = orderDetails?.rate;

  const IntRate = parseInt(rate);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        width: "100%",
      }}
    >
      <Typography
        variant="h6"
        component="div"
        sx={{
          color: "var(--black)",
          fontWeight: "500",
          fontFamily: "var(--primary-font)",
          marginBottom: "20px",
          marginTop: "30px",
          fontSize: {
            xs: "14px",
            sm: "16px",
            md: "18px",
            lg: "20px",
            xl: "24px",
          },
        }}
      >
        Order Details
      </Typography>

      <Box
        sx={{
          width: "100%",
        }}
        key={orderDetails?.id}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            width: "100%",
            marginBottom: "20px",
            backgroundColor: "#F5F5F5",
            padding: "20px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="body1"
              component="div"
              sx={{
                color: "var(--theme-color)",
                fontWeight: "500",
                fontFamily: "var(--primary-font)",
                fontSize: {
                  xs: "13px",
                  sm: "14px",
                  md: "14px",
                  lg: "15px",
                  xl: "15px",
                },
              }}
            >
              Order {""}
              {orderDetails?.order_code}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "var(--black)",
                fontWeight: "400",
                fontFamily: "var(--primary-font)",
                fontSize: {
                  xs: "13px",
                  sm: "14px",
                  md: "14px",
                  lg: "15px",
                  xl: "15px",
                },
              }}
            >
              Placed on {orderDetails?.created_at}
            </Typography>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Box
              sx={{
                backgroundColor: "var(--green)",
                color: "var(--white)",
                padding: "5px",
                width: "fit-content",
                borderRadius: "5px",
              }}
            >
              paid
            </Box>
            <Typography
              variant="body1"
              sx={{
                color: "var(--black)",
                fontWeight: "400",
                fontFamily: "var(--primary-font)",
                fontSize: {
                  xs: "13px",
                  sm: "14px",
                  md: "14px",
                  lg: "15px",
                  xl: "15px",
                },
              }}
            >
              Total: {""}
              <span
                style={{
                  color: "var(--theme-color)",
                }}
              >
                {" "}
                {orderDetails?.currency} {orderDetails?.total_amount}
              </span>
            </Typography>
          </Box>
        </Box>
        <Grid container>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
              }}
            >
              {orderDetails?.items?.slice(0, displayedItems).map((item) => (
                <Box
                  // sx={{
                  //   display: "flex",
                  //   flexDirection: "column",
                  //   justifyContent: "flex-start",
                  //   alignItems: "flex-start",
                  //   marginBottom: "20px",
                  // }}
                  key={item.id}
                >
                  {item?.product !== null && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        marginBottom: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "85px",
                          height: "85px",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          src={item?.product?.images[0]?.image}
                          alt="..."
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "center",
                            borderRadius: "5px",
                          }}
                        />
                      </Box>

                      <Typography
                        variant="body1"
                        sx={{
                          color: "var(--black)",
                          fontWeight: "500",
                          fontFamily: "var(--primary-font)",
                          fontSize: {
                            xs: "13px",
                            sm: "14px",
                            md: "14px",
                            lg: "15px",
                            xl: "15px",
                          },
                        }}
                      >
                        {item?.product?.name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <Typography
                          variant="body1"
                          sx={{
                            color: "var(--light-black)",
                            fontWeight: "500",
                            fontFamily: "var(--primary-font)",
                            fontSize: {
                              xs: "11px",
                              sm: "12px",
                              md: "12px",
                              lg: "13px",
                              xl: "13px",
                            },
                            marginRight: "10px",
                          }}
                        >
                          Quantity:{""} {item.qty}
                        </Typography>
                        <span
                          style={{
                            color: "#C4C4C4",
                          }}
                        >
                          |
                        </span>
                        <Typography
                          variant="body1"
                          sx={{
                            color: "var(--light-black)",
                            fontWeight: "500",
                            fontFamily: "var(--primary-font)",
                            fontSize: {
                              xs: "11px",
                              sm: "12px",
                              md: "12px",
                              lg: "13px",
                              xl: "13px",
                            },
                            marginLeft: "10px",
                          }}
                        >
                          Price: {""}{" "}
                          <span style={{ color: "var(--theme-color)" }}>
                            {orderDetails?.currency} {item.price / IntRate}
                          </span>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          marginTop: "10px",
                        }}
                      >
                        <NavLink
                          style={{
                            color: "var(--theme-color)",
                          }}
                          onClick={handleOpenreview}
                        >
                          Write a review
                        </NavLink>
                        <Modal
                          open={openreview}
                          onClose={handleClosereview}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box
                            sx={{
                              backgroundColor: "var(--white)",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              padding: "20px",
                              width: {
                                xs: "90%",
                                sm: "60%",
                                md: "60%",
                                lg: "40%",
                                xl: "40%",
                              },
                            }}
                          >
                            <ReviewForm
                              handleClosereview={handleClosereview}
                              prodTitle={item.product?.name}
                              prodId={item.product?.id}
                            />
                          </Box>
                        </Modal>
                      </Box>
                    </Box>
                  )}
                  {item?.service !== null && (
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        marginBottom: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          width: "85px",
                          height: "85px",
                          marginBottom: "10px",
                        }}
                      >
                        <img
                          src={item?.service?.images[0]?.image}
                          alt="..."
                          style={{
                            width: "100%",
                            height: "100%",
                            objectFit: "cover",
                            objectPosition: "center",
                            borderRadius: "5px",
                          }}
                        />
                      </Box>

                      <Typography
                        variant="body1"
                        sx={{
                          color: "var(--black)",
                          fontWeight: "500",
                          fontFamily: "var(--primary-font)",
                          fontSize: {
                            xs: "13px",
                            sm: "14px",
                            md: "14px",
                            lg: "15px",
                            xl: "15px",
                          },
                        }}
                      >
                        {item?.service?.name}
                      </Typography>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        {" "}
                        <Typography
                          variant="body1"
                          sx={{
                            color: "var(--light-black)",
                            fontWeight: "500",
                            fontFamily: "var(--primary-font)",
                            fontSize: {
                              xs: "11px",
                              sm: "12px",
                              md: "12px",
                              lg: "13px",
                              xl: "13px",
                            },
                          }}
                        >
                          Price: {""}{" "}
                          <span style={{ color: "var(--theme-color)" }}>
                            {orderDetails?.currency} {item.price / IntRate}
                          </span>
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          marginTop: "10px",
                        }}
                      >
                        <NavLink
                          style={{
                            color: "var(--theme-color)",
                          }}
                          onClick={handleOpenPujareview}
                        >
                          Write a review
                        </NavLink>
                        <Modal
                          open={openPujareview}
                          onClose={handleClosePujareview}
                          aria-labelledby="modal-modal-title"
                          aria-describedby="modal-modal-description"
                        >
                          <Box
                            sx={{
                              backgroundColor: "var(--white)",
                              position: "absolute",
                              top: "50%",
                              left: "50%",
                              transform: "translate(-50%, -50%)",
                              padding: "20px",
                              width: {
                                xs: "90%",
                                sm: "60%",
                                md: "60%",
                                lg: "40%",
                                xl: "40%",
                              },
                            }}
                          >
                            <PujaReviewForm
                              handleClosereview={handleClosePujareview}
                              pujaTitle={item.service?.name}
                              pujaId={item.service?.id}
                            />
                          </Box>
                        </Modal>
                      </Box>
                    </Box>
                  )}
                </Box>
              ))}
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                {displayedItems < orderDetails?.items?.length && (
                  <Button
                    variant="outlined"
                    sx={{
                      color: "var(--theme-color)",
                      border: "1px solid var(--theme-color)",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      marginTop: "20px",
                      marginBottom: "30px",
                      fontSize: {
                        xs: "11px",
                        sm: "11px",
                        md: "13px",
                        lg: "15px",
                        xl: "15px",
                      },
                      textTransform: "capitalize",
                      "&:hover": {
                        backgroundColor: "var(--theme-color)",
                        color: "white",
                        border: "1px solid var(--theme-color)",
                        transform: "scale(1.1)",
                      },
                    }}
                    onClick={handleShowMore}
                  >
                    Show More
                    <KeyboardArrowDownIcon />
                  </Button>
                )}
                {displayedItems > 2 && (
                  <Button
                    variant="outlined"
                    sx={{
                      color: "var(--theme-color)",
                      border: "1px solid var(--theme-color)",
                      width: "100%",
                      display: "flex",
                      justifyContent: "center",
                      textTransform: "capitalize",
                      alignItems: "center",

                      marginTop: "20px",
                      marginBottom: "50px",
                      fontSize: {
                        xs: "11px",
                        sm: "11px",
                        md: "13px",
                        lg: "15px",
                        xl: "15px",
                      },
                      "&:hover": {
                        backgroundColor: "var(--theme-color)",
                        color: "var(--white)",
                        border: "1px solid var(--theme-color)",
                        transform: "scale(1.1)",
                      },
                    }}
                    onClick={handleShowLess}
                  >
                    Show Less
                    <KeyboardArrowUpIcon />
                  </Button>
                )}
              </Box>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            sm={8}
            md={8}
            lg={8}
            xl={8}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {orderDetails?.status === "cancel" ? (
              <Typography
                variant="body1"
                sx={{
                  color: "red",
                  fontWeight: "700",
                  fontFamily: "var(--primary-font)",
                  fontSize: {
                    xs: "14px",
                    sm: "15px",
                    md: "16px",
                    lg: "18px",
                    xl: "18px",
                  },
                }}
              >
                Your Order has been Cancelled, Please contact to us for more
                info
              </Typography>
            ) : (
              <OrderTracker orderId={orderId} orderDetails={orderDetails} />
            )}
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          sx={{
            disply: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Box
              sx={{
                backgroundColor: "var(--very-lightthemecolor)",
                fontFamily: "var(--primary-font)",
                color: "var(--light-black)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                padding: "20px",
                height: "250px",
                marginRight: {
                  xs: "0px",
                  sm: "10px",
                  md: "10px",
                  lg: "10px",
                  xl: "10px",
                },
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "500",
                  fontSize: {
                    xs: "18px",
                    sm: "20px",
                    md: "20px",
                    lg: "23px",
                    xl: "23px",
                  },
                }}
              >
                Shipping Address
              </Typography>
              <Box
                sx={{
                  marginTop: "20px",
                  textAlign: "left",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                  }}
                >
                  {orderDetails?.users?.receiver_firstname} {""}{" "}
                  {orderDetails?.users?.receiver_secondname}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                  }}
                >
                  {orderDetails?.users?.receiver_email}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                    marginTop: "10px",
                  }}
                >
                  {orderDetails?.users?.receiver_city} ,
                  {orderDetails?.users?.receiver_state}, {""}
                  {orderDetails?.users?.receiver_country}
                  {orderDetails?.users?.receiver_zip_code}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                    marginTop: "20px",
                  }}
                >
                  {orderDetails?.users?.receiver_number}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Box
              sx={{
                backgroundColor: "var(--very-lightthemecolor)",
                fontFamily: "var(--primary-font)",
                color: "var(--light-black)",
                display: "flex",
                flexDirection: "column",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                padding: "20px",
                height: "250px",
                marginLeft: {
                  xs: "0px",
                  sm: "20px",
                  md: "20px",
                  lg: "20px",
                  xl: "20px",
                },
                marginRight: {
                  xs: "0px",
                  sm: "10px",
                  md: "10px",
                  lg: "10px",
                  xl: "10px",
                },
              }}
            >
              <Typography
                variant="body1"
                sx={{
                  fontWeight: "500",
                  fontSize: {
                    xs: "18px",
                    sm: "20px",
                    md: "20px",
                    lg: "23px",
                    xl: "23px",
                  },
                }}
              >
                Billing Address
              </Typography>
              <Box
                sx={{
                  marginTop: "20px",
                  textAlign: "left",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                  }}
                >
                  {orderDetails?.users?.name}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                  }}
                >
                  {orderDetails?.users?.email}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                    marginTop: "10px",
                  }}
                >
                  {orderDetails?.users?.city} {""}, {orderDetails?.users?.state}{" "}
                  ,{orderDetails?.users?.country}
                  {orderDetails?.users?.postal_code}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                    marginTop: "20px",
                  }}
                >
                  {orderDetails?.users?.number}
                </Typography>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
            <Box
              sx={{
                backgroundColor: "var(--very-lightthemecolor)",
                fontFamily: "var(--primary-font)",
                color: "var(--light-black)",
                display: "flex",
                flexDirection: "column",
                padding: "20px",
                height: "250px",
                marginLeft: {
                  xs: "0px",
                  sm: "30px",
                  md: "30px",
                  lg: "30px",
                  xl: "30px",
                },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontWeight: "500",
                    fontSize: {
                      xs: "18px",
                      sm: "20px",
                      md: "20px",
                      lg: "23px",
                      xl: "23px",
                    },
                  }}
                >
                  Total Summary
                </Typography>
              </Box>

              <Box
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                  }}
                >
                  Sub Total
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                  }}
                >
                  {orderDetails?.currency} {""} {orderDetails?.sub_total}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: "10px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                  }}
                >
                  Delivery Fee
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                  }}
                >
                  {orderDetails?.currency} {""}
                  {orderDetails?.delivery_charge}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                  }}
                >
                  Total
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: {
                      xs: "14px",
                      sm: "15px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                  }}
                >
                  {orderDetails?.currency} {""}
                  {orderDetails?.total_amount}
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};
