import React from "react";
import { Box } from "@mui/system";
import { Container, Grid, Typography } from "@mui/material";
import backimg from "../../assets/backimg.png";
import { useState } from "react";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const GoodDharmaBottom = ({
  story_left_image,
  story_left_title,
  story_left_description,
  story_center_image,
  story_right_image,
  story_right_title,
  story_right_description,
  watermark,
}) => {
  const [displaylogo, setDisplaylogo] = useState(watermark);

  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.sitesettings);
    return res.data;
  };

  const { data: siteSettings } = useQuery({
    queryKey: ["sitesettingsData"],
    queryFn: getData,
  });

  const leftData = [
    {
      imgUrl: story_left_image,
      title: story_left_title,
      desc: story_left_description,
    },
  ];

  const rightData = [
    {
      imgUrl: story_right_image,
      title: story_right_title,
      desc: story_right_description,
    },
  ];

  const mergedData = leftData.concat(rightData);
  return (
    <Box
      className="images-cont"
      sx={{
        marginTop: "80px",
        marginBottom: {
          xs: "0px",
          sm: "30px",
          md: "30px",
          lg: "30px",
          xl: "30px",
        },
        backgroundImage: {
          xs: "none",
          sm: "none",
          md: `url(${backimg})`,
          lg: `url(${backimg})`,
          xl: `url(${backimg})`,
        },
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
      }}
    >
      <Container>
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
            top: {
              xs: "-50px",
              sm: "-60px",
              md: "70px",
              lg: "80px",
              xl: "50px",
            },
          }}
        >
          {mergedData?.map((item, index) => (
            <Grid
              item
              xs={5}
              sm={5}
              md={2}
              lg={2}
              xl={2}
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                  xl: "row",
                },
                justifyContent: "space-between",
                alignItems: "center",
                position: "relative",
                mt: {
                  xs: "0px",
                  sm: "0px",
                  md: "0px",
                  lg: "0px",
                  xl: "0px",
                },
              }}
              key={index}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  // alignItems: index % 2 === 1 ? "flex-end" : "flex-start",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "80%",
                      md: "90%",
                      lg: "100%",
                      xl: "100%",
                    },
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <img
                    src={item.imgUrl}
                    alt="..."
                    style={{
                      height: "auto",
                      width: "80%",
                      objectFit: "cover",
                      objectPosition: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                  <Box
                    sx={{
                      textAlign: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: {
                          xs: "13px",
                          sm: "14px",
                          md: "14px",
                          lg: "16px",
                          xl: "16px",
                        },
                        color: "var(--theme-color)",
                        fontFamily: "var(--primary-font)",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <br />
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: {
                          xs: "11px",
                          sm: "13px",
                          md: "13px",
                          lg: "14px",
                          xl: "14px",
                        },
                        color: "var(--black)",
                        fontFamily: "var(--primary-font)",
                      }}
                    >
                      {item.desc}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={9}
            xl={9}
            className="center-image-cont"
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
                xl: "block",
              },
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: {
                xs: "300px",
                sm: "95%",
                md: "-80px",
                lg: "-90px",
                xl: "-90px",
              },
              left: {
                xs: "90px",
                sm: "30%",
                md: "25%",
                lg: "20%",
                xl: "25%",
              },
              width: {
                xs: "60%",
                sm: "50%",
                md: "60%",
                lg: "50%",
                xl: "50%",
              },
            }}
          >
            <Box>
              <img
                src={story_center_image}
                alt="..."
                style={{
                  position: "relative",
                  overflow: "hidden",
                }}
                className="bottom-image"
              />
            </Box>

            {displaylogo ? (
              <img
                src={siteSettings?.logo}
                alt="..."
                style={{
                  position: "absolute",
                  zIndex: "100",
                  top: "50%",
                  left: "30%",
                }}
                className="top-image"
              />
            ) : (
              ""
            )}
          </Grid>

          <Grid
            xs={12}
            sm={12}
            md={0}
            lg={0}
            xl={0}
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
                lg: "none",
                xl: "none",
              },
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: {
                xs: "40%",
                sm: "40%",
                md: "50%",
                lg: "0%",
                xl: "0%",
              },
              marginLeft: "20px",
            }}
          >
            <img
              src={story_center_image}
              alt="..."
              style={{
                position: "relative",
                width: "100%",
                height: "auto",
              }}
            />

            {displaylogo === 1 ? (
              <Box
                sx={{
                  position: "absolute",
                  zIndex: "100",
                  top: {
                    xs: "75%",
                    sm: "60%",
                    md: "60%",
                    lg: "50%",
                    xl: "50%",
                  },
                  left: {
                    xs: "20%",
                    sm: "25%",
                    md: "28%",
                    lg: "30%",
                    xl: "30%",
                  },
                }}
              >
                <img
                  src={siteSettings?.logo}
                  alt="..."
                  style={{}}
                  className="top-image"
                />
              </Box>
            ) : (
              ""
            )}
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
