import React from "react";
import { Typography, Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import { ContributionBottom } from "./ContributionBottom";

export const Contribution = ({
  social_title,
  social_description,
  social_image,
  social_image_one,
  social_image_two,
  social_image_three,
  social_image_four,
  social_image_five,
  social_image_six,
}) => {
  //straight line
  const stline = {
    width: "100%",
    height: "1px",
    backgroundColor: "#FFFFFF",
    marginTop: "1px",
    opacity: "0.3",
  };
  return (
    <Box
      sx={{
        // zIndex: 100,
        // position: "absolute",
        // right: "0",
        position: "relative",
        right: {
          xs: "0",
          sm: "0",
          md: "-152px",
          lg: "-152px",
          xl: "-152px",
        },
        top: {
          xs: "0",
          sm: "0",
          md: "-120px",
          lg: "-120px",
          xl: "-120px",
        },
        width: {
          xs: "100%",
          sm: "100%",
          md: "90%",
          lg: "90%",
          xl: "90%",
        },
        backgroundColor: "var(--theme-color)",
        marginBottom: {
          xs: "30px",
          sm: "40px",
          md: "0px",
          lg: "0px",
          xl: "0px",
        },
      }}
    >
      <Container>
        <Grid
          container
          xs={12}
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            padding: "60px 0px 60px 0px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={5}
            lg={5}
            xl={5}
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              //   alignItems: "center",
            }}
          >
            <Typography
              variant="h5"
              sx={{
                color: "var(--white)",
                fontSize: {
                  xs: "13px",
                  sm: "14px",
                  md: "15px",
                  lg: "16px",
                  xl: "16px",
                },
                textAlign: "justify",
                fontFamily: "var(--primary-font)",
              }}
            >
              SERVICE INITIATIVE
            </Typography>
            <br />
            <Typography
              variant="h3"
              sx={{
                color: "var(--white)",
                fontSize: {
                  xs: "30px",
                  sm: "30px",
                  md: "30px",
                  lg: "30px",
                  xl: "30px",
                },
                textAlign: "justify",
                fontFamily: "var(--primary-font)",
              }}
            >
              {social_title}
            </Typography>
            <Box sx={{ marginTop: "20px", marginBottom: "40px" }}>
              <Box sx={stline}></Box>
              <Box sx={stline}></Box>
              <Box sx={stline}></Box>
            </Box>
            <Typography
              variant="body1"
              sx={{
                color: "var(--white)",
                fontSize: {
                  xs: "12px",
                  sm: "13px",
                  md: "14px",
                  lg: "15px",
                  xl: "15px",
                },
                fontFamily: "var(--primary-font)",
                textAlign: "justify",
              }}
            >
              {social_description}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "80%",
              }}
            >
              <img
                src={social_image}
                alt=""
                style={{ height: "auto", width: "100%" }}
              />
            </Box>
          </Grid>
        </Grid>
        <Box sx={{ marginTop: "20px", marginBottom: "40px" }}>
          <Box sx={stline}></Box>
          <Box sx={stline}></Box>
          <Box sx={stline}></Box>
        </Box>
      </Container>
      <ContributionBottom
        social_image_one={social_image_one}
        social_image_two={social_image_two}
        social_image_three={social_image_three}
        social_image_four={social_image_four}
        social_image_five={social_image_five}
        social_image_six={social_image_six}
      />
    </Box>
  );
};
