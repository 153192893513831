import React, { useRef } from "react";
import { Box } from "@mui/system";
import { Typography, Button } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import generatePDF from "react-to-pdf";
import useAPI from "../../hooks/useApi";
import { endpoint } from "../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const PrivacyPolicyContents = () => {
  const [language, setLanguage] = React.useState("English");

  const handleChange = (event) => {
    setLanguage(event.target.value);
  };

  const printableContentRef = useRef(null);

  const printContent = () => {
    const printableContent = printableContentRef.current;
    if (!printableContent) {
      console.error("Printable content not found.");
      return;
    }

    const printWindow = window.open("", "", "height=1000,width=1000");
    printWindow.document.write(printableContent.innerHTML);
    printWindow.print();
  };

  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.privacypolicy);
    return res.data;
  };

  const { data: privacypolicies, isLoading } = useQuery({
    queryKey: ["privacypolicies"],
    queryFn: getData,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Box
      sx={{
        marginBottom: "30px",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: {
            xs: "space-evenly",
            sm: "space-between",
            md: "space-between",
            lg: "space-between",
            xl: "space-between",
          },
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            border: "1px solid var(--btn-color)",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: "5px",
            height: {
              xs: "4.5vh",
              sm: "6vh",
              md: "6vh",
              lg: "7vh",
              xl: "7vh",
            },
          }}
        >
          <Typography
            sx={{
              display: {
                xs: "none",
                sm: "flex",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              padding: "10px",
              fontSize: {
                xs: "12px",
                sm: "14px",
                md: "14px",
                lg: "16px",
                xl: "16px",
              },
              fontFamily: "var(--primary-font)",
            }}
          >
            Language:
          </Typography>
          <FormControl fullWidth>
            <select
              style={{
                border: "none", // Set border to none (0)
                outline: "none", // Remove outline on focus (click)
                background: "transparent", // Make the background transparent to remove the native styling
                // padding: "8px 12px", // Add padding to give some spacing between text and border
                fontSize: "16px", // Set font size as desired
                fontFamily: "var(--primary-font)", // Set font family as desired
                color: "var(--theme-color)",
              }}
            >
              <option value="englidh">English</option>
            </select>
          </FormControl>
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Button
            onClick={() =>
              generatePDF(printableContentRef, {
                filename: "Jinlap-privacypolicy.pdf",
              })
            }
            variant="outlined"
            sx={{
              borderColor: "var(--btn-color)",
              color: "var(--btn-color)",
              fontFamily: "var(--primary-font)",
              fontSize: {
                xs: "12px",
                sm: "14px",
                md: "14px",
                lg: "16px",
                xl: "16px",
              },
              padding: {
                xs: "5px",
                sm: "8px",
                md: "8px",
                lg: "10px",
                xl: "10px",
              },
              borderRadius: "5px",
            }}
          >
            <DownloadIcon /> Download
          </Button>
          <Button
            onClick={printContent}
            variant="outlined"
            sx={{
              borderRadius: "5px",
              backgroundColor: "var(--theme-color)",
              color: "var(--white)",
              borderColor: "var(--theme-color)",
              padding: {
                xs: "0",
                sm: "0",
                md: "0",
                lg: "10px",
                xl: "10px",
              },
              marginLeft: "20px",
            }}
          >
            <PrintIcon />
          </Button>
        </Box>
      </Box>

      {/* Button to trigger the print */}
      <Box
        ref={printableContentRef}
        id="printable-content"
        sx={{
          marginTop: "50px",
        }}
      >
        <Box>
          <Typography
            variant="body1"
            sx={{
              fontFamily: "var(--primary-font)",
              textAlign: "left",
              fontSize: {
                xs: "13px",
                sm: "14px",
                md: "15px",
                lg: "16px",
                xl: "16px",
              },
              marginTop: "10px",
              color: "var(--light-black)",
            }}
            dangerouslySetInnerHTML={{
              __html: privacypolicies?.description,
            }}
          ></Typography>
        </Box>
        {privacypolicies?.content.map((item, index) => {
          return (
            <Box
              sx={{
                marginTop: "20px",
                display: "flex",
                flexDirection: "column",
              }}
              key={index}
            >
              <Typography
                sx={{
                  fontFamily: "var(--primary-font)",
                  fontSize: {
                    xs: "16px",
                    sm: "18px",
                    md: "18px",
                    lg: "20px",
                    xl: "20px",
                  },
                  textAlign: "left",
                  color: "var(--theme-color)",
                }}
              >
                {item.content_title}
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "var(--primary-font)",
                  textAlign: "left",
                  fontSize: {
                    xs: "13px",
                    sm: "14px",
                    md: "15px",
                    lg: "16px",
                    xl: "16px",
                  },
                  marginTop: "10px",
                  color: "var(--light-black)",
                }}
                dangerouslySetInnerHTML={{
                  __html: item.content,
                }}
              ></Typography>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
