import React, { useEffect, useState } from "react";
import { Box } from "@mui/system";
import { Typography, Grid, Button } from "@mui/material";
import { useParams } from "react-router-dom";
import { TextField } from "@mui/material";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { Formik, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import dayjs from "dayjs";
import { endpoint } from "../../../constant/endpoint";
import useAPI from "../../../hooks/useApi";
import { useQuery } from "@tanstack/react-query";
import { useCookies } from "react-cookie";

const errorStyle = {
  color: "red",
  fontSize: "12px",
  marginleft: "3rem",
};

const hor_line = {
  width: "100%",
  height: "0.5px",
  backgroundColor: "var(--title-backgrd-color)",
  marginTop: "1px",
};

const sectOptions = [
  {
    id: 1,
    title: "Nyingma",
  },
  {
    id: 2,
    title: "Kagyu",
  },
  {
    id: 3,
    title: "Sakya",
  },
  {
    id: 4,
    title: "Gelug",
  },
];

export const GoodDharmaContentDetailsSide = () => {
  const tomorrow = dayjs().add(1, "day");
  const { id } = useParams();

  const [token, setToken] = useState(""); // Initialize the token state
  const [cookies] = useCookies(["userId", "token"]);

  useEffect(() => {
    // Check if the token is available in cookies
    if (cookies.token) {
      setToken(cookies.token);
      // You can also perform other actions that depend on the token here
    }
  }, [cookies.token]);

  const { getById } = useAPI();

  const getImageData = async () => {
    const res = await getById(endpoint.all_good_dharma, id);
    return res.data;
  };

  const { data: serviceDetails } = useQuery({
    queryKey: ["Gooddharmacontent", id],
    queryFn: getImageData,
  });

  useEffect(() => {
    if (serviceDetails?.sizes && serviceDetails?.sizes.length > 0) {
      setSelectedService(serviceDetails?.sizes[0]?.size);
    }
  }, [serviceDetails]);

  const [selectedService, setSelectedService] = useState(null);

  const [selectedOption, setSelectedOption] = useState(sectOptions[0].title);
  const [date, setDate] = React.useState();

  const handleservice = (service) => {
    setSelectedService(service);
  };

  const handleSubmit = (values, { resetForm }) => {
    resetForm();
  };

  return (
    <Box>
      {serviceDetails && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
            textAlign: "left",
          }}
        >
          {/* //start of description */}
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "-startflex-start",
              alignItems: "flex-start",
              textAlign: "left",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontWeight: "bold",
                marginBottom: "10px",
                fontSize: {
                  xs: "20px",
                  sm: "20px",
                  md: "22px",
                  lg: "25px",
                  xl: "25px",
                },
                fontFamily: "var(--primary-font)",
                color: "var(--theme-color)",
              }}
            >
              {serviceDetails?.name}
            </Typography>

            {/* Add the horizontal line below the title */}

            <div style={hor_line} />
            <div style={hor_line} />
            <div style={hor_line} />

            <Box
              sx={{
                marginTop: "10px",
              }}
            >
              <Typography
                variant="p"
                sx={{
                  color: "var(--light-black)",
                  fontSize: {
                    xs: "13px",
                    sm: "13px",
                    md: "15px",
                    lg: "17px",
                    xl: "17px",
                  },
                  fontFamily: "var(--primary-font)",
                }}
                dangerouslySetInnerHTML={{
                  __html: serviceDetails?.description,
                }}
              ></Typography>
            </Box>
          </Box>

          {/* //end of description */}

          {/* //start of calender */}
          <Formik
            initialValues={{
              date: "",
            }}
            validationSchema={Yup.object().shape({
              date: Yup.date()
                .typeError("The value must be a date (YYYY-MM-DD)")
                .required("Date is required"),
            })}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <Box
                  sx={{
                    marginTop: "40px",
                    display: "flex",
                    flexDirection: "column",
                  }}
                >
                  <label
                    htmlFor="date"
                    style={{
                      fontSize: {
                        xs: "11px",
                        sm: "11px",
                        md: "13px",
                        lg: "15px",
                        xl: "15px",
                      },
                      fontFamily: "var(--primary-font)",
                      marginBottom: "20px",
                    }}
                  >
                    Choose a preferred date:
                  </label>

                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker
                      label="Select Date"
                      id="date"
                      name="date"
                      type="date"
                      renderInput={(params) => <TextField {...params} />}
                      className={
                        errors.date && touched.date ? "input-error" : null
                      }
                      format="YYYY-MM-DD"
                      minDate={tomorrow}
                      // value={date}
                      // onChange={(newValue) => setDate(newValue)}
                      value={date}
                      onChange={(newValue) => {
                        setFieldValue("date", newValue); // Use Formik's setFieldValue function
                        setDate(newValue); // Update date state
                      }}
                      size="small"
                      sx={{
                        width: "70%",
                        backgroundColor: "#fff",
                        border: "0",
                        " & .MuiOutlinedInput-root": {
                          "  &.Mui-focused fieldset": {
                            borderColor: "var(--theme-color)",
                          },
                        },
                        "& .MuiOutlinedInput-root": {
                          border: "1px solid var(--btn-color)",
                          borderRadius: "5px",
                          // padding: "0",
                        },
                        "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            border: "1px solid #eee",
                          },
                        //border colour when focused
                        "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                          {
                            border: "1px solid var(--theme-color)",
                          },
                        //floating label color when focused
                        "& .MuiInputLabel-outlined.Mui-focused": {
                          color: "var(--theme-color)",
                        },
                      }}
                    />
                  </LocalizationProvider>
                  <ErrorMessage
                    name="date"
                    component="span"
                    className="error"
                    style={errorStyle}
                  />
                </Box>

                {/* //end of calender */}

                {/* //start of options */}
                <Box
                  sx={{
                    marginTop: "40px",
                  }}
                >
                  <Box
                    sx={{
                      marginBottom: "20px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "var(--primary-font)",
                        fontSize: {
                          xs: "11px",
                          sm: "11px",
                          md: "13px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                    >
                      Choose an option with a title sect:
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {sectOptions.map((item, key) => {
                      return (
                        <Box key={key}>
                          <Button
                            variant="outlined"
                            key={item.option}
                            className={`${
                              selectedOption === item.title
                                ? "active-option"
                                : ""
                            }`}
                            sx={{
                              border: "1px solid var(--btn-color)",
                              color: "var(--light-black)",
                              borderRadius: "20px",
                              textTransform: "capitalize",
                              marginRight: "20px",
                              marginBottom: {
                                xs: "15px",
                                sm: "0px",
                                md: "0px",
                                lg: "0px",
                                xl: "0px",
                              },
                              fontSize: {
                                xs: "11px",
                                sm: "12px",
                                md: "12px",
                                lg: "14px",
                                xl: "14px",
                              },
                              "&:hover,  &.active-option": {
                                border: "1px solid var(--theme-color)",
                                color: "var(--btn-color)",
                                backgroundColor: "var(--white)",
                              },
                            }}
                            onClick={() => {
                              setSelectedOption(item.title);
                            }}
                          >
                            {item.title}
                          </Button>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>

                {/* //end of options */}

                {/* //start of services */}
                <Box
                  sx={{
                    marginTop: "40px",
                  }}
                >
                  <Box
                    sx={{
                      marginBottom: "20px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "var(--primary-font)",
                        fontSize: {
                          xs: "11px",
                          sm: "11px",
                          md: "13px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                    >
                      Select an service you want to order:
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    {serviceDetails?.sizes?.map((item, key) => {
                      return (
                        <Box key={key}>
                          <Button
                            variant="outlined"
                            key={item.size}
                            className={`${
                              selectedService === item.size
                                ? "active-service"
                                : ""
                            }`}
                            sx={{
                              border: "1px solid var(--btn-color)",
                              color: "var(--light-black)",
                              textTransform: "capitalize",
                              borderRadius: "20px",
                              marginRight: "20px",
                              marginBottom: {
                                xs: "15px",
                                sm: "0px",
                                md: "0px",
                                lg: "0px",
                                xl: "0px",
                              },
                              fontSize: {
                                xs: "11px",
                                sm: "12px",
                                md: "12px",
                                lg: "14px",
                                xl: "14px",
                              },
                              "&:hover, &.active-service": {
                                border: "1px solid var(--theme-color)",
                                color: "var(--btn-color)",
                                backgroundColor: "var(--white)",
                              },
                            }}
                            onClick={() => handleservice(item.size)}
                          >
                            {item.size}
                          </Button>
                        </Box>
                      );
                    })}
                  </Box>
                </Box>
                <Box>
                  {selectedService && (
                    <Box sx={{ marginTop: "40px" }}>
                      <Grid
                        container
                        xs={12}
                        sx={{
                          marginBottom: "20px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "flex-start",
                        }}
                      >
                        <Grid item xs={3}>
                          <Typography
                            variant="body1"
                            sx={{
                              fontFamily: "var(--primary-font)",
                              fontSize: {
                                xs: "11px",
                                sm: "11px",
                                md: "13px",
                                lg: "15px",
                                xl: "15px",
                              },
                              fontWeight: "bold",
                              color: "var(--theme-color)",
                            }}
                          >
                            {selectedService} Service
                          </Typography>
                        </Grid>
                        <Grid item xs={9}>
                          <Box>
                            <div style={hor_line} />
                            <div style={hor_line} />
                            <div style={hor_line} />
                          </Box>
                        </Grid>
                      </Grid>

                      <Typography
                        variant="body2"
                        sx={{
                          color: "var(--light-black)",
                          fontSize: {
                            xs: "11px",
                            sm: "11px",
                            md: "13px",
                            lg: "15px",
                            xl: "15px",
                          },
                          fontFamily: "var(--primary-font)",
                        }}
                      >
                        {
                          serviceDetails?.sizes.filter((item) => {
                            return item.size === selectedService;
                          })[0].size_description
                        }
                      </Typography>
                      <Box
                        sx={{
                          marginTop: "30px",
                        }}
                      >
                        <Typography
                          variant="h4"
                          sx={{
                            color: "var(--theme-color)",
                            fontSize: {
                              xs: "15px",
                              sm: "15px",
                              md: "17px",
                              lg: "17px",
                              xl: "17px",
                            },
                            fontFamily: "var(--primary-font)",
                            fontWeight: "bold",
                          }}
                        >
                          Rs {""}
                          {
                            serviceDetails?.sizes.filter((item) => {
                              return item.size === selectedService;
                            })[0].price
                          }
                        </Typography>
                      </Box>
                    </Box>
                  )}
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    marginTop: "10px",
                  }}
                >
                  <Button
                    variant="outlined"
                    type="submit"
                    sx={{
                      backgroundColor: "var(--theme-color)",
                      color: "var(--white)",
                      borderRadius: "5px",
                      marginTop: "20px",
                      border: "1px solid var(--theme-color)",
                      fontSize: {
                        xs: "11px",
                        sm: "13px",
                        md: "13px",
                        lg: "15px",
                        xl: "15px",
                      },
                      marginRight: "20px",
                      padding: "10px",
                      textTransform: "inherit",
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: "var(--theme-color)",
                        border: "1px solid var(--theme-color)",
                      },
                    }}
                    onClick={async () => {
                      const selectedServiceDetails = serviceDetails?.sizes.find(
                        (size) => size.size === selectedService
                      );
                      values.date = date.format("YYYY-MM-DD");

                      const selectedData = {
                        service_id: serviceDetails?.id,
                        // title: serviceDetails.name,
                        // imgUrl: serviceDetails.images[0].image,
                        date: values.date,
                        sect: selectedOption,
                        size: selectedService,
                        price: selectedServiceDetails?.price,
                      };

                      try {
                        const response = await axios.post(
                          process.env.REACT_APP_JINLAP_BASE_URL +
                            `/services/add-to-cart`,
                          selectedData,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                              Accept: "application/json",
                              "Content-Type": "application/json",
                            },
                          }
                        );

                        {
                          response.data.status === true
                            ? toast.success(
                                `${serviceDetails.name} added to cart successfully!`
                              )
                            : toast.warning(response.data.message);
                        }
                      } catch (error) {
                        toast.error(error.response.data.message);
                      }

                      handleSubmit(values);
                    }}
                  >
                    <ShoppingBagOutlinedIcon
                      fontSize="medium"
                      sx={{
                        marginRight: "5px",
                      }}
                    />
                    Add to Cart
                  </Button>
                  <Button
                    variant="outlined"
                    sx={{
                      backgroundColor: "var(--white)",
                      color: "var(--btn-color)",
                      borderRadius: "5px",
                      marginTop: "20px",
                      textTransform: "inherit",
                      fontSize: {
                        xs: "11px",
                        sm: "13px",
                        md: "13px",
                        lg: "15px",
                        xl: "15px",
                      },
                      padding: "10px",
                      border: "1px solid var(--btn-color)",
                      "&:hover": {
                        backgroundColor: "transparent",
                        color: "var(--theme-color)",
                        border: "1px solid var(--theme-color)",
                      },
                    }}
                    onClick={async () => {
                      const selectedServiceDetails = serviceDetails?.sizes.find(
                        (size) => size.size === selectedService
                      );
                      values.date = date.format("YYYY-MM-DD");

                      const selectedData = {
                        service_id: serviceDetails?.id,
                        // title: serviceDetails.name,
                        // imgUrl: serviceDetails.images[0].image,
                        date: values.date,
                        sect: selectedOption,
                        size: selectedService,
                        price: selectedServiceDetails?.price,
                      };

                      try {
                        const response = await axios.post(
                          process.env.REACT_APP_JINLAP_BASE_URL +
                            `/services/add-to-wishlist`,
                          selectedData,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                              Accept: "application/json",
                              "Content-Type": "application/json",
                            },
                          }
                        );
                        {
                          response.data.status === true
                            ? toast.success(
                                `${serviceDetails.name} added to wishlist successfully!`
                              )
                            : toast.warning(response.data.message);
                        }
                      } catch (error) {
                        toast.error(error.response.data.message);
                      }
                      handleSubmit(values);
                    }}
                  >
                    <FavoriteBorderOutlinedIcon
                      fontSize="medium"
                      sx={{
                        marginRight: "5px",
                      }}
                    />
                    Add to Wishlist
                  </Button>
                </Box>
              </Form>
            )}
          </Formik>
        </Box>
      )}
    </Box>
  );
};
