export const privacypolicyTopContent = [
  {
    id: 1,
    imgUrl: "./assets/DharmaGoodpage/topsec_img.png",
    title: "Privacy Policy",
    description: "Last updated: June 1st,2021",
  },
];

export const privacypolicy = [
  {
    id: 1,
    description:
      "Lorem ipsum dolor sit amet consectetur. Consectetur proin pharetra elementum aliquet ac tellus suspendisse sit risus. Cursus elementum molestie aliquam enim aliquet porta erat sed nunc. Facilisis sagittis dictum blandit morbi placerat. Quam mi cras suspendisse elementum eget ut imperdiet. Dictum velit auctor vulputate purus sodales suspendisse id aliquam eu. Ipsum ac sit dignissim a leo nec sit sit. Tellus molestie nulla condimentum gravida interdum nulla sit duis. Feugiat pharetra cursus sit sit quisque et sed. Semper tortor interdum nec parturient.Lorem ipsum dolor sit amet consectetur. Consectetur proin pharetra elementum aliquet ac tellus suspendisse sit risus. Cursus elementum molestie aliquam enim aliquet porta erat sed nunc. Facilisis sagittis dictum blandit morbi placerat. Quam mi cras suspendisse elementum eget ut imperdiet. Dictum velit auctor vulputate purus sodales suspendisse id aliquam eu. Ipsum ac sit dignissim a leo nec sit sit. Tellus molestie nulla condimentum gravida interdum nulla sit duis. Feugiat pharetra cursus sit sit quisque et sed. Semper tortor interdum nec parturient.",
  },
  {
    id: 2,
    title: "Personal Data collected by Jinlap",
    description:
      "The data collected from each customer is used solely for the purpose of executing the features and services defined within the Jinlap Terms of Service. Personal Data collected is never used for marketing or sales purposes of any kind.",
    subdetails: [
      {
        id: "1",
        title: "Customer and user choose in data collected",
        desc: "Lorem ipsum dolor sit amet consectetur. Consectetur proin pharetra elementum aliquet ac tellus suspendisse sit risus. Cursus elementum molestie aliquam enim aliquet porta erat sed nunc. Facilisis sagittis dictum blandit morbi placerat. Quam mi cras suspendisse elementum eget ut imperdiet. Dictum velit auctor vulputate purus sodales suspendisse id aliquam eu. ",
      },
    ],
  },
  {
    id: 3,
    title: "Why the personal data must be collected",
    description:
      "Lorem ipsum dolor sit amet consectetur. Consectetur proin pharetra elementum aliquet ac tellus suspendisse sit risus. Cursus elementum molestie aliquam enim aliquet porta erat sed nunc. Facilisis sagittis dictum blandit morbi placerat. Quam mi cras suspendisse elementum eget ut imperdiet. Dictum velit auctor vulputate purus sodales suspendisse id aliquam eu. ",
  },
  {
    id: 4,
    title: "Our Data Storage, Security and Access Approach",

    description:
      "Lorem ipsum dolor sit amet consectetur. Consectetur proin pharetra elementum aliquet ac tellus suspendisse sit risus. Cursus elementum molestie aliquam enim aliquet porta erat sed nunc. Facilisis sagittis dictum blandit morbi placerat. Quam mi cras suspendisse elementum eget ut imperdiet. Dictum velit auctor vulputate purus sodales suspendisse id aliquam eu. ",
  },
  {
    id: 5,
    title: "How our Software Use Cookies",
    description:
      "Lorem ipsum dolor sit amet consectetur. Consectetur proin pharetra elementum aliquet ac tellus suspendisse sit risus. Cursus elementum molestie aliquam enim aliquet porta erat sed nunc. Facilisis sagittis dictum blandit morbi placerat. Quam mi cras suspendisse elementum eget ut imperdiet. Dictum velit auctor vulputate purus sodales suspendisse id aliquam eu. ",
  },
  {
    id: 6,
    title: "Communication Methods Used for Policy Updates",
    description:
      "Lorem ipsum dolor sit amet consectetur. Consectetur proin pharetra elementum aliquet ac tellus suspendisse sit risus. Cursus elementum molestie aliquam enim aliquet porta erat sed nunc. Facilisis sagittis dictum blandit morbi placerat. Quam mi cras suspendisse elementum eget ut imperdiet. Dictum velit auctor vulputate purus sodales suspendisse id aliquam eu. ",
  },
];
