import React from "react";
import { Grid, Typography, Container } from "@mui/material";
import { Box } from "@mui/system";
import "./StrongerSection.css";

export const BottomStrongerSection = ({ images }) => {
  return (
    <Box className="bottomstrongerSection">
      <Container>
        <Grid
          container
          xs={12}
          sx={{
            display: "flex",
            flexDirection: {
              xs: "row",
              sm: "row",
              md: "row",
              lg: "row",
              xl: "row",
            },
            justifyContent: {
              xs: "space-evenly",
              sm: "space-evenly",
              md: "space-between",
              lg: "space-between",
              xl: "space-between",
            },
            alignItems: "center",
            marginTop: "50px",
          }}
        >
          {images?.slice(0, 5).map((item, index) => (
            <Grid
              item
              key={item.title}
              xs={5}
              sm={2}
              md={2}
              lg={2}
              xl={2}
              sx={{ marginTop: index % 2 === 1 ? "60px" : 0 }}
            >
              <Box
                sx={{
                  width: "100%",
                  height: {
                    xs: "25vh",
                    sm: "20vh",
                    md: "23vh",
                    lg: "35vh",
                    xl: "35vh",
                  },
                  marginBottom: "20px",
                  "&:hover": {
                    transform: "scale(1.1)",
                    transition: "all 0.3s ease-in-out",
                    backgroundColor: "var(--theme-color)",
                    Opacity: "0.5",
                  },
                }}
              >
                <img
                  src={item.images}
                  alt="..."
                  style={{ height: "100%", width: "100%" }}
                />
              </Box>

              <Typography
                variant="body1"
                sx={{
                  fontSize: {
                    xs: "15px",
                    sm: "15px",
                    md: "15px",
                    lg: "15px",
                    xl: "15px",
                  },
                  textAlign: "left",
                  fontFamily: "var(--primary-font)",
                  marginBottom: "15px",
                }}
              >
                {item.images_description}
              </Typography>
            </Grid>
          ))}
        </Grid>
      </Container>
    </Box>
  );
};
