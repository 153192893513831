export const invoiceData = [
  {
    id: 1,
    CompanyDetails: [
      {
        Companyname: "Jinlap",
        Buildingname: "abc building",
        City: " Kathmandu",
        Street: "Ranibari",
        State: "state 5",
        Country: "Nepal",
        Zipcode: "44600",
        contactNumber: "9840000000",
        email: "jinlap@gmail.com",
        website: "www.jinlap.com",
        logo: "./assets/logo.png",
      },
    ],
    CustomerDetails: [
      {
        Customername: "Shiv Shrestha",
        street: "Ranibari",
        city: "Kathmandu",
        state: "state 3",
        country: "Nepal",
        zipcode: "44600",
        contactNumber: "9840000000",
      },
    ],
    InvoiceDetails: [
      {
        invoiceNumber: "INV-0001",
        invoiceDate: "2021-05-05",
      },
    ],

    ItemDetails: [
      {
        id: 1,
        description: "Item Name",
        quantity: 1,
        rate: 100,
        amount: 100,
      },
      {
        id: 2,
        description: "Item Name",
        quantity: 1,
        rate: 100,
        amount: 100,
      },
      {
        id: 3,
        description: "Item Name",
        quantity: 1,
        rate: 100,
        amount: 100,
      },
      {
        id: 4,
        description: "Item Name",
        quantity: 1,
        rate: 100,
        amount: 100,
      },
    ],
    BankDetails: [
      {
        bankName: "Nabil Bank",
        accountholder: "Shiv Shrestha",
        accountNumber: "123456789",
        branch: "Kathmandu",
        ifsc: "12445123456789",
      },
    ],
    SubTotal: 400,
    TaxRate: 13,
    Tax: 52,
    Discount: 10,
    Total: 400,
    DueDate: "2021-05-05",
  },
];
