import React from "react";
import "./Footer.css";
import { Container } from "@mui/material";
import { Box } from "@mui/system";
import { Contact } from "./Contact";
import { DharmaGoods } from "./DharmaGoods";
import { GoodDharma } from "./GoodDharma";
import { Company } from "./Company";
import { Grid } from "@mui/material";
import { Typography } from "@mui/material";
import { paymentData } from "../../../Data/Footer/paymentData";
import { endpoint } from "../../../constant/endpoint";
import Skeleton from "@mui/material/Skeleton";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../../hooks/useApi";

export const Footer = () => {
  const { get } = useAPI();

  const getData = async () => {
    const res = await get(endpoint.sitesettings);
    return res.data;
  };

  const getFooterData = async () => {
    const response = await get(endpoint.footercontents);
    return response.data;
  };

  const { isDataLoading, data: siteSettings } = useQuery({
    queryKey: ["sitesettingsData"],
    queryFn: getData,
  });

  const { data: footerData } = useQuery({
    queryKey: ["footerData"],
    queryFn: getFooterData,
  });

  if (isDataLoading)
    return (
      <>
        <Skeleton variant="rectangular" width="150px" height="200px" />
      </>
    );

  return (
    <Box className="footer">
      <Container>
        <Box
          className="row"
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "flex",
              lg: "flex",
              xl: "flex",
            },
          }}
        >
          {footerData?.map((item, index) => {
            return (
              <Box className="col-md-3" key={index}>
                <Box
                  className="card"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "20px",
                    border: "0",
                    justifyContent: "center",
                    alignItems: "center", // Add this to center items vertically
                  }}
                >
                  <Box
                    sx={{
                      width: "3vw",
                      height: "6vh",
                    }}
                  >
                    <img
                      src={item.image}
                      alt=""
                      style={{
                        height: "100%",
                        width: "100%",
                        display: "block",
                      }} // Add display: "block" for proper alignment
                    />
                  </Box>

                  <Box
                    sx={{
                      marginLeft: "10px",
                      textAlign: "justify",
                      marginTop: "12px",
                      fontSize: {
                        xs: "9px",
                        sm: "9px",
                        md: "12px",
                        lg: "15px",
                        xl: "15px",
                      },
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start", // Add this to center text horizontally
                    }}
                  >
                    <Typography
                      variant="p"
                      sx={{
                        fontWeight: "bold",
                        color: "var(--light-black)",
                        textAlign: "center", // Add this to center text horizontally
                      }}
                    >
                      {item.title_one}
                    </Typography>
                    <p style={{ textAlign: "justify" }}>{item.title_two}</p>{" "}
                    {/* Center the description text */}
                  </Box>
                </Box>
              </Box>
            );
          })}
        </Box>
        <Box sx={{ marginTop: "20px", marginBottom: "40px" }}>
          <Box className="horizontal-line"></Box>
          <Box className="horizontal-line"></Box>
          <Box className="horizontal-line"></Box>
        </Box>
        <Grid container sx={{ justifyContent: "space-between" }} spacing={3}>
          <Grid item xs={12} sm={12} md={12} lg={4} xl={4}>
            <Contact />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <Company />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <DharmaGoods />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={2} xl={2}>
            <GoodDharma />
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            backgroundColor: "rgb(128,128,128, 0.1)",
            padding: "20px",
            width: { xs: "100%", sm: "100%", md: "98%", lg: "65%", xl: "65%" },
            position: "relative",

            // margin: "auto",
            // position: "relative",
            // marginBottom: {
            //   xs: "30px",
            //   sm: "30px",
            //   md: "50px",
            //   lg: "0px",
            //   xl: "0px",
            // },
            // bottom: {
            //   xs: "0px",
            //   sm: "0px",
            //   md: "0px",
            //   lg: "55px",
            //   xl: "55px",
            // },
            // marginTop: {
            //   xs: "0px",
            //   sm: "0px",
            //   md: "0px",
            //   lg: "-70px",
            //   xl: "-70px",
            // },
            left: {
              xs: "0px",
              sm: "0px",
              md: "0px",
              lg: "400px",
              xl: "400px",
            },
            margin: "30px 0px 60px 0px",
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={2}
            lg={3}
            xl={3}
            sx={{
              position: "relative",
            }}
          >
            <Typography
              variant="span"
              sx={{
                color: "--title-backgrd-color",
                fontWeight: "bold",
                marginTop: "10px",
                display: "flex",
                justifyContent: "flex-start",
                marginBottom: "10px",
              }}
            >
              Payment Partners
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={9}
            lg={10}
            xl={9}
            sx={{
              display: "flex",
              alignItems: "flex-start",
              flexWrap: "wrap",
            }}
          >
            {paymentData.map((item, index) => {
              return (
                <img
                  src={item.imgUrl}
                  alt=""
                  style={{ margin: "5px" }}
                  className="paymentlogo"
                  key={index}
                />
              );
            })}
          </Grid>
        </Grid>
        <Grid
          container
          sx={{
            justifyContent: "space-between",
            display: "flex",
            flexDirection: "row",
          }}
        >
          <Grid item xs={9}>
            <Box className="horizontal-line"></Box>
            <Box className="horizontal-line"></Box>
            <Box className="horizontal-line"></Box>
          </Grid>
          <Grid item xs={3}>
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "flex",
                  lg: "flex",
                  xl: "flex",
                },
                position: "relative",
                bottom: "8px",
              }}
            >
              <img
                src={siteSettings?.logo}
                alt=""
                style={{ position: "relative", bottom: "25px" }}
              />
            </Box>
            <Box
              sx={{
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "none",
                  lg: "none",
                  xl: "none",
                },
              }}
            >
              <img
                src={siteSettings?.secondary_logo}
                alt=""
                style={{
                  position: "relative",
                  bottom: "25px",
                  marginLeft: "10%",
                }}
              />
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          // sx={{
          //   display: "flex",
          //   flexDirection: "row",
          //   justifyContent: "space-evenly",
          //   alignItems: "center",
          // }}
        >
          <Grid item xs={5}>
            <Typography
              variant="span"
              sx={{
                marginTop: "20px",
                position: "relative",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                // right: "300px",
                bottom: "50px",
                fontSize: {
                  xs: "5px",
                  sm: "8px",
                  md: "15px",
                  lg: "13px",
                  xl: "13px",
                },
              }}
            >
              Copyright2023Jinlap.All rights reserved.
            </Typography>
          </Grid>

          <Grid item xs={5}>
            <Typography
              variant="span"
              sx={{
                marginTop: "20px",
                position: "relative",
                // right: "80px",
                bottom: "50px",
                display: "flex",
                // justifyContent: "flex-end",
                fontSize: {
                  xs: "5px",
                  sm: "8px",
                  md: "15px",
                  lg: "13px",
                  xl: "13px",
                },
              }}
            >
              Designed and Developed by {""}
              <Box
                sx={{
                  marginLeft: "5px",
                  width: {
                    xs: "1vh",
                    sm: "2vh",
                    md: "2vh",
                    lg: "2vh",
                    xl: "2vh",
                  },
                  height: {
                    xs: "1vh",
                    sm: "2vh",
                    md: "2vh",
                    lg: "2vh",
                    xl: "2vh",
                  },
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/footer/comp_logo.png"}
                  alt=""
                  style={{ width: "100%", height: "100%" }}
                />
              </Box>
            </Typography>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
