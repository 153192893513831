import React from "react";
import "./Subscribe.css";
import { TextField } from "@mui/material";
import { Button, Typography } from "@mui/material";
import { Box } from "@mui/system";
import Modal from "@mui/material/Modal";
import { ThankyouforSubscribe } from "./ThankyouforSubscribe";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Subscribe = () => {
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const [email, setEmail] = React.useState("");
  const [isProcessing, setIsProcessing] = React.useState(false);

  const handleEmail = (e) => {
    setEmail(e.target.value);
  };

  const handleSubscribe = async () => {
    isProcessing(true);
    const data = {
      email: email,
    };
    try {
      await axios
        .post(process.env.REACT_APP_JINLAP_BASE_URL + `/newsletters`, data, {
          headers: {
            Accept: "application/json",
            "Content-Type": "application/json",
          },
        })
        .then((res) => {
          toast.success("Successfully subscribed to newsletter");
          handleOpen();
        });
    } catch (error) {
      toast.error(error.message);
    }
    isProcessing(false);
    setEmail("");
  };
  return (
    <Box className="subscribe">
      <Box
        className="buddha-img"
        sx={{
          display: {
            xs: "none",
            sm: "none",
            md: "flex",
            lg: "flex",
            xl: "flex",
          },
          width: {
            xs: "0",
            sm: "0",
            md: "15vw",
            lg: "18vw",
            xl: "18vw",
          },
          height: {
            xs: "0",
            sm: "0",
            md: "25vh",
            lg: "30vh",
            xl: "32vh",
          },
          padding: {
            xs: "10px",
            sm: "10px",
            md: "15px",
            lg: "20px",
            xl: "20px",
          },
        }}
      >
        <img
          src={process.env.PUBLIC_URL + "/assets/subscribe/buddhaimg.png"}
          alt="..."
          style={{ height: "100%", width: "100%" }}
        />
      </Box>
      <Box className="left-content">
        <Typography
          variant="span"
          sx={{
            color: "var(--theme-color)",
            fontSize: {
              xs: "15px",
              sm: "15px",
              md: "20px",
              lg: "20px",
              xl: "20px",
            },
          }}
        >
          Subscribe to our newsletter
        </Typography>
        <Typography
          variant="span"
          sx={{
            fontSize: {
              xs: "11px",
              sm: "11px",
              md: "15px",
              lg: "18px",
              xl: "18px",
            },
            marginTop: "10px",
          }}
        >
          Get all the latest information on Events, Sales and Offers.
        </Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: {
              xs: "column",
              sm: "column",
              md: "row",
              lg: "row",
              xl: "row",
            },
            justifyContent: "center",
            alignItems: "center",
            fontSize: {
              xs: "13px",
              sm: "13px",
              md: "20px",
              lg: "20px",
              xl: "20px",
            },
            marginTop: "10px",
            // width: "100%",
          }}
        >
          <TextField
            id="search"
            type="email"
            label="Email Address"
            size="small"
            value={email}
            onChange={handleEmail}
            sx={{
              width: {
                xs: "100%",
                sm: "320px",
                md: "350px",
                lg: "400px",
                xl: "400px",
              },

              backgroundColor: "#fff",
              border: "0",
              " & .MuiOutlinedInput-root": {
                "  &.Mui-focused fieldset": {
                  borderColor: "var(--theme-color)",
                },
              },
              "& .MuiOutlinedInput-root": {
                border: "0px solid var(--white)",
                borderRadius: "0",
                padding: "0",
              },
              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline": {
                border: "1px solid #eee",
              },
              //border colour when focused
              "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                {
                  border: "1px solid var(--theme-color)",
                },
              //floating label color when focused
              "& .MuiInputLabel-outlined.Mui-focused": {
                color: "var(--theme-color)",
                backgroundColor: "transparent",
              },
            }}
            inputProps={{
              style: {
                fontSize: {
                  xs: "11px",
                  sm: "11px",
                  md: "18px",
                  lg: "20px",
                  xl: "20px",
                },
              },
            }}
          />
          <Button
            variant="contained"
            sx={{
              backgroundColor: "var(--theme-color)",
              height: "auto",
              fontSize: {
                xs: "11px",
                sm: "11px",
                md: "12px",
                lg: "12px",
                xl: "12px",
              },
              padding: "10px 15px 10px 15px",
              marginTop: { xs: "10px", sm: "20px", md: "0", lg: "0", xl: "0" },
              alignSelf: { xs: "flex-start", sm: "flex-start" },
              marginLeft: {
                xs: "0",
                sm: "0",
                md: "10px",
                lg: "10px",
                xl: "10px",
              },
              "&:hover": {
                backgroundColor: "var(--theme-color)",
                color: "var(--white)",
                transform: "scale(1.1)",
              },
            }}
            onClick={handleSubscribe}
            disabled={isProcessing}
          >
            {isProcessing ? "Processing..." : "Subscribe"}
            <img
              src={process.env.PUBLIC_URL + "/assets/icons/left.png"}
              alt="..."
              style={{ width: "15px", height: "20px", marginLeft: "10px" }}
            />
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                backgroundColor: "var(--white)",
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                padding: "20px",
                width: {
                  xs: "90%",
                  sm: "60%",
                  md: "60%",
                  lg: "40%",
                  xl: "40%",
                },
              }}
            >
              <ThankyouforSubscribe handleCloseSubscribe={handleClose} />
            </Box>
          </Modal>
        </Box>
      </Box>
    </Box>
  );
};
