import React from "react";
import { Box } from "@mui/system";
import { Typography, Container } from "@mui/material";
import { AccountTopContent } from "../../components/AccountComponents/AccountTopContent";

export const AccountPage = () => {
  return (
    <Box>
      <AccountTopContent />
    </Box>
  );
};
