import React from "react";
import { Box } from "@mui/system";
import { Typography, Grid } from "@mui/material";
import { Button } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

const sectOptions = [
  {
    id: 1,
    title: "Nyingma",
  },
  {
    id: 2,
    title: "Kagyu",
  },
  {
    id: 3,
    title: "Sakya",
  },
  {
    id: 4,
    title: "Gelug",
  },
];

export const WishlistRelatedGoodDharma = () => {
  const navigate = useNavigate();

  const [token, setToken] = useState(""); // Initialize the token state
  const [cookies] = useCookies(["userId", "token"]);

  useEffect(() => {
    // Check if the token is available in cookies
    if (cookies.token) {
      setToken(cookies.token);
      // You can also perform other actions that depend on the token here
    }
  }, [cookies.token]);

  const { get } = useAPI();

  const getData = async () => {
    const response = await get(endpoint.all_good_dharma);
    return response.data;
  };

  const { data: allGoodDharma } = useQuery({
    queryKey: ["allGoodDharma"],
    queryFn: getData,
  });

  return (
    <Box>
      <Box>
        <Typography
          variant="h4"
          sx={{
            color: "var(--theme-color)",
            fontFamily: "var(--primary-font)",
            fontWeight: "600",
            fontSize: {
              xs: "11px",
              sm: "12px",
              md: "13px",
              lg: "15px",
              xl: "15px",
            },
          }}
        >
          RELATED GOOD DHARMA
        </Typography>
        <Box
          sx={{
            marginTop: "15px",
          }}
        >
          <Typography
            variant="p"
            sx={{
              color: "var(--light-black)",
              fontFamily: "var(--primary-font)",
              fontWeight: "500",
              fontSize: {
                xs: "20px",
                sm: "22px",
                md: "23px",
                lg: "25px",
                xl: "25px",
              },
            }}
          >
            SIMILAR OFFERINGS YOU MAKE LIKE
          </Typography>
        </Box>
      </Box>
      <Box sx={{ marginTop: "30px" }}>
        <Grid container spacing={3}>
          {allGoodDharma?.slice(0, 4).map((item) => (
            <Grid item xs={12} sm={6} md={6} lg={4} xl={4} key={item.id}>
              <Box>
                <img
                  src={item.images[0]?.image}
                  alt={item.title}
                  style={{ height: "100%", width: "100%" }}
                />
              </Box>

              <Box
                sx={{
                  marginTop: "10px",
                  textAlign: "left",
                  display: "flex",
                  flexDirection: "column",
                  justifyContent: "flex-start",
                  alignItems: "flex-start",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    color: "var(--black-color)",
                    fontSize: {
                      xs: "15px",
                      sm: "16px",
                      md: "18px",
                      lg: "20px",
                      xl: "20px",
                    },
                    textAlign: "center",
                    marginBottom: "10px",
                  }}
                >
                  {item.name}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    color: "var(--light-black)",
                    fontSize: {
                      xs: "13px",
                      sm: "14px",
                      md: "15px",
                      lg: "16px",
                      xl: "16px",
                    },
                    overflow: "hidden", // Hide any overflowing text
                    textOverflow: "ellipsis", // Display ellipsis for long texts
                    display: "-webkit-box",
                    WebkitLineClamp: 3, // Adjust the number of lines to fit your requirements
                    WebkitBoxOrient: "vertical",
                  }}
                >
                  {item.description}
                </Typography>
              </Box>
              <Box
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: "var(--theme-color)",
                    border: "1px solid var(--theme-color)",
                    color: "var(--white)",
                    marginBottom: "10px",
                    fontSize: {
                      xs: "10px",
                      sm: "10px",
                      md: "12px",
                      lg: "15px",
                      xl: "15px",
                    },
                    padding: "10px",
                    ":hover": {
                      //zoom the buttom
                      transform: "scale(1.1)",
                      backgroundColor: "var(--theme-color)",
                      border: "1px solid var(--theme-color)",
                    },
                  }}
                  onClick={() => navigate(`/gooddharma/${item.id}`)}
                >
                  TAKE A LOOK
                  <img
                    src={process.env.PUBLIC_URL + "/assets/icons/left.png"}
                    alt="..."
                    style={{
                      width: "15px",
                      height: "20px",
                      marginLeft: "10px",
                    }}
                  />
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "var(--very-lightthemecolor)",
                      padding: "5px",
                      borderRadius: "50%",
                      color: "var(--theme-color)",
                      "&:hover": {
                        //zoom the buttom
                        transform: "scale(1.1)",
                        backgroundColor: "var(--theme-color)",
                        color: "var(--white)",
                      },
                    }}
                    onClick={async () => {
                      const todaysDate = new Date();
                      todaysDate.setDate(todaysDate.getDate() + 1); // Add 1 day to get tomorrow's date
                      const selecteddate = todaysDate
                        .toISOString()
                        .split("T")[0]; // Format as "YYYY-MM-DD"

                      const selectedData = {
                        service_id: item.id,
                        // title: serviceDetails.name,
                        // imgUrl: serviceDetails.images[0].image,
                        date: selecteddate,
                        sect: sectOptions[0].title,
                        size: item.sizes[0].size,
                        price: item.sizes[0].price,
                      };

                      try {
                        const response = await axios.post(
                          process.env.REACT_APP_JINLAP_BASE_URL +
                            `/services/add-to-wishlist`,
                          selectedData,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                              Accept: "application/json",
                              "Content-Type": "application/json",
                            },
                          }
                        );
                        {
                          response.data.status === true
                            ? toast.success(
                                `${item.name} added to wishlist successfully!`
                              )
                            : toast.warning(response.data.message);
                        }
                      } catch (error) {
                        toast.error(error.response.data.message);
                      }
                    }}
                  >
                    <FavoriteBorderIcon />
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "var(--very-lightthemecolor)",
                      padding: "5px",
                      borderRadius: "50%",
                      color: "var(--theme-color)",
                      marginLeft: "10px",
                      "&:hover": {
                        //zoom the buttom
                        transform: "scale(1.1)",
                        backgroundColor: "var(--theme-color)",
                        color: "var(--white)",
                      },
                    }}
                    onClick={async () => {
                      const todaysDate = new Date();
                      todaysDate.setDate(todaysDate.getDate() + 1); // Add 1 day to get tomorrow's date
                      const selecteddate = todaysDate
                        .toISOString()
                        .split("T")[0]; // Format as "YYYY-MM-DD"

                      const selectedData = {
                        service_id: item.id,
                        // title: serviceDetails.name,
                        // imgUrl: serviceDetails.images[0].image,
                        date: selecteddate,
                        sect: sectOptions[0].title,
                        size: item.sizes[0].size,
                        price: item.sizes[0].price,
                      };

                      try {
                        const response = await axios.post(
                          process.env.REACT_APP_JINLAP_BASE_URL +
                            `/services/add-to-cart`,
                          selectedData,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                              Accept: "application/json",
                              "Content-Type": "application/json",
                            },
                          }
                        );
                        {
                          response.data.status === true
                            ? toast.success(
                                `${item.name} added to cart successfully!`
                              )
                            : toast.warning(response.data.message);
                        }
                      } catch (error) {
                        toast.error(error.response.data.message);
                      }
                    }}
                  >
                    <ShoppingBagOutlinedIcon fontSize="medium" />
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};
