import React from "react";
import { Grid } from "@mui/material";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { FaFacebookF, FaTiktok, FaYoutube } from "react-icons/fa";
import { FaXTwitter } from "react-icons/fa6";
import { ImWhatsapp } from "react-icons/im";
import "./Footer.css";
import { endpoint } from "../../../constant/endpoint";
import Skeleton from "@mui/material/Skeleton";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../../hooks/useApi";

export const Contact = () => {
  const iconBoxStyle = {
    paddingTop: "10px",
    paddingBottom: "10px",
  };

  const { get } = useAPI();

  const getData = async () => {
    const res = await get(endpoint.sitesettings);
    return res.data;
  };

  const { isDataLoading, data: SitesettingsData } = useQuery({
    queryKey: ["sitesettingsData"],
    queryFn: getData,
  });

  if (isDataLoading)
    return (
      <>
        <Skeleton variant="rectangular" width="150px" height="200px" />
      </>
    );
  return (
    <Box className="contact-details">
      {SitesettingsData && (
        <Grid container>
          <Grid
            item
            xs={12}
            sx={{
              textAlign: "justify",
              marginRight: "20px",
            }}
          >
            <Typography
              variant="p"
              sx={{
                marginBottom: "20px",
                fontSize: {
                  xs: "11px",
                  sm: "11px",
                  md: "13px",
                  lg: "15px",
                  xl: "15px",
                },
              }}
            >
              {SitesettingsData.content}
            </Typography>
            <Box
              sx={{
                marginTop: "20px",
                fontSize: {
                  xs: "11px",
                  sm: "11px",
                  md: "13px",
                  lg: "15px",
                  xl: "15px",
                },
                marginBottom: "10px",
              }}
            >
              <Typography
                variant="span"
                sx={{
                  color: "var(--theme-color)",
                  fontWeight: "bold",
                }}
              >
                Contact Us: {""}
              </Typography>
              <Typography variant="span">
                {SitesettingsData.primary_phone}, {""}
                {SitesettingsData.secondary_phone}
              </Typography>
            </Box>
            <Box
              sx={{
                fontSize: {
                  xs: "11px",
                  sm: "11px",
                  md: "13px",
                  lg: "15px",
                  xl: "15px",
                },
                marginBottom: "10px",
              }}
            >
              <Typography
                variant="span"
                sx={{ color: "var(--theme-color)", fontWeight: "bold" }}
              >
                Email: {""}
              </Typography>
              <Typography variant="span">{SitesettingsData.email}</Typography>
            </Box>
            <Box
              sx={{
                fontSize: {
                  xs: "11px",
                  sm: "11px",
                  md: "13px",
                  lg: "15px",
                  xl: "15px",
                },
                marginBottom: "10px",
              }}
            >
              <Typography
                variant="span"
                sx={{ color: "var(--theme-color)", fontWeight: "bold" }}
              >
                Address: {""}
              </Typography>
              <Typography variant="span">{SitesettingsData.address}</Typography>
            </Box>
            <Box
              sx={{
                fontSize: {
                  xs: "11px",
                  sm: "11px",
                  md: "13px",
                  lg: "15px",
                  xl: "15px",
                },
              }}
            >
              <Typography
                variant="span"
                sx={{ color: "var(--theme-color)", fontWeight: "bold" }}
              >
                Hours: {""}
              </Typography>
              <Typography variant="span">{SitesettingsData.hour}</Typography>

              <Box
                sx={{
                  marginTop: "20px",
                  display: "flex",
                  alignItems: "flex-start",
                  zIndex: "1",
                }}
              >
                <ul
                  style={{
                    listStyle: "none",
                    margin: "0",
                    padding: "0",
                    display: "flex",
                  }}
                  className="socialmediaicons"
                >
                  <li className="socialmediaiconlist">
                    <a
                      href={`${SitesettingsData.facebook}`}
                      className="mediaiconstags"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaFacebookF
                        style={iconBoxStyle}
                        size="40px"
                        className="icon"
                      />
                    </a>
                  </li>
                  <li className="socialmediaiconlist">
                    <a
                      href={`${SitesettingsData.twitter}`}
                      className="mediaiconstags"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaXTwitter
                        style={iconBoxStyle}
                        size="40px"
                        className="icon"
                      />
                    </a>
                  </li>
                  <li className="socialmediaiconlist">
                    <a
                      href={`${SitesettingsData.viber}`}
                      className="mediaiconstags"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <ImWhatsapp
                        style={iconBoxStyle}
                        size="40px"
                        className="icon"
                      />
                    </a>
                  </li>
                  <li className="socialmediaiconlist">
                    <a
                      href={`${SitesettingsData.youtube}`}
                      className="mediaiconstags"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaYoutube
                        style={iconBoxStyle}
                        size="40px"
                        className="icon"
                      />
                    </a>
                  </li>
                  <li className="socialmediaiconlist">
                    <a
                      href={`${SitesettingsData.tiktok}`}
                      className="mediaiconstags"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <FaTiktok
                        style={iconBoxStyle}
                        size="40px"
                        className="icon"
                      />
                    </a>
                  </li>
                </ul>
              </Box>
            </Box>
          </Grid>
        </Grid>
      )}
    </Box>
  );
};
