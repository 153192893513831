import React, { useState, useRef, useEffect } from "react";
import Slider from "react-slick";
import { Box } from "@mui/system";
import { Grid } from "@mui/material";
import { useParams } from "react-router-dom";
import ReactPlayer from "react-player";
import VideocamIcon from "@mui/icons-material/Videocam";
import useAPI from "../../hooks/useApi";
import { endpoint } from "../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const ProductDetailsLeftSection = () => {
  const { id } = useParams();

  const { getById } = useAPI();

  const getData = async () => {
    const res = await getById(endpoint.getgoodsbyId, id);
    return res.data;
  };

  const { data: productsDetails } = useQuery({
    queryKey: ["productsDetails", id],
    queryFn: getData,
  });

  // Convert images object to an array and concatenate with videos array
  const combinedArray = [];

  if (Array.isArray(productsDetails?.images)) {
    combinedArray.push(
      ...productsDetails?.images?.map((item) => ({ ...item, type: "image" }))
    );
  }

  if (Array.isArray(productsDetails?.videos)) {
    combinedArray.push(
      ...productsDetails?.videos.map((item) => ({ ...item, type: "video" }))
    );
  }

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1Ref = useRef(null);
  const slider2Ref = useRef(null);

  useEffect(() => {
    setNav1(slider1Ref.current);
    setNav2(slider2Ref.current);
  }, []);

  const [play, setPlay] = useState(false);

  return (
    <Box>
      {productsDetails && (
        <>
          {" "}
          <Slider asNavFor={nav2} ref={slider1Ref} arrows={false} fade={true}>
            {combinedArray.map((item, index) => {
              return (
                <Box
                  sx={{
                    width: "100%",
                    height: "50vh",
                  }}
                  onClick={() => {
                    setPlay(!play);
                  }}
                  key={index}
                >
                  {item.type === "video" ? (
                    <ReactPlayer
                      url={item.video}
                      controls={true}
                      width="100%"
                      height="100%"
                      playing={play}
                    />
                  ) : (
                    <img
                      src={item.image}
                      alt="projectimage"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: " center",
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Slider>
          <Box
            sx={{
              marginTop: "10px",
              marginBottom: {
                xs: "50px",
                sm: "80px",
                md: "10px",
                lg: "0px",
                xl: "0px",
              },
            }}
          >
            <Box
              sx={{
                display: {
                  xs: "none",
                  sm: "block",
                  md: "block",
                  lg: "block",
                  xl: "block",
                },
              }}
            >
              <Slider
                asNavFor={nav1}
                ref={slider2Ref}
                slidesToShow={
                  combinedArray.length < 5 ? combinedArray.length : 5
                }
                swipeToSlide={true}
                focusOnSelect={true}
                arrows={false}
              >
                {combinedArray.map((item, index) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      key={index}
                    >
                      <Box
                        sx={{
                          width: {
                            xs: "20vw",
                            sm: "13vw",
                            md: "14vw",
                            lg: "6.5vw",
                            xl: "6.5vw",
                          },
                          height: "10vh",
                          position: "relative",
                        }}
                      >
                        {item.type === "video" ? (
                          <>
                            <ReactPlayer
                              url={item.video}
                              controls={false}
                              height="12vh"
                              width="100%"
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            />
                            <Box
                              sx={{
                                backgroundColor: "var(--theme-color)",
                                padding: "0px 3px 3px 3px",
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                margin: "3px",
                                color: "#fff",
                                borderRadius: "50%",
                              }}
                            >
                              <VideocamIcon
                                sx={{
                                  height: "15px",
                                  width: "18px",
                                }}
                              />
                            </Box>
                          </>
                        ) : (
                          <img
                            src={item.image}
                            alt="projectimage"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              objectPosition: " center",
                            }}
                          />
                        )}
                      </Box>
                    </Grid>
                  );
                })}
              </Slider>
            </Box>

            <Box
              sx={{
                display: {
                  xs: "block",
                  sm: "none",
                  md: "none",
                  lg: "none",
                  xl: "none",
                },
              }}
            >
              <Slider
                asNavFor={nav1}
                ref={slider2Ref}
                slidesToShow={
                  combinedArray.length < 5 ? combinedArray.length : 5
                }
                swipeToSlide={true}
                focusOnSelect={true}
                arrows={false}
              >
                {combinedArray.slice(0, 4).map((item, index) => {
                  return (
                    <Grid
                      item
                      xs={12}
                      sm={12}
                      md={12}
                      lg={12}
                      xl={12}
                      key={index}
                    >
                      <Box
                        sx={{
                          width: {
                            xs: "20vw",
                            sm: "13vw",
                            md: "14vw",
                            lg: "6vw",
                            xl: "10vw",
                          },
                          height: "10vh",
                          gap: "20px",
                        }}
                      >
                        {item.type === "video" ? (
                          <>
                            <ReactPlayer
                              url={item.video}
                              controls={false}
                              height="12vh"
                              width="100%"
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            />
                            <Box
                              sx={{
                                backgroundColor: "var(--theme-color)",
                                padding: "0px 3px 3px 3px",
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                margin: "3px",
                                color: "#fff",
                                borderRadius: "50%",
                              }}
                            >
                              <VideocamIcon
                                sx={{
                                  height: "15px",
                                  width: "18px",
                                }}
                              />
                            </Box>
                          </>
                        ) : (
                          <img
                            src={item.image}
                            alt="projectimage"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              objectPosition: " center",
                            }}
                          />
                        )}
                      </Box>
                    </Grid>
                  );
                })}
              </Slider>
            </Box>
          </Box>
        </>
      )}
    </Box>
  );
};
