import React, { useEffect } from "react";
import { Box } from "@mui/system";
import { Typography, Grid, Button } from "@mui/material";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { TextField } from "@mui/material";
import InputLabel from "@mui/material/InputLabel";
import { useState } from "react";
import Stack from "@mui/material/Stack";
import FileUploadOutlinedIcon from "@mui/icons-material/FileUploadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import axios from "axios";
import { Cookies } from "react-cookie";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const errorStyle = {
  color: "red",
  fontSize: "12px",
  marginleft: "3rem",
};

export const Profile = () => {
  const [imageUrl, setImageUrl] = useState(null);
  const [file, setFile] = useState();
  const [imageName, setImageName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const cookies = new Cookies();
  const id = cookies.get("userId");
  const userToken = cookies.get("token");

  const [profileData, setProfileData] = useState({
    name: "",
    email: "",
    number: "",
    address: "",
    city: "",
    state: "",
    postal_code: "",
    image: "",
  });

  useEffect(() => {
    async function fetchProfileData() {
      try {
        const response = await axios.get(
          process.env.REACT_APP_JINLAP_BASE_URL + `/users/${id}`,
          {
            headers: {
              Authorization: `Bearer ${userToken}`,
              Accept: "application/json",
              "Content-Type": "application/json",
            },
          }
        );
        setProfileData(response.data.data);
      } catch (error) {
        if (error.response.status === 401) {
          toast.error("Unauthorized access");
        } else {
          console.error("Error fetching profile data:", error);
        }
      }
    }

    fetchProfileData();
  }, [id, userToken, profileData]);

  const initialValues = {
    name: profileData ? profileData.name : "",
    email: profileData ? profileData.email : "",
    number: profileData ? profileData.number : "",
    address: profileData ? profileData.address : "",
    city: profileData ? profileData.city : "",
    state: profileData ? profileData.state : "",
    postal_code: profileData ? profileData.postal_code : "",
    image: profileData ? profileData.image : "",
  };

  const phoneRegExp =
    /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

  const validationSchema = Yup.object().shape({
    name: Yup.string()
      .min(3, "Must have at least 3 characters")
      .max(15, "Must be less than 15 characters"),
    email: Yup.string()
      .email("Must be a valid email address")
      .max(255, "Must be less than 255 characters"),
    number: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .min(10, "Must have at least 10 characters")
      .max(10, "Must be less than 10 characters"),
    address: Yup.string()
      .min(3, "Must have at least 3 characters")
      .max(255, "Must be less than 255 characters"),
    city: Yup.string()
      .min(3, "Must have at least 3 characters")
      .max(255, "Must be less than 255 characters"),
    state: Yup.string()
      .min(3, "Must have at least 3 characters")
      .max(255, "Must be less than 255 characters"),
    postal_code: Yup.string()
      .min(2, "Must have at least 2 characters")
      .max(6, "Must be less than 6 characters"),
  });

  const handleFileUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      setImageName(file.name);
      setImageUrl(reader.result);
    };
    setFile(file);
    reader.readAsDataURL(file);
  };

  const handleRemoveFile = () => {
    setImageUrl(null);
    setImageName("");
  };

  const submitHandler = async (values, { resetForm }) => {
    setIsLoading(true);
    values.image = file;
    const fornData = new FormData();
    fornData.append("name", values.name || profileData.name);
    fornData.append("email", values.email || profileData.email);
    fornData.append("number", values.number || profileData.number);
    fornData.append("address", values.address || profileData.address);
    fornData.append("city", values.city || profileData.city);
    fornData.append("state", values.state || profileData.state);
    fornData.append(
      "postal_code",
      values.postal_code || profileData.postal_code
    );
    fornData.append("image", file || profileData.image);

    try {
      await axios.post(
        process.env.REACT_APP_JINLAP_BASE_URL + `/users/${id}/edit`,
        fornData,
        {
          headers: {
            Authorization: `Bearer ${userToken}`,
            Accept: "application/json",
            "Content-Type": "multipart/form-data",
          },
        }
      );

      toast.success("Profile updated successfully");
      resetForm();
    } catch (error) {
      toast.error(error.response.data.message);
    }
    setIsLoading(false);
  };

  return (
    <Box>
      <Grid container>
        {profileData && (
          <Grid item xs={12} sm={8} md={8} lg={8} xl={8}>
            <Box
              sx={{
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "flex-start",
                flexDirection: "column",
              }}
            >
              <Typography
                variant="h6"
                component="div"
                sx={{
                  color: "var(--black)",
                  fontWeight: "500",
                  fontFamily: "var(--primary-font)",
                  marginBottom: "20px",
                  marginTop: "30px",
                  fontSize: {
                    xs: "14px",
                    sm: "16px",
                    md: "18px",
                    lg: "20px",
                    xl: "24px",
                  },
                }}
              >
                Update Profile information
              </Typography>
              <Formik
                initialValues={initialValues} // Use profileData as initial values
                validationSchema={validationSchema}
                onSubmit={submitHandler}
              >
                {({ errors, touched }) => (
                  <Form
                    style={{
                      width: "100%",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100%",
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "flex-start",
                        alignItems: "flex-start",
                        marginTop: "20px",
                      }}
                    >
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: {
                            xs: "row",
                            sm: "row",
                            md: "row",
                            lg: "row",
                            xl: "row",
                          },
                          justifyContent: "space-between",
                          alignItems: "center",
                          width: "100%",
                          marginTop: "20px",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            sx={{
                              color: "var(--black-color)",
                              fontFamily: "var(--primary-font)",
                              marginBottom: "10px",
                            }}
                          >
                            User Name:
                          </InputLabel>
                          <Field
                            as={TextField}
                            variant="outlined"
                            autoComplete="off"
                            size="small"
                            name="name"
                            placeholder={
                              profileData.name
                                ? profileData.name
                                : "Enter your name"
                            }
                            id="input-with-icon-adornment"
                            className={
                              errors.name && touched.name ? "input-error" : null
                            }
                            sx={{
                              backgroundColor: "transparent",
                              fontFamily: "var(--primary-font)",
                              width: "100%",

                              " & .MuiOutlinedInput-root": {
                                "  &.Mui-focused fieldset": {
                                  borderColor: "var(--btn-color)",
                                },
                              },
                              "& .MuiOutlinedInput-root": {
                                border: "0px solid var(--theme-color)",
                                borderRadius: "0",
                                padding: "0",
                              },
                              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  // border: "1px solid #E4E4E4",
                                  borderRadius: "5px",
                                },
                              //border colour when focused
                              "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "0px solid var(--btn-color))",
                                },
                              //floating label color when focused
                              "& .MuiInputLabel-outlined.Mui-focused": {
                                color: "var(--btn-color)",
                                backgroundColor: "transparent",
                              },
                            }}
                          />
                          <ErrorMessage
                            name="name"
                            component="span"
                            className="error"
                            style={errorStyle}
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            marginLeft: "5%",
                          }}
                        >
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            sx={{
                              color: "var(--black-color)",
                              fontFamily: "var(--primary-font)",
                              marginBottom: "10px",
                            }}
                          >
                            Email Address:
                          </InputLabel>
                          <Field
                            as={TextField}
                            variant="outlined"
                            autoComplete="off"
                            placeholder={
                              profileData.email
                                ? profileData.email
                                : "Enter your email"
                            }
                            size="small"
                            name="email"
                            type="email"
                            id="input-with-icon-adornment"
                            className={
                              errors.email && touched.email
                                ? "input-error"
                                : null
                            }
                            sx={{
                              backgroundColor: "transparent",
                              fontFamily: "var(--primary-font)",
                              width: "100%",

                              " & .MuiOutlinedInput-root": {
                                "  &.Mui-focused fieldset": {
                                  borderColor: "var(--btn-color)",
                                },
                              },
                              "& .MuiOutlinedInput-root": {
                                border: "0px solid var(--theme-color)",
                                borderRadius: "0",
                                padding: "0",
                              },
                              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  // border: "1px solid #E4E4E4",
                                  borderRadius: "5px",
                                },
                              //border colour when focused
                              "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "0px solid var(--btn-color))",
                                },
                              //floating label color when focused
                              "& .MuiInputLabel-outlined.Mui-focused": {
                                color: "var(--btn-color)",
                                backgroundColor: "transparent",
                              },
                            }}
                          />
                          <ErrorMessage
                            name="email"
                            component="span"
                            className="error"
                            style={errorStyle}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: {
                            xs: "row",
                            sm: "row",
                            md: "row",
                            lg: "row",
                            xl: "row",
                          },
                          marginTop: "40px",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            sx={{
                              color: "var(--black-color)",
                              fontFamily: "var(--primary-font)",
                              marginBottom: "10px",
                            }}
                          >
                            Contact Number:
                          </InputLabel>
                          <Field
                            as={TextField}
                            variant="outlined"
                            autoComplete="off"
                            size="small"
                            name="number"
                            placeholder={
                              profileData.number
                                ? profileData.number
                                : "Enter your number"
                            }
                            id="input-with-icon-adornment"
                            className={
                              errors.number && touched.number
                                ? "input-error"
                                : null
                            }
                            sx={{
                              backgroundColor: "transparent",
                              fontFamily: "var(--primary-font)",
                              width: "100%",

                              " & .MuiOutlinedInput-root": {
                                "  &.Mui-focused fieldset": {
                                  borderColor: "var(--btn-color)",
                                },
                              },
                              "& .MuiOutlinedInput-root": {
                                border: "0px solid var(--theme-color)",
                                borderRadius: "0",
                                padding: "0",
                              },
                              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  // border: "1px solid #E4E4E4",
                                  borderRadius: "5px",
                                },
                              //border colour when focused
                              "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "0px solid var(--btn-color))",
                                },
                              //floating label color when focused
                              "& .MuiInputLabel-outlined.Mui-focused": {
                                color: "var(--btn-color)",
                                backgroundColor: "transparent",
                              },
                            }}
                          />
                          <ErrorMessage
                            name="number"
                            component="span"
                            className="error"
                            style={errorStyle}
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            marginLeft: "5%",
                          }}
                        >
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            sx={{
                              color: "var(--black-color)",
                              fontFamily: "var(--primary-font)",
                              marginBottom: "10px",
                            }}
                          >
                            Street Address:
                          </InputLabel>
                          <Field
                            as={TextField}
                            variant="outlined"
                            autoComplete="off"
                            size="small"
                            name="address"
                            id="input-with-icon-adornment"
                            placeholder={
                              profileData.address
                                ? profileData.address
                                : "Enter your address"
                            }
                            className={
                              errors.address && touched.address
                                ? "input-error"
                                : null
                            }
                            sx={{
                              backgroundColor: "transparent",
                              fontFamily: "var(--primary-font)",
                              width: "100%",

                              " & .MuiOutlinedInput-root": {
                                "  &.Mui-focused fieldset": {
                                  borderColor: "var(--btn-color)",
                                },
                              },
                              "& .MuiOutlinedInput-root": {
                                border: "0px solid var(--theme-color)",
                                borderRadius: "0",
                                padding: "0",
                              },
                              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  // border: "1px solid #E4E4E4",
                                  borderRadius: "5px",
                                },
                              //border colour when focused
                              "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "0px solid var(--btn-color))",
                                },
                              //floating label color when focused
                              "& .MuiInputLabel-outlined.Mui-focused": {
                                color: "var(--btn-color)",
                                backgroundColor: "transparent",
                              },
                            }}
                          />
                          <ErrorMessage
                            name="address"
                            component="span"
                            className="error"
                            style={errorStyle}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          width: "100%",
                          display: "flex",
                          flexDirection: {
                            xs: "row",
                            sm: "row",
                            md: "row",
                            lg: "row",
                            xl: "row",
                          },
                          marginTop: "40px",
                        }}
                      >
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            sx={{
                              color: "var(--black-color)",
                              fontFamily: "var(--primary-font)",
                              marginBottom: "10px",
                            }}
                          >
                            City:
                          </InputLabel>
                          <Field
                            as={TextField}
                            variant="outlined"
                            autoComplete="off"
                            size="small"
                            name="city"
                            id="input-with-icon-adornment"
                            placeholder={
                              profileData.city
                                ? profileData.city
                                : "Enter your city"
                            }
                            className={
                              errors.city && touched.city ? "input-error" : null
                            }
                            sx={{
                              backgroundColor: "transparent",
                              fontFamily: "var(--primary-font)",
                              width: "100%",

                              " & .MuiOutlinedInput-root": {
                                "  &.Mui-focused fieldset": {
                                  borderColor: "var(--btn-color)",
                                },
                              },
                              "& .MuiOutlinedInput-root": {
                                border: "0px solid var(--theme-color)",
                                borderRadius: "0",
                                padding: "0",
                              },
                              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  // border: "1px solid #E4E4E4",
                                  borderRadius: "5px",
                                },
                              //border colour when focused
                              "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "0px solid var(--btn-color))",
                                },
                              //floating label color when focused
                              "& .MuiInputLabel-outlined.Mui-focused": {
                                color: "var(--btn-color)",
                                backgroundColor: "transparent",
                              },
                            }}
                          />
                          <ErrorMessage
                            name="city"
                            component="span"
                            className="error"
                            style={errorStyle}
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            marginLeft: "5%",
                          }}
                        >
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            sx={{
                              color: "var(--black-color)",
                              fontFamily: "var(--primary-font)",
                              marginBottom: "10px",
                            }}
                          >
                            State:
                          </InputLabel>
                          <Field
                            as={TextField}
                            variant="outlined"
                            autoComplete="off"
                            size="small"
                            name="state"
                            id="input-with-icon-adornment"
                            placeholder={
                              profileData.state
                                ? profileData.state
                                : "Enter your state"
                            }
                            className={
                              errors.state && touched.state
                                ? "input-error"
                                : null
                            }
                            sx={{
                              backgroundColor: "transparent",
                              fontFamily: "var(--primary-font)",
                              width: "100%",

                              " & .MuiOutlinedInput-root": {
                                "  &.Mui-focused fieldset": {
                                  borderColor: "var(--btn-color)",
                                },
                              },
                              "& .MuiOutlinedInput-root": {
                                border: "0px solid var(--theme-color)",
                                borderRadius: "0",
                                padding: "0",
                              },
                              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  // border: "1px solid #E4E4E4",
                                  borderRadius: "5px",
                                },
                              //border colour when focused
                              "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "0px solid var(--btn-color))",
                                },
                              //floating label color when focused
                              "& .MuiInputLabel-outlined.Mui-focused": {
                                color: "var(--btn-color)",
                                backgroundColor: "transparent",
                              },
                            }}
                          />
                          <ErrorMessage
                            name="state"
                            component="span"
                            className="error"
                            style={errorStyle}
                          />
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                            marginLeft: "5%",
                          }}
                        >
                          <InputLabel
                            htmlFor="input-with-icon-adornment"
                            sx={{
                              color: "var(--black-color)",
                              fontFamily: "var(--primary-font)",
                              marginBottom: "10px",
                            }}
                          >
                            Postal Code:
                          </InputLabel>
                          <Field
                            as={TextField}
                            variant="outlined"
                            autoComplete="off"
                            size="small"
                            name="postal_code"
                            id="input-with-icon-adornment"
                            placeholder={
                              profileData.postal_code
                                ? profileData.postal_code
                                : "Enter your postal code"
                            }
                            className={
                              errors.postalCode && touched.postalCode
                                ? "input-error"
                                : null
                            }
                            sx={{
                              backgroundColor: "transparent",
                              fontFamily: "var(--primary-font)",
                              width: "100%",

                              " & .MuiOutlinedInput-root": {
                                "  &.Mui-focused fieldset": {
                                  borderColor: "var(--btn-color)",
                                },
                              },
                              "& .MuiOutlinedInput-root": {
                                border: "0px solid var(--theme-color)",
                                borderRadius: "0",
                                padding: "0",
                              },
                              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  // border: "1px solid #E4E4E4",
                                  borderRadius: "5px",
                                },
                              //border colour when focused
                              "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "0px solid var(--btn-color))",
                                },
                              //floating label color when focused
                              "& .MuiInputLabel-outlined.Mui-focused": {
                                color: "var(--btn-color)",
                                backgroundColor: "transparent",
                              },
                            }}
                          />
                          <ErrorMessage
                            name="postal_code"
                            component="span"
                            className="error"
                            style={errorStyle}
                          />
                        </Box>
                      </Box>
                      <Box
                        sx={{
                          marginTop: "40px",
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "flex-start",
                          alignItems: "flex-start",
                        }}
                      >
                        <InputLabel
                          htmlFor="input-with-icon-adornment"
                          sx={{
                            color: "var(--black-color)",
                            fontFamily: "var(--primary-font)",
                            marginBottom: "10px",
                          }}
                        >
                          Upload Image:
                        </InputLabel>
                        <Stack
                          spacing={2}
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <label htmlFor="upload-image">
                            <Button
                              variant="outlined"
                              component="span"
                              sx={{
                                borderColor: "var(--btn-color)",
                                color: "var(--black)",
                                fontFamily: "var(--primary-font)",
                                textTransform: "capitalize",
                                fontSize: {
                                  xs: "11px",
                                  sm: "14px",
                                  md: "14px",
                                  lg: "15px",
                                  xl: "15px",
                                },
                                "&:hover": {
                                  backgroundColor: "var(--theme-color)",
                                  borderColor: "var(--theme-color)",
                                  color: "var(--white)",
                                },
                              }}
                            >
                              <FileUploadOutlinedIcon
                                fontSize="medium"
                                sx={{
                                  marginRight: "10px",
                                }}
                              />
                              Upload Image
                            </Button>
                            <input
                              id="upload-image"
                              hidden
                              accept="image/*"
                              name="uploadimage"
                              type="file"
                              onChange={handleFileUpload}
                            />
                          </label>
                          {imageUrl && (
                            <Box
                              sx={{
                                border: "1px solid #E4E4E4",
                                padding: "10px",
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "center",
                                alignItems: "center",
                                borderRadius: "10px",
                                marginTop: "20px",
                              }}
                            >
                              <Box
                                sx={{
                                  width: "80px",
                                  height: "60px",
                                }}
                              >
                                <img
                                  src={imageUrl}
                                  alt="Uploadedimg"
                                  type="file"
                                  accept="image/*"
                                  style={{
                                    width: "100%",
                                    height: "100%",
                                    objectFit: "cover",
                                    objectPosition: "center",
                                  }}
                                />
                              </Box>
                              <Typography
                                variant="body1"
                                sx={{
                                  marginLeft: "20px",
                                  fontFamily: "var(--primary-font)",
                                  fontSize: {
                                    xs: "11px",
                                    sm: "14px",
                                    md: "14px",
                                    lg: "15px",
                                    xl: "15px",
                                  },
                                }}
                              >
                                {imageName}
                              </Typography>
                              <Box>
                                <Button
                                  sx={{
                                    backgroundColor: "transparent",
                                    color: "red",
                                    marginLeft: "20px",
                                    "&:hover": {
                                      backgroundColor: "transparent",
                                      color: "var(--theme-color)",
                                    },
                                  }}
                                  onClick={handleRemoveFile}
                                >
                                  <DeleteOutlineOutlinedIcon />
                                </Button>
                              </Box>
                            </Box>
                          )}
                        </Stack>
                      </Box>
                      <Button
                        variant="outlined"
                        type="submit"
                        sx={{
                          marginTop: "40px",
                          borderColor: "var(--theme-color)",
                          backgroundColor: "var(--theme-color)",
                          color: "var(--white)",
                          fontFamily: "var(--primary-font)",
                          fontSize: {
                            xs: "11px",
                            sm: "14px",
                            md: "14px",
                            lg: "15px",
                            xl: "15px",
                          },
                          "&:hover": {
                            backgroundColor: "var(--theme-color)",
                            borderColor: "var(--theme-color)",
                            color: "var(--white)",
                            transition: "all 0.5s ease",
                            transform: "scale(1.1)",
                          },
                        }}
                        disabled={isLoading}
                      >
                        {isLoading ? (
                          <span style={{ color: "white" }}>Updating...</span>
                        ) : (
                          "Update profile info"
                        )}

                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/icons/left.png"
                          }
                          alt="..."
                          style={{
                            width: "15px",
                            height: "20px",
                            marginLeft: "10px",
                          }}
                        />
                      </Button>
                    </Box>
                  </Form>
                )}
              </Formik>
            </Box>
          </Grid>
        )}

        <Grid item xs={12} sm={4} md={4} lg={4} xl={4}>
          <Box
            sx={{
              marginLeft: {
                xs: "0px",
                sm: "30px",
                md: "30px",
                lg: "40px",
                xl: "40px",
              },

              display: "flex",
              flexDirection: "column",
              // justifyContent: "center",
              // alignItems: "center",
              marginTop: "60px",
              width: "100%",
            }}
          >
            {profileData && (
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                {profileData.image ? (
                  <Box sx={{ height: "25vh", width: "15vw" }}>
                    <img
                      src={profileData.image}
                      alt="..."
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "top",
                        borderRadius: "10px",
                      }}
                    />
                  </Box>
                ) : (
                  <Box
                    sx={{
                      height: "25vh",
                      width: "15vw",
                      backgroundColor: "#5D8AA8",
                      color: "var(--white)",
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      variant="h6"
                      sx={{
                        fontSize: {
                          xs: "40px",
                          sm: "50px",
                          md: "60px",
                          lg: "70px",
                          xl: "80px",
                        },
                      }}
                    >
                      {profileData.name[0]}
                    </Typography>
                  </Box>
                )}

                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    marginTop: "20px",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    width: "70%",
                  }}
                >
                  <Typography
                    variant="h6"
                    sx={{
                      fontFamily: "var(--primary-font)",
                      fontSize: {
                        xs: "14px",
                        sm: "16px",
                        md: "18px",
                        lg: "20px",
                        xl: "24px",
                      },
                    }}
                  >
                    {profileData.name}
                  </Typography>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "20px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "var(--primary-font)",
                        fontSize: {
                          xs: "11px",
                          sm: "13px",
                          md: "13px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                    >
                      Email: {""}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "var(--primary-font)",
                        fontSize: {
                          xs: "11px",
                          sm: "13px",
                          md: "13px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                    >
                      {profileData.email}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "20px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "var(--primary-font)",
                        fontSize: {
                          xs: "11px",
                          sm: "13px",
                          md: "13px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                    >
                      Contact: {""}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "var(--primary-font)",
                        fontSize: {
                          xs: "11px",
                          sm: "13px",
                          md: "13px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                    >
                      {profileData.number}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                      marginTop: "20px",
                      width: "100%",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "var(--primary-font)",
                        fontSize: {
                          xs: "11px",
                          sm: "13px",
                          md: "13px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                    >
                      Location: {""}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontFamily: "var(--primary-font)",
                        fontSize: {
                          xs: "11px",
                          sm: "13px",
                          md: "13px",
                          lg: "15px",
                          xl: "15px",
                        },
                      }}
                    >
                      {profileData.address}
                      <br />
                      {profileData.city} , {profileData.state}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
