import React, { useState } from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { FocusedHappinessBottom } from "./FocusedHappinessBottom";
import { NavLink } from "react-router-dom";

export const FocusedHappiness = ({
  happiness_title_one,
  happiness_title_one_description,
  happiness_title_two,
  happiness_image_one,
  happiness_image_two,
  happiness_image_three,
  mission_image,
  mission_description,
  id,
}) => {
  const [showMore, setShowMore] = useState(false);

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  //dash line
  const DashHorizontalline = {
    height: "0px",
    border: "1px dashed var(--theme-color)",
    opacity: "0.5",
    marginTop: "20px",
    marginBottom: "30px",
  };

  return (
    <Box
      sx={{
        marginBottom: {
          xs: "0px",
        },
        alignItems: "center",
      }}
    >
      <Box>
        <Box
          sx={{
            height: {
              xs: "50vh",
              sm: "30vh",
              md: "35vh",
              lg: "55vh",
              xl: "55vh",
            },
            width: {
              xs: "90%",
              sm: "23vw",
              md: "25vw",
              lg: "30vw",
              xl: "30vw",
            },
            float: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "right",
              xl: "right",
            },
            marginLeft: {
              xs: "0px",
              sm: "15px",
              md: "15px",
              lg: "20px",
              xl: "20px",
            },
            display: {
              xs: "none",
              sm: "none",
              md: "none",
              lg: "block",
              xl: "block",
            },

            position: "relative",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              // backgroundColor: "red",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "60px",
              marginLeft: "30px",
            }}
          >
            <Box
              sx={{
                display: "block",
                width: {
                  xs: "60%",
                  sm: "60%",
                  md: "60%",
                  lg: "100%",
                  xl: "100%",
                },
                position: "relative",
                top: "-50px",
              }}
            >
              <img
                src={happiness_image_one}
                alt="..."
                style={{
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                  height: "auto",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "block",
                width: {
                  xs: "600px",
                  sm: "600px",
                  md: "900px",
                  lg: "1000px",
                  xl: "1000px",
                },
              }}
            >
              <img
                src={happiness_image_two}
                alt="..."
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "block",
                width: {
                  xs: "60%",
                  sm: "60%",
                  md: "60%",
                  lg: "100%",
                  xl: "100%",
                },
                position: "relative",
                top: "45px",
              }}
            >
              <img
                src={happiness_image_three}
                alt="..."
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Box>
          </Box>
          <br />
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "24px",
                lg: "24px",
                xl: "24px",
              },
              fontFamily: "var(--primary-font)",
              color: "var(--theme-color)",
            }}
          >
            {happiness_title_two}
          </Typography>
        </Box>
        <Box
          sx={{
            textAlign: "left",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "24px",
                lg: "24px",
                xl: "24px",
              },
              fontFamily: "var(--primary-font)",
              color: "var(--theme-color)",
              textAlign: "justify",
            }}
          >
            {happiness_title_one}
          </Typography>
          <Box
            sx={{
              marginBottom: "30px",
              width: {
                xs: "100%",
                sm: "58%",
                md: "58%",
                lg: "60%",
                xl: "60%",
              },
            }}
            style={DashHorizontalline}
          ></Box>

          <Typography
            sx={{
              fontSize: "15px",
              fontFamily: "var(--primary-font-family)",
              color: "var(--dark-color)",
              textAlign: "justify",
            }}
          >
            {showMore ? (
              <span
                dangerouslySetInnerHTML={{
                  __html: happiness_title_one_description,
                }}
              ></span>
            ) : (
              <span
                dangerouslySetInnerHTML={{
                  __html: `${happiness_title_one_description?.slice(0, 2000)}`,
                }}
              ></span>
            )}
          </Typography>

          {happiness_title_one_description?.length > 2000 && (
            <NavLink
              style={{
                color: "var(--theme-color)",
                marginBottom: "30px",
              }}
              onClick={toggleShowMore}
            >
              {showMore ? "Read less" : "Read more"}
            </NavLink>
          )}
        </Box>
        <Box
          sx={{
            height: {
              xs: "50vh",
              sm: "30vh",
              md: "35vh",
              lg: "55vh",
              xl: "55vh",
            },
            width: {
              xs: "100%",
              sm: "80%",
              md: "100%",
              lg: "30vw",
              xl: "30vw",
            },

            display: {
              xs: "block",
              sm: "block",
              md: "block",
              lg: "none",
              xl: "none",
            },

            position: "relative",
            marginBottom: "40px",
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              // backgroundColor: "red",
              justifyContent: "center",
              alignItems: "center",
              marginTop: "60px",
            }}
          >
            <Box
              sx={{
                display: "block",
                width: {
                  xs: "60%",
                  sm: "60%",
                  md: "60%",
                  lg: "100%",
                  xl: "100%",
                },
                position: "relative",
                top: "-50px",
              }}
            >
              <img
                src={happiness_image_one}
                alt="..."
                style={{
                  width: "100%",
                  objectFit: "cover",
                  objectPosition: "center",
                  height: "auto",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "block",
                width: {
                  xs: "600px",
                  sm: "600px",
                  md: "900px",
                  lg: "1000px",
                  xl: "1000px",
                },
              }}
            >
              <img
                src={happiness_image_two}
                alt="..."
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Box>
            <Box
              sx={{
                display: "block",
                width: {
                  xs: "60%",
                  sm: "60%",
                  md: "60%",
                  lg: "100%",
                  xl: "100%",
                },
                position: "relative",
                top: "45px",
              }}
            >
              <img
                src={happiness_image_three}
                alt="..."
                style={{
                  width: "100%",
                  height: "auto",
                  objectFit: "cover",
                  objectPosition: "center",
                }}
              />
            </Box>
          </Box>
          <br />
          <Typography
            variant="h3"
            sx={{
              textAlign: "center",
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "24px",
                lg: "24px",
                xl: "24px",
              },
              fontFamily: "var(--primary-font)",
              color: "var(--theme-color)",
            }}
          >
            {happiness_title_two}
          </Typography>
        </Box>
      </Box>

      <FocusedHappinessBottom
        mission_image={mission_image}
        mission_description={mission_description}
      />
    </Box>
  );
};
