import React, { useState } from "react";
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import { NavLink } from "react-router-dom";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { useNavigate } from "react-router-dom";
import ReactPlayer from "react-player";
import VideocamIcon from "@mui/icons-material/Videocam";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import Skeleton from "@mui/material/Skeleton";
import { useQuery } from "@tanstack/react-query";
import { useEffect } from "react";
import { useCookies } from "react-cookie";

const sectOptions = [
  {
    id: 1,
    title: "Nyingma",
  },
  {
    id: 2,
    title: "Kagyu",
  },
  {
    id: 3,
    title: "Sakya",
  },
  {
    id: 4,
    title: "Gelug",
  },
];

export const GreenTaraPuja = ({ id, name }) => {
  const navigate = useNavigate();

  const [token, setToken] = useState(""); // Initialize the token state
  const [cookies] = useCookies(["userId", "token"]);

  useEffect(() => {
    // Check if the token is available in cookies
    if (cookies.token) {
      setToken(cookies.token);
      // You can also perform other actions that depend on the token here
    }
  }, [cookies.token]);

  const [showMore, setShowMore] = useState(false);

  const [showiconMap, setShowiconMap] = useState({});
  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.all_good_dharma);
    return res.data;
  };

  const { isDataLoading, data: allGoodDharma } = useQuery({
    queryKey: ["allGoodDharmaData"],
    queryFn: getData,
  });

  const selectedOption = allGoodDharma?.filter((item) => item.id === id);

  if (isDataLoading)
    return (
      <Box
        sx={{
          marginBottom: "40px",
          display: "flex",
          flexDirection: "row",
          gap: "20px",
        }}
      >
        <Skeleton variant="rectangular" width="100%" height="300px" />
        <Skeleton variant="text" width="100%" height="50px" />
      </Box>
    );

  const toggleShowMore = () => {
    setShowMore(!showMore);
  };
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
      }}
    >
      {selectedOption?.slice(0, 2)?.map((item, index) => {
        const isImageLeft = index % 2 === 0; // Check if the index is even
        const showicon = showiconMap[item.id] || false;

        return (
          <Box
            key={item.id}
            sx={{
              marginBottom: {
                xs: "20px",
                sm: "50px",
                md: "80px",
                lg: "100px",
                xl: "80px",
              },
            }}
          >
            <Box
              sx={{
                width: {
                  xs: "100%",
                  sm: "50%",
                  md: "50%",
                  lg: "37vw",
                  xl: "37vw",
                },
                height: {
                  xs: "auto",
                  sm: "38vh",
                  md: "48vh",
                  lg: "58vh",
                  xl: "60vh",
                },
                float: {
                  xs: "none",
                  sm: isImageLeft ? "left" : "right",
                  md: isImageLeft ? "left" : "right",
                  lg: isImageLeft ? "left" : "right",
                  xl: isImageLeft ? "left" : "right",
                },
                marginLeft: {
                  xs: "0px",
                  sm: isImageLeft ? 0 : "25px",
                  md: isImageLeft ? 0 : "25px",
                  lg: isImageLeft ? 0 : "25px",
                  xl: isImageLeft ? 0 : "25px",
                },
                marginRight: {
                  xs: "0px",
                  sm: isImageLeft ? "25px" : 0,
                  md: isImageLeft ? "25px" : 0,
                  lg: isImageLeft ? "25px" : 0,
                  xl: isImageLeft ? "25px" : 0,
                },
                position: "relative",
              }}
            >
              {showicon === false ? (
                <>
                  <ReactPlayer
                    url={item.videos[0]?.video}
                    controls={false}
                    width="100%"
                    height="100%"
                    style={{
                      objectFit: "cover",
                      backgroundColor: "var(--pathbar-color)",
                    }}
                  />

                  <Box
                    sx={{
                      backgroundColor: "var(--theme-color)",
                      padding: "8px 10px 10px 10px",
                      position: "absolute",
                      bottom: 0,
                      left: 0,
                      margin: "15px",
                      color: "#fff",
                      borderRadius: "50%",
                    }}
                    onMouseEnter={() => {
                      setShowiconMap((prevShowiconMap) => ({
                        ...prevShowiconMap,
                        [item.id]: true,
                      }));
                    }}
                  >
                    <VideocamIcon
                      sx={{
                        height: "25px",
                        width: "25px",
                      }}
                    />
                  </Box>
                </>
              ) : (
                <ReactPlayer
                  url={item.videos[0]?.video}
                  controls={true}
                  width="100%"
                  height="100%"
                  style={{
                    objectFit: "cover",
                    backgroundColor: "var(--pathbar-color)",
                  }}
                />
              )}
            </Box>

            <Box
              sx={{
                textAlign: "justify",
              }}
            >
              <Typography
                variant="p"
                sx={{
                  fontSize: {
                    xs: "18px",
                    sm: "18px",
                    md: "20px",
                    lg: "20px",
                    xl: "20px",
                  },
                  fontWeight: "bold",
                  color: "var(--light-black)",
                  textTransform: "uppercase",
                }}
              >
                {item.name}
              </Typography>
              <br />
              <br />

              <Typography
                sx={{
                  fontSize: "15px",
                  fontFamily: "var(--primary-font-family)",
                  color: "var(--dark-color)",
                }}
              >
                {showMore ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: item?.description_two,
                    }}
                  ></span>
                ) : (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: item.description_two?.slice(0, 1750),
                    }}
                  ></span>
                )}
                {item?.description_two?.length > 1750 && (
                  <NavLink
                    style={{
                      color: "var(--theme-color)",
                      marginBottom: "30px",
                      whiteSpace: "nowrap",
                      display: "inline",
                    }}
                    onClick={toggleShowMore}
                  >
                    {showMore ? "Read less" : "Read more"}
                  </NavLink>
                )}
              </Typography>
              {/* {!showMore && ( */}
              <Box sx={{ display: "flex" }}>
                <Button
                  variant="contained"
                  sx={{
                    backgroundColor: "var(--theme-color)",
                    color: "var(--white)",
                    marginBottom: "10px",
                    fontSize: {
                      xs: "8px",
                      sm: "10px",
                      md: "12px",
                      lg: "15px",
                      xl: "15px",
                    },
                    marginTop: {
                      xs: "10px",
                      sm: "10px",
                      md: "15px",
                      lg: "18px",
                      xl: "18px",
                    },
                    padding: "10px",
                    "&:hover": {
                      backgroundColor: "var(--theme-color)",
                      color: "var(--white)",
                      transform: "scale(1.1)",
                    },
                  }}
                  onClick={() => {
                    navigate(`/gooddharma/${item.id}`);
                  }}
                >
                  Take a look
                  <img
                    src={process.env.PUBLIC_URL + "/assets/icons/left.png"}
                    alt="..."
                    style={{
                      width: "15px",
                      height: "20px",
                      marginLeft: "10px",
                    }}
                  />
                </Button>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                    alignItems: "center",
                    marginLeft: "20px",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "var(--very-lightthemecolor)",
                      padding: "5px",
                      borderRadius: "50%",
                      color: "var(--theme-color)",
                      "&:hover": {
                        //zoom the buttom
                        transform: "scale(1.1)",
                        backgroundColor: "var(--theme-color)",
                        color: "var(--white)",
                      },
                    }}
                    onClick={async () => {
                      const todaysDate = new Date();
                      todaysDate.setDate(todaysDate.getDate() + 1); // Add 1 day to get tomorrow's date
                      const selecteddate = todaysDate
                        .toISOString()
                        .split("T")[0]; // Format as "YYYY-MM-DD"

                      const selectedData = {
                        service_id: item.id,
                        // title: serviceDetails.name,
                        // imgUrl: serviceDetails.images[0].image,
                        date: selecteddate,
                        sect: sectOptions[0].title,
                        size: item.sizes[0].size,
                        price: item.sizes[0].price,
                      };

                      try {
                        const response = await axios.post(
                          process.env.REACT_APP_JINLAP_BASE_URL +
                            `/services/add-to-wishlist`,
                          selectedData,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                              Accept: "application/json",
                              "Content-Type": "application/json",
                            },
                          }
                        );
                        {
                          response.data.status === true
                            ? toast.success(
                                `${item.name} added to wishlist successfully!`
                              )
                            : toast.warning(response.data.message);
                        }
                      } catch (error) {
                        toast.error(error.response.data.message);
                      }
                    }}
                  >
                    <FavoriteBorderIcon />
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "var(--very-lightthemecolor)",
                      padding: "5px",
                      borderRadius: "50%",
                      color: "var(--theme-color)",
                      marginLeft: "10px",
                      "&:hover": {
                        //zoom the buttom
                        transform: "scale(1.1)",
                        backgroundColor: "var(--theme-color)",
                        color: "var(--white)",
                      },
                    }}
                    onClick={async () => {
                      const todaysDate = new Date();
                      todaysDate.setDate(todaysDate.getDate() + 1); // Add 1 day to get tomorrow's date
                      const selecteddate = todaysDate
                        .toISOString()
                        .split("T")[0]; // Format as "YYYY-MM-DD"

                      const selectedData = {
                        service_id: item.id,
                        // title: serviceDetails.name,
                        // imgUrl: serviceDetails.images[0].image,
                        date: selecteddate,
                        sect: sectOptions[0].title,
                        size: item.sizes[0].size,
                        price: item.sizes[0].price,
                      };

                      try {
                        const response = await axios.post(
                          process.env.REACT_APP_JINLAP_BASE_URL +
                            `/services/add-to-cart`,
                          selectedData,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                              Accept: "application/json",
                              "Content-Type": "application/json",
                            },
                          }
                        );
                        {
                          response.data.status === true
                            ? toast.success(
                                `${item.name} added to cart successfully!`
                              )
                            : toast.warning(response.data.message);
                        }
                      } catch (error) {
                        toast.error(error.response.data.message);
                      }
                    }}
                  >
                    <ShoppingBagOutlinedIcon fontSize="medium" />
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        );
      })}
    </Box>
  );
};
