import React from "react";
import Slider from "react-slick";
import { Skeleton, Typography } from "@mui/material";
import { ContNextArrow, ContPrevArrow } from "../../Shared/arrows/arrows";
import { Box } from "@mui/system";
import { IconButton } from "@mui/material";
import { Container } from "@mui/material";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { useSelector } from "react-redux";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { useCookies } from "react-cookie";

export const FeaturedPaintings = ({ title }) => {
  const [token, setToken] = useState(""); // Initialize the token state
  const [cookies] = useCookies(["userId", "token"]);

  useEffect(() => {
    // Check if the token is available in cookies
    if (cookies.token) {
      setToken(cookies.token);
      // You can also perform other actions that depend on the token here
    }
  }, [cookies.token]);

  const { selectedCurrency, rate } = useSelector((state) => state.currency);

  const { get } = useAPI();

  const getData = async () => {
    const res = await get(endpoint.featured_dharmagood);
    return res.data;
  };

  const { isDataLoading, data: featuredDharmaGood } = useQuery({
    queryKey: ["featuredDharmaGood"],
    queryFn: getData,
  });

  if (isDataLoading) {
    return (
      <>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-start",
            gap: "20px",
          }}
        >
          {[1, 2, 3].map((index) => (
            <Box
              key={index}
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Skeleton variant="rectangular" width={300} height={200} />
              <Skeleton variant="text" width={300} height={30} />
              <Skeleton variant="text" width={300} height={30} />
            </Box>
          ))}
        </Box>
      </>
    );
  }

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,

    nextArrow: <ContNextArrow />,
    prevArrow: <ContPrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <Box
      sx={{
        backgroundColor: "#F8F8F8",
        padding: "50px",
        marginBottom: "120px",
      }}
    >
      <div
        style={{
          zIndex: "1",
          bottom: "70px",
          position: "relative",
          textAlign: "center",
        }}
      >
        <h5
          style={{
            color: "#96195A",
            backgroundColor: "#f1e0e9",
            padding: "10px",
            display: "inline-block",
          }}
        >
          Featured {title}
        </h5>
      </div>
      <Container>
        <Slider {...settings}>
          {featuredDharmaGood?.map((item) => (
            <Box key={item.id}>
              <Box
                sx={{
                  position: "relative",
                  "&:hover": {
                    "&::before": {
                      content: "''",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      right: 0,
                      bottom: 0,
                      backgroundColor: "#e0bace", // Light pink color
                      opacity: 0.7,
                      zIndex: 1,
                    },
                    "& button": {
                      visibility: "visible",
                    },
                  },
                  margin: "0px 10px",
                }}
              >
                <Box
                  sx={{
                    height: "35vh",
                    width: "100%",
                  }}
                >
                  <img
                    src={item.images[0].image}
                    alt={item.name}
                    style={{ height: "100%", width: "100%" }}
                  />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  {/* Icons */}
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "40%",
                      transform: "translate(-50%, -50%)",
                      visibility: "hidden",
                      zIndex: 2,
                    }}
                    size="small"
                    color="primary"
                    onClick={async () => {
                      const wishlistdata = {
                        color: item.colors[0].color,
                        size: item.sizes[0].size,
                        product_id: item.id,
                        currency: selectedCurrency,
                        qty: 1,
                        price: item.after_discount
                          ? (item.after_discount / rate).toFixed(2)
                          : (item.price / rate).toFixed(2),
                      };

                      try {
                        const response = await axios.post(
                          process.env.REACT_APP_JINLAP_BASE_URL +
                            `/products/add-to-wishlist`,
                          wishlistdata,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                              Accept: "application/json",
                              "Content-Type": "application/json",
                            },
                          }
                        );
                        {
                          response.data.status === true
                            ? toast.success(
                                `${item.name} added to wishlist successfully!`
                              )
                            : toast.warning(response.data.message);
                        }
                      } catch (error) {
                        toast.error(error.response.data.message);
                      }
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "var(--very-lightthemecolor)",
                        padding: "5px",
                        borderRadius: "50%",
                        color: "var(--theme-color)",
                        "&:hover": {
                          //zoom the buttom
                          transform: "scale(1.1)",
                          backgroundColor: "var(--theme-color)",
                          color: "var(--white)",
                        },
                      }}
                    >
                      <FavoriteBorderIcon fontSize="medium" />
                    </Box>
                  </IconButton>
                  <IconButton
                    sx={{
                      position: "absolute",
                      top: "50%",
                      left: "60%",
                      transform: "translate(-50%, -50%)",
                      visibility: "hidden",
                      zIndex: 2,
                    }}
                    size="small"
                    color="primary"
                    onClick={async () => {
                      const data = {
                        color: item.colors[0].color,
                        size: item.sizes[0].size,
                        qty: 1,
                        product_id: item.id,
                        currency: selectedCurrency,
                        price: item.after_discount
                          ? (item.after_discount / rate).toFixed(2)
                          : (item.price / rate).toFixed(2),
                      };

                      try {
                        const response = await axios.post(
                          process.env.REACT_APP_JINLAP_BASE_URL +
                            `/products/add-to-cart`,
                          data,
                          {
                            headers: {
                              Authorization: `Bearer ${token}`,
                              Accept: "application/json",
                              "Content-Type": "application/json",
                            },
                          }
                        );
                        {
                          response.data.status === true
                            ? toast.success(
                                `${item.name} added to cart successfully!`
                              )
                            : toast.warning(response.data.message);
                        }
                      } catch (error) {
                        toast.error(error.response.data.message);
                      }
                    }}
                  >
                    <Box
                      sx={{
                        backgroundColor: "var(--very-lightthemecolor)",
                        padding: "5px",
                        borderRadius: "50%",
                        color: "var(--theme-color)",
                        "&:hover": {
                          //zoom the buttom
                          transform: "scale(1.1)",
                          backgroundColor: "var(--theme-color)",
                          color: "var(--white)",
                        },
                      }}
                    >
                      <ShoppingBagOutlinedIcon fontSize="medium" />
                    </Box>
                  </IconButton>
                </Box>
              </Box>

              <Box
                sx={{
                  textAlign: "justify",
                  margin: "10px 10px",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: {
                      xs: "11px",
                      sm: "11px",
                      md: "13px",
                      lg: "15px",
                      xl: "15px",
                    },
                  }}
                >
                  {item.name}
                </Typography>
                <br />
                <Typography
                  variant="span"
                  sx={{
                    color: "#96195a",
                    fontSize: {
                      xs: "11px",
                      sm: "11px",
                      md: "13px",
                      lg: "15px",
                      xl: "15px",
                    },
                    textAlign: "center",
                  }}
                >
                  {item.after_discount ? (
                    <>
                      <del>
                        {selectedCurrency} {""} {(item.price / rate).toFixed(2)}
                      </del>
                      <span
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        {selectedCurrency} {""}{" "}
                        {(item.after_discount / rate).toFixed(2)}
                      </span>
                    </>
                  ) : (
                    <span>
                      {selectedCurrency} {""}
                      {item.price.toFixed(2)}
                    </span>
                  )}
                </Typography>
              </Box>
            </Box>
          ))}
        </Slider>
      </Container>
    </Box>
  );
};
