import React from "react";
import { useSelector } from "react-redux";
import { styled } from "@mui/material/styles";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Box } from "@mui/system";
import { Typography, Button } from "@mui/material";
import RemoveIcon from "@mui/icons-material/Remove";
import CloseIcon from "@mui/icons-material/Close";
import AddIcon from "@mui/icons-material/Add";
import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import useAPI from "../../../hooks/useApi";
import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { endpoint } from "../../../constant/endpoint";
import { useCookies } from "react-cookie";
import { useEffect, useState } from "react";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "#F4F4F4",
    color: "var(--black)",
    fontWeight: "bold",
    fontFamily: "var(--primary-font)",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const hr_line = {
  width: "100%",
  height: "1px",
  backgroundColor: "#E4E4E4",
  marginTop: "20px",
  marginBottom: "20px",
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  fontFamily: "var(--primary-font)",
  "& > td": {
    padding: "20px 10px 20px 10px", // Adjust the padding value as per your preference
    fontWeight: 600, // Set the font weight to 600 for the cells in the row
  },
}));

export const MobDharmaGoodCart = () => {
  const { selectedCurrency, rate } = useSelector((state) => state.currency);

  const [token, setToken] = useState(""); // Initialize the token state
  const [cookies] = useCookies(["userId", "token"]);

  useEffect(() => {
    // Check if the token is available in cookies
    if (cookies.token) {
      setToken(cookies.token);
      // You can also perform other actions that depend on the token here
    }
  }, [cookies.token]);

  const queryClient = useQueryClient();

  const { updatedCart, getCartById } = useAPI();

  const getData = async () => {
    const response = await getCartById(endpoint.getgoodsbyId);
    return response.data;
  };

  const { data: dharmagoodcart } = useQuery({
    queryKey: ["products"],
    queryFn: getData,
  });

  const { mutate: mutateCart } = useMutation(updatedCart, {
    onSuccess: () => {
      // After the mutation is successful, update the dharmagoodcart data by refetching it.
      // refetchDharmaGoodCart();
      toast.success("Cart updated successfully");
    },
    onError: (errors) => {
      toast.error(errors.message);
    },
    onSettled: () => {
      queryClient.invalidateQueries("products");
    },
  });

  const handleQuantityChange = async (itemId, newQty) => {
    // Update local state immediately
    const updatedCart = dharmagoodcart.map((item) => {
      if (item.id === itemId) {
        return {
          ...item,
          qty: newQty,
          total_price: item.price * newQty,
        };
      }
      return item;
    });

    const UpdatedData = {
      id: itemId,
      data: {
        qty: newQty,
        price: updatedCart.find((item) => item.id === itemId)?.price,
        total_price: updatedCart.find((item) => item.id === itemId)
          ?.total_price,
      },
    };
    mutateCart(UpdatedData);
  };

  return (
    <TableContainer
      component={Paper}
      sx={{
        marginBottom: "50px",
        boxShadow: "none", // Remove the box shadow
      }}
    >
      <Table sx={{ minWidth: 300 }} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell>Dharma Goods</StyledTableCell>
          </TableRow>
        </TableHead>
        <Box
          sx={{
            marginBottom: "20px",
          }}
        ></Box>
        <TableBody>
          {dharmagoodcart ? (
            dharmagoodcart?.map((item, index) => (
              <StyledTableRow key={index}>
                {/* {item.map((item, index) => (
              <> */}
                <StyledTableCell
                  component="th"
                  scope="row"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "flex-start",
                    alignItems: "flex-start",
                    gap: "10px",
                    fontFamily: "var(--primary-font)",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "flex-start",
                      alignItems: "flex-start",
                    }}
                  >
                    <Box
                      sx={{
                        width: "100px",
                        height: "80px",
                        overflow: "hidden",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <img
                        src={item.image[0]?.image_path}
                        alt="..."
                        style={{
                          width: "100%",
                          height: "100%",
                          objectFit: "cover",
                          objectPosition: "center",
                        }}
                      />
                    </Box>

                    <Button
                      sx={{
                        marginTop: "20px",
                        display: "flex",

                        justifyContent: "flex-start",
                      }}
                      onClick={async () => {
                        try {
                          await axios.delete(
                            process.env.REACT_APP_JINLAP_BASE_URL +
                              `/products/${item.id}/cart`,
                            {
                              headers: {
                                Authorization: `Bearer ${token}`,
                                Accept: "application/json",
                                "Content-Type": "application/json",
                              },
                            }
                          );
                          toast.success(
                            "Product removed from cart successfully"
                          );
                        } catch (error) {
                          toast.error(error.response.data.message);
                        }
                      }}
                    >
                      <CloseIcon
                        fontSize="medium"
                        sx={{
                          color: "var(--white)",
                          backgroundColor: "var(--theme-color)",
                          "&:hover": {
                            color: "var(--theme-color)",
                            backgroundColor: "var(--white)",
                          },
                        }}
                      />
                    </Button>
                  </Box>

                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      justifyContent: "center",
                      alignItems: "flex-start",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "500",
                        fontSize: "18px",
                        color: "var(--light-black)",
                        fontFamily: "var(--primary-font)",
                        marginBottom: "5px",
                      }}
                    >
                      {item.product.name}
                    </Typography>
                    <Typography
                      variant="body1"
                      sx={{
                        fontWeight: "bold",
                        fontSize: "15px",
                        color: "var(--theme-color)",
                        fontFamily: "var(--primary-font)",
                        marginBottom: "5px",
                      }}
                    >
                      {selectedCurrency} {(item.price / rate).toFixed(2)}
                    </Typography>
                    <Box
                      sx={{
                        border: "1px solid #E4E4E4",
                        padding: "5px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        flexDirection: "row",
                        width: "80%",
                      }}
                    >
                      <Button
                        onClick={() =>
                          handleQuantityChange(item.id, item.qty - 1)
                        }
                        disabled={item.qty === 1}
                        sx={{
                          color: "var(--black)",
                          backgroundColor: "transparent",

                          "&:hover": {
                            color: "var(--theme-color)",
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <RemoveIcon fontSize="small" />
                      </Button>

                      {item.qty}
                      <Button
                        onClick={() =>
                          handleQuantityChange(item.id, item.qty + 1)
                        }
                        disabled={
                          item.qty >= 10 && item.qty >= item.product.remaing_qty
                        }
                        sx={{
                          color: "var(--black)",
                          backgroundColor: "transparent",
                          "&:hover": {
                            color: "var(--theme-color)",
                            backgroundColor: "transparent",
                          },
                        }}
                      >
                        <AddIcon fontSize="small" />
                      </Button>
                    </Box>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        marginTop: "20px",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: "14px",
                          fontWeight: "bold",
                          fontFamily: "var(--primary-font)",
                        }}
                      >
                        Total Price: {""}
                      </Typography>
                      <Typography
                        sx={{
                          color: "var(--theme-color)",
                          marginLeft: "10px",
                          fontSize: "14px",
                          fontWeight: "bold",
                          fontFamily: "var(--primary-font)",
                        }}
                      >
                        {isNaN((item.price / rate).toFixed(2)) ||
                        isNaN(item.qty)
                          ? "Invalid Price"
                          : `${selectedCurrency} ${
                              ((item.price * item.qty) / rate).toFixed(2) // Round to 2 decimal places here
                            }`}
                      </Typography>
                    </Box>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            ))
          ) : (
            <StyledTableRow>
              <StyledTableCell colSpan={5} align="center">
                No items in the cart.
              </StyledTableCell>
            </StyledTableRow>
          )}
        </TableBody>
      </Table>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          alignItems: "center",
          gap: "10px",
          margin: "20px",
        }}
      >
        <Typography
          variant="h6"
          sx={{
            fontWeight: "500",
            fontSize: {
              xs: "13px",
              sm: "13px",
              md: "14px",
              lg: "15px",
              xl: "15px",
            },

            fontFamily: "var(--primary-font)",
            justifyContent: "flex-end",
            display: "flex",
            alignItems: "center",
          }}
        >
          DHARMA GOOD SUBTOTAL: {""}
        </Typography>

        <Typography
          variant="h6"
          sx={{
            fontWeight: "bold",
            color: "var(--theme-color)",
            marginleft: "20px",
            fontSize: {
              xs: "14px",
              sm: "15px",
              md: "16px",
              lg: "18px",
              xl: "18px",
            },
            justifyContent: "flex-end",
            display: "flex",
            alignItems: "center",
            fontFamily: "var(--primary-font)",
          }}
        >
          {selectedCurrency}{" "}
          {dharmagoodcart
            ? (
                dharmagoodcart?.reduce(
                  (acc, item) => acc + item.qty * item.price,
                  0
                ) / rate
              ).toFixed(2)
            : 0}
        </Typography>
      </Box>
      <Box sx={hr_line}></Box>

      {/* {dharmagoodtotalQuantity} */}
    </TableContainer>
  );
};
