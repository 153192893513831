import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import Donationicon from "../../components/assets/donationicon.png";

const horizontal_line = {
  width: "100%",
  height: "1px",
  backgroundColor: "var(--title-backgrd-color)",
};
export const ThankyouforDonation = () => {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          height: {
            xs: "auto",
            sm: "50vh",
            md: "50vh",
            lg: "70vh",
            xl: "70vh",
          }, // Center vertically at every breakpoint
          boxShadow: "0px 0px 10px 0px rgba(0,0,0,0.75)",
          position: "relative",
          backgroundColor: "var(--white)",
          borderRadius: "10px",
          padding: "10px",
          width: {
            xs: "95%",
            sm: "75%",
            md: "75%",
            lg: "42%",
            xl: "42%",
          },
          borderColor: "var(--white)",
          marginTop: {
            xs: "5%",
            sm: "1.5%",
            md: "1.5%",
            lg: "2%",
            xl: "2%",
          },
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center horizontally
            justifyContent: "center", // Center vertically
          }}
        >
          <div style={horizontal_line}></div>
          <div style={horizontal_line}></div>
          <div style={horizontal_line}></div>

          <img
            src={Donationicon}
            alt="..."
            style={{
              width: "80px",
              zIndex: "1",
              // bottom: "60px",
              top: "20px",
              // position: "absolute",
              backgroundColor: "var(--white)",
            }}
          />
        </Box>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center", // Center horizontally
          }}
        >
          <Typography
            variant="h6"
            sx={{
              textAlign: "center",
              fontSize: {
                xs: "14px",
                sm: "16px",
                md: "16px",
                lg: "18px",
                xl: "28px",
              },
              fontWeight: "500",
              color: "var(--theme-color)",
              fontFamily: "var(--primary-font)",
              marginTop: "30px",
              marginBottom: "20px",
            }}
          >
            Thank you for Donation
          </Typography>
          <Typography
            variant="body1"
            sx={{
              textAlign: "center",
              width: "80%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              margin: "0 auto",
              fontSize: {
                xs: "13px",
                sm: "14px",
                md: "15px",
                lg: "16px",
                xl: "16px",
              },
              fontWeight: "400",
              color: "var(--black-color)",
              fontFamily: "var(--primary-font)",
              marginBottom: "50px",
            }}
          >
            Thank you for your support and for your belief in doing good. Your
            donation has helped us meet our fundraising goal for this campaign.
            With this donation, we’ll be able to continue our project for
            betterment of society.
          </Typography>
          <Box
            sx={{
              width: "15vw",
              height: "auto",
            }}
          >
            <img
              src={process.env.PUBLIC_URL + "/assets/logo.png"}
              alt="..."
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
                objectPosition: "center",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
