import React, { useState, useEffect } from "react";
import { Box } from "@mui/system";
import { Button, TextField, Typography, Grid } from "@mui/material";
import "./ProductDetails.css";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import SellIcon from "@mui/icons-material/Sell";
import TouchAppIcon from "@mui/icons-material/TouchApp";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";
import { useParams } from "react-router-dom";
import { Formik, Form, ErrorMessage, Field } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import useAPI from "../../hooks/useApi";
import { endpoint } from "../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";
import { useCookies } from "react-cookie";

const errorStyle = {
  color: "red",
  fontSize: "12px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  marginTop: "5px",
};

const hor_line = {
  width: "100%",
  height: "0.5px",
  backgroundColor: "var(--title-backgrd-color)",
  // backgroundColor: "blue",
  marginTop: "1px",
};

export const ProductDetailsRightSection = () => {
  const [packaged, setPackaged] = useState(false);
  const { id } = useParams();

  const navigate = useNavigate();

  const [token, setToken] = useState(""); // Initialize the token state
  const [cookies] = useCookies(["userId", "token"]);

  useEffect(() => {
    // Check if the token is available in cookies
    if (cookies.token) {
      setToken(cookies.token);
      // You can also perform other actions that depend on the token here
    }
  }, [cookies.token]);

  const { selectedCurrency, rate } = useSelector((state) => state.currency);

  const { getById } = useAPI();

  const getData = async () => {
    const res = await getById(endpoint.getgoodsbyId, id);
    return res.data;
  };

  const { data: productDetails } = useQuery({
    queryKey: ["productsDetails", id],
    queryFn: getData,
  });

  useEffect(() => {
    if (productDetails?.colors && productDetails?.colors.length > 0) {
      setSelectedColor(productDetails?.colors[0]?.color);
    }

    if (productDetails?.sizes && productDetails?.sizes.length > 0) {
      setSelectedSize(productDetails?.sizes[0]?.size);
    }
  }, [productDetails]);

  const [selectedColor, setSelectedColor] = useState(null);

  const [selectedSize, setSelectedSize] = useState(null);
  // const [quantity, setQuantity] = useState(1);

  const initialValues = {
    size: "",
    quantity: 1,
    color: "",
    email: "",
  };
  const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  const validationSchema = Yup.object().shape({
    quantity: Yup.number()
      .typeError("Quantity must be a number")
      .positive("Quantity must be a positive number")
      .integer("Quantity must be an integer")
      .min(1, "Quantity must be at least 1")
      .required("Quantity is required"),

    email: Yup.string()
      // .required("Email is required")
      .matches(emailRegExp, "Email is not valid"),
  });

  const handleSubmit = (values, { resetForm }) => {
    resetForm();
  };

  return (
    <>
      {productDetails && (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "flex-start",
            alignItems: "flex-start",
          }}
        >
          <Box
            sx={{
              marginBottom: "20px",
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                color: "var(--light-black)",
                fontFamily: "var(--primary-font)",
                fontWeight: "500",
                fontSize: {
                  xs: "16px",
                  sm: "17px",
                  md: "18px",
                  lg: "20px",
                  xl: "20px",
                },
                textAlign: "left",
              }}
            >
              {productDetails?.name}
            </Typography>
          </Box>

          <div style={hor_line} />
          <div style={hor_line} />
          <div style={hor_line} />
          {productDetails?.on_demand !== 1 &&
          productDetails?.on_demand === null ? (
            <Box
              sx={{
                marginTop: "20px",
                marginBottom: "20px",
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
                flexDirection: "row",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                  alignItems: "center",
                  flexDirection: "row",
                }}
              >
                <img
                  src={process.env.PUBLIC_URL + "/assets/icons/Vector.png"}
                  alt="..."
                  style={{
                    height: "20px",
                    width: "20px",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    marginLeft: "10px",
                    fontFamily: "var(--primary-font)",
                    fontSize: {
                      xs: "11px",
                      sm: "11px",
                      md: "13px",
                      lg: "15px",
                      xl: "15px",
                    },
                  }}
                >
                  OCCASSION SALE | {""}
                  <span
                    style={{
                      color: "var(--theme-color)",
                      marginleft: "10px",
                    }}
                  >
                    Only {productDetails?.qty} stock left
                  </span>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "flex-end",
                  alignItems: "flex-end",
                  flexDirection: "row",
                  padding: "2px 5px 2px 5px",
                  backgroundColor: "var(--yellow)",
                  color: "var(--white)",
                }}
              >
                <Typography
                  variant="p"
                  sx={{
                    fontSize: {
                      xs: "10px",
                      sm: "11px",
                      md: "11px",
                      lg: "14px",
                      xl: "14px",
                    },
                  }}
                >
                  4.3
                </Typography>
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                color: "var(--theme-color)",
                display: "flex",
                justifyContent: "flex-start",
                alignItems: "center",
                flexDirection: "row",
                marginTop: "10px",
                marginBottom: "10px",
              }}
            >
              <TouchAppIcon />
              <Typography
                sx={{
                  marginLeft: "10px",
                  fontFamily: "var(--primary-font)",
                  fontSize: {
                    xs: "11px",
                    sm: "11px",
                    md: "13px",
                    lg: "15px",
                    xl: "15px",
                  },
                  color: "var(--theme-color)",
                  marginTop: "10px",
                }}
              >
                On demand only
              </Typography>
            </Box>
          )}

          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ errors, touched, values }) => (
              <Form>
                <Box>
                  <Box
                    sx={{
                      marginTop: "10px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: {
                          xs: "11px",
                          sm: "11px",
                          md: "13px",
                          lg: "15px",
                          xl: "15px",
                        },
                        color: "var(--light-black)",
                        fontFamily: "var(--primary-font)",
                        textAlign: "left",
                      }}
                    >
                      {productDetails?.description}
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      marginTop: "20px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "flex-start",
                      justifyContent: "flex-start",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: {
                          xs: "14px",
                          sm: "14px",
                          md: "14px",
                          lg: "15px",
                          xl: "15px",
                        },
                        fontFamily: "var(--primary-font)",
                        marginBottom: "20px",
                      }}
                    >
                      Size (In cm):
                    </Typography>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {productDetails?.sizes?.map((item) => (
                        <Button
                          variant="outlined"
                          name="size"
                          key={item.size}
                          className={`${
                            errors.size && touched.size ? "input-error" : ""
                          } ${selectedSize === item.size ? "active-size" : ""}`} // Apply the active-size class to the active button
                          sx={{
                            marginRight: "10px",
                            borderRadius: "50px",
                            border: "1px solid var(--btn-color)",
                            color: "var(--light-black)",
                            "&:hover, &.active-size": {
                              border: "1px solid var(--theme-color)",
                            },
                          }}
                          onClick={() => {
                            setSelectedSize(item.size);
                          }}
                        >
                          {item.size}
                        </Button>
                      ))}
                    </Box>

                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        marginTop: "30px",
                        justifyContent: "flex-start",
                        alignItems: "center",
                      }}
                    >
                      <Typography
                        sx={{
                          fontSize: {
                            xs: "14px",
                            sm: "14px",
                            md: "14px",
                            lg: "15px",
                            xl: "15px",
                          },
                          fontFamily: "var(--primary-font)",
                        }}
                      >
                        Quantity:
                      </Typography>
                      <Box
                        component="form"
                        sx={{
                          "& > :not(style)": { ml: 1, width: "20ch" },
                        }}
                        noValidate
                        autoComplete="off"
                      >
                        <Field
                          as={TextField}
                          id="outlined-basic"
                          // label="Outlined"
                          variant="outlined"
                          name="quantity"
                          className={
                            errors.quantity && touched.quantity
                              ? "input-error"
                              : null
                          }
                          // value={quantity}
                          // onChange={(e) => {
                          //   // Parse the input value to an integer
                          //   // const parsedValue = parseInt(e.target.value);
                          //   setQuantity(e.target.value);
                          // }}
                          min="1"
                          type="number"
                          size="small"
                          sx={{
                            " & .MuiOutlinedInput-root": {
                              "  &.Mui-focused fieldset": {
                                borderColor: "var(--theme-color)",
                              },
                            },
                            "& .MuiOutlinedInput-root": {
                              border: "0px solid var(--white)",
                              borderRadius: "0",
                              padding: "0",
                            },
                            "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                              {
                                border: "1px solid var(--btn-color)",
                              },
                            //border colour when focused
                            "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                              {
                                border: "1px solid var(--theme-color)",
                              },
                            //floating label color when focused
                            "& .MuiInputLabel-outlined.Mui-focused": {
                              color: "var(--theme-color)",
                            },
                          }}
                        />
                        <ErrorMessage
                          name="quantity"
                          component="span"
                          className="error"
                          style={errorStyle}
                        />
                      </Box>
                    </Box>
                    {productDetails?.colors &&
                    productDetails?.colors.length > 0 ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          marginTop: "30px",
                          justifyContent: "flex-start",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          sx={{
                            fontSize: {
                              xs: "14px",
                              sm: "14px",
                              md: "14px",
                              lg: "15px",
                              xl: "15px",
                            },
                            fontFamily: "var(--primary-font)",
                          }}
                        >
                          Colors:
                        </Typography>

                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          {productDetails?.colors?.map((item) => (
                            <Box
                              name="color"
                              key={item.color}
                              className={`${
                                errors.color && touched.color
                                  ? "input-error"
                                  : ""
                              } ${
                                selectedColor === item.color
                                  ? "active-color"
                                  : ""
                              }`} // Apply the active-color class to the active button
                              sx={{
                                width: "30px",
                                display: "inline-block", // To prevent alignment issues
                                height: "30px",
                                borderRadius: "50%",
                                backgroundColor: item.color,
                                position: "relative",
                                marginLeft: "30px",
                                "&:hover, &.active-color": {
                                  "&::before": {
                                    content: '""',
                                    position: "absolute",
                                    top: "-3px", // Adjust the gap here
                                    left: "-3px", // Adjust the gap here
                                    width: "36px", // Adjust the size here
                                    height: "36px", // Adjust the size here
                                    borderRadius: "50%",
                                    border: "2px solid var(--theme-color)",
                                  },
                                },
                              }}
                              onClick={() => {
                                setSelectedColor(item.color);
                              }}
                            />
                          ))}
                        </Box>
                      </Box>
                    ) : (
                      ""
                    )}

                    {productDetails?.on_demand !== 1 &&
                    productDetails?.on_demand === null ? (
                      <>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "40px",
                            justifyContent: "flex-start",
                            alignItems: "center",
                          }}
                        >
                          <Typography
                            variant="span"
                            sx={{
                              display: "flex",
                              justifyContent: "flex-start",
                              color: "var(--theme-color)",
                              alignItems: "center",
                            }}
                          >
                            {productDetails?.discountedprice ? (
                              <>
                                <span
                                  style={{
                                    marginRight: "10px",
                                    fontSize: "20px",
                                  }}
                                >
                                  {selectedCurrency} {""}
                                  {(
                                    productDetails?.discountedprice / rate
                                  ).toFixed(2)}
                                </span>
                                {""}
                                <del
                                  style={{
                                    color: "var(--btn-color)",
                                    fontSize: "14px",
                                  }}
                                  l
                                >
                                  {selectedCurrency}
                                  {(productDetails?.price / rate).toFixed(2)}
                                </del>
                              </>
                            ) : (
                              <span>
                                {" "}
                                {selectedCurrency} {""}
                                {(productDetails?.price / rate).toFixed(2)}
                              </span>
                            )}
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "row",
                            marginTop: "20px",
                          }}
                        >
                          <Button
                            variant="outlined"
                            type="submit"
                            sx={{
                              backgroundColor: "var(--theme-color)",
                              color: "var(--white)",
                              borderRadius: "5px",
                              marginTop: "20px",
                              border: "1px solid var(--theme-color)",
                              fontSize: {
                                xs: "11px",
                                sm: "13px",
                                md: "13px",
                                lg: "15px",
                                xl: "15px",
                              },
                              textTransform: "capitalize",
                              marginRight: "20px",
                              padding: "10px",
                              "&:hover": {
                                backgroundColor: "transparent",
                                color: "var(--theme-color)",
                                border: "1px solid var(--theme-color)",
                              },
                            }}
                            onClick={async () => {
                              const updatedValues = {
                                ...values,
                                product_id:
                                  productDetails?.images[0].product_id,
                                price: (productDetails?.price / rate).toFixed(
                                  2
                                ),
                                color: selectedColor,
                                size: selectedSize,
                                qty: parseInt(values.quantity),
                                currency: selectedCurrency,
                              };

                              try {
                                const response = await axios.post(
                                  process.env.REACT_APP_JINLAP_BASE_URL +
                                    `/products/add-to-cart`,
                                  updatedValues,
                                  {
                                    headers: {
                                      Authorization: `Bearer ${token}`,
                                      Accept: "application/json",
                                      "Content-Type": "application/json",
                                    },
                                  }
                                );
                                if (response.data.status === true) {
                                  toast.success(
                                    `${productDetails.name} added to cart successfully!`
                                  );

                                  // Call handleSubmit only after a successful submission
                                  handleSubmit();
                                }
                              } catch (error) {
                                toast.error(error.response.data.message);
                              }
                              // handleSubmit();
                            }}
                          >
                            <ShoppingBagOutlinedIcon
                              fontSize="medium"
                              sx={{
                                marginRight: "5px",
                              }}
                            />
                            Add to cart
                          </Button>
                          <Button
                            variant="outlined"
                            type="submit"
                            sx={{
                              backgroundColor: "var(--white)",
                              color: "var(--btn-color)",
                              borderRadius: "5px",
                              marginTop: "20px",
                              fontSize: {
                                xs: "11px",
                                sm: "13px",
                                md: "13px",
                                lg: "15px",
                                xl: "15px",
                              },
                              padding: "10px",
                              textTransform: "inherit",
                              marginRight: "20px",
                              border: "1px solid var(--btn-color)",
                              "&:hover": {
                                backgroundColor: "transparent",
                                color: "var(--theme-color)",
                                border: "1px solid var(--theme-color)",
                              },
                            }}
                            onClick={async () => {
                              const updatedValues = {
                                ...values,
                                product_id:
                                  productDetails?.images[0].product_id,
                                qty: 1,
                                price: (productDetails?.price / rate).toFixed(
                                  2
                                ),
                                color: selectedColor,
                                size: selectedSize,
                                currency: selectedCurrency,
                              };

                              try {
                                const response = await axios.post(
                                  process.env.REACT_APP_JINLAP_BASE_URL +
                                    `/products/add-to-wishlist`,
                                  updatedValues,
                                  {
                                    headers: {
                                      Authorization: `Bearer ${token}`,
                                      Accept: "application/json",
                                      "Content-Type": "application/json",
                                    },
                                  }
                                );

                                {
                                  response.data.status === true
                                    ? toast.success(
                                        `${productDetails.name} added to wishlist successfully!`
                                      )
                                    : toast.warning(response.data.message);
                                }
                              } catch (error) {
                                toast.error(error.response.data.message);
                              }
                              handleSubmit(values);
                            }}
                          >
                            <FavoriteBorderOutlinedIcon
                              fontSize="medium"
                              sx={{
                                marginRight: "5px",
                              }}
                            />
                            Add to Wishlist
                          </Button>

                          <Box
                            sx={{
                              display: {
                                xs: "none",
                                sm: "flex",
                                md: "flex",
                                lg: "flex",
                                xl: "flex",
                              },
                            }}
                          >
                            <Button
                              variant="outlined"
                              sx={{
                                backgroundColor: "var( --yellow)",
                                color: "var(--black)",
                                borderRadius: "5px",
                                marginTop: "20px",
                                border: "1px solid var(--yellow)",
                                fontSize: {
                                  xs: "11px",
                                  sm: "13px",
                                  md: "13px",
                                  lg: "15px",
                                  xl: "15px",
                                },
                                textTransform: "inherit",
                                marginRight: "20px",
                                fontWeight: "500",
                                padding: "10px",
                                "&:hover": {
                                  backgroundColor: "transparent",
                                  color: "var(--theme-color)",
                                  border: "1px solid var(--theme-color)",
                                },
                              }}
                              onClick={async () => {
                                const updatedValues = {
                                  ...values,
                                  product_id:
                                    productDetails?.images[0].product_id,
                                  price: (productDetails?.price / rate).toFixed(
                                    2
                                  ),
                                  color: selectedColor,
                                  size: selectedSize,
                                  qty: parseInt(values.quantity),
                                  currency: selectedCurrency,
                                  total_amount:
                                    (productDetails?.price / rate).toFixed(2) *
                                    parseInt(values.quantity),
                                  sub_total:
                                    (productDetails?.price / rate).toFixed(2) *
                                    parseInt(values.quantity),
                                  rate: rate,
                                };

                                try {
                                  const response = await axios.post(
                                    process.env.REACT_APP_JINLAP_BASE_URL +
                                      `/product/buynow`,
                                    updatedValues,
                                    {
                                      headers: {
                                        Authorization: `Bearer ${token}`,
                                        Accept: "application/json",
                                        "Content-Type": "application/json",
                                      },
                                    }
                                  );
                                  {
                                    response.data.status === true
                                      ? toast.success(
                                          `${productDetails.name} buyed successfully!`
                                        )
                                      : toast.warning(response.data.message);
                                  }
                                  navigate("/checkout");
                                } catch (error) {
                                  if (error.response.status === 401) {
                                    toast.error("Unauthorized access");
                                  } else {
                                    toast.error("something went wrong", error);
                                  }
                                }
                                handleSubmit(values);
                              }}
                            >
                              <SellIcon
                                fontSize="medium"
                                sx={{
                                  marginRight: "5px",
                                  color: "var(--theme-color)",
                                }}
                              />
                              Buy now
                            </Button>
                          </Box>
                        </Box>
                        <Box
                          sx={{
                            display: {
                              xs: "flex",
                              sm: "none",
                              md: "none",
                              lg: "none",
                              xl: "none",
                            },
                          }}
                        >
                          <Button
                            variant="outlined"
                            sx={{
                              backgroundColor: "var( --yellow)",
                              color: "var(--black)",
                              borderRadius: "5px",
                              marginTop: "20px",
                              border: "1px solid var(--yellow)",
                              fontSize: {
                                xs: "11px",
                                sm: "13px",
                                md: "13px",
                                lg: "15px",
                                xl: "15px",
                              },
                              marginRight: "20px",
                              fontWeight: "500",
                              padding: "10px",
                              textTransform: "inherit",
                              "&:hover": {
                                backgroundColor: "transparent",
                                color: "var(--theme-color)",
                                border: "1px solid var(--theme-color)",
                              },
                            }}
                            onClick={async () => {
                              const updatedValues = {
                                ...values,
                                product_id:
                                  productDetails?.images[0].product_id,
                                price: (productDetails?.price / rate).toFixed(
                                  2
                                ),
                                color: selectedColor,
                                size: selectedSize,
                                qty: parseInt(values.quantity),
                                currency: selectedCurrency,
                                total_amount:
                                  (productDetails?.price / rate).toFixed(2) *
                                  parseInt(values.quantity),
                                sub_total:
                                  (productDetails?.price / rate).toFixed(2) *
                                  parseInt(values.quantity),
                                rate: rate,
                              };

                              try {
                                const response = await axios.post(
                                  process.env.REACT_APP_JINLAP_BASE_URL +
                                    `/product/buynow`,
                                  updatedValues,
                                  {
                                    headers: {
                                      Authorization: `Bearer ${token}`,
                                      Accept: "application/json",
                                      "Content-Type": "application/json",
                                    },
                                  }
                                );
                                {
                                  response.data.status === true
                                    ? toast.success(
                                        `${productDetails.name} buyed successfully!`
                                      )
                                    : toast.warning(response.data.message);
                                }
                                navigate("/checkout");
                              } catch (error) {
                                toast.error(error.response.data.message);
                              }
                              handleSubmit(values);
                            }}
                          >
                            <SellIcon
                              fontSize="medium"
                              sx={{
                                marginRight: "5px",
                                color: "var(--theme-color)",
                              }}
                            />
                            Buy now
                          </Button>
                        </Box>
                      </>
                    ) : (
                      <Box
                        sx={{
                          width: "100%",
                        }}
                      >
                        <Box
                          sx={{
                            color: "var(--theme-color)",
                            display: "flex",
                            justifyContent: "flex-start",
                            alignItems: "center",
                            flexDirection: "row",
                            marginTop: "10px",
                            backgroundColor: "#F8F8F8",
                          }}
                        >
                          <TouchAppIcon />
                          <Typography
                            sx={{
                              marginLeft: "10px",
                              fontFamily: "var(--primary-font)",
                              fontSize: {
                                xs: "11px",
                                sm: "12px",
                                md: "13px",
                                lg: "14px",
                                xl: "14px",
                              },
                              color: "var(--theme-color)",
                              marginTop: "10px",
                              textAlign: "left",
                            }}
                          >
                            You will receive your order within 1 month. We will
                            keep you updated on the product through email.
                          </Typography>
                        </Box>
                        <Box
                          sx={{
                            width: "100%",
                            marginTop: "20px",
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "flex-start",
                            alignItems: "flex-start",
                          }}
                        >
                          <Field
                            as={TextField}
                            id="email"
                            type="email"
                            name="email"
                            label="Enter your Email Address"
                            size="small"
                            // value={email}
                            // onChange={(e) => setEmail(e.target.value)}
                            className={
                              touched.email && errors.email
                                ? "input-error"
                                : null
                            }
                            sx={{
                              width: "100%",
                              backgroundColor: "transparent",
                              border: "0",
                              " & .MuiOutlinedInput-root": {
                                "  &.Mui-focused fieldset": {
                                  borderColor: "var(--theme-color)",
                                },
                              },
                              "& .MuiOutlinedInput-root": {
                                border: "0px solid #E4E4E4",
                                borderRadius: "0",
                                padding: "0",
                              },
                              "& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "1px solid #E4E4E4",
                                },
                              //border colour when focused
                              "&:hover .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline":
                                {
                                  border: "1px solid var(--theme-color)",
                                },
                              //floating label color when focused
                              "& .MuiInputLabel-outlined.Mui-focused": {
                                color: "var(--theme-color)",
                              },
                            }}
                            inputProps={{
                              style: {
                                fontSize: {
                                  xs: "11px",
                                  sm: "11px",
                                  md: "18px",
                                  lg: "20px",
                                  xl: "20px",
                                },
                              },
                            }}
                          />
                          <ErrorMessage
                            name="email"
                            component="span"
                            className="error"
                            style={errorStyle}
                          />

                          <Button
                            variant="outlined"
                            type="submit"
                            sx={{
                              backgroundColor: "var(--theme-color)",
                              color: "var(--white)",
                              borderRadius: "5px",
                              marginTop: "10px",
                              border: "1px solid var(--theme-color)",
                              textTransform: "inherit",
                              fontSize: {
                                xs: "11px",
                                sm: "13px",
                                md: "13px",
                                lg: "15px",
                                xl: "15px",
                              },
                              padding: "10px",
                              "&:hover": {
                                backgroundColor: "transparent",
                                color: "var(--theme-color)",
                                border: "1px solid var(--theme-color)",
                              },
                            }}
                            onClick={async () => {
                              const updatedValues = {
                                ...values,
                                product_id:
                                  productDetails?.images[0].product_id,
                                price: (productDetails?.price / rate).toFixed(
                                  2
                                ),
                                color: selectedColor,
                                size: selectedSize,
                                qty: parseInt(values.quantity),
                                currency: selectedCurrency,
                              };

                              try {
                                const response = await axios.post(
                                  process.env.REACT_APP_JINLAP_BASE_URL +
                                    `/products/add-to-cart`,
                                  updatedValues,
                                  {
                                    headers: {
                                      Authorization: `Bearer ${token}`,
                                      Accept: "application/json",
                                      "Content-Type": "application/json",
                                    },
                                  }
                                );
                                {
                                  response.data.status === true
                                    ? toast.success(
                                        `${productDetails.name} added to cart successfully!`
                                      )
                                    : toast.warning(response.data.message);
                                }
                              } catch (error) {
                                toast.error(error.response.data.message);
                              }
                              handleSubmit(values);
                            }}
                          >
                            <ShoppingBagOutlinedIcon
                              fontSize="medium"
                              sx={{
                                marginRight: "5px",
                              }}
                            />
                            Add to Cart
                          </Button>
                        </Box>
                      </Box>
                    )}
                  </Box>
                </Box>
              </Form>
            )}
          </Formik>

          <>
            <Grid
              container
              xs={12}
              sx={{
                marginBottom: "20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: "40px",
              }}
            >
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    fontSize: {
                      xs: "11px",
                      sm: "11px",
                      md: "13px",
                      lg: "15px",
                      xl: "15px",
                    },
                    fontWeight: "bold",
                    color: "var(--theme-color)",
                  }}
                >
                  Highlights
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Box>
                  <div style={hor_line} />
                  <div style={hor_line} />
                  <div style={hor_line} />
                </Box>
              </Grid>
            </Grid>
            <Box>
              <Grid
                container
                xs={12}
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  justifyContent: "flex-start",
                }}
              >
                {/* {productDetails.highlights && ( */}
                <Grid item xs={6}>
                  <ul
                    style={{
                      listStyleType: "none",
                    }}
                  >
                    <li>
                      <Typography
                        variant="body1"
                        sx={{
                          fontFamily: "var(--primary-font)",
                          fontSize: {
                            xs: "11px",
                            sm: "11px",
                            md: "13px",
                            lg: "15px",
                            xl: "15px",
                          },
                          color: "var(--black-color)",
                          textAlign: "left",
                        }}
                        dangerouslySetInnerHTML={{
                          __html: productDetails?.highlights,
                        }}
                      ></Typography>
                    </li>
                  </ul>
                </Grid>
                {/* )} */}
              </Grid>
            </Box>
          </>
          <>
            <Grid
              container
              xs={12}
              sx={{
                marginBottom: "20px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "flex-start",
                marginTop: "40px",
              }}
            >
              <Grid
                item
                xs={2}
                sx={{
                  display: "flex",
                  justifyContent: "flex-start",
                }}
              >
                <Typography
                  variant="body1"
                  sx={{
                    fontFamily: "var(--primary-font)",
                    fontSize: {
                      xs: "11px",
                      sm: "11px",
                      md: "13px",
                      lg: "15px",
                      xl: "15px",
                    },
                    fontWeight: "bold",
                    color: "var(--theme-color)",
                  }}
                >
                  Description
                </Typography>
              </Grid>
              <Grid item xs={10}>
                <Box>
                  <div style={hor_line} />
                  <div style={hor_line} />
                  <div style={hor_line} />
                </Box>
              </Grid>
            </Grid>
            <Box sx={{ position: "relative" }}>
              <Typography
                variant="body1"
                sx={{
                  fontFamily: "var(--primary-font)",
                  fontSize: {
                    xs: "11px",
                    sm: "11px",
                    md: "13px",
                    lg: "15px",
                    xl: "15px",
                  },
                  color: "var(--black-color)",
                  textAlign: "left",
                }}
              >
                {productDetails?.description}
              </Typography>
              {!packaged ? (
                <Box
                  sx={{
                    position: "absolute",
                    bottom: "0",
                    left: "0",
                    width: "100%",
                    height: "100%",
                    background:
                      "linear-gradient(to bottom, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 1) 100%)",
                  }}
                >
                  <Typography
                    variant="body1"
                    sx={{
                      color: "var(--theme-color)",
                      position: "absolute",
                      bottom: "-15px",
                      fontSize: {
                        xs: "10px",
                        sm: "11px",
                        md: "12px",
                        lg: "13px",
                        xl: "14px",
                      },
                      fontWeight: "600",
                    }}
                  >
                    ** Detail description along with cultural significance, more
                    with package only
                  </Typography>
                </Box>
              ) : (
                ""
              )}
            </Box>
          </>
        </Box>
      )}
    </>
  );
};
