import React from "react";
import { Box } from "@mui/system";
import { Button, Typography } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import thankyouIcon from "../assets/thankyouicon.png";

const horizontal_line = {
  width: "100%",
  height: "1px",
  backgroundColor: "var(--title-backgrd-color)",
};
export const ThankyouforReview = ({ handleClosereview }) => {
  return (
    <Box>
      <Box
        sx={{
          height: "50px",
        }}
      >
        <Button
          sx={{
            position: "absolute",
            top: "10px",
            right: "10px",
            color: "var(--black-color)",
            "&:hover": {
              backgroundColor: "transparent",
              color: "var(--theme-color)",
            },
          }}
          onClick={handleClosereview}
        >
          <CloseIcon />
        </Button>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Center horizontally
        }}
      >
        <div style={horizontal_line}></div>
        <div style={horizontal_line}></div>
        <div style={horizontal_line}></div>

        <img
          src={thankyouIcon}
          alt="..."
          style={{
            width: "80px",
            zIndex: "1",
            // bottom: "60px",
            top: "10px",
            position: "absolute",
            backgroundColor: "var(--white)",
          }}
        />
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center", // Center horizontally
        }}
      >
        <Typography
          variant="h6"
          sx={{
            textAlign: "center",
            fontSize: {
              xs: "14px",
              sm: "16px",
              md: "16px",
              lg: "18px",
              xl: "28px",
            },
            fontWeight: "500",
            color: "var(--theme-color)",
            fontFamily: "var(--primary-font)",
            marginTop: "30px",
            marginBottom: "20px",
          }}
        >
          Thank you for your review
        </Typography>
        <Typography
          variant="body1"
          sx={{
            textAlign: "center",
            width: "80%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "0 auto",
            fontSize: {
              xs: "13px",
              sm: "14px",
              md: "15px",
              lg: "16px",
              xl: "16px",
            },
            fontWeight: "400",
            color: "var(--black-color)",
            fontFamily: "var(--primary-font)",
            marginBottom: "50px",
          }}
        >
          Thank you for your valuable review. We wanted to express our gratitude
          for taking the time to review Your feedback and insights were
          incredibly valuable
        </Typography>
        <Box
          sx={{
            width: "15vw",
            height: "auto",
          }}
        >
          <img
            src={process.env.PUBLIC_URL + "/assets/logo.png"}
            alt="..."
            style={{
              width: "100%",
              height: "100%",
              objectFit: "cover",
              objectPosition: "center",
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};
