import React from "react";
import { Box } from "@mui/system";
import { Container } from "@mui/material";
import { PrivacyPolicyContents } from "../../components/PrivacyPolicyComp/PrivacyPolicyContents";
import { TopSection } from "../../components/Shared/TopSection/TopSection";
import { privacypolicyTopContent } from "../../Data/PrivacyPolicyData/PrivacyPolicyData";
import { Subscribe } from "../../components/Shared/Subscribe/Subscribe";
import useAPI from "../../hooks/useApi";
import { endpoint } from "../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const PrivacyPolicyPage = () => {
  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.privacypolicy);
    return res.data;
  };

  const { data: privacypolicies, isLoading } = useQuery({
    queryKey: ["privacypolicies"],
    queryFn: getData,
  });

  if (isLoading) {
    return <div>Loading...</div>;
  }
  return (
    <Box>
      {privacypolicyTopContent && (
        <Box>
          <TopSection
            image={privacypolicies?.image}
            title={privacypolicies?.top_title}
            description={`Last Updated : ${privacypolicies?.updated_date}`}
          />
        </Box>
      )}
      <Container>
        <PrivacyPolicyContents />
        <Subscribe />
      </Container>
    </Box>
  );
};
