import React from "react";
import { Typography, Container, Grid, Button } from "@mui/material";
import { Box } from "@mui/system";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import "../ProjectsComponent/Runningprojects/RunningProjects.css";
import { NavLink } from "react-router-dom";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { useState } from "react";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import useAPI from "../../hooks/useApi";
import { endpoint } from "../../constant/endpoint";

export const SupportArtistsContent = () => {
  const [displayedItems, setDisplayedItems] = useState(3);

  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.artists);
    return res.data;
  };

  const { isDataLoading, data: supportartistsData } = useQuery({
    queryKey: ["supportartistsData"],
    queryFn: getData,
  });

  const handleShowMore = () => {
    setDisplayedItems(supportartistsData?.length);
  };
  const handleShowLess = () => {
    setDisplayedItems(3);
  };

  const { selectedCurrency, rate } = useSelector((state) => state.currency);

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        marginBottom: "100px",
      }}
    >
      <Grid container spacing={3}>
        {supportartistsData?.slice(0, displayedItems).map((item, index) => (
          <Grid item xs={12} sm={12} md={6} lg={4} xl={4} key={index}>
            <Box sx={{ width: "100%" }}>
              <Box
                sx={{
                  width: "100%",
                  height: "47vh",
                }}
              >
                <img
                  src={item.images[0].artistImages}
                  alt="..."
                  style={{
                    height: "100%",
                    width: "100%",
                    objectFit: "cover",
                    objectPosition: "center",
                  }}
                />
                <Box
                  sx={{
                    position: "relative",
                    width: "10%",
                    height: "auto",
                    backgroundColor: "var(--theme-color)",
                    padding: "25px",
                    borderRadius: "50%",
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    bottom: "30px",
                    left: "78%",
                    border: "5px solid var(--white)",
                  }}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "./assets/supportartists/support-icon.png"
                    }
                    alt=""
                    style={{
                      width: "80%",
                      height: "auto",
                      zIndex: "1",
                      position: "absolute",
                    }}
                  />
                </Box>
              </Box>

              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  margin: "10px 0px 10px 0px",
                  justifyContent: "flex-start",
                  alignItems: "center",
                }}
              >
                <LocationOnOutlinedIcon
                  fontSize="medium"
                  sx={{
                    color: "var(--theme-color)",
                  }}
                />
                <Typography
                  variant="body1"
                  sx={{
                    color: "var( --light-black)",
                    fontSize: {
                      xs: "11px",
                      sm: "12px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                    fontFamily: "var(--primary-font)",
                    marginLeft: "10px",
                  }}
                >
                  {item.address}
                </Typography>
              </Box>

              {/* end of date and time */}

              {/* start od lines */}
              <Box
                sx={{
                  marginTop: "10px",
                  marginBottom: "20px",
                  width: "100%",
                }}
              >
                <Box className="horizontal-line"></Box>
                <Box className="horizontal-line"></Box>
                <Box className="horizontal-line"></Box>
              </Box>

              {/* end of lines */}

              {/* start of data */}
              <Box>
                <Typography
                  variant="h3"
                  sx={{
                    width: "100%",
                    textAlign: "justify",
                    marginTop: "5px",
                    marginBottom: "20px",
                    fontSize: {
                      xs: "17px",
                      sm: "18px",
                      md: "20px",
                      lg: "23px",
                      xl: "20px",
                    },
                    fontFamily: "var(--primary-font)",
                    color: "var(--black)",
                  }}
                >
                  {item.title}
                </Typography>
                <Typography
                  variant="body1"
                  sx={{
                    width: "100%",
                    textAlign: "justify",
                    marginTop: "5px",
                    marginBottom: "20px",
                    fontSize: {
                      xs: "11px",
                      sm: "12px",
                      md: "13px",
                      lg: "14px",
                      xl: "14px",
                    },
                    fontFamily: "var(--primary-font)",
                    color: "var( --light-black)",
                    overflow: "hidden", // Hide any overflowing text
                    textOverflow: "ellipsis", // Display ellipsis for long texts
                    display: "-webkit-box",
                    WebkitLineClamp: 3, // Adjust the number of lines to fit your requirements
                    WebkitBoxOrient: "vertical",
                  }}
                  dangerouslySetInnerHTML={{
                    __html: item.description,
                  }}
                ></Typography>
              </Box>
              <Box
                className="progress-section"
                data-aos="fade-left"
                data-aos-once="true"
              >
                <Box className="task-progress">
                  <Typography
                    variant="body1"
                    sx={{
                      fontSize: {
                        xs: "10px",
                        sm: "10px",
                        md: "11px",
                        lg: "12px",
                        xl: "12px",
                      },
                      fontFamily: "var(--primary-font)",
                      color: "var( --light-black)",
                      margin: "10px 0px 10px 0px",
                    }}
                  >
                    Last donation 22 hr ago
                  </Typography>
                  <progress
                    Box
                    className="progress progress1"
                    max={(item.goal_amount / rate).toFixed(2)}
                    value={
                      item.raised_amount ? item.raised_amount.toFixed(2) : 0
                    }
                  ></progress>
                  <Typography
                    variant="p"
                    sx={{
                      fontSize: {
                        xs: "10px",
                        sm: "10px",
                        md: "11px",
                        lg: "12px",
                        xl: "13px",
                      },
                      fontFamily: "var(--primary-font)",
                      color: "var( --light-black)",
                      margin: "10px 0px 10px 0px",
                      fontWeight: "bold",
                      textAlign: "left",
                      float: "left",
                    }}
                  >
                    <span style={{ color: "var(--theme-color" }}>
                      {selectedCurrency} {""}
                      {item.raised_amount
                        ? (item.raised_amount / rate).toFixed(2)
                        : 0}
                      {""} raised {""}
                    </span>
                    of {(item.goal_amount / rate).toFixed(2)} goal
                  </Typography>
                  <br />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "flex-start", // Align NavLink to the left
                    alignItems: "flex-start",
                    width: "100%",
                  }}
                >
                  <NavLink
                    to={`/supportartists/${item.id}`}
                    style={{ color: "var(--theme-color)" }}
                  >
                    Read More
                  </NavLink>
                </Box>
              </Box>

              {/* end of data */}
            </Box>
          </Grid>
        ))}
      </Grid>
      {/* </Box> */}
      {displayedItems < supportartistsData?.length && (
        <Button
          variant="outlined"
          sx={{
            color: "var(--theme-color)",
            border: "1px solid var(--theme-color)",
            textTransform: "capitalize",
            width: {
              xs: "100%",
              sm: "100%",
              md: "15%",
              lg: "15%",
              xl: "15%",
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "50px",
            marginBottom: "50px",
            fontSize: {
              xs: "11px",
              sm: "11px",
              md: "13px",
              lg: "15px",
              xl: "15px",
            },
            "&:hover": {
              backgroundColor: "var(--theme-color)",
              color: "white",
              border: "1px solid var(--theme-color)",
              transform: "scale(1.1)",
            },
          }}
          onClick={handleShowMore}
        >
          Show More
          <KeyboardArrowDownIcon />
        </Button>
      )}
      {displayedItems > 3 && (
        <Button
          variant="outlined"
          sx={{
            color: "var(--theme-color)",
            border: "1px solid var(--theme-color)",
            textTransform: "capitalize",
            width: {
              xs: "100%",
              sm: "100%",
              md: "15%",
              lg: "15%",
              xl: "15%",
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            margin: "auto",
            marginTop: "50px",
            marginBottom: "50px",
            fontSize: {
              xs: "11px",
              sm: "11px",
              md: "13px",
              lg: "15px",
              xl: "15px",
            },
            "&:hover": {
              backgroundColor: "var(--theme-color)",
              color: "var(--white)",
              border: "1px solid var(--theme-color)",
              transform: "scale(1.1)",
            },
          }}
          onClick={handleShowLess}
        >
          Show Less
          <KeyboardArrowUpIcon />
        </Button>
      )}
    </Box>
  );
};
