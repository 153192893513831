import React, { useEffect } from "react";
import MailOutlineIcon from "@mui/icons-material/MailOutline";
import PersonIcon from "@mui/icons-material/Person";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import { FaFacebookF, FaTiktok, FaYoutube } from "react-icons/fa";
import { ImWhatsapp } from "react-icons/im";
import MenuItem from "@material-ui/core/MenuItem";
import FormControl from "@material-ui/core/FormControl";
import Select from "@material-ui/core/Select";
import { makeStyles } from "@material-ui/core/styles";
import Modal from "@mui/material/Modal";
import "./Topbar.css";
import { LogInRegTab } from "../../LogInRegistrationComponents/LogInRegTab";
import CloseIcon from "@mui/icons-material/Close";
import { Button } from "@mui/material";
import { Profilepopup } from "../../ProfilePopup/Profilepopup";
import { CurrencyData } from "../../../ApiIntegration/Shared/CurrencyData";
import { useDispatch } from "react-redux";
import { FaXTwitter } from "react-icons/fa6";
import {
  setSelectedCurrency,
  getSelectedCurrencyRate,
} from "../../../feature/CurrencySlice";
import { Cookies } from "react-cookie";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

const iconBoxStyle = {
  padding: "10px",
};

const useStyles = makeStyles((theme) => ({
  button: {
    display: "block",
    // marginTop: theme.spacing(2),
  },
  formControl: {
    // margin: theme.spacing(5),
    minWidth: 120,
    fontFamily: "var(--primary-font)",
    backgroundColor: "transparent",
    "& .MuiSelect-icon": {
      color: "white",
    },
    "& .MuiSelect-root": {
      color: "white",
    },
    "& .MuiInput-underline:before": {
      borderBottomColor: "transparent",
    },
    "& .MuiInput-underline:hover:not(.Mui-disabled):before": {
      borderBottomColor: "transparent",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "transparent",
    },
  },
  select: {
    textAlign: "center",
    textDecoration: "none",
    color: "var(--white)",
    fontFamily: "var(--primary-font)",
    marginTop: "10px",
  },
}));

export const Topbar = () => {
  const classes = useStyles();
  const [currencies, setCurrencies] = React.useState([]);
  const cookies = new Cookies();

  const username = cookies.get("user");

  useEffect(() => {
    const fetchCurrenciesDataAsync = async () => {
      try {
        const apiData = await CurrencyData();
        setCurrencies(apiData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchCurrenciesDataAsync();
  }, []);

  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.sitesettings);
    return res.data;
  };

  const { data: siteSettings } = useQuery({
    queryKey: ["sitesettingsData"],
    queryFn: getData,
  });

  const [country, setCountry] = React.useState("NRS");
  const [open, setOpen] = React.useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [isShow, setShow] = React.useState(false);
  const dispatch = useDispatch();

  const handleChange = (event) => {
    const selectedCurrency = event.target.value;
    setCountry(selectedCurrency); // Update the local state as well
    dispatch(setSelectedCurrency(selectedCurrency));

    const Currency = currencies.filter(
      (item) => item.symbol === selectedCurrency
    );

    const Currencyrate = Currency[0].rate;
    dispatch(getSelectedCurrencyRate(Currencyrate));
  };

  const handleShowClick = () => {
    setShow(!isShow);
  };

  return (
    <>
      {siteSettings && (
        <Box
          className="topbar-container"
          sx={{
            // backgroundColor: "#96195a",
            width: {
              xs: "100%",
              sm: "100%",
              md: "70%",
              lg: "80%",
              xl: "80%",
            },
            height: "30px",
            display: "flex",
            justifyContent: "space-between",
            fontWeight: "500",
          }}
        >
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "flex",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
            }}
          >
            <ul
              style={{
                listStyle: "none",
                margin: "0",
                padding: "0",
                display: "flex",
              }}
              className="socialmediaicon"
            >
              <li className="socialmediaiconlists">
                <a
                  href={`${siteSettings.facebook}`}
                  className="mediaiconstag"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaFacebookF
                    style={iconBoxStyle}
                    size="40px"
                    className="icon"
                  />
                </a>
              </li>
              <li className="socialmediaiconlists">
                <a
                  href={`${siteSettings.twitter}`}
                  className="mediaiconstag"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaXTwitter
                    style={iconBoxStyle}
                    size="40px"
                    className="icon"
                  />
                </a>
              </li>
              <li className="socialmediaiconlists">
                <a
                  href="https://api.whatsapp.com/send?phone=9865601077"
                  className="mediaiconstag"
                  target="_blank"
                  rel="noreferrer"
                >
                  <ImWhatsapp
                    style={iconBoxStyle}
                    size="40px"
                    className="icon"
                  />
                </a>
              </li>
              <li className="socialmediaiconlists">
                <a
                  href={`${siteSettings.youtube}`}
                  className="mediaiconstag"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaYoutube
                    style={iconBoxStyle}
                    size="40px"
                    className="icon"
                  />
                </a>
              </li>
              <li className="socialmediaiconlists">
                <a
                  href={`${siteSettings.tiktok}`}
                  className="mediaiconstag"
                  target="_blank"
                  rel="noreferrer"
                >
                  <FaTiktok
                    style={iconBoxStyle}
                    size="40px"
                    className="icon special"
                  />
                </a>
              </li>
            </ul>
          </Box>

          <Box
            sx={{
              color: "var(--white)",
              padding: "20px",
              backgroundColor: "var(--theme-color)",
              alignItems: "center",
              display: {
                xs: "none",
                sm: "none",
                md: "none",
                lg: "flex",
                xl: "flex",
              },
              aligntext: "center",
              //   width: "600px",
              width: {
                xs: "16%",
                sm: "16%",
                md: "40%",
                lg: "32%",
                xl: "36.5%",
              },
              justifyContent: "flex-end",
              fontSize: {
                xs: "8px",
                sm: "8px",
                md: "15px",
                lg: "15px",
                xl: "15px",
              },
            }}
            className="email-icon"
          >
            <Box sx={{ marginRight: "5px" }}>
              <MailOutlineIcon fontSize="medium" />
            </Box>

            <Typography
              sx={{
                fontSize: {
                  xs: "10px",
                  sm: "12px",
                  md: "15px",
                  lg: "17px",
                  xl: "17px",
                },
              }}
            >
              <a
                href={`mailto:${siteSettings.email}`}
                target="_blank"
                rel="noreferrer"
                style={{
                  textDecoration: "none",
                  color: "var(--white)",
                  fontFamily: "var(--primary-font)",
                }}
              >
                {siteSettings.email}
              </a>
            </Typography>
          </Box>
          <Box
            sx={{
              color: "var(--white)",
              padding: "20px",
              backgroundColor: "var(--theme-color)",
              alignItems: "center",
              display: {
                xs: "flex",
                sm: "flex",
                md: "flex",
                lg: "flex",
                xl: "flex",
              },
              aligntext: "center",
              //   width: "235px",
              width: {
                xs: "50%",
                sm: "31%",
                md: "44.5%",
                lg: "25%",
                xl: "25%",
              },
              justifyContent: "flex-start",
              fontSize: {
                xs: "10px",
                sm: "12px",
                md: "15px",
                lg: "17px",
                xl: "17px",
              },
            }}
          >
            <Typography
              variant="span"
              sx={{
                fontSize: {
                  xs: "15px",
                  sm: "15px",
                  md: "15px",
                  lg: "17px",
                  xl: "17px",
                },
              }}
            >
              Currency:{""}
            </Typography>

            <FormControl className={classes.formControl}>
              <Select
                // open={open}
                // onClose={handleClose}
                // onOpen={handleOpen}
                value={country}
                name="country"
                onChange={handleChange}
              >
                {currencies.map((option, key) => (
                  <MenuItem
                    value={option.symbol}
                    key={key}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <img
                      src={option.image}
                      alt={option.name}
                      style={{
                        height: "20px",
                        width: "20px",
                      }}
                    />{" "}
                    <span
                      style={{
                        marginLeft: "5px",
                        fontFamily: "var(--primary-font)",
                      }}
                    >
                      {option.symbol}
                    </span>
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box
            sx={{
              color: "var(--white)",
              padding: "20px",
              backgroundColor: "var(--theme-color)",
              textDecoration: "none",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              aligntext: "center",
              //   width: "200px",
              width: {
                xs: "49%",
                sm: "45%",
                md: "31%",
                lg: "22%",
                xl: "21%",
              },
              justifyContent: "flex-start",
              cursor: "pointer",
            }}
            className="person-icon"
          >
            <Box>
              <PersonIcon fontSize="medium" />
            </Box>
            {username ? (
              <Typography
                sx={{
                  fontSize: {
                    xs: "15px",
                    sm: "15px",
                    md: "15px",
                    lg: "17px",
                    xl: "17px",
                  },
                  marginTop: {
                    xs: "2px",
                    sm: "2px",
                    md: "2px",
                    lg: "3px",
                    xl: "5px",
                  },
                  textTransform: "capitalize",
                }}
                onClick={handleShowClick}
              >
                {username}
              </Typography>
            ) : (
              <Typography
                sx={{
                  fontSize: {
                    xs: "15px",
                    sm: "15px",
                    md: "15px",
                    lg: "17px",
                    xl: "17px",
                  },
                  marginTop: {
                    xs: "2px",
                    sm: "2px",
                    md: "2px",
                    lg: "3px",
                    xl: "5px",
                  },
                }}
                onClick={handleOpen}
              >
                Login/Signup
              </Typography>
            )}
          </Box>

          {isShow && <Profilepopup handlClickClose={handleShowClick} />}
          <Modal
            open={open}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                left: "50%",
                transform: "translate(-50%, -50%)",
                width: {
                  xs: "95%",
                  sm: "80%",
                  md: "80%",
                  lg: "60%",
                  xl: "60%",
                },
                bgcolor: "white",
                borderRadius: "10px",
              }}
            >
              <Box
                sx={{
                  position: "absolute",
                  top: "10px",
                  right: "10px",
                }}
              >
                <Button
                  sx={{
                    color: "var(--black)",
                    backgroundColor: "transparent",
                    "&:hover": {
                      color: "var(--theme-color)",
                      backgroundColor: "transparent",
                    },
                  }}
                  onClick={handleClose}
                >
                  <CloseIcon />
                </Button>
              </Box>
              <LogInRegTab handleClose={handleClose} />
            </Box>
          </Modal>
        </Box>
      )}
    </>
  );
};
