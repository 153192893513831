import "./App.css";
import React, { useEffect, useState } from "react";
import { Route, Routes } from "react-router-dom";
import { Landpage } from "./pages/Landpage/Landpage";
import { Footer } from "./components/Shared/Footer/Footer";
import { AppBar } from "./components/Shared/AppBar/AppBar";
import { DharmaGoodPage } from "./pages/DharmaGoodPage/DharmaGoodPage";
import { GoodDharmaPage } from "./pages/GoodDharmaPage/GoodDharmaPage";
import { IndividualCategorypage } from "./pages/IndividualCategorypage/IndividualCategorypage";
import { AboutPage } from "./pages/AboutPage/AboutPage";
import { ProjectPage } from "./pages/ProjectPage/ProjectPage";
import { RunningProjectDetail } from "./pages/RunningProjectDetailPage/RunningProjectDetail";
import { SupportArtistPage } from "./pages/SupportArtistPage/SupportArtistPage";
import { SupportArtistsDetailPage } from "./pages/SupportArtistsDetailPage/SupportArtistsDetailPage";
import { PastIniatives } from "./pages/PastInitativesPage/PastIniatives";
import { PastInitiativesDetailPage } from "./pages/PastInitiativesDetailPage/PastInitiativesDetailPage";
import { GoodDharmaDetailsPage } from "./pages/GoodDharmaDetailsPage/GoodDharmaDetailsPage";
import { ProductDetails } from "./pages/ProductDetails/ProductDetails";
import { DonationPage } from "./pages/DonationPage/DonationPage";
import { TermsConditionPage } from "./pages/Terms&ConditionPage/TermsConditionPage";
import { PrivacyPolicyPage } from "./pages/PrivacyPolicyPage/PrivacyPolicyPage";
import { toast } from "react-toastify";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Invoice } from "./components/Invoice/Invoice";
import { LandingSearchPage } from "./pages/LandingSearchPage/LandingSearchPage";
import routes from "./RestrictedRoutes";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";
import Stripe from "./Payment/Stripe";
import { Completion } from "./Payment/Completion";
import { ProjectDonation } from "./pages/ProjectDonation/ProjectDonation";
import { ThankyouforDonation } from "./components/DonationPageComp/ThankyouforDonation";
import { ThankyouforOrder } from "./components/CheckoutPageComponents/ThankyouforOrder";

function App() {
  const [userRole, setUserRole] = useState(null);
  const navigate = useNavigate();
  const cookies = new Cookies();

  useEffect(() => {
    // Load user role from cookies or your authentication system
    const role = cookies.get("role");
    setUserRole(role);
  }, []);

  // Function to navigate to protected routes
  const navigateToProtectedRoute = (path) => {
    if (userRole) {
      navigate(path);
    } else {
      // Redirect to login or any other appropriate page
      navigate("/"); // You can update this to your login page
      toast.error("Unauthorized !!, Please Login First");
    }
  };

  return (
    <div className="App">
      <ToastContainer />
      <AppBar />
      <Routes>
        <Route path="/" element={<Landpage />} />
        <Route path="/search-results" element={<LandingSearchPage />} />
        <Route path="/about-us" element={<AboutPage />} />
        <Route path="/dharma-goods" element={<DharmaGoodPage />} />
        <Route path="/good-dharma" element={<GoodDharmaPage />} />
        <Route path="/goods/:id" element={<IndividualCategorypage />} />
        <Route path="/projects" element={<ProjectPage />} />
        <Route path="/project-donation/:id" element={<ProjectDonation />} />
        <Route path="/runningprojects/:id" element={<RunningProjectDetail />} />
        <Route path="/support-artist" element={<SupportArtistPage />} />
        <Route
          path="/supportartists/:id"
          element={<SupportArtistsDetailPage />}
        />
        <Route path="/past-initatives" element={<PastIniatives />} />
        <Route
          path="/pastinitatives/:id"
          element={<PastInitiativesDetailPage />}
        />
        <Route path="/gooddharma/:id" element={<GoodDharmaDetailsPage />} />
        <Route path="/productdetails/:id" element={<ProductDetails />} />
        <Route path="/artist-donation/:id" element={<DonationPage />} />
        <Route path="/terms&conditions" element={<TermsConditionPage />} />
        <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
        <Route path="/invoice" element={<Invoice />} />
        <Route path="/stripe" element={<Stripe />} />
        <Route path="/completion" element={<Completion />} />
        <Route path="/thankyoufordonation" element={<ThankyouforDonation />} />
        <Route path="/thankyoufororder" element={<ThankyouforOrder />} />
        {routes.map((route, index) => (
          <Route
            key={index}
            path={route.path}
            element={
              <route.component
                navigateToProtectedRoute={navigateToProtectedRoute}
              />
            }
          />
        ))}

        {/* Redirect to a default route if the user doesn't have access */}
        <Route path="*" element={<Landpage />} />
      </Routes>

      <Footer />
      {/* </Elements>
      )} */}
    </div>
  );
}

export default App;
