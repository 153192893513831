import React from "react";
import { Typography, Container, Grid } from "@mui/material";
import { Box } from "@mui/system";
import "./Focusedhappiness.css";

export const FocusedHappinessBottom = ({
  mission_image,
  mission_description,
}) => {
  //dash line
  const DashHorizontalline = {
    width: "100%",
    height: "0px",
    border: "1px dashed var(--theme-color)",
    opacity: "0.5",
    marginTop: "20px",
    marginBottom: "20px",
  };
  return (
    <Container
      sx={{
        marginBottom: {
          xs: "0px",
          sm: "0px",
          md: "30px",
          lg: "30px",
          xl: "30px",
        },
        marginTop: {
          xs: "0px",
          sm: "60px",
          md: "60px",
          lg: "60px",
          xl: "60px",
        },
      }}
    >
      <Grid container spacing={1}>
        <Grid
          item
          xs={12}
          sm={12}
          md={8}
          lg={6}
          xl={6}
          sx={{
            position: "relative",
            width: "100%",
          }}
        >
          <Box
            sx={{
              width: "55%",
              height: "95%",
              position: "relative",
              top: 0,
              left: 0,
            }}
          >
            <img
              src={
                process.env.PUBLIC_URL +
                "./assets/AboutPage/Storyimages/mission1.png"
              }
              alt="altimage"
              style={{ width: "100%", height: "100%" }}
            />
          </Box>
          <Box
            sx={{
              width: "60%",
              height: "77%",
              position: "absolute",
              bottom: 0,
              right: "20%",
              top: "20%",
              zIndex: 1,
            }}
          >
            <img
              src={mission_image}
              alt="..."
              style={{ width: "100%", height: "100%" }}
            />
            <Box className="sidebox">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "./assets/AboutPage/Storyimages/smallhandicon.png"
                }
                alt="..."
              />
            </Box>
          </Box>
        </Grid>
        <Grid
          item
          xs={12}
          sm={12}
          md={12}
          lg={6}
          xl={6}
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            textAlign: "justify",
          }}
        >
          <Typography
            variant="h3"
            sx={{
              fontSize: {
                xs: "24px",
                sm: "24px",
                md: "24px",
                lg: "24px",
                xl: "24px",
              },
              fontFamily: "var(--primary-font)",
              color: "var(--theme-color)",
              textAlign: "justify",
            }}
          >
            Our Mission
          </Typography>
          <Box style={DashHorizontalline}></Box>
          <Typography
            variant="body1"
            sx={{
              fontSize: {
                xs: "11px",
                sm: "12px",
                md: "13px",
                lg: "14px",
                xl: "14px",
              },
              fontFamily: "var(--primary-font)",
              color: "var(--black)",
              textAlign: "left",
            }}
            dangerouslySetInnerHTML={{ __html: mission_description }}
          ></Typography>
        </Grid>
      </Grid>
    </Container>
  );
};
