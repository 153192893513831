import React, { useState, useRef, useEffect } from "react";
import { Typography, Grid, Skeleton } from "@mui/material";
import { Box } from "@mui/system";
import { useParams } from "react-router-dom";
import { NavLink } from "react-router-dom";
import { FaFacebookF } from "react-icons/fa";
import { ImWhatsapp } from "react-icons/im";
import ReactPlayer from "react-player";
import Slider from "react-slick";
import VideocamIcon from "@mui/icons-material/Videocam";
import { useLocation } from "react-router-dom";
import { FaXTwitter } from "react-icons/fa6";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const ContentDataSection = () => {
  const { id } = useParams();

  const [nav1, setNav1] = useState(null);
  const [nav2, setNav2] = useState(null);
  const slider1Ref = useRef(null);
  const slider2Ref = useRef(null);

  useEffect(() => {
    setNav1(slider1Ref.current);
    setNav2(slider2Ref.current);
  }, []);

  const [play, setPlay] = useState(false);

  const location = useLocation();

  // Function to generate shareable URLs for social media platforms
  const generateShareUrl = () => {
    // Replace these placeholders with the actual URL and content you want to share
    const shareUrl = `${window.location.origin}${location.pathname}`;
    const shareTitle = "Check out this page"; // Replace with your page title
    const shareDescription = "Description of the shared content"; // Replace with your description

    return {
      facebook: `https://www.facebook.com/sharer/sharer.php?u=${shareUrl}`,
      twitter: `https://twitter.com/intent/tweet?text=${encodeURIComponent(
        shareTitle
      )}&url=${encodeURIComponent(shareUrl)}`,
      whatsapp: `https://api.whatsapp.com/send?text=${encodeURIComponent(
        `${shareTitle}\n${shareUrl}`
      )}`,
      youtubeUrl: "https://www.youtube.com/user/yourchannel", // Replace with the user's YouTube channel URL
      tiktokUrl: "https://www.tiktok.com/youraccount",
    };
  };

  const socialMediaUrls = generateShareUrl();

  const { getById } = useAPI();

  const getData = async () => {
    const res = await getById(endpoint.projects, id);
    return res.data;
  };

  const { isDataLoading, data: runningprojectsDetails } = useQuery({
    queryKey: ["runningprojectsDetails"],
    queryFn: getData,
  });

  if (isDataLoading)
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "flex-start",
      }}
    >
      <Skeleton variant="rectangular" width="100%" height="60vh" />
      <Skeleton variant="text" width="100%" height="20px" />
      <Skeleton variant="text" width="100%" height="20px" />
      <Skeleton variant="text" width="100%" height="20px" />
      <Skeleton variant="text" width="50%" height="20px" />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <Skeleton variant="rectangular" width="200px" height="30vh" />
        <Skeleton variant="rectangular" width="200px" height="30vh" />
        <Skeleton variant="rectangular" width="200px" height="30vh" />
        <Skeleton variant="rectangular" width="200px " height="30vh" />
      </Box>
    </Box>;

  const combinedArray = [];

  if (Array.isArray(runningprojectsDetails?.images)) {
    combinedArray.push(
      ...runningprojectsDetails?.images?.map((item) => ({
        ...item,
        type: "image",
      }))
    );
  }

  if (Array.isArray(runningprojectsDetails?.videos)) {
    combinedArray.push(
      ...runningprojectsDetails?.videos?.map((item) => ({
        ...item,
        type: "video",
      }))
    );
  }
  // const showMore = showMoreMap[runningprojectsDetails?.id] || false;

  const iconBoxStyle = {
    paddingTop: "10px",
    paddingBottom: "10px",

    //grey line on the right side of the icon
  };

  const [showMore, setShowMore] = useState(false);
  const toggleShowMore = () => setShowMore(!showMore);

  const truncatedDescription = runningprojectsDetails?.description?.slice(
    0,
    800
  );

  return (
    <Box
      sx={{
        marginTop: "20px",
      }}
    >
      {runningprojectsDetails && (
        <Box>
          <Slider asNavFor={nav2} ref={slider1Ref} arrows={false} fade={true}>
            {combinedArray.map((item) => {
              return (
                <Box
                  sx={{
                    width: "100%",
                    height: "50vh",
                  }}
                  onClick={() => {
                    setPlay(!play);
                  }}
                >
                  {item.type === "video" ? (
                    <ReactPlayer
                      url={item?.videos}
                      controls={true}
                      width="100%"
                      height="100%"
                      playing={play}
                    />
                  ) : (
                    <img
                      src={item?.images}
                      alt="projectimage"
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "cover",
                        objectPosition: "center",
                      }}
                    />
                  )}
                </Box>
              );
            })}
          </Slider>
          <Box
            sx={{
              marginTop: "30px",
            }}
          >
            <Typography
              sx={{
                textAlign: "justify",
                fontSize: {
                  xs: "15px",
                  sm: "15px",
                  md: "15px",
                  lg: "16px",
                  xl: "16px",
                },
                fontFamily: "var(--primary-font)",
                color: "var(--light-black)",
                // paddingBottom: "25px",
              }}
            >
              {showMore ? (
                <div
                  dangerouslySetInnerHTML={{
                    __html: runningprojectsDetails?.description,
                  }}
                />
              ) : (
                <div
                  dangerouslySetInnerHTML={{
                    __html: truncatedDescription + "...",
                  }}
                />
              )}
              {runningprojectsDetails?.description?.length > 800 && (
                <NavLink
                  style={{
                    color: "var(--theme-color)",
                    marginBottom: "30px",
                  }}
                  onClick={toggleShowMore}
                >
                  {showMore ? "Read less" : "Read more"}
                </NavLink>
              )}
            </Typography>
          </Box>
          <Box
            sx={{
              marginTop: "50px",
            }}
          >
            <Typography
              variant="h4"
              sx={{
                fontSize: {
                  xs: "19px",
                  sm: "20px",
                  md: "22px",
                  lg: "24px",
                  xl: "24px",
                },
                color: "var(--theme-color)",
                fontFamily: "var( --primary-font)",
                textAlign: "justify",
              }}
            >
              Some Glimpse of this project
            </Typography>
            <Box
              sx={{
                marginTop: "20px",
              }}
            >
              {/* <Grid container spacing={2}> */}
              <Slider
                asNavFor={nav1}
                ref={slider2Ref}
                slidesToShow={
                  combinedArray?.length > 3 ? 3 : combinedArray?.length
                }
                swipeToSlide={true}
                focusOnSelect={true}
                arrows={false}
              >
                {combinedArray.map((item, index) => {
                  return (
                    <Grid item xs={12} sm={12} md={12} lg={11} xl={11}>
                      <Box
                        sx={{
                          width: "100%",
                          height: "30vh",
                          position: "relative",
                        }}
                        key={index}
                      >
                        {item.type === "video" ? (
                          <Box>
                            <ReactPlayer
                              url={item?.videos}
                              controls={false}
                              height="30vh"
                              width="100%"
                              onClick={(e) => {
                                e.preventDefault();
                              }}
                            />
                            <Box
                              sx={{
                                backgroundColor: "var(--theme-color)",
                                padding: "0px 3px 3px 3px",
                                position: "absolute",
                                bottom: 0,
                                left: 0,
                                margin: "3px",
                                color: "#fff",
                                borderRadius: "50%",
                              }}
                            >
                              <VideocamIcon
                                sx={{
                                  height: "15px",
                                  width: "18px",
                                }}
                              />
                            </Box>
                          </Box>
                        ) : (
                          <img
                            src={item?.images}
                            alt="projectimage"
                            style={{
                              width: "100%",
                              height: "100%",
                              objectFit: "cover",
                              objectPosition: " center",
                            }}
                          />
                        )}
                      </Box>
                    </Grid>
                  );
                })}
              </Slider>
              {/* </Grid> */}
            </Box>
          </Box>
          <Box
            sx={{
              marginTop: "50px",
              display: "flex",
              flexDirection: {
                xs: "column",
                sm: "column",
                md: "row",
                lg: "row",
                xl: "row",
              },
              justifyContent: "flex-start",
              alignItems: "center",
              gap: "20px",
            }}
          >
            <Box>
              <Typography
                variant="body1"
                sx={{
                  fontSize: {
                    xs: "11px",
                    sm: "11px",
                    md: "13px",
                    lg: "15px",
                    xl: "18px",
                  },
                  color: "var(--black)",
                  fontFamily: "var( --primary-font)",
                  textAlign: "justify",
                  fontWeight: "500",
                }}
              >
                Share this story
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                alignItems: "flex-start",
                zIndex: "1",
                marginTop: {
                  xs: "10px",
                  sm: "10px",
                  md: "0",
                  lg: "0",
                  xl: "0",
                },
              }}
            >
              <ul
                style={{
                  listStyle: "none",
                  margin: "0",
                  padding: "0",
                  display: "flex",
                }}
                className="socialmediaicons"
              >
                <li className="socialmediaiconlist">
                  <a
                    href={socialMediaUrls.facebook}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mediaiconstags"
                  >
                    <FaFacebookF
                      style={iconBoxStyle}
                      size="40px"
                      className="icon"
                    />
                  </a>
                </li>
                <li className="socialmediaiconlist">
                  <a
                    href={socialMediaUrls.twitter}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mediaiconstags"
                  >
                    <FaXTwitter
                      style={iconBoxStyle}
                      size="40px"
                      className="icon"
                    />
                  </a>
                </li>
                <li className="socialmediaiconlist">
                  <a
                    href={socialMediaUrls.whatsapp}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="mediaiconstags"
                  >
                    <ImWhatsapp
                      style={iconBoxStyle}
                      size="40px"
                      className="icon"
                    />
                  </a>
                </li>
                {/* <li className="socialmediaiconlist">
              <a
                href={socialMediaUrls.youtube}
                target="_blank"
                rel="noopener noreferrer"
                className="mediaiconstags"
              >
                <FaYoutube style={iconBoxStyle} size="40px" className="icon" />
              </a>
            </li>
            <li className="socialmediaiconlist">
              <a
                href={socialMediaUrls.tiktok}
                target="_blank"
                rel="noopener noreferrer"
                className="mediaiconstags"
              >
                <FaTiktok style={iconBoxStyle} size="40px" className="icon" />
              </a>
            </li> */}
              </ul>
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
