import React, { useEffect, useState } from "react";
import "../../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import { NavLink } from "react-router-dom";
import "./Navbar.css";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import LocalMallOutlinedIcon from "@mui/icons-material/LocalMallOutlined";
import { Box } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { FaFacebookF, FaTiktok, FaYoutube } from "react-icons/fa";
import { ImWhatsapp } from "react-icons/im";
import { FaXTwitter } from "react-icons/fa6";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";
import { useCookies } from "react-cookie";

export const Navbar = () => {
  const [activeNavItem, setActiveNavItem] = React.useState("");

  const [token, setToken] = useState(""); // Initialize the token state
  const [id, setId] = useState(""); // Initialize the token state
  const [cookies] = useCookies(["userId", "token"]);

  useEffect(() => {
    // Check if the token is available in cookies
    if (cookies.token) {
      setToken(cookies.token);
      setId(cookies.userId);
      // You can also perform other actions that depend on the token here
    }
  }, [cookies.token]);

  const { getCartById, get } = useAPI();
  const getNavbarData = async () => {
    const res = await get(endpoint.navbar);
    return res.data;
  };

  const { data: navbarData } = useQuery({
    queryKey: ["navbarData"],
    queryFn: getNavbarData,
  });

  const getDharmaGoodCartData = async () => {
    const res = await getCartById(endpoint.getgoodsbyId);
    return res.data;
  };

  const { data: dharmagoodcart } = useQuery({
    queryKey: ["dharmagoodcart"],
    queryFn: getDharmaGoodCartData,
    enabled: !!token,
  });

  const getGoodDharamaCartData = async () => {
    const res = await getCartById(endpoint.all_good_dharma);
    return res.data;
  };

  const { data: gooddharmacart } = useQuery({
    queryKey: ["gooddharmacart"],
    queryFn: getGoodDharamaCartData,
    enabled: !!token,
  });

  const cartitems =
    (gooddharmacart?.length || 0) + (dharmagoodcart?.length || 0);

  return (
    <Box
      sx={{
        backgroundColor: "var(--white)",
        width: "100%",
        height: "70px",
        display: {
          xs: "flex",
          sm: "flex",
          md: "flex",
          lg: "flex",
          xl: "flex",
        },
        justifyContent: {
          xs: "flex-end",
          sm: "flex-end",
          md: "flex-end",
          lg: "flex-end",
          xl: "flex-end",
        },
        fontSize: "15px",
        fontWeight: "500",
        textAlign: "center",
        marginTop: "10px",
        marginRight: {
          xs: "0px",
          sm: "0px",
          md: "0px",
          lg: "80px",
          xl: "100px",
        },
        marginLeft: "20px",
      }}
    >
      <nav
        className="navbar navbar-expand-lg navbar-dark navbar-style"
        style={{
          width: "fit-content",
        }}
      >
        <div
          className="container-fluid"
          style={{
            marginRight: "0",
            marginLeft: "auto",
            width: "fit-content",
            justifyContent: "flex-end",
            alignItems: "center",
            backgroundColor: "var(--white)",
          }}
        >
          {token && id ? (
            <Box
              sx={{
                display: {
                  xs: "flex",
                  sm: "flex",
                  md: "none",
                  lg: "none",
                  xl: "none",
                },
                position: "relative",
                flexDirection: "row",
                justifyContent: "flex-end",
                alignItems: "center",
              }}
            >
              <li
                className="nav-item"
                style={{
                  listStyle: "none",
                }}
              >
                <NavLink
                  to="/wishlist"
                  style={{
                    color: "var(--light-black)",
                    textDecoration: "none",
                    "&:hover": {
                      color: "var(--theme-color)",
                    },
                  }}
                >
                  <FavoriteBorderIcon />
                </NavLink>
              </li>
              <li
                className="nav-item"
                style={{
                  listStyle: "none",
                  marginRight: "10px",
                }}
              >
                <NavLink
                  to="/cart"
                  style={{
                    color: "var(--light-black)",
                    textDecoration: "none",
                    "&:hover": {
                      color: "var(--theme-color)",
                    },
                  }}
                >
                  <div sx={{ position: "relative" }}>
                    <LocalMallOutlinedIcon />
                    <div
                      style={{
                        position: "absolute",
                        top: "-10px",
                        // right: "0px",
                        left: "90px",
                        backgroundColor: "var(--theme-color)",
                        borderRadius: "50%",
                        padding: "1px 6px 2px 6px",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        fontSize: "12px",
                        fontWeight: "bold",
                        color: "white",
                        fontFamily: "var(--primary-font)",
                      }}
                    >
                      {cartitems}
                    </div>
                  </div>
                </NavLink>
              </li>
            </Box>
          ) : (
            ""
          )}

          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarNav"
            aria-controls="navbarNav"
            aria-expanded="false"
            aria-label="Toggle navigation"
            // style={{ backgroundColor: "#96195a" }}
          >
            <MenuIcon sx={{ color: "var(--theme-color)" }} fontSize="large" />
          </button>
          <div className="collapse navbar-collapse" id="navbarNav">
            <ul className="navbar-nav">
              {navbarData
                ?.filter(
                  (item) => item.is_hidden !== 1 && item.is_hidden === null
                )
                .map((item, index) => (
                  <li className="nav-item" key={index}>
                    <NavLink
                      to={item?.slug}
                      className={`nav-NavLink ${
                        activeNavItem === item?.slug ? "active" : ""
                      }`}
                      isActive={() => setActiveNavItem(item?.slug)}
                      style={{
                        whiteSpace: "nowrap",
                      }}
                    >
                      {item?.navbar_name}
                    </NavLink>
                  </li>
                ))}
              {token && id ? (
                <Box
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "flex",
                      lg: "flex",
                      xl: "flex",
                    },
                    position: "relative",
                  }}
                >
                  <li className="nav-item">
                    <NavLink
                      to="/wishlist"
                      style={{
                        color: "var(--light-black)",
                        textDecoration: "none",
                        "&:hover": {
                          color: "var(--theme-color)",
                        },
                      }}
                    >
                      <FavoriteBorderIcon />
                    </NavLink>
                  </li>
                  <li className="nav-item">
                    <NavLink
                      to="/cart"
                      style={{
                        color: "var(--light-black)",
                        textDecoration: "none",
                        "&:hover": {
                          color: "var(--theme-color)",
                        },
                      }}
                    >
                      <div sx={{ position: "relative" }}>
                        <LocalMallOutlinedIcon />
                        <div
                          style={{
                            position: "absolute",
                            top: "-10px",
                            // right: "0px",
                            left: "90px",
                            backgroundColor: "var(--theme-color)",
                            borderRadius: "50%",
                            padding: "1px 6px 2px 6px",
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            fontSize: "12px",
                            fontWeight: "bold",
                            color: "white",
                            fontFamily: "var(--primary-font)",
                          }}
                        >
                          {cartitems}
                        </div>
                      </div>
                    </NavLink>
                  </li>
                </Box>
              ) : (
                ""
              )}

              <Box
                sx={{
                  height: "30px",
                  justifyContent: "space-evenly",
                  fontWeight: "500",
                  display: {
                    xs: "flex",
                    sm: "flex",
                    md: "none",
                    lg: "none",
                    xl: "none",
                  },
                  alignItems: "center",
                  // justifyContent: "center",
                  marginTop: "30px",
                  width: "100%",
                  zIndex: "100",
                  paddingBottom: "40px",
                }}
              >
                <Box
                  sx={{
                    color: "var(--white)",
                    padding: "5px",
                    backgroundColor: "var(--theme-color)",
                    width: "25%",
                  }}
                  className="icongrp facebook-icon"
                >
                  <FaFacebookF className="socialmedia-icon" size="20px" />
                </Box>
                <Box
                  sx={{
                    color: "var(--white)",
                    padding: "5px",
                    backgroundColor: "var(--theme-color)",
                    width: "15%",
                  }}
                  className="icongrp twitter-icon"
                >
                  <FaXTwitter className="socialmedia-icon" size="20px" />
                </Box>
                <Box
                  sx={{
                    color: "var(--white)",
                    padding: "5px",
                    backgroundColor: "var(--theme-color)",
                    width: "15%",
                  }}
                  className="icongrp whatsapp-icon"
                >
                  <ImWhatsapp className="socialmedia-icon" size="20px" />
                </Box>
                <Box
                  sx={{
                    color: "var(--white)",
                    padding: "5px",
                    backgroundColor: "var(--theme-color)",
                    width: "15%",
                  }}
                  className="icongrp youtube-icon"
                >
                  <FaYoutube className="socialmedia-icon" size="20px" />
                </Box>
                <Box
                  sx={{
                    color: "var(--white)",
                    padding: "5px",
                    backgroundColor: "var(--theme-color)",
                    width: "25%",
                  }}
                  className="icongrp tiktok-icon"
                >
                  <FaTiktok className="socialmedia-icon" size="20px" />
                </Box>
              </Box>
            </ul>
          </div>
        </div>
      </nav>
    </Box>
  );
};
