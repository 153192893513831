import React from "react";
import { Box } from "@mui/system";
import { Container, Grid, Typography } from "@mui/material";
import { useState } from "react";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const StoryBottom = ({
  left_image,
  left_title,
  left_description,
  center_image,
  right_image,
  right_title,
  right_description,
  watermark,
}) => {
  const [displaylogo, setDisplaylogo] = useState(watermark);

  const leftData = [
    {
      imgUrl: left_image,
      title: left_title,
      desc: left_description,
    },
  ];

  const rightData = [
    {
      imgUrl: right_image,
      title: right_title,
      desc: right_description,
    },
  ];

  const mergedItems = leftData.concat(rightData);

  const { get } = useAPI();
  const getData = async () => {
    const res = await get(endpoint.sitesettings);
    return res.data;
  };

  const { data: siteSettings } = useQuery({
    queryKey: ["sitesettingsData"],
    queryFn: getData,
  });
  return (
    <Box
      className="images-cont"
      sx={{
        marginTop: "80px",
        marginBottom: "30px",
        backgroundPosition: "center",
        backgroundRepeat: "no-repeat",
        width: "100%",
      }}
    >
      <Container>
        <Grid
          container
          xs={12}
          spacing={2}
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
            position: "relative",
            top: {
              xs: "-50px",
              sm: "-60px",
              md: "70px",
              lg: "80px",
              xl: "50px",
            },
          }}
        >
          {mergedItems.map((item, index) => (
            <Grid
              item
              xs={5}
              sm={5}
              md={2}
              lg={2}
              xl={2}
              sx={{
                display: "flex",
                flexDirection: {
                  xs: "column",
                  sm: "column",
                  md: "row",
                  lg: "row",
                  xl: "row",
                },
                justifyContent: "space-between",
                alignItems: "center",
                position: "relative",
              }}
              key={index}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                  justifyContent: "center",
                  alignItems: "center",
                  position: "relative",
                }}
              >
                <Box
                  sx={{
                    width: {
                      xs: "100%",
                      sm: "90%",
                      md: "90%",
                      lg: "100%",
                      xl: "100%",
                    },
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                    flexDirection: "column",
                    textAlign: "center",
                  }}
                >
                  <img
                    src={item.imgUrl}
                    alt="..."
                    style={{
                      height: "auto",
                      width: "80%",
                      objectFit: "cover",
                      objectPosition: "center",
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  />
                  <Box
                    sx={{
                      textAlign: "center",
                      marginBottom: "20px",
                    }}
                  >
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: {
                          xs: "13px",
                          sm: "14px",
                          md: "14px",
                          lg: "16px",
                          xl: "16px",
                        },
                        color: "var(--white)",
                        fontFamily: "var(--primary-font)",
                      }}
                    >
                      {item.title}
                    </Typography>
                    <br />
                    <Typography
                      variant="body1"
                      sx={{
                        fontSize: {
                          xs: "11px",
                          sm: "13px",
                          md: "13px",
                          lg: "14px",
                          xl: "14px",
                        },
                        color: "var(--white)",
                        fontFamily: "var(--primary-font)",
                      }}
                    >
                      {item.desc}
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Grid>
          ))}

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={9}
            xl={9}
            className="center-image-cont"
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
                xl: "block",
              },
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              position: "absolute",
              top: {
                xs: "300px",
                sm: "95%",
                md: "-80px",
                lg: "-90px",
                xl: "-90px",
              },
              left: {
                xs: "90px",
                sm: "30%",
                md: "25%",
                lg: "20%",
                xl: "25%",
              },
              width: {
                xs: "60%",
                sm: "50%",
                md: "60%",
                lg: "50%",
                xl: "50%",
              },
            }}
          >
            <img
              src={center_image}
              alt="..."
              style={{
                position: "relative",
                overflow: "hidden",
              }}
              className="bottom-image"
            />

            {displaylogo === 1 ? (
              <Box
                sx={{
                  position: "absolute",
                  zIndex: "100",
                  top: {
                    xs: "70%",
                    sm: "55%",
                    md: "55%",
                    lg: "45%",
                    xl: "45%",
                  },
                  left: {
                    xs: "25%",
                    sm: "30%",
                    md: "33%",
                    lg: "35%",
                    xl: "35%",
                  },
                }}
              >
                <img
                  src={siteSettings?.logo}
                  alt="..."
                  style={{}}
                  className="top-image"
                />
              </Box>
            ) : (
              ""
            )}
          </Grid>

          <Grid
            xs={12}
            sm={12}
            md={0}
            lg={0}
            xl={0}
            sx={{
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
                lg: "none",
                xl: "none",
              },
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
              width: {
                xs: "40%",
                sm: "40%",
                md: "50%",
                lg: "0%",
                xl: "0%",
              },
              marginLeft: "20px",
              marginTop: "50px",
            }}
          >
            <img
              src={siteSettings?.imgUrl}
              alt="..."
              style={{
                position: "relative",
                width: "100%",
                height: "auto",
              }}
            />

            <Box
              sx={{
                position: "absolute",
                zIndex: "100",
                top: {
                  xs: "75%",
                  sm: "70%",
                  md: "0",
                  lg: "0",
                  xl: "0",
                },
                left: {
                  xs: "25%",
                  sm: "35%",
                  md: "0",
                  lg: "0",
                  xl: "0",
                },
              }}
            >
              {displaylogo ? (
                <img
                  src={siteSettings?.logo}
                  alt="..."
                  style={{
                    position: "absolute",
                    zIndex: "100",
                    top: "45%",
                    left: "35%",
                  }}
                  className="top-image"
                />
              ) : (
                ""
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};
