import React from "react";
import { Box } from "@mui/system";
import { Typography } from "@mui/material";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import { AccountTable } from "./AccountTable";
import moneybag from "../../assets/moneybag.png";
import useAPI from "../../../hooks/useApi";
import { endpoint } from "../../../constant/endpoint";
import { useQuery } from "@tanstack/react-query";

export const MyAccount = () => {
  const { getOrderData } = useAPI();

  const getOrdersData = async () => {
    const response = await getOrderData(endpoint.ordertotal);
    return response.data;
  };

  const { isLoading, data: totalorders } = useQuery({
    queryKey: ["totalorders"],
    queryFn: getOrdersData,
  });


  if (isLoading) return <div>Loading...</div>;

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: {
            xs: "column",
            sm: "row",
            md: "row",
            lg: "row",
            xl: "row",
          },
          alignItems: "center",
          gap: "30px",
          marginTop: "50px",
        }}
      >
        <Box
          sx={{
            backgroundColor: "var( --title-backgrd-color)",
            padding: "20px 30px 20px 30px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            borderRadius: "5px",
          }}
        >
          <Box
            sx={{
              backgroundColor: "var(--white)",
              padding: "5px",
              borderRadius: "50%",
            }}
          >
            <SyncAltIcon fontSize="medium" />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  lg: "18px",
                  xl: "18px",
                },
                fontWeight: "500",
                color: "var(--light-black)",
                fontFamily: "var(--primary-font)",
              }}
            >
              Total Orders
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  lg: "18px",
                  xl: "18px",
                },
                fontWeight: "500",
                color: "var(--light-black)",
                fontFamily: "var(--primary-font)",
              }}
            >
              {totalorders?.totalOrder}
            </Typography>
          </Box>
        </Box>
        <Box
          sx={{
            backgroundColor: "var( --title-backgrd-color)",
            padding: "20px 30px 20px 30px",
            display: "flex",
            flexDirection: "row",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
            borderRadius: "5px",
          }}
        >
          <Box
            sx={{
              backgroundColor: "var(--white)",
              padding: "5px",
              borderRadius: "50%",
              width: "35px",
            }}
          >
            <img
              src={moneybag}
              alt="..."
              style={{
                width: "15px",
                height: "15px",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "flex-start",
            }}
          >
            <Typography
              variant="body1"
              sx={{
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  lg: "18px",
                  xl: "18px",
                },
                fontWeight: "500",
                color: "var(--light-black)",
                fontFamily: "var(--primary-font)",
              }}
            >
              Total Payment
            </Typography>
            <Typography
              variant="body1"
              sx={{
                fontSize: {
                  xs: "14px",
                  sm: "15px",
                  md: "16px",
                  lg: "18px",
                  xl: "18px",
                },
                fontWeight: "500",
                color: "var(--light-black)",
                fontFamily: "var(--primary-font)",
              }}
            >
              {totalorders?.totalPayment}
            </Typography>
          </Box>
        </Box>
      </Box>
      <Box>
        <AccountTable />
      </Box>
    </>
  );
};
